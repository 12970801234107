import {
  Menu,
  MenuItem,
  Theme,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import ButtonV2 from 'components/library/ButtonV2';
import { useState, MouseEvent, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const useStyles = makeStyles((theme: Theme) => ({
  buttonPressed: {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
  },
  list: {
    padding: 0,
  },
  option: {
    color: theme.palette.primary.main,
    fontSize: 12,
  },
  textOptions: {
    textTransform: 'uppercase',
  },
}));

type Props = {
  options: string[];
  onClick: (optionsSelected: Options) => void;
  disabled?: boolean;
};

type Options = {
  [key: string]: boolean;
};

const MultiCheckBoxButton = ({ disabled, onClick, options = [] }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [optionsSelected, setOptionsSelected] = useState<Options>({
    all: true,
  });

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClicked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valuesCopy = { ...optionsSelected };
    const keyClicked = event.target.name;
    const valueClicked = event.target.checked;

    if (keyClicked === 'all') {
      const otherKeys = Object.keys(valuesCopy).filter(
        ([key]) => key !== 'all'
      );
      const deactivateOtherOptions: Options = {};
      for (const key of otherKeys) {
        deactivateOtherOptions[key] = false;
      }
      setOptionsSelected({
        ...deactivateOtherOptions,
        all: true,
      });
      return;
    }

    const currentOptions = {
      ...valuesCopy,
      all: false,
      [keyClicked]: valueClicked,
    };

    setOptionsSelected(currentOptions);

    const optionsToConsider = Object.keys(currentOptions).filter(
      (key) => key !== 'all'
    );

    const isFalse = (key: string) => {
      return currentOptions[key] === false;
    };

    const isOptionSelected = !optionsToConsider.every(isFalse);
    if (!isOptionSelected) {
      setOptionsSelected({
        all: true,
      });
      return;
    }
  };

  useEffect(() => {
    onClick(optionsSelected);
  }, [optionsSelected]);

  const getValuesSelected = () => {
    const selectedFilters = Object.keys(optionsSelected).filter(
      (k) => optionsSelected[k]
    );

    return selectedFilters.join(',  ');
  };

  return (
    <div>
      <ButtonV2
        data-testid="multiCheckBoxButton"
        id="multiCheckBox-button"
        aria-controls={isMenuOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={handleClick}
        endIcon={
          isMenuOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
        }
        variant="outlined"
        className={clsx({ [classes.buttonPressed]: isMenuOpen })}
        disableRipple
        disabled={disabled}
      >
        <Typography className={classes.textOptions}>
          {getValuesSelected()}
        </Typography>
      </ButtonV2>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        classes={{ list: classes.list }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem className={classes.option} divider>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                checked={!!optionsSelected['all']}
                onChange={handleOptionClicked}
                name="all"
              />
            }
            label="All"
          />
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option} className={classes.option} divider>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!optionsSelected[option.toLocaleLowerCase()]}
                  onChange={handleOptionClicked}
                  name={option.toLocaleLowerCase()}
                  data-testid={`item-${option}`}
                />
              }
              label={option}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MultiCheckBoxButton;
