import { useEffect } from 'react';

import { useLDClient } from 'launchdarkly-react-client-sdk';

import useCurrentUser from './useCurrentUser';

const useLaunchDarklyIdentify = () => {
  const ldClient = useLDClient();
  const { data: currentUser } = useCurrentUser();
  const isLoggedIn = !!currentUser;

  useEffect(() => {
    if (ldClient && isLoggedIn) {
      const { email, firstName, lastName } = currentUser;

      // track LD user by default.
      ldClient.identify({
        key: email,
        email,
        firstName,
        lastName,
      });
    }
  }, [ldClient, isLoggedIn]);
};

export default useLaunchDarklyIdentify;
