import { Dialog, IconButton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { ReactNode } from 'react';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  children: ReactNode;
  width?: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const Modal = ({ isOpen, handleClose, children, width }: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      fullWidth
      PaperProps={{ style: { borderRadius: 8, width } }}
    >
      <IconButton
        aria-label="close"
        data-testid="icon-closeShipmentByCarrier"
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      {children}
    </Dialog>
  );
};

export default Modal;
