import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Theme,
  Grid,
} from '@mui/material';
import { MyLocation } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { HeadquarterDetail } from 'shipment/api';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 24,
    fontWeight: 700,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  icon: {
    fontSize: 24,
  },
  subtitle: {
    fontWeight: 700,
    paddingBottom: theme.spacing(0.5),
  },
  address: {
    fontSize: 16,
  },
  box: {
    borderRadius: 8,
    backgroundColor: '#F7FAFD',
    padding: theme.spacing(2),
  },
  content: {
    padding: '0 24px 24px',
    '& > *:not(:first-child)': {
      marginTop: 20,
    },
  },
}));

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  headquarter: HeadquarterDetail;
};

const HQDetailsModal = ({ isOpen, handleClose, headquarter }: Props) => {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Typography className={classes.title}>Headquarters</Typography>
        <IconButton
          aria-label="close"
          data-testid="icon-closeHQModal"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container className={classes.box}>
          <Grid item xs={1} className={classes.icon}>
            <MyLocation color="primary" fontSize="inherit" />
          </Grid>
          <Grid item xs={11}>
            <Typography className={classes.subtitle}>
              Carrier Headquarters
            </Typography>
            <Typography className={classes.address}>
              {headquarter?.address1 ?? ''},
            </Typography>
            <Typography className={classes.address}>
              {headquarter?.city ?? ''}, {headquarter?.region ?? ''}{' '}
              {headquarter?.postalCode ?? ''}
            </Typography>
            <Typography className={classes.address}>
              {headquarter?.country ?? ''}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default HQDetailsModal;
