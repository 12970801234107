import { RouteProps } from 'react-router-dom';
import { useEffect } from 'react';
import Loader from 'components/Loader';
import SentryRoute from './SentryRoute';
import useCurrentUser from 'hooks/useCurrentUser';
import { useUserSession } from '@reibus/frontend-utility';

export const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const {
    isLoading,
    isSuccess: isLoggedIn,
    isFetching: isRetrievingUser,
  } = useCurrentUser();
  const {
    data: userSession,
    isLoading: isUserSessionLoading,
    isFetching: isRetrievingUserSession,
    failureCount,
  } = useUserSession();

  const loggedInCurrentUser = !isLoading && isLoggedIn;
  const loggedInUserSession = !isUserSessionLoading && userSession;
  const isLoggingIn =
    isRetrievingUser || (isRetrievingUserSession && failureCount < 2);

  useEffect(() => {
    if (!loggedInCurrentUser && !loggedInUserSession && !isLoggingIn) {
      if (window.location.pathname !== `/login`) {
        location.assign(`/login/?ref=/logistics${rest.location?.pathname}`);
      }
    }
  }, [loggedInCurrentUser, loggedInUserSession, isLoggingIn]);

  return (
    <SentryRoute
      {...rest}
      render={() => (
        // @ts-ignore
        <Loader hasLoaded={!isLoading}>{isLoggedIn ? children : null}</Loader>
      )}
    />
  );
};
