export function Check() {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.84 22.1597V23.9997C44.8375 28.3126 43.441 32.5091 40.8587 35.9634C38.2763 39.4177 34.6466 41.9447 30.5107 43.1675C26.3749 44.3904 21.9545 44.2435 17.9089 42.7489C13.8634 41.2543 10.4093 38.4919 8.06192 34.8739C5.71453 31.2558 4.59958 26.9759 4.88335 22.6724C5.16712 18.3689 6.83441 14.2724 9.63656 10.9939C12.4387 7.71537 16.2256 5.43049 20.4324 4.48002C24.6392 3.52955 29.0405 3.9644 32.98 5.71973"
        stroke="#0D908D"
        strokeWidth="3.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.84 8L24.84 28.02L18.84 22.02"
        stroke="#0D908D"
        strokeWidth="3.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
