import { useEffect } from 'react';
import mixpanelBrowser from 'mixpanel-browser';
import { KeyAndUntypedValue } from 'api/types';
import { mixpanelFake } from './mixpanelFake';

const apiToken = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN || '';

if (!apiToken) {
  console.error('No Mixpanel API token found.  Please verify .env file used.');
}

const debug = process.env.NODE_ENV !== 'production';

export const isMockMixpanel = !apiToken;
const mixpanel = isMockMixpanel ? mixpanelFake : mixpanelBrowser;

mixpanel.init(apiToken, { debug });

export function usePageView(pageName: string, properties?: KeyAndUntypedValue) {
  useEffect(() => {
    mixpanel.track(pageName, properties);
  }, []);
}

export function trackEvent(eventTag: string, properties?: KeyAndUntypedValue) {
  mixpanel.track(`Event: ${eventTag}`, properties);
}

export default mixpanel;
