import { useRef } from 'react';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import Button from 'components/SteelUI/atoms/Button';
import { Body } from 'components/SteelUI/atoms/typography';

import Location from 'request-for-quote/v2/form/Location';
import { useState } from 'react';
import Loader from 'components/Loader';

import { useFreightEstimate } from 'hooks/useFreightEstimate';

import { trackEvent } from 'utils/mixpanel';

import type { PlacePredictionFn } from 'request-for-quote/v2/RequestForQuoteV2';
import type { AddressBreakdown } from 'request-for-quote/v2/form/AddressFieldWithPrefill';

import { EquipmentSingleSelect } from 'request-for-quote/v2/form/Equipment';
import { PickUpDateInput } from 'request-for-quote/v2/form/Date';
import FormSection from 'request-for-quote/v2/form/FormSection';
import NoQuoteModal from './NoQuoteModal';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    boxShadow: '0px 0px 4px 0px rgba(33, 34, 36, 0.10)',
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  },
  button: {
    padding: '12px 32px',
    lineHeight: '125%',
  },
  link: {
    color: '#2D6CCB',
    textDecoration: 'none',
  },
  twoDays: {
    marginLeft: '16px',
    marginRight: '16px',
  },
});

type QuickFreightQuoteFormProps = {
  isLoading: boolean;
  initialFormData: Record<string, any>;
  placeFetchers: PlacePredictionFn[];
  places: google.maps.places.AutocompletePrediction[];
  placesServiceInstance: google.maps.places.PlacesService | null;
};

const QuickFreightQuoteForm = ({
  isLoading,
  initialFormData,
  placeFetchers,
  places,
  placesServiceInstance,
}: QuickFreightQuoteFormProps) => {
  const classes = useStyles();

  const history = useHistory();

  const [originAddressBreakdown, setOriginAddressBreakdown] =
    useState<AddressBreakdown>();
  const [destinationAddressBreakdown, setDestinationAddressBreakdown] =
    useState<AddressBreakdown>();
  const [noQuoteModalOpen, setNoQuoteModalOpen] = useState(false);

  const submittedValuesRef = useRef<Record<string, string> | null>(null);

  const { mutate: getFreightEstimate, isLoading: isSubmitting } =
    useFreightEstimate({
      onSuccess: (freightEstimate) => {
        trackEvent('Quick Freight Quote', {
          equipment: submittedValuesRef.current?.equipment,
          pickupDate: submittedValuesRef.current?.pickupDate,
          originAddress: freightEstimate.transformedOriginAddress,
          destinationAddress: freightEstimate.transformedDestinationAddress,
          estimate: freightEstimate.estimate,
          confidenceLevel: freightEstimate.confidenceLevel,
        });

        if (freightEstimate.estimate && submittedValuesRef.current?.equipment) {
          history.push({
            pathname: `/quote-details`,
            state: {
              quickForm: true,
              ...submittedValuesRef.current,
              equipment: [submittedValuesRef.current.equipment],
              priceEstimate: freightEstimate.estimate,
              destinationAddress: {
                ...freightEstimate?.transformedDestinationAddress,
                location: {
                  lat:
                    destinationAddressBreakdown?.geometry?.location?.lat() ?? 0,
                  lon:
                    destinationAddressBreakdown?.geometry?.location?.lng() ?? 0,
                },
              },
              originAddress: {
                ...freightEstimate?.transformedOriginAddress,
                location: {
                  lat: originAddressBreakdown?.geometry?.location?.lat() ?? 0,
                  lon: originAddressBreakdown?.geometry?.location?.lng() ?? 0,
                },
              },
            },
          });
        } else {
          setNoQuoteModalOpen(true);
        }
      },
      onError: () => {
        setNoQuoteModalOpen(true);
      },
    });

  const handleSubmit = async (values: Record<string, any>) => {
    submittedValuesRef.current = values;
    getFreightEstimate({
      equipment: values.equipment,
      pickupDate: values.pickupDate,
      originAddressBreakdown,
      destinationAddressBreakdown,
      originAddress: values.originAddress,
      destinationAddress: values.destinationAddress,
    });
  };

  return (
    <Loader hasLoaded={!isLoading}>
      <NoQuoteModal
        open={noQuoteModalOpen}
        handleClose={() => setNoQuoteModalOpen(false)}
        submittedValues={submittedValuesRef.current}
      />
      <div className={classes.root}>
        <Body size="large">
          Email us at{' '}
          <a className={classes.link} href="mailto:shippersupport@reibus.com">
            shippersupport@reibus.com
          </a>{' '}
          or give us a call at{' '}
          <a className={classes.link} href="tel:+18888580648">
            (888) 858-0648
          </a>
          , if you need assistance or have any questions.
        </Body>

        <FinalForm initialValues={initialFormData} onSubmit={handleSubmit}>
          {({ handleSubmit }) => (
            <FormSpy
              subscription={{
                values: true,
                hasValidationErrors: true,
                errors: true,
              }}
            >
              {({ hasValidationErrors }) => {
                return (
                  <form onSubmit={handleSubmit} noValidate>
                    <Box width={'824px'}>
                      <Location
                        placeFetchers={placeFetchers}
                        places={places}
                        placesServiceInstance={placesServiceInstance}
                        setOriginAddressBreakdown={setOriginAddressBreakdown}
                        setDestinationAddressBreakdown={
                          setDestinationAddressBreakdown
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                      }}
                    >
                      <Box width={'400px'}>
                        <FormSection title="Equipment">
                          <EquipmentSingleSelect quickForm={true} />
                        </FormSection>
                      </Box>
                      <Box width={'280px'}>
                        <FormSection title="Ship Date">
                          <div>
                            <PickUpDateInput />
                            <Body size="small" className={classes.twoDays}>
                              *If no date is provided, we’ll assume a pickup
                              date 2 days from today
                            </Body>
                          </div>
                        </FormSection>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                      }}
                    >
                      <Button
                        variant="contained"
                        type="submit"
                        className={classes.button}
                        disabled={
                          hasValidationErrors || isLoading || isSubmitting
                        }
                      >
                        Submit
                      </Button>
                    </Box>
                  </form>
                );
              }}
            </FormSpy>
          )}
        </FinalForm>
      </div>
    </Loader>
  );
};

export default QuickFreightQuoteForm;
