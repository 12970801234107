import { Dialog, IconButton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import Error from 'features/cse/disposition/disposition-modal/Error';
import CoverShipment from 'features/cse/disposition/disposition-modal/flows/cover-shipment';
import DeclineShipment from 'features/cse/disposition/disposition-modal/flows/decline-shipment';
import Other from 'features/cse/disposition/disposition-modal/flows/other';
import { useDispositionContext } from 'features/cse/disposition/contexts/DispositionContext';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    width: 560,
  },
}));

const DispositionModal = () => {
  const classes = useStyles();
  const { dispositionFlow, closeDispositionModal } = useDispositionContext();

  const dispositionFlowMap = {
    COVER: <CoverShipment />,
    DECLINE: <DeclineShipment />,
    OTHER: <Other />,
    ERROR: <Error tryAgain={() => closeDispositionModal('CANCEL')} />,
  };

  return !dispositionFlow ? null : (
    <Dialog open={true} maxWidth={false}>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={() => closeDispositionModal('CANCEL')}
      >
        <CloseIcon />
      </IconButton>
      <div className={classes.content}>
        {dispositionFlowMap[dispositionFlow]}
      </div>
    </Dialog>
  );
};

export default DispositionModal;
