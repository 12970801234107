import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Theme,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import EquipmentDetailsRoute from './components/EquipmentDetailsRoute';
import EquipmentDetailsTable from './components/EquipmentDetailsTable';
import EquipmentDetailsComments from './components/EquipmentDetailsComments';
import { EquipmentDetails, getEquipmentDetails } from './api';
import { useQuery } from 'react-query';
import EquipmentDetailsError from './components/EquipmentDetailsError';
import { useAuthorizationToken } from '@reibus/frontend-utility';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 24,
    fontWeight: 700,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  table: {
    width: 1000,
    border: `1px solid ${theme.palette.grey[100]}`,
    marginBottom: theme.spacing(3),
  },
  tableHead: {
    background: theme.palette.grey[100],
  },
  columns: {
    fontWeight: 600,
    width: 125,
  },
  subtitle: {
    color: theme.palette.grey[700],
  },
  content: {
    padding: '0 24px 24px',
    '& > *:not(:first-child)': {
      marginTop: 20,
    },
  },
}));

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  equipmentID: string;
  loadboard: 'DAT' | 'REIBUS' | 'ITS';
};

const EquipmentDetailsModal = ({
  isOpen,
  handleClose,
  loadboard,
  equipmentID,
}: Props) => {
  const classes = useStyles();
  const authToken = useAuthorizationToken();

  const {
    data: details = {} as EquipmentDetails,
    isLoading,
    isError,
  } = useQuery(
    ['equipmentDetails', equipmentID],
    () => getEquipmentDetails(authToken, equipmentID),
    {
      enabled: isOpen && !!authToken,
    }
  );

  const loadboardLabel = loadboard === 'REIBUS' ? 'REQ' : loadboard;

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography className={classes.title}>{loadboardLabel}</Typography>
        <IconButton
          aria-label="close"
          data-testid="icon-closeShipmentByCarrier"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {isLoading ? (
          <CircularProgress />
        ) : !isError ? (
          <>
            <EquipmentDetailsRoute {...details} />
            <EquipmentDetailsTable {...details} loadboard={loadboard} />
            <EquipmentDetailsComments {...details} />
          </>
        ) : (
          <EquipmentDetailsError handleClose={handleClose} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EquipmentDetailsModal;
