import { Stack } from '@mui/material';
import Tooltip from 'components/SteelUI/atoms/Tooltip';
import { GlobalRouteStop } from 'search-shipments/search-shipments-form/utils';

type Props = {
  endLocationLabel: string;
  globalRoute: GlobalRouteStop[];
};

const MultiStopTooltip = ({ endLocationLabel, globalRoute }: Props) => {
  const title =
    globalRoute.length > 2 ? (
      <Stack direction="column" alignItems="center">
        <div>{`This shipment has ${globalRoute.length} stops:`}</div>
        {globalRoute.map((stop) => (
          <div key={`multistoptooltip-${stop.id}`}>
            {`${stop.address.locationLabel}${
              stop.address.zip ? `, ${stop.address.zip}` : ''
            }`}
          </div>
        ))}
      </Stack>
    ) : null;
  const label =
    globalRoute.length > 2 ? (
      <Stack direction="row" spacing={1}>
        <div>{endLocationLabel}</div>
        <div>
          <b>({globalRoute.length})</b>
        </div>
      </Stack>
    ) : (
      endLocationLabel
    );
  return (
    <Tooltip title={title} placement="top" arrow>
      <>{label}</>
    </Tooltip>
  );
};

export default MultiStopTooltip;
