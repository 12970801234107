import { makeStyles } from '@mui/styles';
import { Theme, Container } from '@mui/material';
import { connectRefinementList, connectRange } from 'react-instantsearch-dom';
import { RefinementListProvided } from 'react-instantsearch-core';
import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from '@mui/material';
import clsx from 'clsx';
import { AlgoliaShipmentProps } from 'api/shipment-visibility-dashboard';
import { useEffect } from 'react';

type ReibusButtonProps = NonTertiaryButtonProps | TertiaryButtonProps;

interface NonTertiaryButtonProps extends ToggleButtonGroupProps {
  kind?: 'primary' | 'secondary' | 'alternate';
  fill?: boolean;
}

interface TertiaryButtonProps extends ToggleButtonGroupProps {
  kind: 'tertiary';
  fill: boolean;
}

type Color =
  | 'info'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'warning'
  | 'standard'
  | undefined;

export enum ShipmentTabOption {
  IN_PROGRESS = 'In Progress',
  MOVE_COMPLETED = 'Move Completed',
}

type ToggleBarProps = {
  color: Color;
  handleShipmentSelect: (
    shipment: null | AlgoliaShipmentProps | undefined
  ) => void;
  handleCurrentStatusList: (value: ShipmentTabOption) => void;
  handleQueryParams?: (
    handleCurrentStatusList: (value: string) => void
  ) => void;
};

type Refine = {
  min?: number;
  max?: number;
  sortBy?: string[];
};

type ToggleBarWithTimeStampProps = {
  statusRefine: (status: ShipmentTabOption) => void;
  currentStatusRefinement: ShipmentTabOption;
  refine: ({ min, max, sortBy }: Refine) => void;
  handleCurrentStatusList: (value: ShipmentTabOption) => void;
  handleQueryParams?: (
    handleCurrentStatusList: (value: ShipmentTabOption) => void
  ) => void;
};

const useStyles = makeStyles<Theme, ReibusButtonProps>((theme) => ({
  container: {
    padding: 0,
    width: '100%',
    marginTop: theme.spacing(1),
  },
  selected: {
    backgroundColor: 'rgba(93, 5, 73, 0.1) !important',
    color: '#5A0547 !important',
  },
  toggleGroup: {
    width: '100%',
  },
  primary: {
    borderRadius: 3,
    height: 40,
    width: '100%',
    whiteSpace: 'nowrap',
    color: theme.palette.primary.main,
    backgroundColor: '#EEEEEE',
  },
}));

const TOGGLE_OPTIONS = [
  ShipmentTabOption.IN_PROGRESS,
  ShipmentTabOption.MOVE_COMPLETED,
] as ShipmentTabOption[];

const END_DATE_TIMESTAMP_FIELD = 'endDateTimestamp';

const NINETY_DAYS_TO_MILLISECONDS = 1000 * 60 * 60 * 24 * 90;

export const _ToggleBarWithTimestampRange = ({
  color,
  refine: timestampRefine,
  statusRefine,
  currentStatusRefinement,
  handleShipmentSelect,
  handleCurrentStatusList,
  handleQueryParams,
}: ToggleBarProps & ToggleBarWithTimeStampProps) => {
  const classes = useStyles({ color });

  useEffect(() => {
    handleQueryParams?.(handleToggleChange);
  }, []);

  // FIX ME - https://github.com/reibus/reibus-logistics-frontend/pull/231#discussion_r943776500
  const handleToggleChange = (value: ShipmentTabOption) => {
    const oldShipmentsTimestamp =
      value === ShipmentTabOption.MOVE_COMPLETED
        ? new Date().getTime() - NINETY_DAYS_TO_MILLISECONDS
        : 0;
    timestampRefine({
      min: oldShipmentsTimestamp,
    });
    statusRefine(value);
    handleCurrentStatusList(value);
    handleShipmentSelect(null);
  };

  return (
    <Container className={classes.container}>
      <ToggleButtonGroup
        value={currentStatusRefinement}
        exclusive
        onChange={(_, value) => {
          if (value) handleToggleChange(value);
        }}
        className={classes.toggleGroup}
      >
        {TOGGLE_OPTIONS.map((toggleOption, index: number) => {
          return (
            <ToggleButton
              data-testid={`toggleButton-${index}`}
              key={toggleOption}
              classes={{
                root: clsx(classes[color || 'primary']),
                selected: classes.selected,
              }}
              color={color}
              value={toggleOption}
            >
              {String(toggleOption).toLocaleUpperCase()}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Container>
  );
};

export const ToggleBarWithTimestampRange = connectRange(
  _ToggleBarWithTimestampRange
);

export const _ToggleBar = ({
  color = 'primary',
  refine,
  currentRefinement,
  handleShipmentSelect,
  handleCurrentStatusList,
  handleQueryParams,
}: RefinementListProvided & ToggleBarProps) => (
  <ToggleBarWithTimestampRange
    statusRefine={refine}
    currentStatusRefinement={currentRefinement}
    color={color}
    defaultRefinement={{ min: 0 }}
    attribute={END_DATE_TIMESTAMP_FIELD}
    handleShipmentSelect={handleShipmentSelect}
    handleCurrentStatusList={handleCurrentStatusList}
    handleQueryParams={handleQueryParams}
  />
);

export const ToggleBar = connectRefinementList(_ToggleBar);
