import { Stack, Box } from '@mui/material';
import { Accordion } from 'components/SteelUI/molecules';
import { DispositionType } from 'features/cse/disposition/api';
import type { ShipmentDisposition } from 'features/cse/disposition/api';
import makeStyles from '@mui/styles/makeStyles';
import { formatInTimeZone } from 'date-fns-tz';
import { getDispositionReasonLabel, getDispositionTypeLabel } from '../utils';

const useStyles = makeStyles({
  container: {
    fontSize: '14px',
    lineHeight: '125%',
  },
  label: {
    fontWeight: 400,
  },
  bold: {
    fontWeight: 600,
  },
});

type Props = {
  disposition: ShipmentDisposition;
};

const Label = ({
  companyName = 'Company Name N/A',
  dispositionType,
}: {
  companyName?: string;
  dispositionType?: DispositionType;
}) => {
  const classes = useStyles();
  const dispositionTypeLabel = getDispositionTypeLabel(dispositionType);
  return (
    <Stack direction="row" justifyContent="space-between">
      <Box>{companyName}</Box>
      <Box className={classes.bold}>{dispositionTypeLabel}</Box>
    </Stack>
  );
};

const ActivityLogEntryDisposition = ({ disposition }: Props) => {
  const classes = useStyles();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formatUser = () => {
    if (disposition.source === 'DIGITAL_FREIGHT_AWARD') {
      return 'Auto-Awards';
    }

    if (!disposition.user?.name) {
      return 'N/A';
    }

    return disposition.user.name;
  };

  const label = (
    <Box className={classes.label}>
      <Label
        companyName={disposition?.carrier?.name}
        dispositionType={disposition?.disposition}
      />
    </Box>
  );

  const posted = !disposition?.createdAt
    ? 'N/A'
    : formatInTimeZone(disposition.createdAt, timezone, 'h:mm a');

  const reason = getDispositionReasonLabel(disposition?.reason);

  return (
    <Accordion label={label}>
      <Stack spacing={1} className={classes.container}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box className={classes.bold}>{reason}</Box>
          <Box>
            <span className={classes.bold}>Posted: </span>
            {posted}
          </Box>
        </Stack>
        <Box>
          <span className={classes.bold}>User: </span>
          {formatUser()}
        </Box>
        <Stack>
          <Box className={classes.bold}>Note:</Box>
          <Box>{disposition?.comments ?? 'None'}</Box>
        </Stack>
      </Stack>
    </Accordion>
  );
};

export default ActivityLogEntryDisposition;
