import { ReactNode } from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RouteChildrenProps } from 'react-router-dom';

const useStyles = makeStyles({
  wrap: {
    minHeight: '70vh',
    position: 'relative',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 1,
  },
});

type LoaderProps = {
  hasLoaded: boolean;
  children:
    | ((
        props: RouteChildrenProps<{ [x: string]: string | undefined }, unknown>
      ) => React.ReactNode)
    | React.ReactNode
    | undefined;
};

const Loader = ({ hasLoaded, children }: LoaderProps) => {
  const classes = useStyles();
  if (hasLoaded) {
    return <>{children}</>;
  } else {
    return (
      <div className={classes.wrap}>
        <div data-testid="page-loader" className={classes.container}>
          <CircularProgress color="primary" />
        </div>
      </div>
    );
  }
};

export default Loader;
