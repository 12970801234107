import { Box, Divider, Stack } from '@mui/material';
import { Form, FormSpy } from 'react-final-form';
import Button from 'components/SteelUI/atoms/Button';

type Props = {
  children: React.ReactNode;
  handleSubmit: (values: Record<string, string>) => void;
  handleClear: () => void;
  onChange: (values: Record<string, string>) => void;
  disableSubmit?: boolean;
  isLoading?: boolean;
};

const FiltersForm = ({
  children,
  handleSubmit,
  handleClear,
  onChange,
  disableSubmit = false,
  isLoading = false,
}: Props) => {
  const filters = {};

  return (
    <Form onSubmit={handleSubmit} initialValues={filters}>
      {({ handleSubmit, form }) => {
        return (
          <>
            <FormSpy onChange={({ values }) => onChange(values)} />
            <form onSubmit={handleSubmit}>
              <Stack
                sx={{ width: '360px' }}
                divider={<Divider sx={{ backgroundColor: '#EDEEF0' }} />}
              >
                {children}
                <Box sx={{ p: '24px' }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        form.reset();
                        handleClear();
                      }}
                      style={{ width: '45%' }}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      style={{ width: '45%' }}
                      disabled={disableSubmit}
                      loading={isLoading}
                    >
                      Save
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </form>
          </>
        );
      }}
    </Form>
  );
};

export default FiltersForm;
