import { Card, Theme, Stack, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import ButtonV2 from 'components/library/ButtonV2';
import DispositionEmail from './DispositionEmail';
import Phone from 'shipment/carrier-contact-info-modal/Phone';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(2),
  },
  item: {
    padding: theme.spacing(0, 2),
  },
  link: {
    textDecoration: 'none',
  },
  button: {
    minWidth: 'max-content',
    height: 42,
  },
}));

type Props = {
  carrierID: string;
  carrierName: string;
  phoneNumber: string;
  email: string;
};

const TurvoContact = ({
  carrierID,
  carrierName,
  phoneNumber,
  email,
}: Props) => {
  const classes = useStyles();

  const turvoLink = `${process.env.REACT_APP_TURVO_URL}/#/accounts/${carrierID}/contacts`;

  return (
    <Card variant="outlined" className={classes.card}>
      <Stack spacing={4} direction="row" alignItems="center">
        <Box sx={{ width: '250px' }}>
          <a
            href={turvoLink}
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            <ButtonV2 className={classes.button}>View Turvo Contacts</ButtonV2>
          </a>
        </Box>
        <Box sx={{ alignContent: 'flex-start' }}>
          <Stack spacing={1} direction="column">
            <Phone phone={phoneNumber} />
            <DispositionEmail
              carrierID={carrierID}
              carrierName={carrierName}
              email={email}
            />
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
};

export default TurvoContact;
