import { makeStyles } from '@mui/styles';
import { Grid, Link, Theme } from '@mui/material';

import { TopShipmentModals } from '../TopShipment';

import type { CarrierRank } from 'features/cse/Carrier/types';

const useStyles = makeStyles((theme: Theme) => ({
  indicatorsWrapper: {
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  textLink: {
    fontWeight: 400,
    fontSize: 16,
    textDecoration: 'none',
    cursor: 'pointer',
  },
}));

type CapacityIndicatorsProps = {
  setOpenModal: (modal: TopShipmentModals) => void;
  scores: CarrierRank;
};

const CapacityIndicators = ({
  setOpenModal,
  scores,
}: CapacityIndicatorsProps) => {
  const { datScore, itsScore, laneScore, hqScore, availableEquipmentScore } =
    scores;

  const buttonToDisplay = [
    {
      key: 'dat',
      score: datScore,
    },
    {
      key: 'its',
      score: itsScore,
    },
    {
      key: 'lane',
      score: laneScore,
    },
    {
      key: 'hq',
      score: hqScore,
    },
    {
      key: 'req',
      score: availableEquipmentScore,
    },
  ];

  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="flex-start"
      className={classes.indicatorsWrapper}
    >
      {buttonToDisplay.map((button) =>
        !button.score ? null : (
          <Grid item sx={{ paddingRight: '16px' }} key={button.key}>
            <Link
              onClick={() => setOpenModal(button.key as TopShipmentModals)}
              key={button.key}
              className={classes.textLink}
              underline="none"
            >
              {button.key.toUpperCase()}
            </Link>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default CapacityIndicators;
