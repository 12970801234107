import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ButtonV2 from 'components/library/ButtonV2';
import Modal from 'components/library/Modal';
import clsx from 'clsx';
import { trackEvent } from 'utils/mixpanel';
import { Info } from 'components/icons/Info';
import { MixpanelTag } from 'utils/constants';
import type { ShipmentWithScores } from 'features/cse/Carrier/types';
import { createEmailContent } from './functions/EmailContentGenerator';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  carrierName?: string;
  shipmentsCount: number;
  cseCarrierID: string;
  shipments: ShipmentWithScores[];
};

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    padding: 24,
  },
  title: {
    fontSize: 24,
    marginTop: 7,
  },
  text: {
    marginTop: 8,
    '& > *:not(:first-child)': {
      marginTop: 24,
    },
    textAlign: 'center',
  },
  button: {
    width: 238,
  },
  buttonContainer: {
    marginTop: spacing(4),
  },
  confirmButton: {
    marginLeft: spacing(2),
  },
  icon: {
    marginTop: spacing(2),
  },
}));

const SendTopShipmentsModal = ({
  isOpen,
  handleClose,
  carrierName,
  shipmentsCount,
  cseCarrierID,
  shipments,
}: Props) => {
  const classes = useStyles();
  const { subject, body } = createEmailContent(shipments, carrierName);

  const trackUserClick = (sent: boolean) => {
    trackEvent(MixpanelTag.ClickSendTopShipments, {
      date: new Date(),
      carrierId: cseCarrierID,
      shipmentsCount,
      sent,
    });
    handleClose();
  };
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} width={540}>
      <Grid
        container
        direction="column"
        className={classes.container}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item className={classes.icon}>
          <Info />
        </Grid>
        <Grid item className={classes.title}>
          Send Top Shipments
        </Grid>
        <Grid item className={classes.text}>
          <Grid item>
            You’ll be redirected to Outlook to confirm the sending of{' '}
            {shipmentsCount} Top Shipments to {carrierName}.
          </Grid>

          <Grid item>Are you sure you want to continue?</Grid>
        </Grid>
        <Grid item className={classes.buttonContainer}>
          <ButtonV2
            className={classes.button}
            variant="outlined"
            onClick={() => trackUserClick(false)}
          >
            No
          </ButtonV2>
          <ButtonV2
            className={clsx(classes.button, classes.confirmButton)}
            onClick={() => {
              trackUserClick(true);
              window.open(`mailto:?subject=${subject}&body=${body}`, '_blank');
            }}
          >
            Yes
          </ButtonV2>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default SendTopShipmentsModal;
