import { useQuery } from 'react-query';
import { useAuthorizationToken } from '@reibus/frontend-utility';

import { getCarrierDetailInfo } from 'features/cse/Carrier/api';

import type { Carrier } from 'features/cse/Carrier/types';

export const useCarrier = (cseCarrierId: string) => {
  const authorization = useAuthorizationToken();

  return useQuery<Carrier, Error>(
    ['carrierDetails', cseCarrierId],
    async () => await getCarrierDetailInfo(authorization, cseCarrierId),
    {
      enabled: !!authorization,
    }
  );
};
