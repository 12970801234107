import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  header: {
    padding: '12px 16px',
    position: 'relative',
    '&:hover': { cursor: 'pointer' },
  },
  label: {
    paddingRight: '32px',
  },
  stringLabel: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '125%',
  },
  icon: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    width: '8px',
  },
  content: {
    padding: '12px 16px',
    backgroundColor: '#F6F7F7',
  },
}));

type Props = {
  label: string | React.ReactNode;
  children: React.ReactNode;
};

const Accordion = ({ label, children }: Props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const chevronIcon = expanded
    ? icon({ name: 'chevron-up', style: 'light' })
    : icon({ name: 'chevron-down', style: 'light' });

  return (
    <Box>
      <Box className={classes.header} onClick={() => setExpanded(!expanded)}>
        <Box
          className={clsx(
            classes.label,
            classes.stringLabel && typeof label === 'string'
          )}
        >
          {label}
        </Box>
        <FontAwesomeIcon icon={chevronIcon} className={classes.icon} />
      </Box>
      <Collapse in={expanded}>
        <Box className={classes.content}>{children}</Box>
      </Collapse>
    </Box>
  );
};

export default Accordion;
