import { Grid, SvgIcon, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import clsx from 'clsx';

import { useSearchShipmentsContext } from 'search-shipments/SearchShipmentsContext';

import type { SortBy } from 'search-shipments/SearchShipmentsContext';

export type SortingProps = {
  label: string;
  id: SortBy | string;
  align: 'left' | 'right';
  sortable: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icons: {
    marginRight: -8,
    marginLeft: 4,
  },
  icon: {
    lineHeight: 0,
  },
  iconArrowUp: {
    marginBottom: -12,
  },
  active: {
    color: theme.palette.common.black,
    height: 20,
  },
  inactive: {
    color: theme.palette.grey['300'],
    height: 20,
  },
}));

const Sorting = ({ label, id, align, sortable }: SortingProps) => {
  const { sortBy, sortOrder, search } = useSearchShipmentsContext();
  const classes = useStyles();

  const handleClick = () => {
    if (!sortable) return;
    search({
      searchSortBy: id as SortBy,
      searchSortOrder: sortOrder === 'asc' ? 'desc' : 'asc',
    });
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={align === 'left' ? 'flex-start' : 'flex-end'}
      onClick={handleClick}
      className={classes.root}
    >
      <Grid item>{label}</Grid>
      {!sortable ? null : (
        <Grid item className={classes.icons}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item className={clsx(classes.icon, classes.iconArrowUp)}>
              <SvgIcon
                component={KeyboardArrowUp}
                className={
                  sortBy === id && sortOrder === 'asc'
                    ? classes.active
                    : classes.inactive
                }
                data-testid={`sort-asc-${label
                  .toLocaleLowerCase()
                  .split(' ')
                  .join('-')}`}
              />
            </Grid>
            <Grid item className={classes.icon}>
              <SvgIcon
                component={KeyboardArrowDown}
                className={
                  sortBy === id && sortOrder === 'desc'
                    ? classes.active
                    : classes.inactive
                }
                data-testid={`sort-desc-${label
                  .toLocaleLowerCase()
                  .split(' ')
                  .join('-')}`}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Sorting;
