import { Grid, Skeleton } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { Link } from 'react-router-dom';

type Props = {
  label?: string;
  isLoading: boolean;
  href?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
  target?: React.HTMLAttributeAnchorTarget | undefined;
};

const CellLabel = ({ label, isLoading, href, onClick, target }: Props) => {
  if (isLoading) return <Skeleton height={30} />;

  const formattedLabel = !label ? 'N/A' : label;

  return !href ? (
    <>{formattedLabel}</>
  ) : (
    <Link to={href} onClick={onClick} target={target}>
      <Grid container justifyContent="space-between" sx={{ color: '#2D6CCB' }}>
        <Grid item>{formattedLabel}</Grid>
        <Grid item>
          <OpenInNew />
        </Grid>
      </Grid>
    </Link>
  );
};

export default CellLabel;
