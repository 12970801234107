import Grid from '@mui/material/Unstable_Grid2';

import { H2 } from 'components/SteelUI/atoms/typography';
import { Commodity, Service } from 'features/cse/AutoAward/Confirmation/api';
import AdditionalInformationSection from 'features/cse/AutoAward/Confirmation/components/AdditionalInformation/AdditionalInformationSection';
import {
  formatCommodities,
  formatEquipmentNeededDesc,
  formatServices,
  formatWeight,
} from 'features/cse/AutoAward/Confirmation/components/AdditionalInformation/utils';

type Props = {
  weight: number;
  services: Service[];
  commodities: Commodity[];
  equipmentNeededDesc: string;
};

const AdditionalInformation = ({
  weight,
  services,
  commodities,
  equipmentNeededDesc,
}: Props) => {
  const equipmentFormatted = formatEquipmentNeededDesc(equipmentNeededDesc);
  const servicesFormatted = formatServices(services);
  const weightFormatted = formatWeight(weight);
  const commoditiesFormatted = formatCommodities(commodities);
  return (
    <>
      <H2 weight="bold">Additional Information</H2>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <AdditionalInformationSection
            label="Equipment Type:"
            value={equipmentFormatted}
          />
        </Grid>
        <Grid xs={6}>
          <AdditionalInformationSection
            label="Services:"
            value={servicesFormatted}
          />
        </Grid>
        <Grid xs={6}>
          <AdditionalInformationSection
            label="Weight:"
            value={weightFormatted}
          />
        </Grid>
        <Grid xs={6}>
          <AdditionalInformationSection
            label="Commodity:"
            value={commoditiesFormatted}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AdditionalInformation;
