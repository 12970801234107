import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ButtonV2 from 'components/library/ButtonV2';
import { useShipmentContext } from 'shipment/ShipmentContext';

type Props = {
  handleClose: () => void;
};

const useStyles = makeStyles({
  item: {
    padding: 12,
  },
});

const EquipmentDetailsError = ({ handleClose }: Props) => {
  const { fetchTopCarriers } = useShipmentContext();
  const classes = useStyles();

  const handleClick = () => {
    fetchTopCarriers();
    handleClose();
  };

  return (
    <Grid container justifyContent="center">
      <Grid item className={classes.item}>
        This DAT listing is no longer active.
      </Grid>
      <Grid item className={classes.item}>
        Please refresh the page to update the Top Carriers.
      </Grid>
      <Grid item className={classes.item}>
        <ButtonV2 onClick={handleClick}>Refresh Top Carriers</ButtonV2>
      </Grid>
    </Grid>
  );
};

export default EquipmentDetailsError;
