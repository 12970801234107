import { Box, Drawer, Paper, IconButton, Stack, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const FiltersDrawer = ({ children, open, setOpen }: Props) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
      hideBackdrop
      variant="persistent"
    >
      <Stack
        justifyContent="space-between"
        divider={<Divider sx={{ backgroundColor: '#EDEEF0' }} />}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: '24px',
          }}
        >
          <Box sx={{ fontWeight: 700, fontSize: '18px', lineHeight: '125%' }}>
            Filters
          </Box>
          <IconButton onClick={() => setOpen(false)} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>{children}</Box>
      </Stack>
    </Drawer>
  );
};

export default FiltersDrawer;
