import { Box, Divider, Stack } from '@mui/material';
import Button from 'components/SteelUI/atoms/Button';
import { Drawer } from 'components/SteelUI/molecules';
import { formatInTimeZone } from 'date-fns-tz';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useAuthorizationToken } from '@reibus/frontend-utility';
import ActivityLogGroup from './ActivityLogGroup';

import { getActivityLogByShipmentId } from './api';
import useActivityLog from 'features/cse/ActivityLog/useActivityLog';

type Props = {
  shipmentId: string;
};

const ActivityLog = ({ shipmentId }: Props) => {
  const [open, setOpen] = useState(false);

  const { data = [], isLoading } = useActivityLog(shipmentId);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const activityLogMappedByDate = new Map();
  const activityLog = data;
  activityLog.forEach((activity) => {
    let date = 'N/A';
    if ('createdAt' in activity) {
      date = formatInTimeZone(
        activity.createdAt as string,
        timezone,
        'yyyy-MM-dd'
      );
    }
    if ('notifiedAt' in activity) {
      date = formatInTimeZone(activity.notifiedAt, timezone, 'yyyy-MM-dd');
    }

    activityLogMappedByDate.set(date, [
      ...(activityLogMappedByDate.get(date) ?? []),
      activity,
    ]);
  });

  return (
    <Box>
      <>
        <Button
          variant="text"
          loading={isLoading}
          onClick={() => setOpen(!open)}
        >
          <Box>Activity Log</Box>
        </Button>
        <Drawer
          title="Activity Log"
          onClose={() => setOpen(false)}
          open={open}
          variant="persistent"
        >
          <Stack sx={{ minWidth: '400px' }} divider={<Divider />}>
            {Array.from(activityLogMappedByDate).map(([date, activities]) => (
              <ActivityLogGroup
                key={`activity-log-group-${date}`}
                date={date}
                activities={activities}
              />
            ))}
          </Stack>
        </Drawer>
      </>
    </Box>
  );
};

export default ActivityLog;
