import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { AvailableEquipmentTable } from './components/AvailableEquipmentTable';

import type { Equipment } from 'features/cse/Carrier/types';

type AvailableEquipmentProps = {
  cseCarrierID: string;
  mcNumber?: string;
  dotNumber?: string;
  equipment: Equipment[] | undefined;
  isLoading: boolean;
};

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    fontWeight: 700,
    marginBottom: 8,
    fontSize: spacing(3),
  },
}));

const AvailableEquipment = ({
  cseCarrierID,
  equipment,
  isLoading,
  dotNumber,
  mcNumber,
}: AvailableEquipmentProps) => {
  const classes = useStyles();

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <Typography className={classes.title}>Available Equipment</Typography>
      </Grid>
      <AvailableEquipmentTable
        equipment={equipment ?? []}
        isLoading={isLoading}
        cseCarrierID={cseCarrierID}
        mcNumber={mcNumber}
        dotNumber={dotNumber}
      />
    </Grid>
  );
};

export default AvailableEquipment;
