import axios from 'axios';
import { getApiRoot } from 'utils/apiUtils';
import {
  Coordinate,
  KeyAndValue,
  ReferenceNumber,
  Status,
  TurvoDate,
  TurvoGlobalRoute,
} from './types';

export type PublicShipmentRespProps = {
  id: number;
  shipmentID?: string;
  customId: string;
  startDate: TurvoDate;
  phase: KeyAndValue;
  endDate: TurvoDate;
  status: Status;
  daysUntilCompletion: string;
  globalRoute: TurvoGlobalRoute[];
  shipmentType: string;
  commodity: string;
  weight: string;
  skidsAndPallets: string;
  isMarketplaceShipment: boolean;
  tenderedAt: string;
  truckLocation?: Coordinate;
  shipmentStatus: KeyAndValue;
  referenceNumbers: ReferenceNumber[];
  label: string;
};

export const getPublicShipmentDetails = async (
  encodedShipmentId: string
): Promise<PublicShipmentRespProps> => {
  const url = `${getApiRoot()}/shipments/${encodedShipmentId}/public`;
  const response = await axios.get(url);

  if (response.status !== 200) {
    throw new Error(response.statusText);
  }

  return response.data;
};
