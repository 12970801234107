import LoadingButton from '@mui/lab/LoadingButton';
import { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FONT_WEIGHT_BOLD } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: FONT_WEIGHT_BOLD,
    disabled: {
      color: theme.palette.grey['600'],
      backgroundColor: theme.palette.grey['100'],
    },
    borderRadius: 8,
    '&:active': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  containedPrimary: {
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  outlinedPrimary: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  disabled: {
    color: `${theme.palette.grey['600']} !important`,
    backgroundColor: `${theme.palette.grey['100']} !important`,
  },
  loading: {
    marginLeft: theme.spacing(2),
    color: theme.palette.grey['600'],
  },
}));

const Button = ({
  variant = 'contained',
  children,
  ...rest
}: LoadingButtonProps) => {
  const classes = useStyles();

  return (
    <LoadingButton
      variant={variant}
      disableRipple
      disableFocusRipple
      disableElevation
      classes={{
        root: classes.root,
        containedPrimary: classes.containedPrimary,
        outlinedPrimary: classes.outlinedPrimary,
        disabled: classes.disabled,
      }}
      {...rest}
    >
      <span>{children}</span>
    </LoadingButton>
  );
};

export default Button;
