import { makeStyles } from '@mui/styles';
import { Theme, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  phone: string;
  iconClassName?: string;
};

export const formatPhoneNumber = (phoneInput: string) => {
  if (!phoneInput) return 'N/A';
  const phoneNoGlobalCode = phoneInput.includes('+')
    ? phoneInput.slice(2)
    : phoneInput;

  const phone = phoneNoGlobalCode.includes('-')
    ? phoneNoGlobalCode.replace(/-/g, '')
    : phoneNoGlobalCode;

  const areaCode = phone.slice(0, 3);
  const prefix = phone.slice(3, 6);
  const line = phone.slice(6);
  return `(${areaCode}) ${prefix} - ${line}`;
};

const Phone = ({ phone, iconClassName }: Props) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <FontAwesomeIcon
        icon={faPhone}
        className={clsx(classes.icon, iconClassName)}
      />
      {formatPhoneNumber(phone)}
    </Box>
  );
};

export default Phone;
