import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { FieldMetaState } from 'react-final-form';

const useStyles = makeStyles({
  error: {
    color: '#92140c',
    lineHeight: 1.25,
    paddingTop: 5,
  },
});

type FormFieldErrorProps = {
  meta: FieldMetaState<unknown>;
};

export const FormFieldError = ({ meta }: FormFieldErrorProps) => {
  const { error, touched } = meta;
  const classes = useStyles();
  return touched && error ? (
    <Typography variant="body2" className={classes.error} data-testid="error">
      {error}
    </Typography>
  ) : null;
};
