import { Skeleton, SkeletonProps } from '@mui/lab';
import { ReactNode } from 'react';

interface Props extends SkeletonProps {
  isLoading: boolean;
  children: ReactNode;
}

const SkeletonWrapper = ({ isLoading, children, ...rest }: Props) => {
  return isLoading ? <Skeleton {...rest} /> : <>{children}</>;
};

export default SkeletonWrapper;
