import { useState } from 'react';
import { Box, Collapse, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

type Props = {
  label: string;
  children: React.ReactNode;
};

const CollapsibleField = ({ label, children }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const chevronIcon = expanded
    ? icon({ name: 'chevron-up', style: 'light' })
    : icon({ name: 'chevron-down', style: 'light' });

  return (
    <Box>
      <Box
        sx={{ p: '24px', '&:hover': { cursor: 'pointer' } }}
        onClick={() => setExpanded(!expanded)}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '125%' }}>
            {label}
          </Box>
          <FontAwesomeIcon icon={chevronIcon} />
        </Stack>
      </Box>
      <Collapse in={expanded}>
        <Box sx={{ p: '0 24px 24px' }}>{children}</Box>
      </Collapse>
    </Box>
  );
};

export default CollapsibleField;
