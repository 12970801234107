import { useQuery } from 'react-query';

import { useUserSession } from '@reibus/frontend-utility';
import { getApiRoot } from 'utils/apiUtils';
import { useFlags } from 'launchdarkly-react-client-sdk';

import axios from 'axios';

export type Address = {
  address1?: string;
  address2?: string;
  postalCode?: string;
  city?: string;
  region?: string;
  country?: string;
};

type PreFrieghtData = {
  customerName: string;
  commodityType: string;
  packageType: string;
  weight: number;
  destinationAddress: {
    postalCode: string;
  };
  originAddress: Address;
};

const useGetResourcePreFrieghtData = (resourceId: string | null) => {
  const { data: userSession = {} } = useUserSession();
  const { isReibusUser = false, identityToken } = userSession;
  const { ct238PrefillFqrFormFromOffer } = useFlags();

  const config = {
    headers: {
      Authorization: identityToken,
    },
    params: {
      resourceType: 'QUOTE_BID',
    },
  };

  return useQuery<PreFrieghtData | undefined, Error>(
    ['prefillFreightData', resourceId],
    async () => {
      const response = await axios.get<PreFrieghtData>(
        `${getApiRoot()}/freight-quotes/pre-freight/${resourceId}`,
        config
      );
      return response.data;
    },
    {
      enabled: isReibusUser && !!resourceId && ct238PrefillFqrFormFromOffer,
    }
  );
};

export default useGetResourcePreFrieghtData;
