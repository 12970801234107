import axios from 'axios';

import { getApiRoot } from 'utils/apiUtils';

type TMSCompany = {
  tmsID: string;
  tmsName: string;
};

type Coordinates = { latitude: string; longitude: string };

const getCustomers = async (
  authorization: string
): Promise<Map<string, string>> => {
  const url = `${getApiRoot()}/tms-companies`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const { status, statusText, data } = await axios.get(url, config);

  if (status !== 200) {
    throw new Error(statusText);
  }

  const companies = new Map();

  data.forEach(({ tmsID, tmsName }: TMSCompany) => {
    companies.set(tmsID, tmsName);
  });

  return companies;
};

const fetchShipmentsByLocation = async (
  authorization: string,
  originCoordinates: Coordinates | undefined,
  destinationCoordinates: Coordinates | undefined
): Promise<string[]> => {
  const url = `${getApiRoot()}/shipments/byRadius`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const body = JSON.stringify({
    originCoordinates,
    destinationCoordinates,
  });
  const response = await axios.post(url, body, config);

  if (response.status !== 200) {
    throw new Error('shipments/byRadius error: ' + response.statusText);
  }

  return response.data;
};

export type { Coordinates };
export { fetchShipmentsByLocation, getCustomers };
