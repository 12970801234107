import {
  Checkbox,
  CircularProgress,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ButtonV2 from 'components/library/ButtonV2';
import { useState } from 'react';
import { useDispositionContext } from 'features/cse/disposition/contexts/DispositionContext';
import { useDispositionsForShipmentContext } from 'features/cse/disposition/contexts/DispositionsForShipmentContext';

type Props = {
  coverShipment: (
    comments: string | undefined,
    deleteEquipment?: boolean
  ) => void;
};

const useStyles = makeStyles(({ spacing }: Theme) => ({
  cancel: {
    paddingRight: spacing(3),
  },
  button: {
    width: 120,
  },
  container: {
    display: 'flex',
    gap: spacing(3),
    flexFlow: 'column',
  },
}));

const Submit = ({ coverShipment }: Props) => {
  const classes = useStyles();
  const { closeDispositionModal, carrierName } = useDispositionContext();
  const { refetch } = useDispositionsForShipmentContext();
  const [comments, setComments] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [deleteEquipment, setDeleteEquipment] = useState(false);

  const handleCoverShipment = async () => {
    try {
      setIsLoading(true);
      await coverShipment(comments, deleteEquipment);
    } finally {
      setIsLoading(false);
      refetch();
    }
  };

  return (
    <>
      <DialogTitle>
        <Typography variant="h3">Cover Shipment</Typography>
      </DialogTitle>
      <DialogContent className={classes.container}>
        <Typography>{carrierName} is interested in the shipment.</Typography>
        <TextField
          variant="outlined"
          placeholder="Comments"
          onChange={(event) => {
            setComments(event.target.value);
          }}
          multiline
          fullWidth
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={deleteEquipment}
              onChange={() => setDeleteEquipment(!deleteEquipment)}
            />
          }
          label="Remove affiliated carrier equipment from CSE"
        />
        <Grid container direction="row-reverse">
          <Grid item>
            <ButtonV2
              onClick={handleCoverShipment}
              className={classes.button}
              disabled={!comments || isLoading}
            >
              {isLoading ? <CircularProgress size={20} /> : 'Cover'}
            </ButtonV2>
          </Grid>
          <Grid item className={classes.cancel}>
            <ButtonV2
              variant="outlined"
              onClick={() => closeDispositionModal()}
              className={classes.button}
              disabled={isLoading}
            >
              Cancel
            </ButtonV2>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default Submit;
