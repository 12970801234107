import { FieldProps } from 'components/library/form/types';

const SHIPMENT_SEARCH_FORM_FIELDS: FieldProps[] = [
  {
    id: 'customerName',
    databaseName: 'customerName',
    label: 'Customer Name',
    placeholder: 'Customer Name',
    inputType: 'TEXT',
    required: false,
    displayForReibusPersonnelOnly: false,
  },
  {
    id: 'shipmentId',
    databaseName: 'shipmentId',
    label: 'Shipment ID',
    placeholder: 'i.e. 31484-20920',
    inputType: 'TEXT',
    required: false,
    maxLength: 11,
    displayForReibusPersonnelOnly: false,
  },
  {
    id: 'originAddress',
    databaseName: 'originAddress',
    label: 'Origin',
    description: 'Origin',
    placeholder: 'Origin',
    inputType: 'ADDRESS',
    required: false,
    displayForReibusPersonnelOnly: false,
  },
  {
    id: 'destinationAddress',
    databaseName: 'destinationAddress',
    label: 'Destination',
    description: 'Destination',
    placeholder: 'Destination',
    inputType: 'ADDRESS',
    required: false,
    displayForReibusPersonnelOnly: false,
  },
  {
    id: 'pickupDate',
    databaseName: 'pickupDate',
    label: 'Pickup date',
    description: 'Pickup date',
    placeholder: 'Pickup date',
    inputType: 'DATE',
    required: false,
    displayForReibusPersonnelOnly: false,
  },
  {
    id: 'equipmentType',
    databaseName: 'equipmentType',
    label: 'Equipment type',
    description: 'Equipment type',
    placeholder: 'Equipment type',
    inputType: 'AUTO_SELECT',
    selectOptions: [
      {
        id: '1',
        label: 'Flatbed',
        optionName: '1',
      },
      {
        id: '2',
        label: 'Dry Van',
        optionName: '2',
      },
      {
        id: '3',
        label: 'Reefer',
        optionName: '3',
      },
      {
        id: '4',
        label: 'Container',
        optionName: '4',
      },
      {
        id: '5',
        label: 'Hopper Bottom',
        optionName: '5',
      },
      {
        id: '6',
        label: 'Conestoga',
        optionName: '6',
      },
      {
        id: '7',
        label: 'Tanker, Steel',
        optionName: '7',
      },
      {
        id: '8',
        label: 'Pneumatic',
        optionName: '8',
      },
      {
        id: '9',
        label: 'Step Deck',
        optionName: '9',
      },
      {
        id: '10',
        label: 'Dump Trailer',
        optionName: '10',
      },
      {
        id: '11',
        label: 'Hotshot',
        optionName: '11',
      },
      {
        id: '12',
        label: 'Drop Deck, Landoll',
        optionName: '12',
      },
      {
        id: '13',
        label: 'Removable Gooseneck',
        optionName: '13',
      },
      {
        id: '14',
        label: 'Stretch Trailer',
        optionName: '14',
      },
      {
        id: '15',
        label: 'Double Drop',
        optionName: '15',
      },
      {
        id: '16',
        label: 'Auto Carrier',
        optionName: '16',
      },
      {
        id: '17',
        label: 'Power Only',
        optionName: '17',
      },
      {
        id: '18',
        label: 'Container Trailer',
        optionName: '18',
      },
      {
        id: '19',
        label: 'Van, Hotshot',
        optionName: '19',
      },
      {
        id: '20',
        label: 'Van w/ Curtains',
        optionName: '20',
      },
      {
        id: '21',
        label: 'Van, Vented',
        optionName: '21',
      },
      {
        id: '22',
        label: 'Other',
        optionName: '22',
      },
    ],
    required: false,
    displayForReibusPersonnelOnly: false,
  },
  {
    id: 'deliveryDate',
    databaseName: 'deliveryDate',
    label: 'Delivery date',
    description: 'Delivery date',
    placeholder: 'Delivery date',
    inputType: 'DATE',
    required: false,
    minDateGreaterThanInput: {
      input: 'pickupDate',
      errorMessage:
        'Please enter a date that is the same or later than the pickup date.',
    },
    displayForReibusPersonnelOnly: false,
  },
];

export { SHIPMENT_SEARCH_FORM_FIELDS };
