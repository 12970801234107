import { CognitoUser } from 'amazon-cognito-identity-js';
import { CountryCode } from 'api/types';
import { useUserSession } from '@reibus/frontend-utility';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { MeasurementSystem, User } from 'api/user';
import useCurrentUser from 'hooks/useCurrentUser';
import { UserSession } from 'utils/auth';

type UserContext = {
  user: User;
  isLoading: boolean;
  isLoggedIn: boolean;
  userSession: UserSession;
};

const defaultEmptyUser = {
  firstName: '',
  lastName: '',
  systemRoles: [] as string[],
  companyId: '',
  companyName: '',
  shippingLocation: '',
  shippingLocationPostalCode: '',
  measurementSystem: 'IMPERIAL' as MeasurementSystem,
  email: '',
  phoneNumber: '',
  userTypes: [] as string[],
  tmsCompany: { tmsID: '' },
};

const defaultUserSession = {
  cognitoUser: {} as CognitoUser,
  cognitoGroups: [] as string[],
  isReibusUser: false,
  identityToken: undefined,
};

const UserContext = createContext<UserContext>({} as UserContext);

const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const currentUser = useCurrentUser();
  const [user, setUser] = useState<User>(defaultEmptyUser);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const currentUserSession = useUserSession();
  const [userSession, setUserSession] =
    useState<UserSession>(defaultUserSession);

  useEffect(() => {
    if (currentUser.isSuccess) {
      setUser(currentUser.data);
      setIsLoggedIn(true);
    }

    setIsLoading(currentUser.isLoading);
  }, [currentUser]);

  return (
    <UserContext.Provider value={{ user, isLoading, isLoggedIn, userSession }}>
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = (): UserContext => useContext(UserContext);

const mockAuthenticatedUserContext: UserContext = {
  user: {
    firstName: 'Jester',
    lastName: 'The Tester',
    companyName: 'Big Ole Test Company',
    userTypes: [] as string[],
    systemRoles: ['ReibusCompanyAdmin'],
    shippingLocation: 'GA' as CountryCode,
    measurementSystem: 'IMPERIAL',
    companyId: 'companyId',
  } as User,
  userSession: {
    cognitoGroups: ['reibus', 'cse'] as string[],
    identityToken: 'identityToken',
  } as UserSession,
  isLoading: false,
  isLoggedIn: true,
};

const mockUnauthenticatedUserContext: UserContext = {
  user: defaultEmptyUser,
  userSession: defaultUserSession,
  isLoading: false,
  isLoggedIn: false,
};

const MockUserContextProvider = ({
  isAuthenticated,
  value,
  children,
}: {
  isAuthenticated: boolean;
  value?: UserContext;
  children: ReactNode;
}) => (
  <UserContext.Provider
    value={
      isAuthenticated
        ? { ...mockAuthenticatedUserContext, ...value }
        : { ...mockUnauthenticatedUserContext, ...value }
    }
  >
    {children}
  </UserContext.Provider>
);

export { UserContext, UserContextProvider, useUserContext };
export const exportedForTesting = { MockUserContextProvider };
