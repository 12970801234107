import { Button as MuiButton, ButtonProps } from '@mui/material';
import { Theme, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

interface ReibusButtonProps extends ButtonProps {
  kind?:
    | 'primary'
    | 'secondary'
    | 'alternate'
    | 'outlined'
    | 'tertiary'
    | 'info';
  fill?: boolean;
  isLoading?: boolean;
}

const useButtonStyles = makeStyles<Theme, ReibusButtonProps>((theme) => ({
  root: {
    borderRadius: 0,
    fontWeight: 700,
    fontSize: '16px',
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    lineHeight: 1.5,
    textTransform: 'none',
    letterSpacing: 0.5,
  },
  primary: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    color: '#fff',
    borderRadius: 8,
    height: 35,
    whiteSpace: 'nowrap',
  },
  secondary: {
    '&:hover': {
      backgroundColor: '#EAF2FF',
    },
    backgroundColor: '#fff',
    borderRadius: 8,
    height: 35,
    whiteSpace: 'nowrap',
    margin: theme.spacing(1),
    color: theme.palette.info.dark,
    border: `1px solid ${theme.palette.info.dark}`,
  },
  info: {
    '&:hover': {
      backgroundColor: '#215199',
    },
    backgroundColor: theme.palette.info.dark,
    color: '#fff',
    borderRadius: 8,
    height: 35,
    whiteSpace: 'nowrap',
  },
  alternate: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    borderRadius: 8,
    color: '#fff',
    height: 42,
    whiteSpace: 'nowrap',
  },
  outlined: {
    borderRadius: 8,
    fontSize: theme.typography.body2.fontSize,
    padding: `1px ${theme.spacing(1)}`,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  spinner: {
    color: 'white',
  },
}));

const Button = ({
  children,
  kind = 'primary',
  fill = false,
  color = 'primary',
  isLoading = false,
  ...rest
}: ReibusButtonProps) => {
  const classes = useButtonStyles({ fill, color, ...rest });
  const childrenValue = isLoading ? (
    <CircularProgress className={classes.spinner} size={20} />
  ) : (
    children
  );
  if (kind === 'tertiary') {
    return (
      <MuiButton
        classes={{ root: clsx(classes.root, classes.tertiary) }}
        color={color}
        {...rest}
      >
        {childrenValue}
      </MuiButton>
    );
  }

  if (kind === 'secondary') {
    return (
      <MuiButton
        classes={{ root: clsx(classes.root, classes.secondary) }}
        variant="contained"
        color={color}
        {...rest}
      >
        {childrenValue}
      </MuiButton>
    );
  }

  if (kind === 'alternate') {
    return (
      <MuiButton
        classes={{ root: clsx(classes.root, classes.alternate) }}
        variant="contained"
        color={color}
        {...rest}
      >
        {childrenValue}
      </MuiButton>
    );
  }

  if (kind === 'outlined') {
    return (
      <MuiButton
        classes={{ root: clsx(classes.root, classes.outlined) }}
        variant="outlined"
        color={color}
        {...rest}
      >
        {childrenValue}
      </MuiButton>
    );
  }

  if (kind === 'info') {
    return (
      <MuiButton
        classes={{ root: clsx(classes.root, classes.info) }}
        variant="contained"
        color={color}
        {...rest}
      >
        {childrenValue}
      </MuiButton>
    );
  }

  return (
    <MuiButton
      classes={{ root: clsx(classes.root, classes.primary) }}
      variant="contained"
      color={color}
      {...rest}
    >
      {childrenValue}
    </MuiButton>
  );
};

export default Button;
