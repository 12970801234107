import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { sharedStyles } from './shared';

import type { TypographyProps } from './types';

const useStyles = makeStyles<TypographyProps>()((_, props) => {
  return {
    root: {
      ...sharedStyles(props),
      fontSize: 14,
      lineHeight: '17.5px',
    },
  };
});

const H5 = ({ className, ...rest }: TypographyProps) => {
  const { classes, cx } = useStyles(rest);
  return (
    <Typography
      variant="h5"
      className={cx(classes.root, className)}
      {...rest}
    />
  );
};

export default H5;
