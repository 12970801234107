import { useQuery } from 'react-query';
import { getLocationDetails } from 'shipment/api';
import { useAuthorizationToken } from '@reibus/frontend-utility';

export default function useShipmentLocations(shipmentId: string) {
  const authorization = useAuthorizationToken();
  return useQuery(
    ['shipment-locations', shipmentId],
    async () => await getLocationDetails(authorization, shipmentId),
    { enabled: !!authorization }
  );
}
