import { Tabs as MuiTabs } from '@mui/material';
import { TabsProps } from '@mui/material/Tabs/Tabs';

const Tabs = (props: TabsProps) => {
  return (
    <MuiTabs
      {...props}
      sx={{
        '& .MuiTabs-indicator': {
          height: '4px',
          backgroundColor: '#2D6CCB',
        },
        ...props.sx,
      }}
    />
  );
};

export default Tabs;
