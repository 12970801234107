import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  iconLink: {
    marginRight: 11,
  },
});

export type SocialMediaDataType = {
  link: string;
  image: string;
  label?:
    | 'linkedin'
    | 'youtube'
    | 'twitter'
    | 'Reibus International App on Google Play Store'
    | 'Reibus International App on Apple App Store';
};

interface Props {
  socialMedia: SocialMediaDataType;
}

export const SocialMediaButton = ({ socialMedia }: Props) => {
  const classes = useStyles();
  return (
    <a className={classes.iconLink} href={socialMedia.link} target="__blank">
      <img
        alt={`Reibus International on ${socialMedia.label}`}
        src={socialMedia.image}
      />
    </a>
  );
};
