import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import SupportingText from '../atoms/SupportingText';

type Props = {
  value?: string | string[];
  label: string;
  options: ReadonlyArray<{
    label: string;
    value: string;
  }>;
  onChange: (event: SelectChangeEvent<string | string[]>) => void;
  required?: boolean;
  supportingText?: string;
  error?: string;
};

const chevronIcon = icon({ name: 'chevron-down', style: 'light' });

const useStyles = makeStyles()((theme) => ({
  select: {
    fontSize: '16px',
    borderRadius: '0.5rem',
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.5rem',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2D6CCB',
      borderWidth: '2px',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2D6CCB',
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
      borderWidth: '2px',
    },
    '&.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
    },
  },
  hasValue: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2D6CCB',
      borderWidth: '2px',
    },
  },
  label: {
    fontSize: '16px',
    display: 'flex',
    flexDirection: 'row',
    '&.MuiInputLabel-root:hover': {
      color: '#2D6CCB',
    },
    '&.Mui-focused': {
      color: '#2D6CCB',
    },
    '&.MuiFormLabel-filled': {
      color: '#2D6CCB',
    },
    '&.Mui-error': {
      color: theme.palette.error.main,
    },
    '& .MuiFormLabel-asterisk': {
      order: -1,
      margin: '0 4px 0 0',
    },
  },
  selectIcon: {
    color: '#64676B',
    paddingRight: '1rem',
  },
  menuPaper: {
    maxHeight: '10.6rem',
    maxWidth: '18.5rem',
    borderRadius: '0.5rem',
    boxShadow: 'none',
    filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))',
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    fontSize: '16px',
    height: '2.625rem',
    minHeight: '2.625rem',
    color: '#64676B',
    '&.Mui-selected': {
      backgroundColor: '#FFF',
    },
    '&:focus': {
      backgroundColor: '#FFF',
      border: '3px solid #21519933',
    },
    '&:hover': {
      backgroundColor: '#EAF2FF',
    },
  },
}));

const Dropdown = ({
  label,
  options,
  value,
  onChange,
  required,
  supportingText,
  error,
}: Props) => {
  const ref = useRef(null);
  const { classes, cx } = useStyles();
  const hasValue = value !== undefined && value !== '';

  return (
    <FormControl fullWidth size="small" error={!!error}>
      <InputLabel required={required} className={classes.label} error={!!error}>
        {label}
      </InputLabel>
      <Select
        label={label}
        value={value}
        onChange={onChange}
        required={required}
        className={cx(classes.select, { [classes.hasValue]: hasValue })}
        IconComponent={() => (
          <FontAwesomeIcon
            icon={chevronIcon}
            className={cx(classes.selectIcon)}
          />
        )}
        MenuProps={{
          classes: { paper: classes.menuPaper, list: classes.menuList },
          disableAutoFocusItem: true,
          anchorEl: ref.current,
          PaperProps: { style: { maxHeight: 336 } },
        }}
        ref={ref}
        error={!!error}
      >
        {options.map((option) => (
          <MenuItem
            value={option.value}
            key={option.value}
            sx={{ whitespace: 'normal' }}
            className={classes.menuItem}
            disableRipple
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <Box paddingTop="8px">
        <SupportingText text={supportingText} error={error} />
      </Box>
    </FormControl>
  );
};

export default Dropdown;
