import { useQuery } from 'react-query';
import { getCarrierContactInfo } from '../shipment/api';
import { useAuthorizationToken } from '@reibus/frontend-utility';

export default function useCarrierContactInfo(
  cseCarrierId: string,
  isOpen: boolean
) {
  const authorization = useAuthorizationToken();
  return useQuery(
    ['carrierContactInfo', cseCarrierId],
    async () => await getCarrierContactInfo(authorization, cseCarrierId),
    {
      enabled: isOpen && !!authorization,
    }
  );
}
