import { useState, useEffect } from 'react';

import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { displayFormattingValue } from '@reibus/reibus-core-utils';

import useShipmentLocations from 'shipment/hooks/useShipmentLocations';

import { Pay } from './Price';
import { H3, Body } from 'components/SteelUI/atoms/typography';

const LABELS = {
  PRICE_INFORMATION: 'Pay Information',
  TARGET_PAY: 'Target Pay',
  MIN_PAY: 'Min Pay',
  MAX_PAY: 'Max Pay',
};

const useStyles = makeStyles(
  ({ breakpoints, spacing, palette: { grey } }: Theme) => ({
    priceInformation: {
      width: '100%',
      borderLeft: `1px solid ${grey['100']}`,
      paddingLeft: 24,
      [breakpoints.down('sm')]: {
        borderLeft: 'none',
        paddingLeft: 0,
      },
    },
    perMile: {
      color: grey['700'],
      marginTop: spacing(1),
    },
  })
);

type PerMile = {
  perMileMin: string | null;
  perMileMax: string | null;
  targetPerMile: string | null;
};

type Props = {
  targetPay: number | null;
  minPay: number | null;
  maxPay: number | null;
  shipmentId: string;
};

export const PriceInformation = ({
  minPay,
  maxPay,
  targetPay,
  shipmentId,
}: Props) => {
  const classes = useStyles();

  const { data } = useShipmentLocations(shipmentId);
  const [perMile, setPerMile] = useState<PerMile>();

  const pricePerMile = (value: number, miles: number) => {
    const perMile = value / miles;
    const formattedPerMile = `$${displayFormattingValue(perMile, {
      decimalPlaces: 2,
      trailingZeros: true,
    })} / mile`;
    return formattedPerMile;
  };

  useEffect(() => {
    if (data?.distance) {
      setPerMile({
        perMileMin: minPay ? pricePerMile(minPay, data.distance) : null,
        perMileMax: maxPay ? pricePerMile(maxPay, data.distance) : null,
        targetPerMile: targetPay
          ? pricePerMile(targetPay, data.distance)
          : null,
      });
    }
  }, [data]);

  return (
    <Grid item className={classes.priceInformation} xs={12} md={12}>
      <Grid container direction="column">
        <Grid item>
          <H3 weight="semibold">{LABELS.PRICE_INFORMATION}</H3>
        </Grid>
        <Grid container direction="row" justifyContent="space-between">
          <Grid>
            <Pay pay={targetPay} label={LABELS.TARGET_PAY} />
            <Body className={classes.perMile}>{perMile?.targetPerMile}</Body>
          </Grid>

          <Grid>
            <Pay pay={minPay} label={LABELS.MIN_PAY} />
            <Body className={classes.perMile}>{perMile?.perMileMin}</Body>
          </Grid>
          <Grid>
            <Pay pay={maxPay} label={LABELS.MAX_PAY} />
            <Body className={classes.perMile}>{perMile?.perMileMax}</Body>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
