import { LinearProgress } from '@mui/material';
import { withStyles } from '@mui/styles';

const invertValue = (val: number) => {
  //the number should be inverted for vertical translateY, eg: 100% will be 0
  return 100 - val;
};

type transformType = {
  value: number;
};

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: '100%',
    zIndex: 1,
    position: 'absolute',
    borderRadius: 5,
    width: 4,
    border: 0,
  },
  colorPrimary: {
    backgroundColor: '#bdbdbd',
  },
  bar: {
    //vertical line Bar
    transform: ({ value }: transformType) => {
      const hasProgress = value % 100 !== 0;
      return hasProgress
        ? `translateY(${-invertValue(value)}%) !important`
        : '';
    },
    transition: 'none',
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

type progressType = {
  value: number;
};

const ProgressBar = ({ value }: progressType) => {
  return (
    <BorderLinearProgress
      data-testid="customProgressBar"
      variant="determinate"
      value={value}
    />
  );
};

export default ProgressBar;
