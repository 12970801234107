import { useState } from 'react';
import {
  postOtherShipmentDisposition,
  ShipmentDispositionData,
} from 'features/cse/disposition/api';
import Error from 'features/cse/disposition/disposition-modal/Error';
import Submit from 'features/cse/disposition/disposition-modal/flows/other/screens/Submit';
import Success from 'features/cse/disposition/disposition-modal/flows/other/screens/Success';
import { useDispositionContext } from 'features/cse/disposition/contexts/DispositionContext';
import { trackEvent } from 'utils/mixpanel';
import { useAuthorizationToken } from '@reibus/frontend-utility';

type Screen = 'submit' | 'success' | 'error';

const Other = () => {
  const { cseCarrierID, shipmentId, compositeScore, setRefetchNotes, source } =
    useDispositionContext();
  const [screen, setScreen] = useState<Screen>('submit');
  const authToken = useAuthorizationToken();

  const handleOther = async ({
    reason,
    subreason,
    subreasonExplanation,
    comments,
  }: Pick<
    ShipmentDispositionData,
    'reason' | 'subreason' | 'subreasonExplanation' | 'comments'
  >) => {
    const shipmentDispositionData: ShipmentDispositionData = {
      disposition: 'OTHER',
      cseCarrierID,
      shipmentId,
      compositeScore,
      reason,
      subreason,
      subreasonExplanation,
      comments,
      source,
    };

    try {
      await postOtherShipmentDisposition(authToken, shipmentDispositionData);
      setRefetchNotes(true);
      setScreen('success');
      trackEvent('Shipment Disposition - Other', {
        shipmentDispositionData,
      });
    } catch (e) {
      setScreen('error');
    }
  };

  switch (screen) {
    case 'submit':
      return <Submit other={handleOther} />;
    case 'success':
      return <Success />;
    case 'error':
    default:
      return <Error tryAgain={() => setScreen('submit')} />;
  }
};

export default Other;
