import { useEffect, useState } from 'react';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Autocomplete, TextField } from '@mui/material';
import { Field } from 'react-final-form';
import { stringValidator as defaultStringValidator } from './fieldValidators';
import { FormFieldError } from './FormFieldError';
import { makeStyles } from '@mui/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { GoogleAutoCompleteProps } from './AddressField';

type AutocompletePrediction = google.maps.places.AutocompletePrediction;
type AutoCompletionRequest = google.maps.places.AutocompletionRequest;

const useTextFieldStyles = makeStyles(() => ({
  asterisk: {
    color: '#c62828',
    fontSize: '1.2rem',
  },
}));

type TypeAheadResult = {
  description: string;
  place_id: string;
};

const getPlacePredictionsFns = (types: string[]) =>
  useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    options: {
      types,
    } as AutoCompletionRequest,
  });

const getUniquePlaces = (predictions: AutocompletePrediction[]) => {
  const uniquePlaceIds = [
    ...new Set(predictions.map(({ place_id }) => place_id)),
  ];
  return uniquePlaceIds.map((id) =>
    predictions.find(({ place_id }) => id === place_id)
  ) as AutocompletePrediction[];
};

type PlacePredictionFn = (
  opt: google.maps.places.AutocompletionRequest
) => void;

export const GoogleAutoCompleteField = ({
  name,
  label,
  required,
  validator = defaultStringValidator,
  searchTypes = ['(regions)', 'address'], // Include '(regions)' to allow postal codes
  maxResults = 6,
  initialDescription,
  onClear,
}: GoogleAutoCompleteProps) => {
  const classes = useTextFieldStyles();

  const validate = required ? validator : undefined;

  const [description, setDescription] = useState(initialDescription ?? '');

  const placeFetchers: PlacePredictionFn[] = [];
  const places: google.maps.places.AutocompletePrediction[] = [];
  let placesServiceInstance: google.maps.places.PlacesService | null = null;

  searchTypes.forEach((searchType) => {
    const {
      placePredictions,
      getPlacePredictions,
      placesService = null,
    } = getPlacePredictionsFns([searchType]);
    placeFetchers.push(getPlacePredictions);
    places.push(...placePredictions);
    if (!placesServiceInstance) {
      placesServiceInstance = placesService;
    }
  });

  const fetchPlacePredictions = (term: AutoCompletionRequest) => {
    placeFetchers.forEach((placeFetcher) => placeFetcher(term));
  };

  const combinePredictions = (): AutocompletePrediction[] => {
    return getUniquePlaces(places).slice(0, maxResults);
  };

  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        useEffect(() => {
          input.value &&
            placesServiceInstance?.getDetails(
              {
                placeId: input.value,
              },
              (placeDetails) => {
                setDescription(placeDetails?.formatted_address as string);
                fetchPlacePredictions({
                  input: placeDetails?.formatted_address as string,
                });
              }
            );
        }, [placesServiceInstance]);

        return (
          <>
            <Autocomplete
              id="address-autocomplete"
              freeSolo
              options={combinePredictions()}
              onChange={(_, option) => {
                //option is what returns the value when the user has selected from typeahead
                const typedOption = option as TypeAheadResult;
                const { place_id = '', description = '' } = typedOption ?? {};
                input.onChange(place_id);
                setDescription(description);
              }}
              //TODO change types
              getOptionLabel={(option: any) => {
                return option.description;
              }}
              renderOption={(props: any, option: any) => {
                return (
                  <div {...props}>
                    <LocationOnIcon
                      fontSize="small"
                      htmlColor="#C4C4C4"
                      viewBox="-3 -9 32 32"
                    />{' '}
                    {option.description}
                  </div>
                );
              }}
              inputValue={description}
              onInputChange={(_, value, reason) => {
                if (reason === 'clear') onClear?.();
                setDescription(value);
                // Trigger fetching if the input looks like a zip code (5 digits)
                if (/^\d{5}$/.test(value)) {
                  fetchPlacePredictions({ input: value });
                }
              }}
              renderInput={(params) => (
                <TextField
                  data-testid={`googleTextField-${name}`}
                  variant="outlined"
                  {...params}
                  onChange={({ target: { value: input } }) =>
                    fetchPlacePredictions({ input })
                  }
                  value={description}
                  label={label}
                  required={required}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                />
              )}
            />
            <FormFieldError meta={meta} />
          </>
        );
      }}
    </Field>
  );
};

export const exportedForTesting = { getUniquePlaces };
