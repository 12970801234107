import { makeStyles } from '@mui/styles';
import { Theme, Grid, useMediaQuery, Divider } from '@mui/material';
import ShipmentDetails from './ShipmentDetails';
import { AlgoliaShipmentProps } from 'api/shipment-visibility-dashboard';
import ShipmentStatus from './ShipmentStatus/ShipmentStatus';
import ShipmentMap from './ShipmentMap';
import ShipmentDocumentDownloads from './ShipmentDocumentDownloads';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { StopInfo, Routes, Coordinate } from 'api/types';
import { ShipmentTabOption } from 'components/library/ToggleBar';

const useStyles = makeStyles((theme: Theme) => ({
  leftColumn: {
    paddingRight: theme.spacing(6),
  },
  mapContainer: {
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: `0 0 ${theme.spacing(3)} 0`,
  },
  shipmentDetails: {
    marginBottom: 16,
  },
  [theme.breakpoints.down('sm')]: {
    leftColumn: {
      paddingRight: 0,
    },
  },
}));

type ShipmentItemProps = {
  selectedShipment: AlgoliaShipmentProps | undefined;
};

const extractAddressFromGlobalRoute = (globalRouteItem: Routes): StopInfo => {
  const { address, stopType } = globalRouteItem;
  if (!address) {
    throw new Error(
      'Global route entry expected address field, but none was found'
    );
  }

  return {
    coordinate: {
      lat: address.lat,
      lon: address.lon,
    },
    stopType: stopType.value,
  } as StopInfo;
};

const extractLocationCoordinates = (
  selectedShipment: AlgoliaShipmentProps
): Coordinate | undefined => {
  const { truckLocation, currentLocation } = selectedShipment;

  if (!currentLocation) return truckLocation;

  return {
    lat: currentLocation.latitude,
    lon: currentLocation.longitude,
  };
};

const ShipmentItem = ({ selectedShipment }: ShipmentItemProps) => {
  if (!selectedShipment) return <>No shipments available at this time</>;

  const {
    endDate,
    endDateFull,
    startDateFull,
    tenderedAt,
    statusLabel,
    statusCode,
    globalRoute,
    currentLocation,
    id,
  } = selectedShipment;

  const classes = useStyles();
  const mobile = useMediaQuery('(max-width:1175px)');

  const { rl1300PodDownloadButton, rl2459SvdUseP44CurrentLocation } =
    useFlags();

  const globalRouteAsCoords = selectedShipment?.globalRoute.map(
    extractAddressFromGlobalRoute
  );

  return (
    <Grid container direction={mobile ? 'column' : 'row'}>
      <Grid className={classes.leftColumn} sm={mobile ? 12 : 7} item>
        <ShipmentStatus
          endDate={endDate}
          endDateFull={endDateFull}
          startDateFull={startDateFull}
          tenderedAt={tenderedAt}
          statusLabel={statusLabel}
          statusCode={statusCode}
          globalRoute={globalRoute}
          currentLocation={currentLocation}
          shipmentID={id}
        />
      </Grid>
      <Grid sm={mobile ? 12 : 5} item>
        <Grid
          sm
          className={classes.mapContainer}
          item
          data-testid="shipmentMap"
        >
          <ShipmentMap
            routes={globalRouteAsCoords}
            truckLocation={
              rl2459SvdUseP44CurrentLocation
                ? extractLocationCoordinates(selectedShipment)
                : selectedShipment.truckLocation
            }
            globalRoute={globalRouteAsCoords}
            isMarketplaceShipment={selectedShipment.isMarketplaceShipment}
          />
        </Grid>
        <Divider className={classes.divider} />
        <Grid sm item className={classes.shipmentDetails}>
          {/* fix me, only pass ref numbers */}
          <ShipmentDetails shipmentDetails={selectedShipment} />
        </Grid>
        {rl1300PodDownloadButton &&
        selectedShipment.status !== ShipmentTabOption.IN_PROGRESS ? (
          <Grid sm item data-testid="shipment-document-downloads">
            <ShipmentDocumentDownloads shipmentId={id} />
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default ShipmentItem;

export const exportedForTesting = {
  extractAddressFromGlobalRoute,
  extractLocationCoordinates,
};
