export const Download = () => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 7.75H7.25V0.25H5.75V7.75H3.5L6.5 10.75L9.5 7.75ZM0.5 12.25V13.75H12.5V12.25H0.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
