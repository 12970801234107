import PageTitle from 'components/PageTitle';
import PageWrapper from 'components/PageWrapper';
import ShipmentsResults from 'search-shipments/search-shipments-results/ShipmentsResults';
import { SearchShipmentsProvider } from 'search-shipments/SearchShipmentsContext';

import SearchShipmentsForm from 'search-shipments/search-shipments-form/SearchShipmentsForm';

const SearchShipments = () => {
  return (
    <SearchShipmentsProvider>
      <PageWrapper showShortFooter isNewTheme>
        <PageTitle
          title="CAPACITY SOURCING ENGINE"
          description="You can start using the CSE by searching for shipments or carriers."
        />
        <SearchShipmentsForm />
        <ShipmentsResults />
      </PageWrapper>
    </SearchShipmentsProvider>
  );
};

export default SearchShipments;
