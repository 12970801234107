import { Box } from '@mui/material';
import { H3 } from 'components/SteelUI/atoms/typography';
import React from 'react';

type Props = {
  title: string;
  children?: React.ReactNode;
};

const FormSection = ({ title, children }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        paddingBottom: '40px',
      }}
    >
      <H3 weight="bold">{title}</H3>
      {children}
    </Box>
  );
};

export default FormSection;
