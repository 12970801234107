import { useQuery } from 'react-query';

import { getAutoAwardById } from '../Confirmation/api';

const useQueryAutoAward = (id: string) => {
  return useQuery(
    ['auto-award-shipment-confirmation', id],
    () => getAutoAwardById(id),
    { retry: false }
  );
};

export default useQueryAutoAward;
