import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Skeleton, Stack } from '@mui/material';

import { Tab, Tabs } from 'components/SteelUI/atoms';
import { Body, H2 } from 'components/SteelUI/atoms/typography';
import type { CurrentRate } from 'features/LogisticsQuoteManager/hooks/useGetCurrentRates';

import Rates from './Rates';

type TabValue = 'days15' | 'days30';

type Props = {
  hasNeverFetched: boolean;
  isLoading: boolean;
  currentRates: CurrentRate[];
  distance: number;
  freightQuoteRequestId: string;
};
const RatesComparison = ({
  hasNeverFetched,
  isLoading,
  currentRates,
  distance,
  freightQuoteRequestId,
}: Props) => {
  const [selectedTab, setSelectedTab] = useState<TabValue>('days15');
  const calculatorIcon = icon({ name: 'calculator-simple', style: 'light' });
  const warningIcon = icon({ name: 'triangle-exclamation', style: 'solid' });

  const handleChangeTab = (event: React.SyntheticEvent, value: TabValue) => {
    setSelectedTab(value);
  };

  if (hasNeverFetched) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        direction="column"
        height="100%"
        spacing="48px"
      >
        <H2 textAlign="center">Rate Comparison & Markup Will Appear Here</H2>
        <H2>
          <FontAwesomeIcon color="#CACDD1" icon={calculatorIcon} size="6x" />
        </H2>
      </Stack>
    );
  }

  if (isLoading) {
    return (
      <Skeleton
        variant="rounded"
        sx={{
          width: '100%',
          height: '100%',
        }}
      />
    );
  }

  return !currentRates?.length ? (
    <Stack
      alignItems="center"
      justifyContent="center"
      direction="row"
      height="100%"
      spacing="24px"
    >
      <Body size="large">
        <FontAwesomeIcon color="#FFA500" icon={warningIcon} />
      </Body>
      <Body size="large" weight="semibold">
        No Rate Data Available For This Lane
      </Body>
    </Stack>
  ) : (
    <>
      <Tabs value={selectedTab} onChange={handleChangeTab}>
        <Tab label="Prev 15 Days" value="days15" sx={{ fontSize: '18px' }} />
        <Tab label="Prev 30 Days" value="days30" sx={{ fontSize: '18px' }} />
      </Tabs>
      {selectedTab === 'days15' ? (
        <Rates
          datRate={currentRates.find(
            ({ source, timeFrame }) =>
              source === 'DAT' && timeFrame === '15_DAYS'
          )}
          reibusRate={currentRates.find(
            ({ source, timeFrame }) =>
              source === 'REIBUS' && timeFrame === '15_DAYS'
          )}
          distance={distance}
          freightQuoteRequestId={freightQuoteRequestId}
        />
      ) : (
        <Rates
          datRate={currentRates.find(
            ({ source, timeFrame }) =>
              source === 'DAT' && timeFrame === '30_DAYS'
          )}
          reibusRate={currentRates.find(
            ({ source, timeFrame }) =>
              source === 'REIBUS' && timeFrame === '30_DAYS'
          )}
          distance={distance}
          freightQuoteRequestId={freightQuoteRequestId}
        />
      )}
    </>
  );
};

export default RatesComparison;
