import { useState } from 'react';
import {
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TabContext, TabPanel } from '@mui/lab';
import OtherShipmentsItem from './OtherShipmentsItem';
import { getOtherShipments, OtherShipmentsQueryType } from 'shipment/api';
import { useQuery } from 'react-query';
import clsx from 'clsx';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAuthorizationToken } from '@reibus/frontend-utility';

type OtherShipmentsProps = {
  shipmentId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  otherShipmentsTitle: {
    padding: `${theme.spacing(2)} 0`,
    fontWeight: 600,
  },
  container: {
    marginTop: theme.spacing(1),
    padding: 0,
    overflow: 'auto',
    width: '100%',
  },
  item: {
    paddingBottom: theme.spacing(2),
    flexGrow: 1,
  },
  resultsContainer: {
    height: '300px',
  },
  noResults: {
    color: theme.palette.grey['700'],
  },
  containerOthers: {
    maxHeight: '60vh',
    overflowY: 'auto',
  },
}));

const OtherShipments = ({ shipmentId }: OtherShipmentsProps) => {
  const classes = useStyles();
  const { rl1600CseSimilarShipments, rl1604CseMultiShipments } = useFlags();
  const authToken = useAuthorizationToken();

  const OTHER_SHIPMENTS_TYPE_MULTI = 'multi';
  const OTHER_SHIPMENTS_TYPE_SIMILAR = 'similar';

  const defaultTab: OtherShipmentsQueryType = rl1604CseMultiShipments
    ? OTHER_SHIPMENTS_TYPE_MULTI
    : OTHER_SHIPMENTS_TYPE_SIMILAR;
  const [value, setValue] = useState<OtherShipmentsQueryType>(defaultTab);

  const { isLoading, data: otherShipments } = useQuery(
    ['otherShipments', shipmentId, value],
    () => getOtherShipments(authToken, shipmentId, value),
    { enabled: !!shipmentId && !!value && !!authToken }
  );

  const LoadingResults = () => {
    const type = `${value}${
      value === OTHER_SHIPMENTS_TYPE_SIMILAR ? ' ' : '-'
    }`;
    if (!isLoading && otherShipments?.length) return null;
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.resultsContainer}
      >
        {isLoading ? (
          <CircularProgress size={20} />
        ) : otherShipments?.length ? null : (
          <p className={classes.noResults} data-testid="no-other-shipments">
            There are no {type}shipments to display
          </p>
        )}
      </Grid>
    );
  };

  type OtherShipmentsListProps = {
    type: 'similar' | 'multi';
  };

  const OtherShipmentsList = ({ type }: OtherShipmentsListProps) => {
    return (
      <>
        {otherShipments?.map((shipment) => (
          <OtherShipmentsItem
            shipment={shipment}
            type={type}
            key={shipment.shipmentID}
          />
        ))}
      </>
    );
  };

  return (
    <>
      <Typography variant="h3" className={classes.otherShipmentsTitle}>
        Other Shipments
      </Typography>
      <TabContext value={value}>
        <Tabs
          variant="fullWidth"
          onChange={(_, newValue: OtherShipmentsQueryType) =>
            setValue(newValue)
          }
          value={value}
        >
          {rl1604CseMultiShipments ? (
            <Tab label="Multi-Shipment" value={OTHER_SHIPMENTS_TYPE_MULTI} />
          ) : null}
          {rl1600CseSimilarShipments ? (
            <Tab
              label="Similar Shipment"
              value={OTHER_SHIPMENTS_TYPE_SIMILAR}
            />
          ) : null}
        </Tabs>

        {rl1604CseMultiShipments ? (
          <TabPanel value="multi" className={classes.container}>
            <OtherShipmentsList type={OTHER_SHIPMENTS_TYPE_MULTI} />
          </TabPanel>
        ) : null}
        {rl1600CseSimilarShipments ? (
          <TabPanel
            value="similar"
            className={clsx(classes.container, classes.containerOthers)}
          >
            <OtherShipmentsList type={OTHER_SHIPMENTS_TYPE_SIMILAR} />
          </TabPanel>
        ) : null}
        <LoadingResults />
      </TabContext>
    </>
  );
};

export default OtherShipments;
