import {
  DispositionReason,
  DispositionSubreason,
  DispositionType,
} from 'features/cse/disposition/api';

const getDispositionTypeLabel = (
  dispositionType: DispositionType | undefined
): string => {
  switch (dispositionType) {
    case 'COVER':
      return 'Cover';
    case 'DECLINE':
      return 'Decline';
    case 'OTHER':
      return 'Other';
    default:
      return 'N/A';
  }
};

const getDispositionReasonLabel = (
  dispositionReason: DispositionReason | undefined
): string => {
  switch (dispositionReason) {
    case 'EQUIPMENT':
      return 'Equipment';
    case 'CONTACTED':
      return 'Contacted';
    case 'WRONG_INFO':
      return 'Wrong Information';
    case 'OTHER':
      return 'Other';
    case 'PREFERENCE':
      return 'Preference';
    case 'PRICE':
      return 'Price';
    default:
      return 'N/A';
  }
};

const getDispositionSubreasonLabel = (
  dispositionSubreason: DispositionSubreason | undefined
): string => {
  switch (dispositionSubreason) {
    case 'EQUIPMENT_NOT_AVAILABLE':
      return 'Equipment Not Available';
    case 'DOES_NOT_MATCH_REQUIREMENTS':
      return 'Does Not Match Requirements';
    case 'LANE':
      return 'Lane';
    case 'ORIGIN':
      return 'Origin';
    case 'DESTINATION':
      return 'Destination';
    case 'COMMODITY_TYPE':
      return 'Commodity Type';
    case 'NO_ANSWER':
      return 'No Answer';
    case 'WILL_GET_BACK':
      return 'Will Get Back';
    case 'PHONE_NUMBER':
      return 'Phone Number';
    case 'EMAIL':
      return 'Email';
    default:
      return 'N/A';
  }
};

export {
  getDispositionTypeLabel,
  getDispositionReasonLabel,
  getDispositionSubreasonLabel,
};
