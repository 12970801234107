import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  triangleExclamation: {
    color: '#FFB507',
    width: '2rem',
    height: '2em',
  },
}));

const ModalWarningIcon = () => {
  const { classes } = useStyles();

  return (
    <FontAwesomeIcon
      className={classes.triangleExclamation}
      icon={faTriangleExclamation}
    />
  );
};

export default ModalWarningIcon;
