import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Body, H2 } from 'components/SteelUI/atoms/typography';
import type { DigitalFreightAwardStatus } from 'features/cse/AutoAward/types';

const useStyles = makeStyles({
  wrap: {
    borderBottom: '1px solid #EDEEF0',
    paddingRight: 24,
    paddingBottom: 24,
  },
  triangleExclamation: {
    color: '#FFB507',
    width: '3rem',
    height: '3em',
  },
  sorry: {
    lineHeight: 0,
    paddingTop: 8,
    paddingBottom: 8,
  },
});

type Props = {
  status: DigitalFreightAwardStatus;
};

const ShipmentNotAvailable = ({ status }: Props) => {
  const classes = useStyles();

  const icon =
    status === 'ACCEPTED' ? null : (
      <FontAwesomeIcon
        className={classes.triangleExclamation}
        icon={faTriangleExclamation}
      />
    );

  const header =
    status === 'ACCEPTED'
      ? 'This Shipment Has Been Accepted'
      : 'Sorry, This Shipment Is No Longer Available';

  const body =
    status === 'ACCEPTED'
      ? 'A Reibus carrier sales representative will be in touch shortly to confirm the details for this shipment. If you have any questions, please call + 1(888) 663 -0785'
      : 'We will contact you as soon as we find you another shipment. If you have any questions, feel free to call +1 (888) 663-0785';

  return (
    <Grid container flexDirection="column" className={classes.wrap}>
      {icon}
      <H2 className={classes.sorry} weight="semibold">
        {header}
      </H2>
      <Body size="large">{body}</Body>
    </Grid>
  );
};

export default ShipmentNotAvailable;
