import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  footerContainer: {
    color: '#939393',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 400,
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'underline',
    },
  },
  [theme.breakpoints.down(900)]: {
    footerContainer: {
      fontSize: 11,
    },
  },
}));

const currentYear = new Date().getFullYear();

const ShortFooter = () => {
  const classes = useStyles();
  return (
    <div className={classes.footerContainer}>
      <span>
        © {currentYear} Reibus. All rights reserved.{' '}
        <a className={classes.link} href="/privacy-policy">
          Privacy policy
        </a>{' '}
        |{' '}
        <a className={classes.link} href="/terms-of-use">
          Terms of Service
        </a>
      </span>
    </div>
  );
};

export default ShortFooter;
