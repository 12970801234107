import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { sharedStyles } from './shared';

import type { TypographyProps } from './types';

type Size = 'xs' | 'small' | 'medium' | 'large';

type BodyProps = TypographyProps & {
  size?: Size;
};

const FONT_SIZE_MAP: Record<Size, number> = {
  xs: 12,
  small: 14,
  medium: 16,
  large: 18,
};

const LINE_HEIGHT_MAP: Record<Size, number> = {
  xs: 15,
  small: 17.5,
  medium: 20,
  large: 22.5,
};

const bodyStyles = (size: Size = 'medium') => ({
  fontSize: FONT_SIZE_MAP[size],
  lineHeight: `${LINE_HEIGHT_MAP[size]}px`,
});

const useStyles = makeStyles<BodyProps>()((_, { weight, size, ...rest }) => {
  return {
    root: {
      ...sharedStyles({ weight, ...rest }),
      ...bodyStyles(size),
    },
  };
});

const Body = ({ className, ...rest }: BodyProps) => {
  const { classes, cx } = useStyles(rest);
  return (
    <Typography
      variant="body1"
      className={cx(classes.root, className)}
      {...rest}
    />
  );
};

export default Body;
