import { Stack, Box, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { MyLocation, LocationOn } from '@mui/icons-material';

import {
  isMidnight,
  getFlexDatetime,
  formatSchedulingType,
} from 'features/cse/Carrier/utils/data';
import {
  Stop,
  Appointment,
} from 'components/SteelUI/organisms/RouteDetails/RouteDetails';
import { Body, H5 } from 'components/SteelUI/atoms/typography';
import { TooltipEllipsis } from 'components/TooltipEllipsis';

type Props = {
  stopType: 'Pickup' | 'Delivery';
  showNotes?: boolean;
} & Stop;

const useStyles = makeStyles()((theme: Theme) => ({
  icon: {
    fontSize: 30,
    paddingRight: theme.spacing(1),
  },
}));

export const formatDate = ({
  datetime,
  timezone,
  flex = 0,
  schedulingType,
}: Appointment): string => {
  if (!datetime) return 'N/A';
  const date = new Date(datetime);
  const parts = [];

  if (isMidnight(datetime, timezone)) {
    parts.push(
      date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: timezone,
      })
    );
  } else {
    const startDate = date.toLocaleTimeString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      timeZone: timezone,
    });

    const flexDate = getFlexDatetime({ datetime, timezone, flex });
    const shortTimezone = date
      .toLocaleTimeString('en-us', {
        timeZone: timezone,
        timeZoneName: 'short',
      })
      .split(' ')[2];

    parts.push(
      `${startDate} ${flex !== 0 ? `- ${flexDate}` : ''} ${shortTimezone}`
    );
  }

  const schedulingTypeFormatted = formatSchedulingType(schedulingType);
  if (schedulingTypeFormatted) {
    parts.push(schedulingTypeFormatted);
  }
  return parts.join(', ');
};

const RouteStop = ({
  stopType,
  address,
  appointment,
  name,
  notes,
  showNotes = false,
}: Props) => {
  const { classes } = useStyles();
  const { address1, city, region, postalCode } = address;
  return (
    <Stack direction="row">
      <Box className={classes.icon}>
        {stopType === 'Pickup' ? (
          <MyLocation color="primary" fontSize="inherit" />
        ) : (
          <LocationOn color="primary" fontSize="inherit" />
        )}
      </Box>
      <Stack direction="column" spacing={1}>
        <H5 weight="light" color="gray700" textTransform="uppercase">
          {stopType}
        </H5>
        <Box>
          <Body weight="bold">{name}</Body>
          <Body>{address1}</Body>
          <Body>
            {city}, {region} {postalCode}
          </Body>
        </Box>
        <Body size="small" color="gray700">
          Scheduled: {formatDate(appointment)}
        </Body>
        {showNotes && notes ? (
          <Box>
            <Body weight="medium">Notes:</Body>
            <Body size="small" color="gray700">
              <TooltipEllipsis textValue={notes} hasTwoLines />
            </Body>
          </Box>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default RouteStop;
