import { makeStyles } from '@mui/styles';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Theme } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-flex',
    whiteSpace: 'nowrap',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  emailLink: {
    color: '#2D6CCB',
  },
  link: {
    color: '#2D6CCB',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

type Props = {
  email: string;
  subject?: string;
  body?: string;
  handleClick?: () => void;
  iconClassName?: string;
};

const Email = ({ email, subject, body, handleClick, iconClassName }: Props) => {
  const classes = useStyles();

  const { rl1920PrePopulatedEmail } = useFlags();

  const buildMailTo = () => {
    if (rl1920PrePopulatedEmail && email) {
      return (
        <div className={classes.emailLink}>
          <FontAwesomeIcon icon={faEnvelope} className={classes.icon} />
          <a
            className={classes.link}
            data-testid={'emailLink'}
            href={`mailto:${email}?subject=${subject || ''}${
              body ? '&body=' : ''
            }${body || ''}`}
            onClick={handleClick}
          >
            {email}
          </a>
        </div>
      );
    }

    return (
      <>
        <EmailOutlinedIcon className={clsx(classes.icon, iconClassName)} />
        {email ?? 'N/A'}
      </>
    );
  };

  return <div className={classes.root}>{buildMailTo()}</div>;
};

export default Email;
