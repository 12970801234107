import axios from 'axios';
import { getApiRoot } from 'utils/apiUtils';

import { Shipment } from 'shipment/api';
import type {
  Carrier,
  Equipment,
  ShipmentWithScores,
  TopShipmentsFilters,
} from 'features/cse/Carrier/types';

const getTopShipments = async (
  authorization: string,
  cseCarrierID: string
): Promise<ShipmentWithScores[]> => {
  const url = `${getApiRoot()}/carriers/${cseCarrierID}/shipments/scored`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const { status, statusText, data } = await axios.get(url, config);

  if (status !== 200) {
    throw new Error(statusText);
  }

  return data;
};

const getCarrierDetailInfo = async (
  authorization: string,
  cseCarrierId: string
): Promise<Carrier> => {
  const url = `${getApiRoot()}/carriers/${cseCarrierId}/detail-info`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const { data } = await axios.get(url, config);

  return data[0];
};

const getTopShipmentsPaginated = async ({
  authorization,
  cseCarrierID,
  page = 1,
  pageSize = 10,
  filters = {},
}: {
  authorization: string;
  cseCarrierID: string;
  page?: number;
  pageSize?: number;
  filters?: TopShipmentsFilters;
}): Promise<{
  results: ShipmentWithScores[];
  totalCount: number;
  totalPages: number;
}> => {
  const {
    originCoordinates: { latitude: originLat, longitude: originLong } = {},
    destinationCoordinates: {
      latitude: destinationLat,
      longitude: destinationLong,
    } = {},
    equipmentType,
    equipmentListing,
  } = filters;
  const url = `${getApiRoot()}/carriers/${cseCarrierID}/shipments/scored`;
  const params = {
    page,
    pageSize,
    originLat,
    originLong,
    destinationLat,
    destinationLong,
    equipmentType: equipmentType?.join(',') ?? undefined,
    equipmentListing: equipmentListing?.join(',') ?? undefined,
  };
  const config = {
    headers: {
      Authorization: authorization,
    },
    params,
  };
  const { data } = await axios.get(url, config);

  return data;
};

const getCarrierLaneHistory = async (
  authorization: string,
  cseCarrierId: string
): Promise<Shipment[]> => {
  const endpointRoute = `/carriers/${cseCarrierId}/lane-history`;
  const url = `${getApiRoot()}${endpointRoute}`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const { data } = await axios.get(url, config);

  return data.sort(
    (a: Shipment, b: Shipment) =>
      a.startDateDetailed &&
      b.startDateDetailed?.date?.localeCompare(a.startDateDetailed?.date)
  );
};

const getCarrierLaneHistoryPaginated = async (
  authorization: string,
  cseCarrierId: string,
  page = 1,
  pageSize = 20
): Promise<{
  results: Shipment[];
  totalCount: number;
  totalPages: number;
}> => {
  const endpointRoute = `/carriers/${cseCarrierId}/lane-history?page=${page}&pageSize=${pageSize}`;
  const url = `${getApiRoot()}${endpointRoute}`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const { data } = await axios.get(url, config);

  const sortedShipments = data.results.sort(
    (a: Shipment, b: Shipment) =>
      a.startDateDetailed &&
      b.startDateDetailed?.date?.localeCompare(a.startDateDetailed?.date)
  );

  return {
    results: sortedShipments,
    totalCount: data.totalCount,
    totalPages: data.totalPages,
  };
};

const getCarrierAvailableEquipment = async (
  authorization: string,
  cseCarrierId: string
): Promise<Equipment[]> => {
  const endpointRoute = `/carriers/${cseCarrierId}/equipment`;
  const url = `${getApiRoot()}${endpointRoute}`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const { data } = await axios.get(url, config);
  return data.availableEquipment;
};

const deleteEquipment = async ({
  authorization,
  equipmentId,
}: {
  authorization?: string;
  equipmentId: string;
}) => {
  if (!authorization) throw new Error('Missing authorization token');
  const endpointRoute = `/carriers/equipment/${equipmentId}`;
  const url = `${getApiRoot()}${endpointRoute}`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };

  const response = await axios.delete(url, config);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(response.statusText);
  }
};

export {
  getCarrierDetailInfo,
  getTopShipments,
  getCarrierLaneHistory,
  getCarrierAvailableEquipment,
  deleteEquipment,
  getTopShipmentsPaginated,
  getCarrierLaneHistoryPaginated,
};
