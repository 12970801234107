import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Theme,
  FormControl,
  Select as MuiSelect,
  MenuItem,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import { Field, FormSpy } from 'react-final-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormFieldError } from './FormFieldError';
import { SelectOption } from './types';
import { stringValidator } from './fieldValidators';
import { Country } from './AddressFieldTypes';
import {
  CanadaRegions,
  MexicanRegions,
  UniteStatesRegions,
} from './RegionSelectOptions';

const getOptionsBasedOnCountry = (country: Country): SelectOption[] => {
  switch (country) {
    case 'MEX':
      return MexicanRegions;
    case 'CAN':
      return CanadaRegions;
    case 'USA':
      return UniteStatesRegions;
  }
};

const useRegionSelectStyles = makeStyles((theme: Theme) => ({
  inputLabel: {
    top: -6,
    left: 14,
  },
  asterisk: {
    color: '#c62828',
    fontSize: '1.2rem',
  },
  formRoot: {
    '& fieldset > legend > span': {
      paddingRight: theme.spacing(1.5),
    },
  },
}));

type RegionSelectProps = {
  placeholder?: string;
  name: string;
  label: string;
  required?: boolean;
  validator?: (value: string) => string | void;
  labelClassName?: string;
  parentDBName: string;
};

// todo, refactor me so that i'm a wrapper around singleSelect
export const RegionSelect = ({
  placeholder = 'Please Select an Option',
  label,
  required = true,
  name,
  labelClassName,
  validator = stringValidator,
  parentDBName,
}: RegionSelectProps) => {
  const [country, setCountry] = useState<Country>('USA');
  const validate = required ? validator : undefined;
  const options = getOptionsBasedOnCountry(country);

  return (
    <>
      <Field name={name} validate={validate}>
        {({ input, meta }) => {
          const [placeholderSelected, setPlaceholderSelected] = useState(
            !input.value
          );

          const classes = useRegionSelectStyles({ placeholderSelected });

          const handleChange = (event: SelectChangeEvent<string>) => {
            setPlaceholderSelected(!event.target.value);
            input.onChange(event.target.value);
          };
          const formRootClass = required ? { root: classes.formRoot } : {};

          return (
            <FormControl
              fullWidth
              required={required}
              error={false}
              classes={formRootClass}
            >
              <FormSpy
                onChange={({ values }) => {
                  const currentCountry = values[parentDBName]
                    ?.country as Country;
                  if (!currentCountry) return null;

                  if (currentCountry !== country) {
                    setCountry(currentCountry);
                    input.onChange('');
                  }
                }}
              />
              <InputLabel
                id={`${name}-label`}
                className={labelClassName}
                classes={{
                  root: classes.inputLabel,
                  asterisk: classes.asterisk,
                }}
              >
                {label}
              </InputLabel>
              <MuiSelect
                labelId={`${name}-label`}
                value={input.value}
                label={label}
                variant="outlined"
                placeholder={placeholder}
                onChange={(e) => handleChange(e)}
                inputProps={{
                  id: name,
                  onBlur: input.onBlur,
                  onFocus: input.onFocus,
                  name,
                }}
                IconComponent={ExpandMoreIcon}
                MenuProps={{
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                }}
              >
                <MenuItem style={{ display: 'none' }} aria-disabled>
                  <div>
                    <span>{placeholder}</span>
                  </div>
                </MenuItem>
                {options?.map((option) => (
                  <MenuItem key={option.optionName} value={option.optionName}>
                    <div>
                      <span>{option.label}</span>
                    </div>
                  </MenuItem>
                ))}
              </MuiSelect>
              <FormFieldError meta={meta} />
            </FormControl>
          );
        }}
      </Field>
    </>
  );
};
