import { GoogleMapsScriptProvider } from 'hooks/useGoogleMapsScript';
import { Helmet } from 'react-helmet-async';
import ShipmentVisibilityDashboard from 'shipment-visibility-dashboard';

const SVDPage = () => {
  return (
    <>
      <Helmet>
        <link
          href={`https://${process.env.REACT_APP_ALGOLIA_APP_ID}-dsn.algolia.net`}
          rel="preconnect"
        />
        <link
          href={`https://${process.env.REACT_APP_ALGOLIA_APP_ID}-1.algolianet.com`}
          rel="preconnect"
        />
        <link
          href={`https://${process.env.REACT_APP_ALGOLIA_APP_ID}-2.algolianet.com`}
          rel="preconnect"
        />
        <link
          href={`https://${process.env.REACT_APP_ALGOLIA_APP_ID}-3.algolianet.com`}
          rel="preconnect"
        />
      </Helmet>
      <GoogleMapsScriptProvider>
        <ShipmentVisibilityDashboard />
      </GoogleMapsScriptProvider>
    </>
  );
};

export default SVDPage;
