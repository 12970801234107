import { useHistory } from 'react-router-dom';

import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Dialog, IconButton, Button } from '@mui/material';
import { Body, H2 } from 'components/SteelUI/atoms/typography';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(({ spacing }) => ({
  paper: {
    width: 440,
    borderRadius: '8px !important',
    backgroundColor: '#fff',
    padding: spacing(3),
  },
  iconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  link: {
    color: '#2D6CCB',
    textDecoration: 'none',
  },
  phone: { fontWeight: 700, textDecoration: 'none', color: '#212224' },
  button: {
    backgroundColor: '#2D6CCB',
    borderRadius: '8px',
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 700,
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    marginTop: spacing(2),
    '&:hover': {
      backgroundColor: '#215199',
      boxShadow: '0px 1px 3px 1px rgba(33, 34, 36, 0.15)',
    },
  },
}));

type NoQuoteProps = {
  open: boolean;
  handleClose: () => void;
  submittedValues: Record<string, string> | null;
};

const NoQuoteModal = ({ open, handleClose, submittedValues }: NoQuoteProps) => {
  const { classes } = useStyles();
  const history = useHistory();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Stack direction="row" justifyContent="flex-end">
        <IconButton onClick={handleClose} className={classes.iconButton}>
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      </Stack>
      <Stack direction="row" alignItems="center" columnGap="16px">
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          fontSize="32px"
          color="#FFB507"
        />
        <H2>Unable to Generate Quote</H2>
      </Stack>
      <Stack rowGap="24px" marginTop="16px">
        <Body>
          We were not able to generate a quote based on the details provided.
        </Body>
        <Body>
          To re-try generating a quote, please add more information using our
          Book Freight form, by clicking the button below.
        </Body>
        <Body>
          Please contact us at{' '}
          <a className={classes.phone} href="tel:+18888580648">
            (888) 858-0648
          </a>{' '}
          or email us at{' '}
          <a className={classes.link} href="mailto:shippersupport@reibus.com">
            shippersupport@reibus.com
          </a>
          , if you have questions or need additional assistance.
        </Body>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() =>
            history.push({
              pathname: `/request-for-quote`,
              state: {
                equipment: submittedValues?.equipment,
                originAddressPlaceId: submittedValues?.originAddressPlaceId,
                destinationAddressPlaceId:
                  submittedValues?.destinationAddressPlaceId,
                pickupDate: submittedValues?.pickupDate,
              },
            })
          }
          fullWidth
        >
          Book Freight Form
        </Button>
      </Stack>
    </Dialog>
  );
};

export default NoQuoteModal;
