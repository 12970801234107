import { memo } from 'react';
import { Theme } from '@mui/material';
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
} from '@mui/lab';
import { makeStyles } from '@mui/styles';
import ProgressBar from './ProgressBar';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  connector: {
    height: '100%',
    zIndex: 1,
  },
  separator: {
    width: '100%',
  },
  content: {
    padding: '6px 32px',
  },
  item: {
    '&::before': {
      content: 'none',
    },
  },
  iconActive: {
    position: 'relative',
    zIndex: 2,
    width: 30,
    height: 30,
    margin: 0,
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    position: 'relative',
    zIndex: 2,
    width: 30,
    height: 30,
    margin: 0,
  },
}));

type TimelineType = {
  children: JSX.Element;
  progressValue: number;
  isActive: boolean;
  isFirstValue: boolean;
  info: JSX.Element;
};

const ProgressItem = ({
  children,
  progressValue,
  isActive,
  isFirstValue,
  info,
}: TimelineType) => {
  const classes = useStyles();
  return (
    <TimelineItem
      classes={{
        root: classes.item,
      }}
    >
      <TimelineSeparator
        classes={{
          root: classes.separator,
        }}
      >
        <TimelineDot
          classes={{
            root: clsx(isActive ? classes.iconActive : classes.icon),
          }}
        >
          {children}
        </TimelineDot>
        {isFirstValue ? null : (
          <TimelineConnector
            classes={{
              root: classes.connector,
            }}
          >
            <ProgressBar value={progressValue} />
          </TimelineConnector>
        )}
      </TimelineSeparator>
      <TimelineContent
        classes={{
          root: classes.content,
        }}
      >
        {info}
      </TimelineContent>
    </TimelineItem>
  );
};

export default memo(ProgressItem);
