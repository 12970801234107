import {
  CircularProgress,
  Collapse,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import ButtonV2 from 'components/library/ButtonV2';
import { ChangeEvent, useState } from 'react';
import {
  DispositionReason,
  DispositionSubreason,
  ShipmentDispositionData,
} from 'features/cse/disposition/api';
import { useDispositionContext } from 'features/cse/disposition/contexts/DispositionContext';
import { useDispositionsForShipmentContext } from 'features/cse/disposition/contexts/DispositionsForShipmentContext';

type Other = Pick<ShipmentDispositionData, 'reason' | 'subreason' | 'comments'>;

type Props = {
  other: (input: Other) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    padding: theme.spacing(2, 0),
  },
  reason: {
    padding: theme.spacing(0, 2),
    borderRadius: 8,
  },
  selected: {
    backgroundColor: theme.palette.grey['100'],
    padding: theme.spacing(2),
  },
  comments: {
    padding: theme.spacing(2, 0),
  },
  cancel: {
    paddingRight: theme.spacing(3),
  },
  button: {
    width: 120,
  },
}));

const Submit = ({ other }: Props) => {
  const classes = useStyles();
  const { closeDispositionModal, carrierName } = useDispositionContext();
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState<DispositionReason>();
  const [subreason, setSubreason] = useState<DispositionSubreason>();
  const [comments, setComments] = useState<string>();
  const { refetch } = useDispositionsForShipmentContext();

  const handleChangeReason = (event: ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value as DispositionReason);
    setSubreason(undefined);
  };

  const handleChangeSubreason = (event: ChangeEvent<HTMLInputElement>) => {
    setSubreason(event.target.value as DispositionSubreason);
  };

  const handleChangeComments = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setComments(event.target.value);
  };

  const handleDeclineShipment = async () => {
    try {
      setIsLoading(true);
      await other({
        reason,
        subreason,
        comments,
      });
    } finally {
      setIsLoading(false);
      refetch();
    }
  };

  const disabledForRequiredFields = () => {
    if (isLoading) return true;
    if (!reason) return true;
    if (reason === 'OTHER') return comments ? false : true;
    return subreason ? false : true;
  };

  return (
    <>
      <DialogTitle>
        <Typography variant="h3">Other</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          Another outcome of the interaction with {carrierName}
        </Typography>
        <div className={classes.divider}>
          <Divider />
        </div>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            aria-label="Reason"
            name="reason"
            value={reason}
            onChange={handleChangeReason}
          >
            <div
              className={clsx(classes.reason, {
                [classes.selected]: reason === 'CONTACTED',
              })}
            >
              <FormControlLabel
                value="CONTACTED"
                control={<Radio />}
                label={<Typography variant="caption">Contacted</Typography>}
              />
              <Collapse in={reason === 'CONTACTED'}>
                <div className={classes.divider}>
                  <Divider />
                </div>
                <RadioGroup
                  aria-label="Reason"
                  name="contactedSubreason"
                  value={subreason}
                  onChange={handleChangeSubreason}
                >
                  <Grid container direction="row">
                    <Grid item>
                      <FormControlLabel
                        value="NO_ANSWER"
                        control={<Radio />}
                        label={
                          <Typography variant="caption">No answer</Typography>
                        }
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        value="WILL_GET_BACK"
                        control={<Radio />}
                        label={
                          <Typography variant="caption">
                            Will get back
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        value="EMAIL"
                        control={<Radio />}
                        label={
                          <Typography variant="caption">Emailed</Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </Collapse>
            </div>
            <div
              className={clsx(classes.reason, {
                [classes.selected]: reason === 'WRONG_INFO',
              })}
            >
              <FormControlLabel
                value="WRONG_INFO"
                control={<Radio />}
                label={
                  <Typography variant="caption">Wrong information</Typography>
                }
              />
              <Collapse in={reason === 'WRONG_INFO'}>
                <div className={classes.divider}>
                  <Divider />
                </div>
                <RadioGroup
                  aria-label="Reason"
                  name="wrongInfoSubreason"
                  value={subreason}
                  onChange={handleChangeSubreason}
                >
                  <Grid container direction="row">
                    <Grid item>
                      <FormControlLabel
                        value="PHONE_NUMBER"
                        control={<Radio />}
                        label={
                          <Typography variant="caption">
                            Phone Number
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        value="EMAIL"
                        control={<Radio />}
                        label={<Typography variant="caption">Email</Typography>}
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </Collapse>
            </div>
            <div className={classes.reason}>
              <FormControlLabel
                value="OTHER"
                control={<Radio />}
                label={<Typography variant="caption">Other</Typography>}
              />
            </div>
          </RadioGroup>
        </FormControl>
        <div className={classes.comments}>
          <TextField
            variant="outlined"
            label="Comments"
            onChange={handleChangeComments}
            multiline
            fullWidth
            required={reason === 'OTHER'}
          />
        </div>
        <Grid container direction="row-reverse">
          <Grid item>
            <ButtonV2
              onClick={handleDeclineShipment}
              className={classes.button}
              disabled={disabledForRequiredFields()}
            >
              {isLoading ? <CircularProgress size={20} /> : 'Save'}
            </ButtonV2>
          </Grid>
          <Grid item className={classes.cancel}>
            <ButtonV2
              variant="outlined"
              onClick={() => closeDispositionModal('CANCEL')}
              className={classes.button}
              disabled={isLoading}
            >
              Cancel
            </ButtonV2>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default Submit;
