import ButtonV2 from 'components/library/ButtonV2';
import { useState } from 'react';
import AddEquipmentDrawer from './AddEquipmentDrawer';

type Props = {
  cseCarrierID: string;
  mcNumber?: string;
  dotNumber?: string;
};

const AddEquipment = ({ cseCarrierID, mcNumber, dotNumber }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ButtonV2 size="large" onClick={() => setOpen(!open)}>
        Add Equipment
      </ButtonV2>
      <AddEquipmentDrawer
        cseCarrierID={cseCarrierID}
        mcNumber={mcNumber}
        dotNumber={dotNumber}
        isOpen={open}
        handleClose={() => setOpen(false)}
      />
    </>
  );
};

export default AddEquipment;
