import { Theme, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { faWeightHanging, faCalendar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  displayFormattingValue,
  valuesFormatList,
} from '@reibus/reibus-core-utils';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    justifyContent: 'center',
    width: 'auto',
    marginTop: theme.spacing(3),
    padding: 0,
  },
  title: {
    fontSize: 20,
  },
  informationContainer: {
    padding: 0,
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    width: '50%',
    margin: `0px ${theme.spacing(1)}`,
  },
  value: {
    textAlign: 'right',
    width: '40%',
  },
  noDates: {
    textAlign: 'center',
    marginTop: theme.spacing(5),
  },
  [theme.breakpoints.down(900)]: {
    value: {
      textAlign: 'left',
    },
    subtitle: {
      width: 'auto',
    },
    noDates: {
      textAlign: 'left',
    },
  },
  icons: {
    color: '#2D6CCB',
    width: '1.5rem',
    height: '1.5em',
  },
  [theme.breakpoints.only('xs')]: {
    noDates: {
      textAlign: 'center',
    },
  },
}));

type props = {
  weight: number;
  pickupDate?: string;
  deliveryDate?: string;
};

type containerProps = {
  name: string;
  value: string;
  icon: JSX.Element;
};

const {
  listings: { weight: commaSeparated },
} = valuesFormatList;

const InformationContainer = ({ name, value, icon }: containerProps) => {
  const classes = useStyles();
  return (
    <>
      <Container className={classes.informationContainer}>
        {icon}
        <Typography className={classes.subtitle}>{name}:</Typography>
        <Typography className={classes.value}>{value}</Typography>
      </Container>
    </>
  );
};

const AdditionalInformation = ({ pickupDate, deliveryDate, weight }: props) => {
  const classes = useStyles();

  const displayDateFormat = (date: Date) => {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };
  return (
    <Container className={classes.container}>
      <Typography className={classes.title} variant="h5">
        Additional Information
      </Typography>
      {weight ? (
        <InformationContainer
          value={`${displayFormattingValue(weight, commaSeparated)} lbs`}
          icon={
            <FontAwesomeIcon className={classes.icons} icon={faWeightHanging} />
          }
          name={'Weight'}
        />
      ) : null}
      {pickupDate ? (
        <InformationContainer
          value={displayDateFormat(new Date(pickupDate))}
          icon={<FontAwesomeIcon className={classes.icons} icon={faCalendar} />}
          name={'Pickup Date'}
        />
      ) : null}
      {deliveryDate ? (
        <InformationContainer
          value={displayDateFormat(new Date(deliveryDate))}
          icon={<FontAwesomeIcon className={classes.icons} icon={faCalendar} />}
          name={'Delivery Date'}
        />
      ) : null}

      {!pickupDate || !deliveryDate ? (
        <Typography className={classes.noDates}>
          {!pickupDate && 'Pickup'} {!pickupDate && !deliveryDate && ' and '}{' '}
          {!deliveryDate && 'Delivery'} date not specified.
        </Typography>
      ) : null}
    </Container>
  );
};

export default AdditionalInformation;
