import { Box, Paper } from '@mui/material';
import { useSearchByCarrierContext } from 'features/cse/search/SearchByCarrier/SearchByCarrierContext';
import NoResults from './components/NoResults';
import Pagination from 'components/SteelUI/organisms/Pagination';
import ResultsTable, { BodyCell, HeadCell } from './components/ResultsTable';
import { initCap } from 'features/cse/Carrier/utils/data';

const headCells: HeadCell[] = [
  {
    label: 'Carrier Name',
    width: '25%',
    sortBy: 'carrierName',
  },
  {
    label: 'MC#',
    width: '10%',
    align: 'right',
  },
  {
    label: 'DOT#',
    width: '10%',
    align: 'right',
  },
  {
    label: 'HQ Location',
    width: '20%',
    sortBy: 'headquarterLabel',
  },
  {
    label: 'Status',
    width: '10%',
    sortBy: 'status',
  },
  {
    label: 'Account Owner',
    width: '25%',
    sortBy: 'accountOwnerName',
  },
];

const bodyCells: BodyCell[] = [
  {
    label: 'carrierName',
    href: '/cse/carrier',
  },
  {
    label: 'carrierMCNumber',
    align: 'right',
  },
  {
    label: 'carrierDOTNumber',
    align: 'right',
  },
  {
    label: 'headquarterLabel',
  },
  {
    label: 'status',
    formatting: initCap,
  },
  {
    label: 'accountOwnerName',
  },
];

const SearchByCarrierResults = () => {
  const { isLoading, page, setPage, results, sort, setSort } =
    useSearchByCarrierContext();

  if (isLoading || !results) {
    return null;
  }

  if (!results.hits.length) {
    return <NoResults />;
  }

  const { hits, nbHits, hitsPerPage } = results;

  return (
    <Box sx={{ p: '0 24px' }}>
      <Paper sx={{ p: '24px' }}>
        <Box
          sx={{
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '30px',
            pb: '24px',
          }}
        >
          Search Results
        </Box>
        <ResultsTable
          hits={hits}
          headCells={headCells}
          bodyCells={bodyCells}
          isLoading={isLoading}
          currentSort={sort}
          setSort={setSort}
        />
        <Box sx={{ pt: '24px' }}>
          <Pagination
            page={page}
            count={nbHits}
            rowsPerPage={hitsPerPage}
            changePage={setPage}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default SearchByCarrierResults;
