import axios from 'axios';

import { getApiRoot } from 'utils/apiUtils';
import type { ShipmentDisposition } from '../disposition/api';
import type { DigitalFreightAward } from 'features/cse/AutoAward/types';

type ShipmentStatusHistory = {
  status: string;
  createdAt: Date;
};

type AutoAwardLog = Pick<
  DigitalFreightAward,
  'notifiedAt' | 'offerAmount' | 'carrier' | 'sentTo'
> & {
  offerSource: 'LANE_HISTORY' | 'GREENSCREENS' | 'MANUAL_TRIGGER';
};

type ActivityLog = ShipmentDisposition | ShipmentStatusHistory | AutoAwardLog;

const getActivityLogByShipmentId = async (
  authorization: string,
  shipmentId: string
): Promise<ActivityLog[]> => {
  if (!authorization) {
    throw new Error('missing authorization when querying activity log');
  }
  if (!shipmentId) {
    throw new Error('missing shipmentId when querying activity log');
  }
  const url = `${getApiRoot()}/shipments/${shipmentId}/activity-log`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };

  const {
    data: { activityLog = [] },
  } = await axios.get(url, config);

  return activityLog;
};

export type { ShipmentStatusHistory, ActivityLog, AutoAwardLog };
export { getActivityLogByShipmentId };
