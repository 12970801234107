import { format } from 'date-fns';
import { displayFormattingValue } from '@reibus/reibus-core-utils';
import _ from 'lodash';

const options = {
  trailingZeros: true,
  decimalPlaces: 2,
};
const formatAutoAwardDate = (date?: string) =>
  !date ? 'N/A' : format(new Date(date), 'P');

const formatAutoAwardTime = (date?: string) =>
  !date ? 'N/A' : format(new Date(date), 'p');

const formatAutoAwardRate = (rate?: number) =>
  !rate ? 'N/A' : `$${displayFormattingValue(rate, options)}`;

const formatAutoAwardStatus = (status?: string) =>
  !status ? 'N/A' : _.capitalize(status);

const formatAutoAwardCarrierOfferContactsEmails = (
  offerContacts: { email: string }[]
) => {
  if (!offerContacts.length) return 'N/A';
  const emails = offerContacts.map(({ email }) => email).join(', ');
  return emails ?? 'N/A';
};

export const getMarginPercentage = ({
  offerAmount,
  customerPay,
}: {
  offerAmount: number;
  customerPay: number;
}) => {
  const margin = customerPay - offerAmount;
  return (margin / customerPay) * 100;
};

const formatAutoAwardMarginPercentage = ({
  offerAmount,
  customerPay,
}: {
  offerAmount: number;
  customerPay: number;
}) => {
  // customerPay is what Reibus receives from the customer wanting to ship the load
  // offerAmount is Reibus offers to the carrier for shipping the load
  // margin is the difference between what Reibus is receiving and paying out
  // margin percentage is the ratio of the margin to the offer amount
  if (!offerAmount || !customerPay) return 'N/A';

  const marginPercentage = getMarginPercentage({ offerAmount, customerPay });
  return `${displayFormattingValue(marginPercentage, {
    decimalPlaces: 0,
    trailingZeros: false,
  })}%`;
};

export {
  formatAutoAwardDate,
  formatAutoAwardTime,
  formatAutoAwardRate,
  formatAutoAwardStatus,
  formatAutoAwardCarrierOfferContactsEmails,
  formatAutoAwardMarginPercentage,
};
