import { ReactNode } from 'react';
import { Box, Divider, Stack, Drawer as MuiDrawer } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkLarge } from '@fortawesome/pro-light-svg-icons';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  drawer: {
    '& > .MuiPaper-root': {
      top: '4.5rem',
      height: 'calc(100% - 4.5rem)',
    },
  },
  header: {
    padding: '16px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '125%',
  },
  icon: {
    cursor: 'pointer',
    height: '16px',
    width: '16px',
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  variant?: 'persistent' | 'temporary';
  title: string;
  children: ReactNode;
};

const Drawer = ({ open, onClose, variant, title, children }: Props) => {
  const classes = useStyles();

  return (
    <MuiDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      variant={variant}
      className={classes.drawer}
      hideBackdrop
    >
      <Stack divider={<Divider />}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className={classes.header}
        >
          <Box className={classes.title}>{title}</Box>
          <FontAwesomeIcon
            onClick={onClose}
            icon={faXmarkLarge}
            className={classes.icon}
          />
        </Stack>
        {children}
      </Stack>
    </MuiDrawer>
  );
};

export default Drawer;
