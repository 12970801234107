import Loader from 'components/Loader';
import type { ReactNode } from 'react';

import { Card, Container, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import PageTitle from 'components/PageTitle';
import PageWrapper from 'components/PageWrapper';
import SkeletonWrapper from 'components/library/SkeletonWrapper';
import { ShipmentContextProvider } from 'shipment/ShipmentContext';
import { usePageView } from 'utils/mixpanel';

import BasicInfo from 'shipment/BasicInfo';
import TopCarriers from 'shipment/top-carriers';
import Locations from 'shipment/locations';
import AdditionalInformation from 'shipment/additional-information';
import OtherShipments from './other-shipments/OtherShipments';
import { DispositionsForShipmentContextProvider } from 'features/cse/disposition/contexts/DispositionsForShipmentContext';
import useShipmentDetails from 'shipment/hooks/useShipmentDetails';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    paddingBottom: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(2),
  },
  notFound: {
    textAlign: 'center',
  },
  [theme.breakpoints.up('md')]: {
    otherShipmentContainer: {
      padding: `0 0 0 ${theme.spacing(2)}`,
    },
  },
}));

const ShipmentPageWrapper = ({
  shipmentId,
  children,
}: {
  shipmentId: string;
  children: React.ReactNode;
}) => {
  return (
    <PageWrapper showShortFooter isNewTheme title={`Shipment (${shipmentId})`}>
      <PageTitle title="Shipment" />
      <Container maxWidth="xl">
        <>{children}</>
      </Container>
    </PageWrapper>
  );
};

const ShipmentPageCard = ({
  isLoading = false,
  children,
}: {
  isLoading?: boolean;
  children: ReactNode;
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.item}>
      <Card
        className={classes.card}
        sx={{
          boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10)',
          borderRadius: '8px',
          borderColor: '#EDEEF0',
        }}
      >
        <SkeletonWrapper isLoading={isLoading}>{children}</SkeletonWrapper>
      </Card>
    </Grid>
  );
};

const Shipment = () => {
  const {
    rl1452ShipmentLocations,
    rl1540ShipmentAdditionalInformation,
    rl1600CseSimilarShipments,
    rl1604CseMultiShipments,
  } = useFlags();

  const classes = useStyles();
  const { shipmentId } = useParams() as { shipmentId: string };

  usePageView('Page View: Shipment', { shipmentId: shipmentId });

  const { isLoading, data } = useShipmentDetails(shipmentId);

  if (!data) {
    return (
      <ShipmentPageWrapper shipmentId={shipmentId}>
        <Loader hasLoaded={!isLoading}>
          <Grid container justifyContent="center">
            <Grid item>
              <Card className={classes.card}>
                <h2 className={classes.notFound}>404 Shipment not found</h2>
              </Card>
            </Grid>
          </Grid>
        </Loader>
      </ShipmentPageWrapper>
    );
  }

  const {
    shipmentTMSCompanies: { tmsName: customerName = '' },
    id: turvoId,
    equipmentNeededDesc,
    services,
    commodities,
    minPrice,
    maxPrice,
    netWeight,
    netWeightUnit,
    targetPay,
    label,
    loadboardPosting,
  } = data;

  const displayOtherShipments =
    rl1600CseSimilarShipments || rl1604CseMultiShipments;

  return (
    <ShipmentContextProvider shipmentId={shipmentId}>
      <ShipmentPageWrapper shipmentId={shipmentId}>
        <DispositionsForShipmentContextProvider shipmentId={shipmentId}>
          <Grid container>
            <ShipmentPageCard isLoading={isLoading}>
              <BasicInfo
                customerName={customerName}
                shipmentId={shipmentId}
                statusLabel={label}
                turvoId={turvoId}
              />
            </ShipmentPageCard>
            {!rl1452ShipmentLocations ? null : (
              <ShipmentPageCard>
                <Locations shipmentId={shipmentId} />
              </ShipmentPageCard>
            )}
            {!rl1540ShipmentAdditionalInformation ? null : (
              <ShipmentPageCard>
                <AdditionalInformation
                  weightUnit={netWeightUnit}
                  equipmentNeededDesc={equipmentNeededDesc}
                  services={services}
                  commodities={commodities}
                  weight={netWeight}
                  minPay={minPrice}
                  maxPay={maxPrice}
                  targetPay={targetPay}
                  shipmentId={shipmentId}
                  postings={loadboardPosting}
                />
              </ShipmentPageCard>
            )}
            <Grid item xs={12} md={displayOtherShipments ? 8 : 12}>
              <ShipmentPageCard>
                <TopCarriers shipmentId={shipmentId} reibusId={turvoId} />
              </ShipmentPageCard>
            </Grid>
            {displayOtherShipments ? (
              <Grid
                item
                xs={12}
                md={4}
                className={classes.otherShipmentContainer}
              >
                <ShipmentPageCard>
                  <OtherShipments shipmentId={shipmentId} />
                </ShipmentPageCard>
              </Grid>
            ) : null}
          </Grid>
        </DispositionsForShipmentContextProvider>
      </ShipmentPageWrapper>
    </ShipmentContextProvider>
  );
};

export default Shipment;
