import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(4),
  },
}));

type Props = {
  description?: string;
};

const NoResults = ({ description }: Props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item className={classes.container}>
        <Typography variant="body2">
          {description || 'No results found.'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NoResults;
