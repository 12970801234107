import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

type PlaceResult = google.maps.places.PlaceResult;
type GeocoderAddressComponent = google.maps.GeocoderAddressComponent;

type Coordinates = { latitude: string; longitude: string };
type GoogleMapAddress = {
  postal_code?: string;
  country?: string;
  administrative_area_level_1?: string;
  locality?: string;
  route?: string;
  street_number?: string;
  sublocality_level_1?: string;
};

const getCoordinatesGeocode = async (
  placeDetails: PlaceResult | null
): Promise<Coordinates> => {
  const geocodes = await geocodeByAddress(
    placeDetails?.formatted_address as string
  );
  const { lat, lng } = await getLatLng(geocodes[0]);
  return {
    latitude: String(lat),
    longitude: String(lng),
  };
};

const transformAddress = (components?: GeocoderAddressComponent[]) => {
  if (!components) {
    return {
      address1: '',
      city: '',
      region: '',
    };
  }
  //These are the fields we care about returned by Google API
  const addressFields = [
    'street_number',
    'route',
    'locality',
    'sublocality_level_1',
    'administrative_area_level_1',
  ];

  const address: GoogleMapAddress = {};
  for (const field of addressFields) {
    for (const component of components) {
      if (component.types.includes(field)) {
        address[field as keyof GoogleMapAddress] = component.short_name;
      }
    }
  }

  const address1 = [address.street_number, address.route]
    .filter(Boolean)
    .join(' ');
  const city = address.locality || address.sublocality_level_1 || '';
  const stateProvince = address.administrative_area_level_1 ?? '';

  return {
    address1,
    city,
    stateProvince,
  };
};

const convertPlaceIdToCoordinates = (
  placeId: string,
  placesService: google.maps.places.PlacesService | null,
  setIsFetching: (isLoading: boolean) => void,
  setCoordinates: (coordinates: Coordinates | null) => void
) => {
  placesService?.getDetails({ placeId }, async (placeDetails) => {
    try {
      setIsFetching(true);
      const coordinates = await getCoordinatesGeocode(placeDetails);
      setCoordinates(coordinates);
    } catch (error) {
      console.error(error);
      setCoordinates(null);
    } finally {
      setIsFetching(false);
    }
  });
};

export { getCoordinatesGeocode, transformAddress, convertPlaceIdToCoordinates };
export type { Coordinates };
