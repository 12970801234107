import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { Skeleton } from '@mui/lab';

type Props = {
  rows?: number;
  columns?: number;
};

const LoadingTable = ({ rows = 20, columns = 6 }: Props) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {Array(columns)
            .fill('')
            .map((_, index) => (
              <TableCell key={`loading-column-${index}`}>
                <Skeleton />
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {Array(rows)
          .fill('')
          .map((_, rowIndex) => (
            <TableRow key={`loading-row-${rowIndex}`}>
              {Array(columns)
                .fill('')
                .map((_, columnIndex) => (
                  <TableCell key={`loading-rowcolumn-${columnIndex}`}>
                    <Skeleton />
                  </TableCell>
                ))}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default LoadingTable;
