import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Theme,
  FormControl,
  Select as MuiSelect,
  MenuItem,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import { Field } from 'react-final-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormFieldError } from './FormFieldError';
import { SelectOption } from './types';
import { stringValidator } from './fieldValidators';

const useSingleSelectStyles = makeStyles((theme: Theme) => ({
  asterisk: {
    color: '#c62828',
    fontSize: '1.2rem',
  },
  formRoot: {
    '& fieldset > legend > span': {
      paddingRight: theme.spacing(1.5),
    },
  },
}));

type SingleSelectProps = {
  placeholder?: string;
  name: string;
  label: string;
  required?: boolean;
  options: SelectOption[];
  validator?: (value: string) => string | void;
  labelClassName?: string;
  defaultValue?: string;
};

export const SingleSelect = ({
  options,
  placeholder = 'Please Select an Option',
  label,
  required = true,
  name,
  labelClassName,
  defaultValue,
  validator = stringValidator,
}: SingleSelectProps) => {
  const validate = required ? validator : undefined;
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        const [placeholderSelected, setPlaceholderSelected] = useState(
          !input.value
        );

        const classes = useSingleSelectStyles({ placeholderSelected });

        const handleChange = (event: SelectChangeEvent<string>) => {
          setPlaceholderSelected(!event.target.value);
          input.onChange(event.target.value);
        };
        const formRootClass = required ? { root: classes.formRoot } : {};

        return (
          <FormControl
            fullWidth
            required={required}
            error={false}
            classes={formRootClass}
          >
            <InputLabel
              id={`${name}-label`}
              className={labelClassName}
              classes={{
                asterisk: classes.asterisk,
              }}
            >
              {label}
            </InputLabel>
            <MuiSelect
              labelId={`${name}-label`}
              value={input.value}
              label={label}
              variant="outlined"
              placeholder={placeholder}
              onChange={(event) => handleChange(event)}
              inputProps={{
                id: name,
                onBlur: input.onBlur,
                onFocus: input.onFocus,
                name,
              }}
              IconComponent={ExpandMoreIcon}
              defaultValue={defaultValue}
            >
              <MenuItem style={{ display: 'none' }} aria-disabled>
                <div>
                  <span>{placeholder}</span>
                </div>
              </MenuItem>
              {options?.map((option) => (
                <MenuItem key={option.optionName} value={option.optionName}>
                  <div>
                    <span>{option.label}</span>
                  </div>
                </MenuItem>
              ))}
            </MuiSelect>
            <FormFieldError meta={meta} />
          </FormControl>
        );
      }}
    </Field>
  );
};
