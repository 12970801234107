import {
  CircularProgress,
  Collapse,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import ButtonV2 from 'components/library/ButtonV2';
import { ChangeEvent, useState } from 'react';
import {
  DispositionReason,
  DispositionSubreason,
  ShipmentDispositionData,
} from 'features/cse/disposition/api';
import { useDispositionContext } from 'features/cse/disposition/contexts/DispositionContext';
import { useQueryClient } from 'react-query';
import { useDispositionsForShipmentContext } from 'features/cse/disposition/contexts/DispositionsForShipmentContext';

type DeclineShipment = Pick<
  ShipmentDispositionData,
  'reason' | 'subreason' | 'subreasonExplanation' | 'comments'
>;

type Props = {
  declineShipment: (input: DeclineShipment) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    padding: theme.spacing(2, 0),
  },
  reason: {
    padding: theme.spacing(0, 2),
    borderRadius: 8,
  },
  selected: {
    backgroundColor: theme.palette.grey['100'],
    padding: theme.spacing(2),
  },
  comments: {
    padding: theme.spacing(2, 0),
  },
  cancel: {
    paddingRight: theme.spacing(3),
  },
  button: {
    width: 120,
  },
  numericInput: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

const Submit = ({ declineShipment }: Props) => {
  const classes = useStyles();
  const { closeDispositionModal, carrierName, cseCarrierID, shipmentId } =
    useDispositionContext();
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState<DispositionReason>();
  const [subreason, setSubreason] = useState<DispositionSubreason>();
  const [subreasonExplanation, setSubreasonExplanation] = useState<string>();
  const [comments, setComments] = useState<string>();
  const queryClient = useQueryClient();
  const { refetch } = useDispositionsForShipmentContext();

  const handleChangeReason = (event: ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value as DispositionReason);
    setSubreason(undefined);
    setSubreasonExplanation(undefined);
  };

  const handleChangeSubreason = (event: ChangeEvent<HTMLInputElement>) => {
    setSubreason(event.target.value as DispositionSubreason);
  };

  const handleChangeSubreasonExplanation = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setSubreasonExplanation(event.target.value);
  };

  const handleChangeComments = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setComments(event.target.value);
  };

  const handleDeclineShipment = async () => {
    try {
      setIsLoading(true);
      await declineShipment({
        reason,
        subreason,
        subreasonExplanation,
        comments,
      });
      if (reason === 'PRICE') {
        queryClient.invalidateQueries([
          'shipmentDispositionDetails',
          cseCarrierID,
          shipmentId,
          ['OTHER', 'DECLINE'],
        ]);
      }
    } finally {
      setIsLoading(false);
      refetch();
    }
  };

  const disabledForRequiredFields = () => {
    if (isLoading) return true;
    if (!reason) return true;
    if (reason === 'OTHER') return comments ? false : true;
    if (reason === 'PRICE') return subreasonExplanation ? false : true;
    return subreason ? false : true;
  };

  return (
    <>
      <DialogTitle>
        <Typography variant="h3">Decline Shipment</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          {carrierName} is declining the shipment because of
        </Typography>
        <div className={classes.divider}>
          <Divider />
        </div>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            aria-label="Reason"
            name="reason"
            value={reason}
            onChange={handleChangeReason}
          >
            <div
              className={clsx(classes.reason, {
                [classes.selected]: reason === 'EQUIPMENT',
              })}
            >
              <FormControlLabel
                value="EQUIPMENT"
                control={<Radio />}
                label={<Typography variant="caption">Equipment</Typography>}
              />
              <Collapse in={reason === 'EQUIPMENT'}>
                <div className={classes.divider}>
                  <Divider />
                </div>
                <RadioGroup
                  aria-label="Reason"
                  name="equipmentSubreason"
                  value={subreason}
                  onChange={handleChangeSubreason}
                >
                  <Grid container direction="row">
                    <Grid item>
                      <FormControlLabel
                        value="EQUIPMENT_NOT_AVAILABLE"
                        control={<Radio />}
                        label={
                          <Typography variant="caption">
                            Equipment not available
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        value="DOES_NOT_MATCH_REQUIREMENTS"
                        control={<Radio />}
                        label={
                          <Typography variant="caption">
                            Doesn&apos;t match requirements
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </Collapse>
            </div>
            <div
              className={clsx(classes.reason, {
                [classes.selected]: reason === 'PREFERENCE',
              })}
            >
              <FormControlLabel
                value="PREFERENCE"
                control={<Radio />}
                label={<Typography variant="caption">Preference</Typography>}
              />
              <Collapse in={reason === 'PREFERENCE'}>
                <div className={classes.divider}>
                  <Divider />
                </div>
                <RadioGroup
                  aria-label="Reason"
                  name="equipmentSubreason"
                  value={subreason}
                  onChange={handleChangeSubreason}
                >
                  <Grid container direction="row">
                    <Grid item>
                      <FormControlLabel
                        value="LANE"
                        control={<Radio />}
                        label={<Typography variant="caption">Lane</Typography>}
                      />
                    </Grid>
                    <Grid>
                      <FormControlLabel
                        value="ORIGIN"
                        control={<Radio />}
                        label={
                          <Typography variant="caption">Origin</Typography>
                        }
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        value="DESTINATION"
                        control={<Radio />}
                        label={
                          <Typography variant="caption">Destination</Typography>
                        }
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        value="COMMODITY_TYPE"
                        control={<Radio />}
                        label={
                          <Typography variant="caption">
                            Commodity Type
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </Collapse>
            </div>
            <div
              className={clsx(classes.reason, {
                [classes.selected]: reason === 'PRICE',
              })}
            >
              <FormControlLabel
                value="PRICE"
                control={<Radio />}
                label={
                  <Typography variant="caption">
                    {`Price${reason === 'PRICE' ? ' (USD)' : ''}`}
                  </Typography>
                }
              />
              <Collapse in={reason === 'PRICE'}>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  value={subreasonExplanation}
                  className={classes.numericInput}
                  onChange={handleChangeSubreasonExplanation}
                  onWheel={(e) =>
                    e.currentTarget.querySelector('input')?.blur()
                  }
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    min: 0,
                  }}
                  style={{ backgroundColor: 'white' }}
                />
              </Collapse>
            </div>
            <div className={classes.reason}>
              <FormControlLabel
                value="OTHER"
                control={<Radio />}
                label={<Typography variant="caption">Other</Typography>}
              />
            </div>
          </RadioGroup>
        </FormControl>
        <div className={classes.comments}>
          <TextField
            variant="outlined"
            label="Comments"
            onChange={handleChangeComments}
            multiline
            fullWidth
            required={reason === 'OTHER'}
          />
        </div>
        <Grid container direction="row-reverse">
          <Grid item>
            <ButtonV2
              onClick={handleDeclineShipment}
              className={classes.button}
              disabled={disabledForRequiredFields()}
            >
              {isLoading ? <CircularProgress size={20} /> : 'Decline'}
            </ButtonV2>
          </Grid>
          <Grid item className={classes.cancel}>
            <ButtonV2
              variant="outlined"
              onClick={() => closeDispositionModal()}
              className={classes.button}
              disabled={isLoading}
            >
              Cancel
            </ButtonV2>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default Submit;
