import {
  Theme,
  Divider,
  Grid,
  Container,
  Box,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ShipmentMap from 'shipment-visibility-dashboard/Shipment/ShipmentMap';
import PriceDetail, { PriceDetailProps } from './PriceDetail';
import { StopInfo } from 'api/types';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: '#fff',
  },
  divider: {
    marginTop: theme.spacing(3),
  },
  estimateContainer: {
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  },
  link: {
    color: '#2D6CCB',
    textDecoration: 'none',
  },
  estimated: {
    marginLeft: 'auto',
    marginRight: 0,
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      padding: 0,
    },
  },
}));

export const getStopInfo = (originAddress, destinationAddress) => {
  return [
    {
      coordinate: {
        lat: originAddress.location.lat,
        lon: originAddress.location.lon,
      },
      stopType: 'Pickup',
    },
    {
      coordinate: {
        lat: destinationAddress.location.lat,
        lon: destinationAddress.location.lon,
      },
      stopType: 'Delivery',
    },
  ] as StopInfo[];
};

const QuoteEstimate = ({ quoteData, bookit, submitting }: PriceDetailProps) => {
  const classes = useStyles();
  const mobile = useMediaQuery('(max-width: 900px)');

  return (
    <Container className={classes.container}>
      <Box
        sx={{
          height: '90%',
        }}
      >
        <ShipmentMap
          routes={getStopInfo(
            quoteData.originAddress,
            quoteData.destinationAddress
          )}
        />
        {!mobile ? <Divider className={classes.divider} /> : null}
        <Grid container className={classes.estimateContainer}>
          <PriceDetail
            quoteData={quoteData}
            bookit={bookit}
            submitting={submitting}
          />
        </Grid>
      </Box>
    </Container>
  );
};

export default QuoteEstimate;
