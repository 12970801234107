import axios from 'axios';
import { getApiRoot } from 'utils/apiUtils';
import {
  Coordinate,
  KeyAndValue,
  ReferenceNumber,
  Status,
  TurvoDate,
  TurvoGlobalRoute,
  Routes,
  CurrentLocation,
} from './types';

interface HasBuyerPurchaseOrderNumber {
  buyerPurchaseOrderNumber?: string;
}

export type AlgoliaShipmentProps = {
  customId?: string; // delete me after switching to just use algolia on shared shipment
  startLocationLabel: string;
  endLocationLabel: string;
  referenceNumbers: {
    referenceNumberType: 'string';
    label: string;
    value: string;
  }[];
  shipmentID: string;
  statusCode: string;
  status: string;
  label: string;
  isMarketplaceShipment: boolean;
  tmsCompanies: { tmsID: number }[];
  tenderedAt?: string;
  startDate: string;
  endDate: string;
  updatedAt: string;
  id: number;
  objectID: string;
  endDateFull: TurvoDate;
  startDateFull: TurvoDate;
  truckLocation?: Coordinate;
  globalRoute: Routes[];
  skidsAndPallets: number;
  daysUntilCompletion: string;
  statusLabel: Status;
  weight: null | number;
  commodity: null | string;
  customerId: number;
  shipmentType: string;
  currentLocation?: CurrentLocation;
};

export type ShipmentListRespProps = {
  id: string;
  startLocationLabel: string;
  endLocationLabel: string;
  status: string;
  statusCode: string;
  label: string;
  shipmentID: string; // this is the id from above the status chip
  updatedAt: string; // date and time in ISO format
  tenderedAt: string; // date and time in ISO
  startDate: string; // date and time in ISO
  endDate: string; // date and time in ISO
  orderNumbers: HasBuyerPurchaseOrderNumber;
  referenceNumbers: ReferenceNumber[];
};

export type ShipmentRespProps = {
  id: string;
  shipmentID: string;
  startDate: TurvoDate;
  startDateFull: TurvoDate;
  endDateFull: TurvoDate;
  phase: KeyAndValue;
  endDate: TurvoDate | string;
  statusLabel: Status;
  daysUntilCompletion: string;
  globalRoute: TurvoGlobalRoute[];
  referenceNumbers: ReferenceNumber[];
  shipmentType: string;
  commodity: string;
  weight: number;
  skidsAndPallets: number;
  isMarketplaceShipment: boolean;
  tenderedAt: string;
  truckLocation: Coordinate;
  statusCode: string;
  label: string;
};

export type ShipmentShareLinkProps = {
  route: string;
};

export const getShipmentList = async (authorization: string) => {
  const url = `${getApiRoot()}/shipments`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const response = await axios.get<ShipmentListRespProps[]>(url, config);

  if (response.status !== 200) {
    throw new Error(response.statusText);
  }

  return response.data;
};

export const getShipmentDetails = async (
  authorization: string,
  shipmentId: string
): Promise<ShipmentRespProps> => {
  const url = `${getApiRoot()}/shipments/${shipmentId}`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const response = await axios.get(url, config);

  if (response.status !== 200) {
    throw new Error(response.statusText);
  }

  return response.data;
};

export const getShipmentShareLink = async (
  authorization: string,
  shipmentId: string
): Promise<ShipmentShareLinkProps> => {
  const url = `${getApiRoot()}/shipments/${shipmentId}/share`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const response = await axios.get(url, config);

  if (response.status !== 200) {
    throw new Error(response.statusText);
  }

  return response.data;
};

export const postMultipleShipmentsDetailsByIds = async (
  authorization: string,
  shipmentIds: string[]
): Promise<ShipmentRespProps[]> => {
  const url = `${getApiRoot()}/shipments`;
  const body = JSON.stringify(shipmentIds);
  const config = {
    headers: {
      Authorization: authorization,
    },
  };

  const response = await axios.post(url, body, config);

  if (response.status !== 200) {
    throw new Error(response.statusText);
  }

  return response.data;
};

export const getDocumentDownloadLinks = async (
  authorization: string,
  shipmentId: number,
  fileType: string
): Promise<{ file: string }> => {
  const url = `${getApiRoot()}/shipments/${shipmentId}/file/${fileType}`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const response = await axios.get(url, config);

  if (response.status !== 200) {
    throw new Error(response.statusText);
  }

  return response.data;
};
