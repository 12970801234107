import { useState } from 'react';
import clsx from 'clsx';
import { InfoOutlined } from '@mui/icons-material';
import {
  Tooltip,
  Box,
  IconButton,
  Theme,
  ClickAwayListener,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';

import { TooltipEllipsis } from 'components/TooltipEllipsis';
import Phone from 'shipment/carrier-contact-info-modal/Phone';

import type { Equipment } from 'features/cse/Carrier/types';

const DEFAULT_VALUE = 'N/A';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  icon: {
    height: spacing(2),
    width: spacing(2),
  },
  clickable: {
    color: palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex',
    placeItems: 'center',
    gap: spacing(1),
    lineHeight: '125%',
  },
  title: {
    fontSize: '20px',
    lineHeight: ' 125%',
    minWidth: '132px',
  },
  bold: {
    fontWeight: 600,
    margin: 0,
  },
  normal: {
    fontWeight: 400,
    margin: 0,
  },
  text: {
    fontSize: spacing(2),
    lineHeight: '125%',
  },
}));

export const ContactTooltip = ({
  posterName,
  contactEmail,
  callbackPhone = '',
}: Equipment) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return posterName || contactEmail || callbackPhone ? (
    <Tooltip
      title={
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Box
            data-testid="contact-tooltip"
            sx={{
              display: 'flex',
              gap: 2,
              flexFlow: 'column',
            }}
            onClick={(event) => event.stopPropagation()}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 1,
              }}
            >
              <Box>
                <p className={clsx(classes.title, classes.normal)}>Contact</p>
              </Box>
              <IconButton
                onClick={(event) => {
                  setOpen(false);
                  event.stopPropagation();
                }}
                sx={{ p: 0 }}
                data-testid="close-contact-tooltip"
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 1,
                flexFlow: 'column',
              }}
            >
              <p className={clsx(classes.bold, classes.text)}>
                {posterName ?? DEFAULT_VALUE}
              </p>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  flexFlow: 'column',
                }}
              >
                <Box>
                  <p className={clsx(classes.normal, classes.text)}>
                    <Phone phone={callbackPhone} />
                  </p>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    flexFlow: 'row',
                  }}
                >
                  <EmailIcon />
                  <Box>
                    <p className={clsx(classes.normal, classes.text)}>
                      {contactEmail ?? DEFAULT_VALUE}
                    </p>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </ClickAwayListener>
      }
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 0px 4px rgba(33, 34, 36, 0.1)',
            borderRadius: 1,
            color: 'black',
            padding: 2,
          },
        },
        disablePortal: true,
      }}
      placement="top-start"
      open={open}
    >
      <span
        className={classes.clickable}
        onClick={(event) => {
          setOpen(!open);
          event.stopPropagation();
        }}
      >
        <InfoOutlined className={classes.icon} />
        <TooltipEllipsis textValue={posterName ?? DEFAULT_VALUE} />
      </span>
    </Tooltip>
  ) : null;
};
