import { Box, Divider, Stack } from '@mui/material';
import { formatInTimeZone } from 'date-fns-tz';
import ActivityLogEntry from './ActivityLogEntry';
import { ActivityLog, ShipmentStatusHistory } from './api';
import { useFlags } from 'launchdarkly-react-client-sdk';

type Props = {
  date: string;
  activities: ActivityLog[];
};

const ActivityLogGroup = ({ date, activities }: Props) => {
  const { rl3605ActivityLogWithAutoAwardOffers } = useFlags();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const today = formatInTimeZone(new Date(), timezone, 'yyyy-MM-dd');

  const title =
    date === today
      ? 'Today'
      : formatInTimeZone(date, timezone, 'LLLL do, yyyy');

  return (
    <>
      <Box
        sx={{
          p: '16px',
          fontWeight: 500,
          fontSize: '18px',
          lineHeight: '125%',
        }}
      >
        {title}
      </Box>
      <Divider />
      <Stack divider={<Divider />}>
        {activities.map((activity, i) => {
          if (!rl3605ActivityLogWithAutoAwardOffers) {
            return 'disposition' in activity ? (
              <ActivityLogEntry
                key={`activity-log-entry-${date}-${i}`}
                details={activity}
              />
            ) : (
              <ActivityLogEntry
                key={`activity-log-entry-${date}-${i}`}
                details={activity as ShipmentStatusHistory}
              />
            );
          } else {
            const details = activity as ActivityLog;
            return (
              <ActivityLogEntry
                key={`activity-log-entry-${date}-${i}`}
                details={activity}
              />
            );
          }
        })}
      </Stack>
    </>
  );
};

export default ActivityLogGroup;
