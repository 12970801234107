import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import {
  useState,
  useEffect,
  createContext,
  ReactNode,
  useContext,
} from 'react';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {
  Coordinates,
  fetchShipmentsByLocation,
  getCustomers,
} from 'search-shipments/api';
import {
  checkValidFormData,
  GlobalRouteStop,
  HitsProps,
  SearchResponse,
  searchShipmentsByDetails,
  searchShipmentsById,
} from 'search-shipments/search-shipments-form/utils';
import { trackEvent } from 'utils/mixpanel';
import { useAuthorizationToken } from '@reibus/frontend-utility';
import { getDistanceInMiles } from 'utils/geography';

type PlaceResult = google.maps.places.PlaceResult;

type SearchShipmentFormData = {
  shipmentId?: string;
  originAddressPlaceId?: string;
  destinationAddressPlaceId?: string;
  equipmentType?: string[];
  pickupDate?: string;
  deliveryDate?: string;
};

type SortOrder = 'asc' | 'desc';

type SortBy =
  | 'shipmentID'
  | 'startLocationLabel'
  | 'endLocationLabel'
  | 'pickupDate'
  | 'deliveryDate'
  | 'distance'
  | 'equipmentType';

type SearchProps = {
  searchPage?: number;
  searchSortBy?: SortBy;
  searchSortOrder?: SortOrder;
  submitted?: boolean;
  payload?: SearchShipmentFormData;
};

type Sort = {
  sortBy: string;
  sortOrder: 'asc' | 'desc';
};

type SearchShipmentsContextType = {
  isLoading: boolean;
  results: undefined | SearchResponse;
  formData: SearchShipmentFormData;
  setFormData: (formData: SearchShipmentFormData) => void;
  asyncSearch: (formData: SearchProps) => void;
  search: (searchProps: SearchProps) => void;
  sortBy: SortBy;
  sortOrder: SortOrder;
  customers: Map<string, string>;
  sort: Sort;
  setSort: (sort: Sort) => void;
  page: number;
  setPage: (page: number) => void;
  fetchingShipmentsWithinRadius: boolean;
};

const SearchShipmentsContext = createContext<
  SearchShipmentsContextType | undefined
>(undefined);

const SearchShipmentsProvider = ({ children }: { children: ReactNode }) => {
  const { rl2220CsePagesUrlsAndTitles } = useFlags();
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState<undefined | SearchResponse>(undefined);
  const [formData, setFormData] = useState<SearchShipmentFormData>({});
  const [sortBy, setSortBy] = useState<SortBy>('pickupDate');
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
  const { placesService } = usePlacesAutocompleteService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
  });
  const [asyncQuery, setAsyncQuery] = useState(false);
  const [customers, setCustomers] = useState(new Map());
  const [originCoordinates, setOriginCoordinates] = React.useState<
    Coordinates | undefined
  >();
  const [destinationCoordinates, setDestinationCoordinates] = useState<
    Coordinates | undefined
  >();

  const [fetchingShipmentsWithinRadius, setFetchingShipmentsWithinRadius] =
    useState(false);
  const [sort, setSort] = useState<Sort>({ sortBy, sortOrder });
  const [page, setPage] = useState(0);
  const authToken = useAuthorizationToken();

  const getCoordinatesGeocode = async (
    placeDetails: PlaceResult | null
  ): Promise<Coordinates> => {
    const geocodes = await geocodeByAddress(
      placeDetails?.formatted_address as string
    );
    const { lat, lng } = await getLatLng(geocodes[0]);
    return {
      latitude: String(lat),
      longitude: String(lng),
    };
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const allCustomers = await getCustomers(authToken);
        setCustomers(allCustomers);
      } catch {
        console.error('failed to fetch all customers');
      }
    };
    fetch();
  }, []);

  // converting place id to coordinates
  // enables searching by radius
  const transformAddressToCoordinates = ({
    addressPlaceId,
    setCoordinates,
  }: {
    addressPlaceId: string | undefined;
    setCoordinates: (coordinates: Coordinates | undefined) => void;
  }) => {
    if (!addressPlaceId) {
      setCoordinates(undefined);
      return;
    }
    placesService?.getDetails(
      { placeId: addressPlaceId },
      async (placeDetails) => {
        setFetchingShipmentsWithinRadius(true);
        setCoordinates(await getCoordinatesGeocode(placeDetails));
        setFetchingShipmentsWithinRadius(false);
      }
    );
  };

  useEffect(() => {
    const { originAddressPlaceId } = formData;
    transformAddressToCoordinates({
      addressPlaceId: originAddressPlaceId,
      setCoordinates: setOriginCoordinates,
    });
  }, [formData?.originAddressPlaceId]);

  useEffect(() => {
    const { destinationAddressPlaceId } = formData;
    transformAddressToCoordinates({
      addressPlaceId: destinationAddressPlaceId,
      setCoordinates: setDestinationCoordinates,
    });
  }, [formData?.destinationAddressPlaceId]);

  const getSearchOrder = ({
    searchSortOrder,
    searchSortBy,
  }: SearchProps): SortOrder => {
    let order = searchSortOrder ?? 'asc';
    if (!!searchSortOrder && sortOrder !== searchSortOrder) {
      order = sortOrder !== 'asc' ? 'asc' : 'desc';
    }
    if (!!searchSortBy && searchSortBy !== sortBy) {
      order = 'asc';
    }

    return order as SortOrder;
  };

  useEffect(() => {
    const { originAddressPlaceId, destinationAddressPlaceId } = formData;

    const readyForQuery = originAddressPlaceId && destinationAddressPlaceId;

    if (asyncQuery && readyForQuery) {
      setAsyncQuery(false);
      search({});
    }
  }, [
    asyncQuery,
    formData?.originAddressPlaceId,
    formData?.destinationAddressPlaceId,
  ]);

  const asyncSearch = ({ payload = formData }: SearchProps) => {
    if (!checkValidFormData(formData)) {
      setFormData(payload);
      setAsyncQuery(true);
    }
  };

  //Calculate distance using global route coordinates
  const addDHOToHits = (results: SearchResponse): SearchResponse => {
    const hitsWithDHO: HitsProps[] = results.hits.map((hit: HitsProps) => {
      const origin = hit.globalRoute.filter(
        (stop: GlobalRouteStop) =>
          stop.stopType.value === 'Pickup' &&
          stop.address.locationLabel === hit.startLocationLabel
      );

      const originDH = originCoordinates
        ? `${getDistanceInMiles(
            {
              latitude: Number(originCoordinates.latitude),
              longitude: Number(originCoordinates.longitude),
            },
            {
              latitude: origin[0].address.lat,
              longitude: origin[0].address.lon,
            }
          ).toFixed(1)} mi`
        : 'N/A';

      return { ...hit, originDH };
    });

    return { ...results, hits: hitsWithDHO };
  };

  // only searches when there is formData and no shipmentId
  const search = async ({
    searchPage,
    searchSortBy,
    searchSortOrder,
    submitted = false,
  }: SearchProps) => {
    setIsLoading(true);
    try {
      if (submitted && formData.shipmentId) {
        const response = await searchShipmentsById(formData.shipmentId);
        if (response.nbHits) {
          trackEvent('CSE Shipment Search by ID', {
            shipmentId: formData.shipmentId,
          });

          window.open(
            !rl2220CsePagesUrlsAndTitles
              ? `/logistics/shipment/${response.hits[0].shipmentID}`
              : `/logistics/cse/shipment/${response.hits[0].shipmentID}`,
            '_blank'
          );
          return;
        }
        setResults(response);
        return;
      }
      const newPage = searchPage ?? page;
      const newSortOrder = getSearchOrder({ searchSortBy, searchSortOrder });
      const newSortBy =
        !!searchSortBy && sortBy !== searchSortBy ? searchSortBy : sortBy;
      const index = `shipments_${newSortBy}_${newSortOrder}`;
      setSortOrder(newSortOrder);
      setSortBy(newSortBy);
      let shipmentsByRadius: string[] = [];

      if (originCoordinates || destinationCoordinates) {
        shipmentsByRadius = await fetchShipmentsByLocation(
          authToken,
          originCoordinates,
          destinationCoordinates
        );
      }

      if (!shipmentsByRadius?.length && formData?.originAddressPlaceId) {
        setResults({ hits: [] as HitsProps[], nbHits: 0 } as SearchResponse);
        return;
      }

      const addressSearchFilter = { shipmentsByRadius };
      const response = await searchShipmentsByDetails(
        {
          ...formData,
          ...addressSearchFilter,
        },
        newPage,
        index
      );

      const hitsWithDHO = addDHOToHits(response);
      setResults(hitsWithDHO);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const { sortBy, sortOrder } = sort;

    if (page !== 0) {
      setPage(0);
    } else {
      search({
        searchPage: page,
        searchSortBy: sortBy as SortBy,
        searchSortOrder: sortOrder,
      });
    }
  }, [sort]);

  useEffect(() => {
    search({
      searchPage: page,
      searchSortBy: sortBy,
      searchSortOrder: sortOrder,
    });
  }, [page]);

  return (
    <SearchShipmentsContext.Provider
      value={{
        isLoading,
        results,
        asyncSearch,
        search,
        formData,
        setFormData,
        sortBy,
        sortOrder,
        customers,
        sort,
        setSort,
        page,
        setPage,
        fetchingShipmentsWithinRadius,
      }}
    >
      {children}
    </SearchShipmentsContext.Provider>
  );
};

const useSearchShipmentsContext = (): SearchShipmentsContextType => {
  const context = useContext(SearchShipmentsContext);
  if (context === undefined) {
    throw new Error(
      'useSearchShipmentsContext must be used within a SearchShipmentsProvider'
    );
  }

  return context;
};

export { SearchShipmentsProvider, useSearchShipmentsContext };

export type { SortBy, SortOrder, SearchShipmentsContextType };

export const exportedForTesting = {
  SearchShipmentsContext,
};
