import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { SearchByCarrierHit } from 'features/cse/search/SearchByCarrier/api';
import { Sort } from 'features/cse/search/SearchByCarrier/SearchByCarrierContext';
import theme from 'theme';
import CellLabel from './CellLabel';
import ColumnHeader from './ColumnHeader';
import { trackEvent } from 'utils/mixpanel';
import { MixpanelTag } from 'utils/constants';

type HeadCell = {
  label: string;
  width?: string;
  sortBy?: string;
  align?: 'left' | 'right';
};

type BodyCell = {
  label: string;
  href?: string;
  align?: 'left' | 'right';
  formatting?: (value: string) => string;
};

type Props = {
  hits: SearchByCarrierHit[];
  headCells: HeadCell[];
  bodyCells: BodyCell[];
  isLoading: boolean;
  currentSort: Sort;
  setSort: (sort: Sort) => void;
};

const ResultsTable = ({
  hits,
  headCells,
  bodyCells,
  isLoading,
  currentSort,
  setSort,
}: Props) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              border: 1,
              borderColor: theme.palette.grey[100],
              backgroundColor: theme.palette.grey[100],
            }}
          >
            {headCells.map((column) => (
              <TableCell
                key={column.label}
                sx={{ width: column.width ?? 'auto' }}
                align={column.align}
              >
                <ColumnHeader
                  label={column.label}
                  sortBy={column.sortBy}
                  align={column.align}
                  currentSort={currentSort}
                  setSort={setSort}
                />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {hits.map((hit: SearchByCarrierHit) => {
            return (
              <TableRow
                key={hit.cseCarrierID}
                sx={{
                  '&:hover': {
                    backgroundColor: '#F2F7FF',
                  },
                }}
              >
                {bodyCells.map((cell) => {
                  const rawLabel = hit[cell.label as keyof typeof hit] ?? '';
                  const formattedLabel = !cell.formatting
                    ? rawLabel
                    : cell.formatting(rawLabel);

                  const href = cell.href
                    ? `${cell.href}/${hit.cseCarrierID}`
                    : undefined;

                  const trackClick = () =>
                    trackEvent(MixpanelTag.ClickResultCseCarrier, {
                      cseCarrierID: hit.cseCarrierID,
                    });

                  return (
                    <TableCell
                      key={cell.label}
                      sx={{
                        border: 1,
                        borderColor: theme.palette.grey[100],
                      }}
                      align={cell.align}
                    >
                      <CellLabel
                        label={formattedLabel}
                        isLoading={isLoading}
                        href={href}
                        onClick={trackClick}
                        target="_blank"
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ResultsTable;
export type { HeadCell, BodyCell };
