import {
  faCircleCheck,
  faCircleExclamation,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack } from '@mui/material';
import { H2 } from 'components/SteelUI/atoms/typography';
import { makeStyles } from 'tss-react/mui';

type Props = {
  alignment: 'center' | 'left';
  title: string;
  status?: 'success' | 'error' | 'warning';
  className?: string;
};

const useStyles = makeStyles()(() => ({
  circleCheck: {
    color: '#237960',
    width: '2rem',
    height: '2em',
  },
  circleExclamation: {
    color: '#BB121A',
    width: '2rem',
    height: '2em',
  },
  triangleExclamation: {
    color: '#FFB507',
    width: '2rem',
    height: '2em',
  },
}));

const ModalTitle = ({ alignment, title, status, className }: Props) => {
  const { classes } = useStyles();
  const iconMap = {
    success: (
      <FontAwesomeIcon className={classes.circleCheck} icon={faCircleCheck} />
    ),
    error: (
      <FontAwesomeIcon
        className={classes.circleExclamation}
        icon={faCircleExclamation}
      />
    ),
    warning: (
      <FontAwesomeIcon
        className={classes.triangleExclamation}
        icon={faTriangleExclamation}
      />
    ),
  };

  const direction = alignment === 'center' ? 'column' : 'row';
  const justifyContent = alignment === 'center' ? 'center' : 'flex-start';
  return (
    <Stack
      direction={direction}
      justifyContent={justifyContent}
      alignItems="center"
      spacing={2}
    >
      {!status ? null : iconMap[status]}
      {title ? (
        <H2 className={className} weight="bold">
          {title}
        </H2>
      ) : null}
    </Stack>
  );
};

export default ModalTitle;
