import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { displayFormattingValue } from '@reibus/reibus-core-utils';
import theme from 'theme';

const format = {
  decimalPlaces: 2,
  trailingZeros: true,
};

type Props = {
  pay?: number | null;
  label: string;
};

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 700,
    fontSize: 16,
  },
  targetPay: {
    fontWeight: theme.typography.fontWeightBold as number,
    fontSize: 24,
  },
}));

export const Pay = (props: Props) => {
  const { label, pay } = props;
  const classes = useStyles();

  const payValue =
    typeof pay === 'number' ? `$${displayFormattingValue(pay, format)}` : 'N/A';

  return (
    <Grid item xs>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="body1" className={classes.title}>
            {label}:
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            className={label === 'Target Pay' ? classes.targetPay : ''}
          >
            {payValue}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
