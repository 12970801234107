import { makeStyles } from '@mui/styles';
import {
  Theme,
  Container,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: 0,
    marginTop: theme.spacing(2),
    width: 'inherit',
  },
  root: {
    paddingRight: 0,
  },
  hasClearIcon: {
    paddingRight: 0,
  },
  clearButton: {
    color: theme.palette.grey[700],
  },
  cssOutlinedRoot: {
    '& $cssOutlinedOutline': {
      paddingRight: 0,
    },
    '&:hover $cssOutlinedOutline': {
      border: '1px solid #DCDEE0 !important',
    },
    borderRadius: 8,
  },
  cssOutlinedInputFocused: {
    '& $cssOutlinedOutline': {
      border: '1px solid #DCDEE0 !important',
    },
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
  },
  cssOutlinedInput: {
    padding: 10,
    paddingRight: 0,
    borderRadius: 8,
    minWidth: 250,
  },
  searchButton: {
    color: '#2D6CCB',
    marginRight: `-${theme.spacing(1)}`,
  },
}));

type Props = {
  id: string;
  label: string;
  value: string | undefined;
  handleChange: (value: string) => void;
};

const SearchBarNoAutocomplete = ({ id, label, value, handleChange }: Props) => {
  const classes = useStyles({});

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    handleChange(value || '');
  };
  const handleClearSearchInput = () => {
    handleChange('');
  };

  return (
    <Container className={classes.container}>
      <TextField
        fullWidth
        variant="outlined"
        label={label}
        name={id}
        onChange={handleInputChange}
        InputProps={{
          value,
          endAdornment: (
            <>
              {!value ? null : (
                <>
                  <IconButton
                    id="listingClearIcon"
                    data-testid="clearIcon"
                    onClick={handleClearSearchInput}
                    className={classes.clearButton}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>

                  <div>|</div>
                </>
              )}
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            </>
          ),
        }}
      />
    </Container>
  );
};

export default SearchBarNoAutocomplete;
