import { H2 } from 'components/SteelUI/atoms/typography';

import RouteDistance from 'components/SteelUI/organisms/RouteDetails/components/RouteDistance';
import RouteStop from 'components/SteelUI/organisms/RouteDetails/components/RouteStop';
import MultiStopList from 'components/SteelUI/organisms/RouteDetails/components/MultiStop';
import { Stack } from '@mui/material';

type Address = {
  address1: string;
  city: string;
  region: string;
  postalCode: string;
};

type SchedulingType =
  | 'BY_APPOINTMENT'
  | 'FCFS'
  | 'TURVO_APPOINTMENT_SCHEDULING';

type Appointment = {
  datetime: string;
  timezone: string;
  schedulingType: SchedulingType;
  flex?: number;
};

type Stop = {
  address: Address;
  appointment: Appointment;
  name: string;
  notes?: string;
};

type Props = {
  pickup: Stop;
  delivery: Stop;
  distance: number;
  showNotes?: boolean;
  route: MultiStop[];
};

const RouteDetails = ({
  pickup,
  delivery,
  distance,
  showNotes = false,
  route,
}: Props) => {
  return (
    <Stack direction="column" spacing={2}>
      <H2 weight="bold">Locations</H2>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <RouteStop {...pickup} showNotes={showNotes} stopType="Pickup" />
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          {route && route?.length > 2 ? <MultiStopList route={route} /> : null}
          <RouteDistance distance={distance} />
        </Stack>
        <RouteStop {...delivery} showNotes={showNotes} stopType="Delivery" />
      </Stack>
    </Stack>
  );
};

export default RouteDetails;
export type { Appointment, Stop, MultiStopList as MultiStop };
