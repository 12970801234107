import SearchByCarrierForm from './components/SearchByCarrierForm';
import SearchByCarrierResults from './components/SearchByCarrierResults';
import { SearchByCarrierContextProvider } from './SearchByCarrierContext';

const SearchByCarrier = () => {
  return (
    <SearchByCarrierContextProvider>
      <SearchByCarrierForm />
      <SearchByCarrierResults />
    </SearchByCarrierContextProvider>
  );
};

export default SearchByCarrier;
