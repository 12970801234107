import { makeStyles } from '@mui/styles';

import { SingleSelect } from 'components/library/form/SingleSelect';

import FormSection from './FormSection';
import { Checkbox } from 'components/library/form/CheckBox';
import { useFormState } from 'react-final-form';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles({
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    columnGap: '24px',
    rowGap: '40px',
  },
  checkboxGrid: {
    display: 'grid',
    columnGap: '48px',
    rowGap: '16px',
  },
  label: {
    whiteSpace: 'nowrap',
  },
});

const TARP_OPTIONS = [
  {
    id: '',
    label: 'None',
    optionName: 'NONE',
  },
  {
    id: '',
    label: "4'",
    optionName: 'FOUR_FOOT',
  },
  {
    id: '',
    label: "6'",
    optionName: 'SIX_FOOT',
  },
  {
    id: '',
    label: "8'",
    optionName: 'EIGHT_FOOT',
  },
];

const LABEL_MAP: Record<string, string> = {
  dunnage: 'Dunnage',
  chains: 'Chains',
  straps: 'Straps',
  loadLocks: 'Load Locks',
  excessiveCargoValue: 'Excessive Cargo Value (>+$100,000)',
  coilRacks: 'Coil Racks',
  edgeProtectors: 'Edge Protectors',
  eTracks: 'E-Tracks',
  loadBars: 'Load Bars',
  pipeStakes: 'Pipe Stakes',
  portPickup: 'Port Pickup',
};

type Layout = {
  columns: number;
  fields: string[];
};

const DEFAULT_LAYOUT_NEW = {
  columns: 4,
  fields: ['excessiveCargoValue'],
};

const DEFAULT_LAYOUT_OLD = {
  columns: 4,
  fields: ['excessiveCargoValue', 'pipeStakes'],
};

const LAYOUT_MAP_OLD: Record<string, Layout> = {
  FLATBED: {
    columns: 3,
    fields: [
      'dunnage',
      'chains',
      'excessiveCargoValue',
      'coilRacks',
      'edgeProtectors',
      'pipeStakes',
    ],
  },
  HOT_SHOT: {
    columns: 3,
    fields: [
      'dunnage',
      'chains',
      'excessiveCargoValue',
      'coilRacks',
      'edgeProtectors',
      'pipeStakes',
    ],
  },
  DRY_VAN: {
    columns: 3,
    fields: [
      'straps',
      'excessiveCargoValue',
      'eTracks',
      'pipeStakes',
      'loadLocks',
      'loadBars',
    ],
  },
  'DRY_VAN,FLATBED': {
    columns: 5,
    fields: [
      'dunnage',
      'chains',
      'straps',
      'loadLocks',
      'excessiveCargoValue',
      'coilRacks',
      'edgeProtectors',
      'eTracks',
      'loadBars',
      'pipeStakes',
    ],
  },
  'DRY_VAN,HOT_SHOT': {
    columns: 5,
    fields: [
      'dunnage',
      'chains',
      'straps',
      'loadLocks',
      'excessiveCargoValue',
      'coilRacks',
      'edgeProtectors',
      'eTracks',
      'loadBars',
      'pipeStakes',
    ],
  },
  'DRY_VAN,FLATBED,HOT_SHOT': {
    columns: 5,
    fields: [
      'dunnage',
      'chains',
      'straps',
      'loadLocks',
      'excessiveCargoValue',
      'coilRacks',
      'edgeProtectors',
      'eTracks',
      'loadBars',
      'pipeStakes',
    ],
  },
  'FLATBED,HOT_SHOT': {
    columns: 3,
    fields: [
      'dunnage',
      'chains',
      'excessiveCargoValue',
      'coilRacks',
      'edgeProtectors',
      'pipeStakes',
    ],
  },
};

const LAYOUT_MAP_NEW: Record<string, Layout> = {
  FLATBED: {
    columns: 3,
    fields: ['excessiveCargoValue', 'portPickup'],
  },
  HOT_SHOT: {
    columns: 3,
    fields: ['excessiveCargoValue', 'portPickup'],
  },
  DRY_VAN: {
    columns: 3,
    fields: [
      'straps',
      'excessiveCargoValue',
      'eTracks',
      'loadLocks',
      'loadBars',
      'portPickup',
    ],
  },
};

const Accessorials = () => {
  const classes = useStyles();
  const { values: formData } = useFormState();

  const equipment = formData.equipment || [];
  const layout = LAYOUT_MAP_NEW[equipment] || DEFAULT_LAYOUT_NEW;

  return (
    <FormSection title="Accessorials">
      <div className={classes.content}>
        <div
          className={classes.checkboxGrid}
          style={{
            gridColumn: '1 / 5',
            gridTemplateColumns: `repeat(${layout.columns}, 1fr)`,
          }}
        >
          {layout.fields.map((field) => (
            <Checkbox
              key={field}
              name={field}
              label={LABEL_MAP[field]}
              required={false}
              labelClassName={classes.label}
            />
          ))}
        </div>
      </div>
    </FormSection>
  );
};

export default Accessorials;
