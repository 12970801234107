import { getApiRoot } from 'utils/apiUtils';
import axios from 'axios';

export const postBookShipmentPublic = async (data: { id: string }) => {
  const url = `${getApiRoot()}/freight-quotes/book-shipment/public`;
  const body = JSON.stringify({
    data,
  });
  return await axios.post(url, body);
};

export const postBookShipmentPrivate = async (
  authorization: string,
  data: { id: string }
) => {
  const url = `${getApiRoot()}/freight-quotes/book-shipment/private`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const body = JSON.stringify({
    data,
  });
  return await axios.post(url, body, config);
};
