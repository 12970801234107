import { Grid, SvgIcon } from '@mui/material';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';

type Sort = {
  sortBy: string;
  sortOrder: 'asc' | 'desc';
};

type Props = {
  label: string;
  sortBy?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  currentSort: Sort;
  setSort: (sort: Sort) => void;
};

const ColumnHeader = ({
  label,
  sortBy,
  align,
  currentSort,
  setSort,
}: Props) => {
  const handleChangeSort = () => {
    if (!sortBy) return;

    if (sortBy !== currentSort.sortBy) {
      setSort({ sortBy, sortOrder: 'asc' });
    } else {
      setSort({
        sortBy,
        sortOrder: currentSort.sortOrder === 'asc' ? 'desc' : 'asc',
      });
    }
  };

  const direction = align === 'right' ? 'row-reverse' : 'row';

  return (
    <Grid
      container
      onClick={handleChangeSort}
      sx={{ fontWeight: 600, cursor: 'pointer' }}
      direction={direction}
    >
      <Grid item>{label}</Grid>
      <Grid item>
        {sortBy !== currentSort.sortBy ? null : (
          <SvgIcon
            component={currentSort.sortOrder === 'asc' ? NorthIcon : SouthIcon}
            sx={{ padding: '0 4px' }}
            fontSize="small"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ColumnHeader;
