import { Stack, Box } from '@mui/material';
import { Accordion } from 'components/SteelUI/molecules';
import { ShipmentStatusHistory } from '../api';
import makeStyles from '@mui/styles/makeStyles';
import { formatInTimeZone } from 'date-fns-tz';

const useStyles = makeStyles({
  container: {
    fontSize: '14px',
    lineHeight: '125%',
  },
  label: {
    fontWeight: 400,
  },
  bold: {
    fontWeight: 600,
  },
});

type Props = {
  activity: ShipmentStatusHistory;
};

const Label = ({ status }: { status: string }) => {
  const classes = useStyles();
  return (
    <Stack direction="row" justifyContent="space-between">
      <Box>{'Shipment Updated'}</Box>
      <Box className={classes.bold}>{status}</Box>
    </Stack>
  );
};

const ActivityLogEntryStatusChange = ({ activity }: Props) => {
  const classes = useStyles();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const label = (
    <Box className={classes.label}>
      <Label status={activity.status} />
    </Box>
  );

  const posted = !activity?.createdAt
    ? 'N/A'
    : formatInTimeZone(activity.createdAt, timezone, 'h:mm a');

  return (
    <Accordion label={label}>
      <Stack spacing={1} className={classes.container}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box className={classes.bold}>Status Change</Box>
          <Box>
            <span className={classes.bold}>Posted: </span>
            {posted}
          </Box>
        </Stack>
      </Stack>
    </Accordion>
  );
};

export default ActivityLogEntryStatusChange;
