import SearchShipmentsForm from 'search-shipments/search-shipments-form/SearchShipmentsForm';
import { SearchShipmentsProvider } from 'search-shipments/SearchShipmentsContext';
import SearchByShipmentResults from './components/SearchByShipmentResults';

const SearchByShipment = () => {
  return (
    <SearchShipmentsProvider>
      <SearchShipmentsForm />
      <SearchByShipmentResults />
    </SearchShipmentsProvider>
  );
};

export default SearchByShipment;
