import { useState, useEffect } from 'react';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { differenceInHours } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation, useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';

import { AddressField } from 'components/library/form/AddressField';
import { convertPlaceIdToCoordinates } from 'utils/googlePlaces';
import { getDistanceInMiles } from 'utils/geography';
import { formatEquipmentType } from 'features/cse/Carrier/utils/data';
import { EquipmentLocation } from 'features/cse/AddEquipment/api';
import useAvailableEquipment from 'features/cse/Carrier/hooks/useAvailableEquipment';

import FiltersButton from './components/FiltersButton';
import FiltersDrawer from './components/FiltersDrawer';
import FiltersForm from './components/FiltersForm';
import CollapsibleField from './components/CollapsibleField';

import type { Coordinates } from 'utils/googlePlaces';
import type { Equipment, ShipmentWithScores } from 'features/cse/Carrier/types';

type Props = {
  allShipments: ShipmentWithScores[];
  setFilteredShipments: (shipments: ShipmentWithScores[]) => void;
  cseCarrierID: string;
};

type EquipmentListingOption = {
  equipmentType: string;
  city: string;
  state: string;
};

type Search = {
  selectedEquipmentTypes: string[];
  selectedEquipmentListingOptionKeys: string[];
  originCoordinates: Coordinates | null;
  destinationCoordinates: Coordinates | null;
};

const FilterTopShipments = ({
  allShipments,
  setFilteredShipments,
  cseCarrierID,
}: Props) => {
  const { placesService } = usePlacesAutocompleteService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
  });

  const { data: equipmentList, isLoading: isEquipmentLoading } =
    useAvailableEquipment(cseCarrierID);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { rl2468FilterByEquipmentListings } = useFlags();

  const [open, setOpen] = useState(false);
  const [renderKey, setRenderKey] = useState(new Date().toISOString());
  const [isFiltering, setIsFiltering] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [equipmentListingFilterCount, setEquipmentListingFilterCount] =
    useState(0);
  const [equipmentTypeFilterCount, setEquipmentTypeFilterCount] = useState(0);
  const [isFetchingOriginCoordinates, setIsFetchingOriginCoordinates] =
    useState(false);
  const [originPlaceId, setOriginPlaceId] = useState('');
  const [originCoordinates, setOriginCoordinates] =
    useState<Coordinates | null>(null);
  const [
    isFetchingDestinationCoordinates,
    setIsFetchingDestinationCoordinates,
  ] = useState(false);
  const [destinationPlaceId, setDestinationPlaceId] = useState('');
  const [destinationCoordinates, setDestinationCoordinates] =
    useState<Coordinates | null>(null);
  const [equipmentListingOptions, setEquipmentListingOptions] = useState<{
    [key: string]: EquipmentListingOption;
  }>();
  const [equipmentTypeOptions, setEquipmentTypeOptions] = useState<Set<string>>(
    new Set()
  );
  const [
    selectedEquipmentListingOptionKeys,
    setSelectedEquipmentListingOptionKeys,
  ] = useState<string[]>([]);
  const [selectedEquipmentTypes, setSelectedEquipmentTypes] = useState<
    string[]
  >([]);
  const [autoFilter, setAutoFilter] = useState(true);
  const [lastSearch, setLastSearch] = useState<Search>();
  const isLoading =
    isFetchingOriginCoordinates ||
    isFetchingDestinationCoordinates ||
    isEquipmentLoading;
  const queryClient = useQueryClient();

  const city = searchParams.get('city');
  const equipmentType = searchParams.get('equipmentType');
  const stateProvince = searchParams.get('stateProvince');
  const equipmentId = searchParams.get('equipmentId');
  const history = useHistory();

  const handleSubmit = (automaticFilter: boolean) => {
    if (!automaticFilter && searchChange()) {
      cleanQueryParams();
    }
    setAutoFilter(automaticFilter);
    setIsFiltering(true);
  };

  const handleClear = (automaticFilter: boolean) => {
    if (!automaticFilter) {
      cleanQueryParams();
    }
    setRenderKey(new Date().toISOString());
    handleSubmit(automaticFilter);
    setOriginCoordinates(null);
    setDestinationCoordinates(null);
    setSelectedEquipmentListingOptionKeys([]);
    setSelectedEquipmentTypes([]);
    setEquipmentListingFilterCount(0);
    setEquipmentTypeFilterCount(0);
  };

  //  The form uses a google maps placeId but the payload needs a location object
  //  so we use a callback to update the state of originPlaceId/destinationPlaceId
  //  and use a hook to convert them to location objects whenever a change is detected
  const onChange = (values: Record<string, string>) => {
    setOriginPlaceId(values?.originPlaceId || '');
    setDestinationPlaceId(values?.destinationPlaceId || '');
  };

  const onEquipmentListingChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedEquipmentListingOptionKeys((prev) => [...prev, value]);
    } else {
      setSelectedEquipmentListingOptionKeys((prev) =>
        prev.filter((key) => key !== value)
      );
    }
  };

  const onEquipmentTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedEquipmentTypes((prev) => [...prev, value]);
    } else {
      setSelectedEquipmentTypes((prev) =>
        prev.filter((type) => type !== value)
      );
    }
  };

  const buildEquipmentFiltersOptions = () => {
    const equipmentTypeOptions: Set<string> = new Set();

    const equipmentListingOptions: { [key: string]: EquipmentListingOption } =
      {};
    equipmentList?.forEach((equipment) => {
      const {
        equipmentType: { description: equipmentTypeDescription },
        EquipmentLocation_EquipmentToEquipmentLocation: {
          city: equipmentOriginCity,
        },
        EquipmentLocation_EquipmentToEquipmentLocation: {
          stateProvince: equipmentOriginState,
        },
      } = equipment;
      const { key, data } = getEquipmentListingData(
        equipmentTypeDescription,
        equipmentOriginCity,
        equipmentOriginState
      );
      if (!equipmentListingOptions[key]) equipmentListingOptions[key] = data;
      equipmentTypeOptions.add(formatEquipmentType(equipment.equipmentType));
    });
    setEquipmentListingOptions(equipmentListingOptions);
    setEquipmentTypeOptions(equipmentTypeOptions);
  };

  useEffect(() => {
    if (isEquipmentLoading || equipmentList?.length === 0) return;
    buildEquipmentFiltersOptions();
  }, [equipmentList, isEquipmentLoading]);

  useEffect(() => {
    if (!placesService) return;
    if (!originPlaceId) {
      setOriginCoordinates(null);
      return;
    }
    convertPlaceIdToCoordinates(
      originPlaceId,
      placesService,
      setIsFetchingOriginCoordinates,
      setOriginCoordinates
    );
  }, [originPlaceId, placesService]);

  useEffect(() => {
    if (!placesService) return;
    if (!destinationPlaceId) {
      setDestinationCoordinates(null);
      return;
    }
    convertPlaceIdToCoordinates(
      destinationPlaceId,
      placesService,
      setIsFetchingDestinationCoordinates,
      setDestinationCoordinates
    );
  }, [destinationPlaceId, placesService]);

  const exceedsByDistance = (
    equipmentLocation: EquipmentLocation | Coordinates,
    coords: Coordinates
  ) =>
    getDistanceInMiles(
      {
        latitude: Number(equipmentLocation?.latitude),
        longitude: Number(equipmentLocation?.longitude),
      },
      {
        latitude: Number(coords?.latitude),
        longitude: Number(coords?.longitude),
      }
    ) > 150;

  const wideAreaDestination = (destination: EquipmentLocation) =>
    !destination.city && !!destination.stateProvince;

  useEffect(() => {
    if (!isFiltering) return;
    if (
      isFetchingOriginCoordinates ||
      isFetchingDestinationCoordinates ||
      isEquipmentLoading
    )
      return;

    const equipmentListing = equipmentList?.filter((equip) => {
      const {
        EquipmentLocation_EquipmentToEquipmentLocation: { city, stateProvince },
        equipmentType: { description },
      } = equip;
      return autoFilter
        ? equip.id === equipmentId
        : selectedEquipmentListingOptionKeys.includes(
            getListingKey(description, city, stateProvince)
          );
    });

    const filteredShipments = allShipments.filter((shipment) => {
      if (originCoordinates) {
        const originExceed = exceedsByDistance(
          originCoordinates,
          shipment.originCoordinates
        );

        if (originExceed) return false;
      }
      if (destinationCoordinates) {
        const destinationExceed = exceedsByDistance(
          destinationCoordinates,
          shipment.destinationCoordinates
        );
        if (destinationExceed) return false;
      }

      if (selectedEquipmentListingOptionKeys.length) {
        const optionKeys = shipment.equipmentNeeded.map((equipment) =>
          getListingKey(
            equipment.description,
            shipment.originAddress.city,
            shipment.originAddress.region
          )
        );

        if (equipmentListing?.length) {
          return matchListings(shipment, equipmentListing);
        } else if (
          !selectedEquipmentListingOptionKeys.some((key) =>
            optionKeys.includes(key)
          )
        ) {
          return false;
        }
      }

      if (selectedEquipmentTypes.length) {
        const optionKeys = shipment.equipmentNeeded.map((equipment) => {
          return equipment.description;
        });

        if (!selectedEquipmentTypes.some((key) => optionKeys.includes(key))) {
          return false;
        }
      }

      return true;
    });

    setFilterCount(
      (originCoordinates ? 1 : 0) +
        (destinationCoordinates ? 1 : 0) +
        (selectedEquipmentListingOptionKeys.length ? 1 : 0) +
        (selectedEquipmentTypes.length ? 1 : 0)
    );

    setFilteredShipments(filteredShipments);
    setIsFiltering(false);
    setEquipmentListingFilterCount(selectedEquipmentListingOptionKeys.length);
    setEquipmentTypeFilterCount(selectedEquipmentTypes.length);
    setLastSearch({
      originCoordinates,
      destinationCoordinates,
      selectedEquipmentTypes,
      selectedEquipmentListingOptionKeys,
    });
  }, [
    isFiltering,
    isFetchingOriginCoordinates,
    isFetchingDestinationCoordinates,
    originCoordinates,
    destinationCoordinates,
  ]);

  const availableDateExceeds = (
    equipment: Equipment,
    shipment: ShipmentWithScores
  ) => {
    const equipmentTime = matchTimeZone(
      equipment.startDate,
      new Date(shipment.startDateDetailed.date)
    );

    const diffHours = differenceInHours(
      new Date(shipment.startDateDetailed.date),
      equipmentTime
    );

    return !(diffHours >= 0 && diffHours <= 24);
  };

  const matchListings = (
    shipment: ShipmentWithScores,
    equipmentListings: Equipment[]
  ) => {
    const { originCoordinates, destinationCoordinates } = shipment;

    const shipmentMatch = equipmentListings
      ?.map((equipment) => {
        const {
          EquipmentLocation_DestinationEquipment,
          EquipmentLocation_EquipmentToEquipmentLocation,
        } = equipment;
        const originExceed = exceedsByDistance(
          EquipmentLocation_EquipmentToEquipmentLocation,
          originCoordinates
        );

        const openDestination =
          EquipmentLocation_DestinationEquipment?.some(wideAreaDestination);
        const destinationExceed =
          EquipmentLocation_DestinationEquipment?.length && !openDestination
            ? EquipmentLocation_DestinationEquipment?.every((destination) =>
                exceedsByDistance(destination, destinationCoordinates)
              )
            : false;

        const availableDateExceed = availableDateExceeds(equipment, shipment);

        const withoutREQ = !Number(shipment.scores.availableEquipmentScore);

        if (
          destinationExceed ||
          originExceed ||
          withoutREQ ||
          availableDateExceed
        ) {
          return false;
        }
        return true;
      })
      ?.some((match) => match);

    return shipmentMatch;
  };

  const matchTimeZone = (startDate: Date, matchDate: Date): Date => {
    const [equipmentDate] = new Date(startDate).toISOString().split('T');
    const [, matchTimeZone] = new Date(matchDate).toISOString().split('T');
    const newEquipmentDate = [equipmentDate, 'T', matchTimeZone].join('');
    return new Date(newEquipmentDate);
  };

  const getListingKey = (description: string, city = '', state = '') =>
    `${formatEquipmentType({
      description,
    })} - ${city}, ${state}`;

  const getEquipmentListingData = (
    equipmentType: string,
    city = '',
    state = ''
  ): {
    data: EquipmentListingOption;
    key: string;
  } => ({
    key: getListingKey(equipmentType, city, state),
    data: {
      equipmentType,
      city,
      state,
    },
  });

  const searchChange = () => {
    return (
      lastSearch?.destinationCoordinates !== destinationCoordinates ||
      lastSearch?.originCoordinates !== originCoordinates ||
      lastSearch?.selectedEquipmentListingOptionKeys !==
        selectedEquipmentListingOptionKeys ||
      lastSearch?.selectedEquipmentTypes !== selectedEquipmentTypes
    );
  };

  useEffect(() => {
    if (city && equipmentType && stateProvince && !isLoading && equipmentId) {
      handleClear(true);
      const { key, data } = getEquipmentListingData(
        equipmentType,
        city,
        stateProvince
      );

      setSelectedEquipmentListingOptionKeys((prev) => [...prev, key]);
      setEquipmentListingOptions((prev) => ({
        ...prev,
        [key]: data,
      }));
      queryClient.invalidateQueries([
        'shipments-scored-carriers',
        cseCarrierID,
      ]);
      handleSubmit(true);
    }
  }, [
    city,
    equipmentType,
    stateProvince,
    isLoading,
    allShipments,
    equipmentId,
  ]);

  const cleanQueryParams = () => {
    if (city && equipmentType && stateProvince) {
      history.push({
        pathname: location.pathname,
        search: `tab=overview`,
      });
    }
  };

  return (
    <>
      <FiltersButton open={open} setOpen={setOpen} filterCount={filterCount} />
      <FiltersDrawer open={open} setOpen={setOpen}>
        <FiltersForm
          handleSubmit={() => handleSubmit(autoFilter && !searchChange())}
          handleClear={() => handleClear(false)}
          isLoading={isLoading}
          onChange={onChange}
        >
          <CollapsibleField label="Origin">
            <AddressField
              name="origin"
              label="Origin"
              showManualLabel={false}
              required={false}
              key={`filter-origin-${renderKey}`}
            />
          </CollapsibleField>
          <CollapsibleField label="Destination">
            <AddressField
              name="destination"
              label="Destination"
              showManualLabel={false}
              required={false}
              key={`filter-destination-${renderKey}`}
            />
          </CollapsibleField>
          {rl2468FilterByEquipmentListings ? (
            <CollapsibleField
              label={`Equipment Listings ${
                equipmentListingFilterCount
                  ? `(${equipmentListingFilterCount})`
                  : ''
              }`}
            >
              {Object.keys(equipmentListingOptions || {}).map((key) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked
                        checked={selectedEquipmentListingOptionKeys.includes(
                          key
                        )}
                        onChange={onEquipmentListingChange}
                        value={key}
                        key={`check-equipment-${key}`}
                      />
                    }
                    label={key}
                    key={`filter-equipment-${key}`}
                  />
                );
              })}
            </CollapsibleField>
          ) : null}
          {rl2468FilterByEquipmentListings ? (
            <CollapsibleField
              label={`Equipment Type ${
                equipmentTypeFilterCount ? `(${equipmentTypeFilterCount})` : ''
              }`}
            >
              {[...equipmentTypeOptions].map((option) => {
                return (
                  <Grid key={`grid-${option}`} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          checked={selectedEquipmentTypes.includes(option)}
                          onChange={onEquipmentTypeChange}
                          value={option}
                          key={`check-equipment-type-${option}`}
                        />
                      }
                      label={option}
                      key={`filter-equipment-type-${option}`}
                    />
                  </Grid>
                );
              })}
            </CollapsibleField>
          ) : null}
        </FiltersForm>
      </FiltersDrawer>
    </>
  );
};

export default FilterTopShipments;
