import { Box } from '@mui/material';
import { Form } from 'components/library/form';
import { trackEvent } from 'utils/mixpanel';
import { useSearchByCarrierContext } from '../../SearchByCarrierContext';
import { CARRIER_SEARCH_FORM_FIELDS, searchFormConfig } from './utils';

type SearchByCarrierFormData = {
  carrierMCNumber?: string;
  carrierDOTNumber?: string;
  carrierName?: string;
  headquarterPlaceId?: string;
};

const emptyFormData: SearchByCarrierFormData = {
  carrierMCNumber: '',
  carrierDOTNumber: '',
  carrierName: '',
  headquarterPlaceId: '',
};

const SearchByCarrierForm = () => {
  const {
    setFormData,
    formData,
    search,
    setPage,
    fetchingCarriersWithinRadius,
  } = useSearchByCarrierContext();

  const clearFields = () => {
    setFormData(emptyFormData);
  };

  const handleSubmit = () => {
    setPage(0);
    search();
    trackEvent('Clicked Search button on CSE Search By Carrier', { formData });
  };

  const handleOnChange = (values: SearchByCarrierFormData) =>
    setFormData({ ...values });

  return (
    <Box sx={{ p: '0 24px' }}>
      <Form
        submitting={false}
        onSubmit={handleSubmit}
        formConfig={searchFormConfig}
        inputData={CARRIER_SEARCH_FORM_FIELDS}
        formName="search-carrier"
        onChange={handleOnChange}
        onCancel={() => clearFields()}
        initialValues={emptyFormData}
        disableSubmit={fetchingCarriersWithinRadius}
      />
    </Box>
  );
};

export default SearchByCarrierForm;
