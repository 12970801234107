import { Box, Card, Stack } from '@mui/material';
import PageTitle from 'components/PageTitle';
import PageWrapper from 'components/PageWrapper';
import { Body, H1, H2 } from 'components/SteelUI/atoms/typography';
import { ModalContainer } from 'components/SteelUI/molecules/modals';
import ModalCloseIcon from 'components/SteelUI/molecules/modals/components/ModalIcons/ModalCloseIcon';
import ModalErrorIcon from 'components/SteelUI/molecules/modals/components/ModalIcons/ModalErrorIcon';
import RateChart from 'features/LogisticsQuoteManager/components/RateChart';
import RatesComparison from 'features/LogisticsQuoteManager/components/RatesComparison';
import type { RatesRequest } from 'features/LogisticsQuoteManager/hooks/useGetCurrentRates';
import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Form from './components/Form';
import useGetCurrentRates from './hooks/useGetCurrentRates';
import useGetHistoricalRates from './hooks/useGetHistoricalRates';

const useStyles = makeStyles()(() => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1fr)',
    gridTemplateRows: 'auto 1fr',
    gap: '1rem',
    width: '100%',
    height: '100%',
    padding: '24px',
  },
  card: {
    padding: '24px',
  },
  cardForm: {
    gridColumn: '1 / span 10',
    gridRow: '1',
  },
  cardChart: {
    gridColumn: '1 / span 7',
    gridRow: '2',
    minHeight: '620px',
  },
  cardComparison: {
    gridColumn: '8 / span 3',
    gridRow: '2',
  },
}));

const LogisticsQuoteManager = () => {
  const { cx, classes } = useStyles();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const {
    mutate: getCurrentRates,
    isLoading: isLoadingCurrentRates,
    data: { currentRates = [], distance = 0, freightQuoteRequestId = '' } = {},
    error: errorCurrentRates,
    isIdle: hasNeverFetchedCurrentRates,
  } = useGetCurrentRates();

  const {
    mutate: getHistoricalRates,
    isLoading: isLoadingHistoricalRates,
    data: { historicalRates: historicalRates = [] } = {},
    error: errorGetHistoricalRates,
    isIdle: hasNeverFetchedHistoricalRates,
  } = useGetHistoricalRates();

  const handleGetRate = (formData: RatesRequest) => {
    getCurrentRates(formData);
    getHistoricalRates(formData);
  };

  useEffect(() => {
    if (errorCurrentRates || errorGetHistoricalRates) {
      setOpenErrorModal(true);
    }
  }, [errorCurrentRates, errorGetHistoricalRates]);

  return (
    <PageWrapper showFooter={false} isNewTheme title="Logistics Quote Manager">
      <PageTitle
        title="Logistics Quote Manager"
        description="Get Started By Inputting Your Shipment Information"
      />
      <Box className={cx(classes.gridContainer)}>
        <Card
          sx={{ padding: '24px' }}
          variant="outlined"
          className={classes.cardForm}
        >
          <Form getRate={handleGetRate} />
        </Card>
        <Card
          variant="outlined"
          className={cx(classes.card, classes.cardChart)}
        >
          <RateChart
            hasNeverFetched={hasNeverFetchedHistoricalRates}
            isLoading={isLoadingHistoricalRates}
            historicalRates={historicalRates}
          />
        </Card>
        <Card
          variant="outlined"
          className={cx(classes.card, classes.cardComparison)}
        >
          <RatesComparison
            hasNeverFetched={hasNeverFetchedCurrentRates}
            isLoading={isLoadingCurrentRates}
            currentRates={currentRates}
            distance={distance}
            freightQuoteRequestId={freightQuoteRequestId}
          />
        </Card>
      </Box>
      <ModalContainer open={openErrorModal}>
        <Box
          sx={{
            backgroundColor: '#FFF',
            borderRadius: '8px',
            padding: '24px',
          }}
        >
          <Stack direction="column" alignItems="end">
            <ModalCloseIcon onClick={() => setOpenErrorModal(false)} />
            <Stack direction="column" alignItems="center">
              <H1>
                <ModalErrorIcon />
              </H1>
              <H2 paddingTop="8px">An error has occurred</H2>
              <Body>Please try again or contact support.</Body>
            </Stack>
          </Stack>
        </Box>
      </ModalContainer>
    </PageWrapper>
  );
};

export default LogisticsQuoteManager;
