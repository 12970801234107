import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

type Props = {
  count: number;
  page: number;
  rowsPerPage: number;
  changePage: (page: number) => void;
};

const Pagination = ({
  count = 0,
  page = 0,
  rowsPerPage = 20,
  changePage,
}: Props) => {
  const lastPage = Math.ceil(count / rowsPerPage);
  const pageCount = lastPage - 1;

  const offset = page * rowsPerPage;
  const lastPageCount =
    count % rowsPerPage === 0 ? rowsPerPage : count % rowsPerPage;
  const to = page !== pageCount ? rowsPerPage : lastPageCount;
  const start = page === 0 ? 1 : offset + 1;
  const end =
    page === 0 ? (count >= rowsPerPage ? rowsPerPage : count) : offset + to;

  return (
    <Grid container justifyContent="flex-end" alignItems="center">
      <Grid item>
        <b>{start}</b> to <b>{end}</b> of <b>{count}</b>
      </Grid>
      <Grid item>
        <IconButton
          onClick={() => changePage(0)}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          onClick={() => changePage(page - 1)}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
      </Grid>
      <Grid item>
        Page <b>{page + 1}</b> of <b>{lastPage}</b>
      </Grid>
      <Grid item>
        <IconButton
          onClick={() => changePage(page + 1)}
          disabled={page >= pageCount}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          onClick={() => changePage(pageCount)}
          disabled={page >= pageCount}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Pagination;
