import { useUserSession } from '@reibus/frontend-utility';

import {
  FreightQuoteRequest,
  postFreightQuoteRequestPrivate,
  postFreightQuoteRequestPublic,
} from 'api/request-for-quote';

export const usePostFreightQuote = () => {
  const { data: userSession = {} } = useUserSession();
  const { identityToken } = userSession;

  return async (data: FreightQuoteRequest) => {
    return identityToken
      ? postFreightQuoteRequestPrivate(identityToken, data)
      : postFreightQuoteRequestPublic(data);
  };
};
