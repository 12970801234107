import { Grid, Card, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SkeletonWrapper from 'components/library/SkeletonWrapper';
import { ReactNode } from 'react';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  item: {
    paddingBottom: spacing(2),
  },
  card: {
    borderRadius: '8px !important',
    padding: spacing(2),
  },
}));

const CarrierPageCard = ({
  isLoading = false,
  children,
}: {
  isLoading?: boolean;
  children: ReactNode;
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined">
      <SkeletonWrapper isLoading={isLoading}>{children}</SkeletonWrapper>
    </Card>
  );
};

export default CarrierPageCard;
