import { makeStyles } from '@mui/styles';
import FormSection from './FormSection';
import { AddressField } from './AddressFieldWithPrefill';

import type { PlacePredictionFn } from 'request-for-quote/v2/RequestForQuoteV2';
import type { AddressBreakdown } from 'request-for-quote/v2/form/AddressFieldWithPrefill';

const useStyles = makeStyles({
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: '24px',
    rowGap: '40px',
  },
});

type LocationProps = {
  placeFetchers: PlacePredictionFn[];
  places: google.maps.places.AutocompletePrediction[];
  placesServiceInstance: google.maps.places.PlacesService | null;
  setOriginAddressBreakdown?: (addressBreakdown: AddressBreakdown) => void;
  setDestinationAddressBreakdown?: (addressBreakdown: AddressBreakdown) => void;
};

const Location = ({
  placeFetchers,
  places,
  placesServiceInstance,
  setOriginAddressBreakdown,
  setDestinationAddressBreakdown,
}: LocationProps) => {
  const classes = useStyles();

  return (
    <FormSection title="Location">
      <div className={classes.content}>
        <AddressField
          name="originAddress"
          label="Origin"
          placeholder="Address"
          required
          placeFetchers={placeFetchers}
          places={places}
          placesServiceInstance={placesServiceInstance}
          setAddressBreakdown={setOriginAddressBreakdown}
        />
        <AddressField
          name="destinationAddress"
          label="Destination"
          placeholder="Address"
          required
          placeFetchers={placeFetchers}
          places={places}
          placesServiceInstance={placesServiceInstance}
          setAddressBreakdown={setDestinationAddressBreakdown}
        />
      </div>
    </FormSection>
  );
};

export default Location;
