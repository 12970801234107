import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    fontSize: 24,
    fontWeight: 400,
    color: '#212224',
    lineHeight: '30px',
    marginTop: spacing(1),
    marginBottom: spacing(4),
    textAlign: 'center',
  },
  icon: {
    marginTop: spacing(2),
  },
}));

const Error = () => {
  const classes = useStyles();

  return (
    <Stack justifyContent="center" alignItems="center">
      <Box className={classes.icon}>
        <FontAwesomeIcon
          icon={faCircleExclamation}
          fontSize={32}
          color={'#BB121A'}
        />
      </Box>
      <Box className={classes.title}>
        There was an error deleting this equipment. Please try again.
      </Box>
    </Stack>
  );
};

export default Error;
