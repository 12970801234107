import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';

type Props = {
  indicators: {
    key: string;
    name: string;
    weight: number;
    totalScore: number;
  }[];
};

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey['100'],
  },
  head: {
    backgroundColor: theme.palette.grey['100'],
    '& *': {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '125%',
    },
  },
  body: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey['100'],
  },
  row: {
    '& > *': {
      fontSize: 14,
      lineHeight: '125%',
    },
    '& > *:first-child': {
      fontWeight: 700,
    },
    '& > *:not(:last-child)': {
      borderRightWidth: 1,
      borderRightStyle: 'solid',
      borderRightColor: theme.palette.grey['100'],
    },
  },
}));

const formatWeight = (weight: number) => {
  return !weight ? `0%` : `${weight * 100}%`;
};

const formatScore = (score: number) => {
  return !score ? `0` : `${score.toFixed(3)}`;
};

const indicatorKeyNameMap = {
  DAT_LOAD_BOARD: 'DAT',
  ITS_LOAD_BOARD: 'ITS',
  LANE_HISTORY: 'Lane History',
  HQ: 'HQ',
};

const CapacityIndicatorsTable = ({ indicators }: Props) => {
  const classes = useStyles();
  const { rl1953IncludeAvailableEquipmentInCompositeScore: rl1953FF } =
    useFlags();
  const indicatorMapWithCarrierAvailability = rl1953FF
    ? { ...indicatorKeyNameMap, REIBUS_LOAD_BOARD: 'REQ' }
    : indicatorKeyNameMap;
  const indicatorsWithCarrierAvailability = rl1953FF
    ? indicators
    : indicators.filter(({ key }) => key !== 'REIBUS_LOAD_BOARD');

  return (
    <Table className={classes.table}>
      <TableHead className={classes.head}>
        <TableRow>
          <TableCell>Capacity Indicator</TableCell>
          <TableCell align="right">Weight</TableCell>
          <TableCell align="right">Score</TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={classes.body}>
        {indicatorsWithCarrierAvailability.map(
          ({ key, weight, totalScore, name }, i) => {
            return (
              <TableRow
                key={`composite-score-details-capacity-indicators-table-${i}`}
                className={classes.row}
              >
                <TableCell>
                  {indicatorMapWithCarrierAvailability[
                    key as keyof typeof indicatorMapWithCarrierAvailability
                  ] ?? name}
                </TableCell>
                <TableCell align="right">{formatWeight(weight)}</TableCell>
                <TableCell align="right">{formatScore(totalScore)}</TableCell>
              </TableRow>
            );
          }
        )}
      </TableBody>
    </Table>
  );
};

export default CapacityIndicatorsTable;
