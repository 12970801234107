import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import NoResults from '../../../SearchByCarrier/components/SearchByCarrierResults/components/NoResults';
import Pagination from 'components/SteelUI/organisms/Pagination';
import { HeadCell } from '../../../SearchByCarrier/components/SearchByCarrierResults/components/ResultsTable';
import {
  SortBy,
  useSearchShipmentsContext,
} from 'search-shipments/SearchShipmentsContext';
import { useEffect, useState } from 'react';
import type { HitsProps } from 'search-shipments/search-shipments-form/utils';
import ColumnHeader from 'features/cse/search/SearchByCarrier/components/SearchByCarrierResults/components/ColumnHeader';
import theme from 'theme';
import { trackEvent } from 'utils/mixpanel';
import { Launch, Search } from '@mui/icons-material';
import ButtonV2 from 'components/library/ButtonV2';
import { TooltipEllipsis } from 'components/TooltipEllipsis';
import {
  formatDate,
  getEquipmentName,
} from 'search-shipments/search-shipments-results/ResultsTable';
import { makeStyles } from '@mui/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Tooltip from 'components/SteelUI/atoms/Tooltip';
import MultiStopTooltip from 'features/cse/search/SearchByShipment/components/SearchByShipmentResults/MultiStopTooltip';

const headCells: HeadCell[] = [
  {
    label: 'Shipment ID',
    sortBy: 'shipmentID',
    align: 'right',
  },
  {
    label: 'Origin',
    sortBy: 'startLocationLabel',
    align: 'left',
  },
  {
    label: 'DH-O',
    sortBy: '',
    align: 'right',
  },
  {
    label: 'Destination',
    sortBy: 'endLocationLabel',
    align: 'left',
  },
  {
    label: 'Pickup',
    sortBy: 'pickupDate',
    align: 'right',
  },
  {
    label: 'Delivery',
    sortBy: 'deliveryDate',
    align: 'right',
  },
  {
    label: 'Distance',
    sortBy: 'distance',
    align: 'right',
  },
  {
    label: 'Equipment Type',
    sortBy: 'equipmentType',
    align: 'left',
  },
  {
    label: 'Customer',
    align: 'left',
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    padding: theme.spacing(3),
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
  },
  tableRow: {
    backgroundColor: '#FFFFFF !important',
    '&:hover': {
      backgroundColor: '#F2F7FF !important',
    },
  },
  tableHead: {
    backgroundColor: `${theme.palette.grey[100]} !important`,
  },
  tableCell: {
    padding: `12px 16px !important`,
  },
  table: {
    border: `1px solid ${theme.palette.grey[50]}`,
    tableLayout: 'fixed',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  textLink: {
    fontWeight: 400,
  },
}));

const SearchByShipmentResults = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const { isLoading, results, sort, setSort, customers, search } =
    useSearchShipmentsContext();
  const getCustomerName = (customerId: number) => {
    return customers.get(String(customerId)) ?? 'N/A';
  };
  const { rl2220CsePagesUrlsAndTitles } = useFlags();

  useEffect(() => {
    search({
      searchPage: page,
      searchSortOrder: sort.sortOrder,
      searchSortBy: sort.sortBy as SortBy,
    });
  }, [page]);

  if (isLoading || !results) {
    return null;
  }

  if (!results.hits.length) {
    return <NoResults />;
  }

  const { hits, nbHits, hitsPerPage } = results;

  return (
    <Box sx={{ p: '0 24px' }}>
      <Paper sx={{ p: '24px' }}>
        <Box
          sx={{
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '30px',
            pb: '24px',
          }}
        >
          Search Results
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  border: 1,
                  borderColor: theme.palette.grey[100],
                  backgroundColor: theme.palette.grey[100],
                }}
              >
                {headCells.map((column) => (
                  <TableCell
                    key={column.label}
                    sx={{ width: column.width ?? 'auto' }}
                    align={column.align}
                  >
                    <ColumnHeader
                      label={column.label}
                      sortBy={column.sortBy}
                      align={column.align}
                      currentSort={sort}
                      setSort={setSort}
                    />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {hits.map((row: HitsProps) => {
                return (
                  <TableRow
                    hover
                    key={row.shipmentID}
                    className={classes.tableRow}
                  >
                    <TableCell className={classes.tableCell} align={'right'}>
                      <Link
                        to={
                          !rl2220CsePagesUrlsAndTitles
                            ? `/shipment/${row.shipmentID}`
                            : `/cse/shipment/${row.shipmentID}`
                        }
                        target="_blank"
                        onClick={() =>
                          trackEvent(
                            'Clicked a shipment link in shipment search results on cse',
                            { shipmentID: row.shipmentID }
                          )
                        }
                        className={classes.link}
                      >
                        <ButtonV2
                          variant="text"
                          className={classes.textLink}
                          endIcon={<Launch />}
                        >
                          {row.shipmentID}
                        </ButtonV2>
                      </Link>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.startLocationLabel}
                    </TableCell>
                    <TableCell className={classes.tableCell} align={'right'}>
                      {row.originDH}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <MultiStopTooltip
                        endLocationLabel={row.endLocationLabel}
                        globalRoute={row.globalRoute}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell} align={'right'}>
                      {formatDate(row.pickupDate)}
                    </TableCell>
                    <TableCell className={classes.tableCell} align={'right'}>
                      {formatDate(row.deliveryDate)}
                    </TableCell>
                    <TableCell className={classes.tableCell} align={'right'}>
                      {row.distance ? `${row.distance} mi` : 'N/A'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <TooltipEllipsis
                        textValue={getEquipmentName(row.equipmentType)}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {getCustomerName(row.customerId)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ pt: '24px' }}>
          <Pagination
            page={page}
            count={nbHits}
            rowsPerPage={hitsPerPage}
            changePage={setPage}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default SearchByShipmentResults;
