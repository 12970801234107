import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Box } from '@mui/material';

import Button from 'components/SteelUI/atoms/Button';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  filterCount: number;
};

const FiltersButton = ({ open, setOpen, filterCount }: Props) => {
  const filterCountText = filterCount ? `(${filterCount})` : '';

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(!open)}
        sx={{ width: '160px' }}
      >
        <Box display="flex" alignItems="center">
          <FontAwesomeIcon icon={icon({ name: 'sliders' })} />
          <Box sx={{ pl: '8px' }}>Filters {filterCountText}</Box>
        </Box>
      </Button>
    </>
  );
};

export default FiltersButton;
