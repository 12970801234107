import { makeStyles } from '@mui/styles';
import { Theme, Container, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { connectSearchBox } from 'react-instantsearch-dom';
import { useEffect } from 'react';
import { SearchBoxProvided } from 'react-instantsearch-core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: 0,
    marginTop: theme.spacing(2),
    width: 'inherit',
  },
}));

type SearchBarProps = SearchBoxProvided & {
  handleQueryParams?: (refine: (value: string) => void) => void;
};

const AlgoliaSearchBar = ({
  refine,
  currentRefinement,
  handleQueryParams,
}: SearchBarProps) => {
  const classes = useStyles();

  useEffect(() => {
    handleQueryParams?.(refine);
  }, []);

  return (
    <Container className={classes.container}>
      <TextField
        id={'search-bar'}
        type="search"
        label="Search"
        variant="outlined"
        fullWidth
        data-testid="search-bar-test"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!currentRefinement ? <SearchIcon /> : null}
            </InputAdornment>
          ),
        }}
        value={currentRefinement}
        onChange={(e) => refine(e.target.value)}
      />
    </Container>
  );
};

export default connectSearchBox<SearchBarProps>(AlgoliaSearchBar);
