import * as React from 'react';
import { useCurrentUser as useSharedCurrentUser } from '@reibus/frontend-utility';

export default function useCurrentUser() {
  const query = useSharedCurrentUser();
  const currentUser = React.useMemo(() => {
    if (!query.data) {
      return query.data;
    }

    const {
      firstName,
      lastName,
      permission: { systemRoles, userTypes },
      company: { name: companyName, tmsCompany, id: companyId },
      shippingLocation,
      shippingLocationPostalCode,
      measurementSystem,
      email,
      phoneNumber,
    } = query.data;

    return {
      firstName,
      lastName,
      systemRoles,
      companyName,
      companyId,
      tmsCompany,
      shippingLocation,
      shippingLocationPostalCode,
      measurementSystem,
      email,
      phoneNumber,
      userTypes,
    };
  }, [query.data]);

  return {
    ...query,
    data: currentUser,
  };
}
