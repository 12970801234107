import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  circleCheck: {
    color: '#237960',
    width: '2rem',
    height: '2em',
  },
}));

const ModalSuccessIcon = () => {
  const { classes } = useStyles();

  return (
    <FontAwesomeIcon className={classes.circleCheck} icon={faCircleCheck} />
  );
};

export default ModalSuccessIcon;
