import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import LinkIcon from '@mui/icons-material/Link';
import { Theme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Button from 'components/library/Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const COPY_SUCCESS_TIME = 15000;

const useStyles = makeStyles((theme: Theme) => ({
  iconWrap: {
    display: 'flex',
    paddingRight: theme.spacing(1),
  },
  shareText: {
    lineHeight: 1,
  },
  tooltip: {
    backgroundColor: 'rgba(38, 50, 56, 1)',
    fontSize: 12,
    lineHeight: '16px',
    borderRadius: 3,
    letterSpacing: 0.4,
    padding: `${theme.spacing(1)} ${theme.spacing(2.5)}`,
    minWidth: 178,
  },
  tooltipArrow: {
    color: 'rgba(38, 50, 56, 1)',
  },
}));

type ShareButtonProps = {
  copyText: string;
  buttonText: string;
  successText: string;
  onClick?: () => void;
};

const ShareButton = ({
  copyText,
  buttonText,
  successText,
  onClick,
}: ShareButtonProps) => {
  const [copied, setCopied] = useState<boolean>(false);
  const handleCopied = () => {
    setCopied(true);
    !onClick ? null : onClick();
  };
  const copyTimeout = () => {
    setTimeout(() => {
      setCopied(false);
    }, COPY_SUCCESS_TIME);
  };
  useEffect(() => {
    if (copied) {
      copyTimeout();
    }
  });

  const classes = useStyles();

  return (
    <>
      <CopyToClipboard text={copyText} onCopy={handleCopied}>
        <Tooltip
          open={copied}
          placement="top"
          title={successText}
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.tooltipArrow,
          }}
          arrow
        >
          <Button kind="outlined">
            <span className={classes.iconWrap}>
              <LinkIcon />
            </span>
            <span className={classes.shareText}>{buttonText}</span>
          </Button>
        </Tooltip>
      </CopyToClipboard>
    </>
  );
};

export default ShareButton;
