import { useState } from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
} from 'recharts';
import type { Payload } from 'recharts/types/component/DefaultLegendContent';
import { Body } from 'components/SteelUI/atoms/typography';
import {
  LaneRateLocationFit,
  RateSource,
  RateType,
} from 'features/LogisticsQuoteManager/hooks/useGetHistoricalRates';
import Tooltip from 'components/SteelUI/atoms/Tooltip';
import { calculateYAxisTicks, formatLocationFit, formatRate } from './utils';

export type ChartDataPoint = {
  date: string;
  rate: number;
  high: number;
  low: number;
};

export type ChartData = {
  rates: ChartDataPoint[];
  origin?: {
    locationFit?: LaneRateLocationFit;
  };
  destination?: {
    locationFit?: LaneRateLocationFit;
  };
  source: RateSource;
  rateType?: RateType;
  name: string;
  color: string;
  id: string;
};

type ChartProps = {
  datasets: ChartData[];
};

const Chart = ({ datasets }: ChartProps) => {
  const hasData = datasets.reduce((acc, dataset) => {
    acc[dataset.id] = dataset.rates.length > 0;
    return acc;
  }, {} as Record<string, boolean>);

  const getFirstAvailableDataset = () => {
    const firstWithData = datasets.find((dataset) => dataset.rates.length > 0);
    return firstWithData?.id || null;
  };

  const [activeLine, setActiveLine] = useState<string | null>(
    getFirstAvailableDataset()
  );

  const allDates = Array.from(
    new Set(
      datasets.flatMap((dataset) => dataset.rates.map((point) => point.date))
    )
  ).sort();

  const mergedData = allDates.map((date) => {
    const dataPoint: Record<string, any> = { date };

    datasets.forEach((dataset) => {
      const point = dataset.rates.find((r) => r.date === date);

      dataPoint[`${dataset.id}.rate`] = point?.rate || null;

      dataPoint[dataset.id] = point
        ? {
            rate: point.rate,
            high: point.high,
            low: point.low,
          }
        : null;
    });

    return dataPoint;
  });

  const allRates = mergedData.flatMap((d) => {
    return datasets
      .map((dataset) => d[dataset.id]?.rate)
      .filter(Boolean) as number[];
  });

  const { yDomain, yTicks } = calculateYAxisTicks(allRates);

  const legendPayload: Payload[] = datasets.map((dataset) => ({
    value: dataset.name,
    id: dataset.id,
    color: dataset.color,
    dataKey: `${dataset.id}.rate`,
    inactive: !hasData[dataset.id],
  }));

  const formatLegendHelperText = (payload: Payload) => {
    const dataset = datasets.find((d) => d.id === payload.id);
    if (!dataset) return null;

    if (dataset.source === 'REIBUS') {
      return '* Reibus Rate Target Area 100 Miles';
    }

    if (
      dataset.source === 'DAT' &&
      !!dataset?.origin?.locationFit &&
      !!dataset?.destination?.locationFit
    ) {
      return `* DAT Rate Based On ${formatLocationFit(
        dataset.origin.locationFit
      )}${
        dataset.origin.locationFit !== dataset.destination.locationFit
          ? `to ${formatLocationFit(dataset.destination.locationFit)}`
          : ''
      } `;
    }

    return null;
  };

  const handleLegendClick = (payload: any) => {
    if (hasData[payload.id]) {
      setActiveLine((prev) => (prev === payload.id ? prev : payload.id));
    }
  };

  const legendFormatter = (value: string, entry: any) => {
    const legendContent = (
      <span
        style={{
          cursor: hasData[entry.id] ? 'pointer' : 'not-allowed',
          display: 'inline-flex',
          alignItems: 'flex-start',
          gap: '8px',
          color: '#34363B',
          opacity: hasData[entry.id] ? 1 : 0.5,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <div
              style={{
                position: 'relative',
                width: '14px',
                height: '14px',
              }}
            >
              <input
                type="radio"
                name="chartLine"
                checked={activeLine === entry.id}
                onChange={() => handleLegendClick(entry)}
                disabled={!hasData[entry.id]}
                style={{
                  cursor: hasData[entry.id] ? 'pointer' : 'not-allowed',
                  width: '14px',
                  height: '14px',
                  margin: 0,
                  appearance: 'none',
                  border: `2px solid ${entry.color}`,
                  borderRadius: '50%',
                  outline: 'none',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  boxSizing: 'border-box',
                  opacity: hasData[entry.id] ? 1 : 0.5,
                }}
              />
              {activeLine === entry.id && hasData[entry.id] && (
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '8px',
                    height: '8px',
                    backgroundColor: entry.color,
                    borderRadius: '50%',
                    pointerEvents: 'none',
                  }}
                />
              )}
            </div>
            <span style={{ color: '#000000' }}>{value}</span>
          </div>
          <Body size="xs" weight="medium">
            {formatLegendHelperText(entry)}
          </Body>
        </div>
      </span>
    );

    return hasData[entry.id] ? (
      legendContent
    ) : (
      <Tooltip title="No data available">{legendContent}</Tooltip>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={500}>
      <ComposedChart data={mergedData} margin={{ bottom: 12, right: 60 }}>
        <CartesianGrid stroke="#666666" opacity={0.3} />
        <XAxis
          dataKey="date"
          tick={{ fontSize: 12 }}
          tickFormatter={(date) =>
            new Date(date).toLocaleString('en-US', { month: 'short' })
          }
          tickLine={{
            stroke: '#666666',
            opacity: 0.3,
            strokeWidth: 1,
          }}
          tickSize={20}
          axisLine={false}
        />

        <YAxis
          tick={{ fontSize: 12, dx: -4 }}
          domain={yDomain}
          ticks={yTicks}
          tickFormatter={(value) => `${formatRate(value)}`}
          axisLine={false}
          interval={0}
          tickLine={{
            strokeWidth: 2,
          }}
          tickSize={12}
        />

        <Legend
          align="left"
          verticalAlign="top"
          height={48}
          wrapperStyle={{
            paddingBottom: 132,
          }}
          payload={legendPayload}
          onClick={handleLegendClick}
          iconSize={0}
          formatter={legendFormatter}
        />

        {datasets.map(
          (dataset) =>
            dataset.rates.length > 0 && (
              <Line
                key={`line-${dataset.id}`}
                name={dataset.name}
                type="monotone"
                dataKey={`${dataset.id}.rate`}
                stroke={dataset.color}
                strokeWidth={2}
                dot={{ r: 4, strokeWidth: 2, fill: dataset.color }}
                connectNulls={false}
              />
            )
        )}

        {datasets.map(
          (dataset) =>
            dataset.rates.length > 0 && (
              <Line
                key={`label-${dataset.id}`}
                dataKey={`${dataset.id}.rate`}
                stroke="none"
                dot={false}
                label={{
                  content: ({ x, y, index }) => {
                    const data = mergedData[index]?.[dataset.id];
                    if (!data) {
                      return null;
                    }
                    return (
                      <g
                        style={{
                          opacity: activeLine === dataset.id ? 1 : 0,
                          transition: 'opacity 0.2s ease',
                        }}
                      >
                        <text
                          x={x - 12}
                          y={y - 72}
                          fill={dataset.color}
                          textAnchor="start"
                          fontSize={18}
                          fontWeight="700"
                        >
                          {formatRate(data.high)}
                        </text>
                        <text
                          x={x - 12}
                          y={y - 40}
                          fill={dataset.color}
                          textAnchor="start"
                          fontSize={18}
                          fontWeight="700"
                        >
                          {formatRate(data.rate)}
                        </text>
                        <text
                          x={x - 12}
                          y={y - 8}
                          fill={dataset.color}
                          textAnchor="start"
                          fontSize={18}
                          fontWeight="700"
                        >
                          {formatRate(data.low)}
                        </text>
                      </g>
                    );
                  },
                }}
              />
            )
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default Chart;
