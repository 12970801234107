import { User } from 'api/user';

import { ShipmentDetailsResponse, LocationDetails } from 'shipment/api';
import { formatDate } from 'components/SteelUI/organisms/RouteDetails/components/RouteStop';
import { Appointment } from 'components/SteelUI/organisms/RouteDetails/RouteDetails';

type DateType = {
  datetime: string;
  timezone: string;
  flex?: number | undefined;
};

type EmailContentResult = {
  subject: string;
  body: string;
};

const getShortDate = (date: DateType) => {
  return new Date(date.datetime).toLocaleDateString('en-US', {
    month: 'numeric',
    day: 'numeric',
    timeZone: date.timezone,
  });
};

export const createEmailContent = (
  user: User,
  shipmentDetails: ShipmentDetailsResponse,
  locations: LocationDetails,
  carrierName = ''
): EmailContentResult => {
  const { firstName, lastName, email: fromEmail } = user;

  const {
    commodities,
    equipmentNeededDesc,
    services,
    netWeight,
    netWeightUnit,
  } = shipmentDetails;

  const { pickup, delivery, route } = locations;

  const commoditiesLine = commodities
    .map((commodity) => commodity.name)
    .join(',');
  const servicesLine = services
    .map((service) => service.description)
    .join(', ');
  const netWeightLine = netWeight ? netWeight + ' ' + netWeightUnit : undefined;

  const pickupLine = `${pickup.address.city}, ${pickup.address.region}`;
  const pickUpDateLine = formatDate(pickup.date as Appointment);
  const pickupDateSubject = getShortDate(pickup.date);

  const deliveryLine = `${delivery.address.city}, ${delivery.address.region}`;
  const deliveryDateLine = formatDate(locations?.delivery.date as Appointment);
  const deliveryDateSubject = getShortDate(delivery.date);

  const stopsFormatted =
    route?.length > 2
      ? `This shipment has ${route.length} stops:%0D%0A${route
          .map((stop) => {
            const locationLabel = [stop.address.city, stop.address.region]
              .filter(Boolean)
              .join(', ');
            return locationLabel;
          })
          .join('%0D%0A')}%0D%0A%0D%0A`
      : '';

  const subject = `Reibus: Shipment available from ${locations?.pickup.address.city}, ${locations?.pickup.address.region} (${pickupDateSubject}) to ${locations?.delivery.address.city}, ${locations?.delivery.address.region}  (${deliveryDateSubject})`;

  const body = `Dear ${carrierName},%0D%0A%0D%0A
We have this shipment available:%0D%0A%0D%0A
Pickup location: ${pickupLine}%0D%0A%0D%0A
Pickup date: ${pickUpDateLine}%0D%0A%0D%0A
Delivery location: ${deliveryLine}%0D%0A%0D%0A
Delivery date: ${deliveryDateLine}%0D%0A%0D%0A
${commoditiesLine ? `Commodity: ${commoditiesLine}%0D%0A%0D%0A` : ''}
Equipment Type: ${equipmentNeededDesc}%0D%0A%0D%0A
${servicesLine ? `Services: ${servicesLine}%0D%0A%0D%0A` : ''}
${netWeightLine ? `Weight: ${netWeightLine}%0D%0A%0D%0A` : ''}
Target Rate:%0D%0A%0D%0A
${stopsFormatted}
${firstName} ${lastName}%0D%0A%0D%0A
${fromEmail}`;

  const encodedBody = body.replace('&', '%26');

  return {
    subject,
    body: encodedBody,
  };
};
