import { DialogContent, Grid, Theme, Typography } from '@mui/material';
import { useDispositionContext } from 'features/cse/disposition/contexts/DispositionContext';
import { faInfoSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonV2 from 'components/library/ButtonV2';

const AlreadyCovered = () => {
  const { closeDispositionModal } = useDispositionContext();

  return (
    <DialogContent>
      <Grid container direction="column" alignItems="center" sx={{ pt: 3 }}>
        <Grid item>
          <FontAwesomeIcon icon={faInfoSquare} color="#5DD2FC" size="2xl" />
        </Grid>
        <Grid item>
          <Typography variant="h3">Already Covered</Typography>
        </Grid>
        <Grid item sx={{ pt: 3 }}>
          <Typography variant="body2" align="center">
            This shipment has been set to covered status in Turvo. It needs to
            be set to tendered status in order to file another disposition for
            this shipment.
          </Typography>
        </Grid>
        <Grid item sx={{ padding: `16px 0px` }}>
          <ButtonV2 onClick={() => closeDispositionModal()}>Close</ButtonV2>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default AlreadyCovered;
