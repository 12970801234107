import clsx from 'clsx';
import { useRef } from 'react';
import { Box, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useContainerDimensions from 'hooks/useContainerDimensions';

const useStyles = makeStyles(() => ({
  doNotShowTooltip: {
    display: 'none',
  },
  additionalInfoValue: {
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  twoLinesInfo: {
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
}));

export const TooltipEllipsis = ({
  textValue,
  hasTwoLines = false,
}: {
  textValue: string;
  hasTwoLines?: boolean;
}) => {
  const classes = useStyles();
  const componentRef = useRef(null);
  const { scrollWidth, width, scrollHeight, height } =
    useContainerDimensions(componentRef);
  const contentIsGreaterThanContainer =
    scrollWidth > width || scrollHeight > height;

  return (
    <Tooltip
      arrow
      title={textValue}
      placement="bottom"
      classes={{
        popper: clsx(
          !contentIsGreaterThanContainer && classes.doNotShowTooltip
        ),
      }}
    >
      <Box
        ref={componentRef}
        className={
          hasTwoLines ? classes.twoLinesInfo : classes.additionalInfoValue
        }
      >
        {textValue}
      </Box>
    </Tooltip>
  );
};
