import { Box } from '@mui/material';

import BrandClearIcon from 'components/icons/BrandClearIcon';
import Wrapper from 'components/NavBar/v2/Wrapper';
import { MixpanelTag } from 'utils/constants';
import { trackEvent } from 'utils/mixpanel';

const MARKETPLACE_DASHBOARD_LINK = '/app/dashboard/';

const BlankNavBar = () => {
  return (
    <Wrapper>
      <Box
        onClick={() =>
          trackEvent(MixpanelTag.ClickedTopNav, {
            title: 'Reibus Logo',
            url: '/app/dashboard',
          })
        }
        sx={{ p: '12px 0' }}
      >
        <a href={MARKETPLACE_DASHBOARD_LINK}>
          <BrandClearIcon />
        </a>
      </Box>
    </Wrapper>
  );
};

export default BlankNavBar;
