import { makeStyles } from '@mui/styles';
import FormSection from './FormSection';
import { DateField } from 'components/library/form/DateField';
import { Radio } from 'components/library/form/Radio';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles({
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    columnGap: '24px',
    rowGap: '40px',
  },
});

export const PickUpDateInput = () => {
  return (
    <DateField
      placeholder="mm/dd/yy"
      name="pickupDate"
      label="Pickup Date"
      required={false}
    />
  );
};

const Date = () => {
  const classes = useStyles();
  const { ct389FqrFormUpdates } = useFlags();

  return (
    <FormSection title="Date">
      <div className={classes.content}>
        {ct389FqrFormUpdates ? (
          <Radio
            name="shipsIn30Days"
            label="Will it ship in the next 30 days?"
            options={[
              {
                value: true,
                label: 'Yes',
              },
              {
                value: false,
                label: 'No',
              },
            ]}
            required={true}
          />
        ) : null}
        {ct389FqrFormUpdates ? (
          <Radio
            name="expeditedUrgency"
            label="Do you need it expedited?"
            options={[
              {
                value: true,
                label: 'Yes',
              },
              {
                value: false,
                label: 'No',
              },
            ]}
            required={true}
          />
        ) : null}
        <PickUpDateInput />
        {!ct389FqrFormUpdates ? (
          <DateField
            placeholder="mm/dd/yy"
            name="deliveryDate"
            label="Delivery Date"
            required={false}
            minDateGreaterThanInput={{
              input: 'pickupDate',
              errorMessage:
                'Please enter a date that is the same or later than the pickup date.',
            }}
            disablePast={false}
          />
        ) : null}
      </div>
    </FormSection>
  );
};

export default Date;
