import { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { useAuthorizationToken } from '@reibus/frontend-utility';
import { useQueryClient } from 'react-query';

import Modal from 'components/library/Modal';
import useDeleteEquipment from 'features/cse/Carrier/hooks/useDeleteEquipment';
import Loading from './screens/Loading';
import Submit from './screens/Submit';
import Success from './screens/Success';
import Error from './screens/Error';

import type { Equipment } from 'features/cse/Carrier/types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  equipment: Equipment;
  cseCarrierID: string;
};

const DeleteEquipmentModal = ({
  isOpen,
  onClose,
  equipment,
  cseCarrierID,
}: Props) => {
  const queryClient = useQueryClient();

  const authorization = useAuthorizationToken();
  const [currentScreen, setCurrentScreen] = useState<
    'submit' | 'loading' | 'success' | 'error'
  >('submit');

  const {
    mutate: deleteEquipment,
    isLoading,
    isSuccess,
    isError,
  } = useDeleteEquipment({
    onSuccess: () => {
      queryClient.invalidateQueries(['availableEquipment', cseCarrierID]);
      queryClient.invalidateQueries([
        'shipments-scored-carriers',
        cseCarrierID,
      ]);
    },
  });

  useEffect(() => {
    if (isLoading) {
      setCurrentScreen('loading');
    } else if (isSuccess) {
      setCurrentScreen('success');
    } else if (isError) {
      setCurrentScreen('error');
    }
  }, [isLoading, isSuccess, isError]);

  const handleDeleteEquipment = () => {
    deleteEquipment({
      authorization,
      equipmentId: equipment.id,
    });
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setCurrentScreen('submit');
    }, 500);
  };

  const screens = {
    submit: (
      <Submit
        handleClose={handleClose}
        handleDeleteEquipment={handleDeleteEquipment}
      />
    ),
    loading: <Loading />,
    success: <Success />,
    error: <Error />,
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} width={440}>
      <Container sx={{ padding: '24px' }}>{screens[currentScreen]}</Container>
    </Modal>
  );
};

export default DeleteEquipmentModal;
