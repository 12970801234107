import { makeStyles } from '@mui/styles';
import { Typography, Container, Chip, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  textid: {
    color: '#939393',
    fontSize: 10,
    marginBottom: 5,
    width: '100%',
  },
  textdate: {
    color: '#939393',
    fontSize: 10,
    marginTop: 5,
    width: '100%',
  },
  container: {
    paddingTop: 3,
    paddingLeft: 8,
    paddingRight: 0,
    textAlign: 'start',
    justifyContent: 'left',
  },
  chip: {
    maxWidth: 90,
    height: 28,
  },
  [theme.breakpoints.down(1300)]: {
    chip: {
      fontSize: 12,
    },
  },
  [theme.breakpoints.down(900)]: {
    container: {
      padding: `${theme.spacing(2)} ${theme.spacing(8)} ${theme.spacing(
        2
      )} ${theme.spacing(2)}`,
    },
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      padding: `${theme.spacing(2)} 0 ${theme.spacing(2)} ${theme.spacing(2)}`,
    },
    chip: {
      fontSize: 10,
      maxWidth: 74,
    },
  },
}));

type statusType = {
  shipmentId: string;
  status: string;
  dateTime: string;
  isSelected: boolean;
};

const StatusItem = ({ shipmentId, status, isSelected }: statusType) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Typography noWrap className={classes.textid}>
        {shipmentId}
      </Typography>
      <Chip
        label={status}
        component="span"
        color={isSelected ? 'primary' : 'default'}
        className={classes.chip}
      />
    </Container>
  );
};

export default StatusItem;
