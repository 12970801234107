import { Box, Stack } from '@mui/material';
import { ModalContainer } from 'components/SteelUI/molecules/modals';
import AutoAwardModalFlows from 'features/cse/AutoAward/components/AutoAwardModal/AutoAwardModalFlows';
import { makeStyles } from 'tss-react/mui';
import { useQueryClient } from 'react-query';

import type { DigitalFreightAward } from 'features/cse/AutoAward/types';
import Tooltip from 'components/SteelUI/atoms/Tooltip';
import { Body } from 'components/SteelUI/atoms/typography';
import { useState } from 'react';
import ModalCloseIcon from 'components/SteelUI/molecules/modals/components/ModalIcons/ModalCloseIcon';
import { useShipmentContext } from 'shipment/ShipmentContext';

const useStyles = makeStyles()({
  tooltip: {
    backgroundColor: '#212224',
    padding: '16px',
    borderRadius: '8px',
  },
  tooltipArrow: {
    color: '#212224',
  },
  text: {
    cursor: 'pointer',
  },
  innerContent: {
    backgroundColor: '#FFF',
    borderRadius: '8px',
    padding: '24px',
  },
});

const getLabels = (notifiedAt: boolean) => {
  if (!notifiedAt) {
    return { body: 'Send Offer', tooltip: 'Manually Send Auto Award' };
  }
  return { body: 'Offer Sent', tooltip: 'Auto Award Sent' };
};

type Props = {
  autoAward?: DigitalFreightAward;
  carrierId: string;
  shipmentId: string;
};

const AutoAwardModal = ({ autoAward, carrierId, shipmentId }: Props) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const [manualAwardSent, setManualAwardSent] = useState(false);
  const { notifiedAt = '' } = autoAward || {};
  const labels = getLabels(!!notifiedAt || manualAwardSent);
  const { fetchTopCarriers } = useShipmentContext();
  const queryClient = useQueryClient();

  const closeModal = () => {
    if (manualAwardSent) {
      queryClient.invalidateQueries(['shipments-scored-carriers', carrierId]);
      fetchTopCarriers();
    }
    setOpen(false);
  };

  return (
    <Box display="flex" alignItems="center">
      <Tooltip
        placement="top"
        arrow
        classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
        title={labels.tooltip}
      >
        <Box onClick={() => setOpen(true)} data-testid="auto-award-modal">
          <Body size="small" color="#2D6CCB" className={classes.text}>
            {labels.body}
          </Body>
        </Box>
      </Tooltip>
      <ModalContainer open={open} onClose={closeModal} maxWidth={false}>
        <Stack className={classes.innerContent} direction="column">
          <Box display="flex" justifyContent="flex-end">
            <ModalCloseIcon onClick={closeModal} />
          </Box>
          <AutoAwardModalFlows
            autoAward={autoAward}
            carrierId={carrierId}
            shipmentId={shipmentId}
            manualAwardSent={manualAwardSent}
            setManualAwardSent={setManualAwardSent}
          />
        </Stack>
      </ModalContainer>
    </Box>
  );
};

export default AutoAwardModal;
