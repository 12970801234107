import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Body, H4 } from 'components/SteelUI/atoms/typography';

const useStyles = makeStyles({
  wrap: {
    paddingTop: 24,
    paddingBottom: 24,
    borderTop: '1px solid #EDEEF0',
    borderBottom: '1px solid #EDEEF0',
  },
  label: {
    marginBottom: 16,
  },
  notes: {
    paddingRight: 50,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
  },
});

type RouteNotesProps = {
  pickupNotes?: string;
  deliveryNotes?: string;
};

const RouteNotes = ({ pickupNotes, deliveryNotes }: RouteNotesProps) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.wrap}>
      {pickupNotes ? (
        <Grid xs={6}>
          <H4 className={classes.label} weight="semibold">
            Pick Up Notes
          </H4>
          <Body className={classes.notes} size="medium">
            {pickupNotes}
          </Body>
        </Grid>
      ) : null}
      {deliveryNotes ? (
        <Grid xs={6}>
          <H4 className={classes.label} weight="semibold">
            Delivery Notes
          </H4>
          <Body className={classes.notes} size="medium">
            {deliveryNotes}
          </Body>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default RouteNotes;
