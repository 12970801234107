import { Box, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Email from 'shipment/carrier-contact-info-modal/Email';
import Phone from 'shipment/carrier-contact-info-modal/Phone';

import type { Equipment } from 'features/cse/Carrier/types';

const useStyles = makeStyles(() => ({
  name: {
    fontWeight: 600,
    fontSize: 18,
  },
  poster: {
    fontSize: 14,
  },
  icon: {
    color: '#64676B',
  },
}));

const EquipmentContact = ({
  equipmentContact,
}: {
  equipmentContact: Equipment;
}) => {
  const classes = useStyles();

  const { posterName, contactEmail, callbackPhone, capacityIndicatorType } =
    equipmentContact;

  const isDAT = capacityIndicatorType === 'DAT';
  const isReq = ['REIBUS_MANUAL', 'REIBUS_AUTOMATIC'].includes(
    capacityIndicatorType
  );
  const formatPosterName = (value?: string) => {
    if (isDAT) return 'Carrier contact';
    return value ?? 'N/A';
  };

  return (
    <Stack spacing={1} direction="column">
      <Box className={classes.name}>{formatPosterName(posterName)}</Box>
      <Box className={classes.poster}>
        {!isDAT ? 'Poster | ' : null}
        {isReq ? 'REQ' : capacityIndicatorType}
      </Box>
      <Phone phone={callbackPhone ?? ''} iconClassName={classes.icon} />
      <Email
        email={contactEmail ?? ''}
        iconClassName={!contactEmail ? classes.icon : ''}
      />
    </Stack>
  );
};

export default EquipmentContact;
