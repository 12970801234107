import { Box, Tooltip } from '@mui/material';
import AutoAwardTooltipDetails from 'features/cse/AutoAward/components/AutoAwardTooltip/AutoAwardTooltipDetails';
import { makeStyles } from 'tss-react/mui';

type Props = {
  offerAmount?: number;
  notifiedAt?: string;
  status?: string;
};

const useStyles = makeStyles()(() => ({
  tooltip: {
    backgroundColor: '#212224',
    padding: '16px',
    borderRadius: '8px',
  },
  tooltipArrow: {
    color: '#212224',
  },
}));

const AutoAwardTooltip = ({ offerAmount, notifiedAt, status }: Props) => {
  const { classes } = useStyles();
  return !notifiedAt ? null : (
    <Tooltip
      placement="top"
      arrow
      classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
      title={
        <AutoAwardTooltipDetails
          rate={offerAmount}
          date={notifiedAt}
          status={status}
        />
      }
    >
      <Box>Carrier Auto Award</Box>
    </Tooltip>
  );
};

export default AutoAwardTooltip;
