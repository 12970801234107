import { Typography, Box, Divider, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Launch } from '@mui/icons-material';
import ButtonV2 from 'components/library/ButtonV2';
import { Link } from 'react-router-dom';
import { trackEvent } from 'utils/mixpanel';
import DetailRoute from 'shipment-visibility-dashboard/ShipmentList/ListItem/DetailRoute';
import { ArrowLong } from 'components/icons/ArrowLong';
import { Shipment } from 'shipment/api';
import { formatDateDisplay } from '../../shipment-visibility-dashboard/Shipment/ShipmentUtils';

const useStyles = makeStyles((theme: Theme) => ({
  arrowIcon: {
    alignSelf: 'center',
    justifyContent: 'center',
  },
  accordionArrows: {
    alignSelf: 'center',
  },
  arrowRight: {
    width: '100%',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  textLink: {
    fontWeight: 700,
    padding: `${theme.spacing(1.5)} 0`,
  },
  box: {
    width: '100%',
    border: `${'1px solid #EEEEEE'}`,
    borderRadius: 10,
    margin: `0 0 ${theme.spacing(2)} 0`,
  },
  container: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  distanceText: {
    fontSize: 14,
    textAlign: 'right',
    fontWeight: 500,
    paddingTop: `${theme.spacing(1.5)} !important`,
  },
}));

type OtherShipmentsProps = {
  shipment: Shipment;
  type?: 'similar' | 'multi';
};

const OtherShipmentsItem = ({ shipment, type }: OtherShipmentsProps) => {
  const classes = useStyles();
  const {
    shipmentID,
    originAddress,
    destinationAddress,
    startDateDetailed,
    endDateDetailed,
    distanceOrigin,
    distanceDropOff,
  } = shipment;

  const getDistanceText = (type: string) => {
    const distance = type === 'similar' ? distanceOrigin : distanceDropOff;
    const label =
      type === 'similar' ? 'Miles from Origin' : 'Miles from Drop Off';

    return `${
      parseFloat(String(distance)).toFixed(1).replace('.0', '') + ' ' + label
    }`;
  };

  return (
    <Box className={classes.box}>
      <Grid container className={classes.container}>
        <Grid item xs={6}>
          <Link
            to={`/shipment/${shipmentID}`}
            target="_blank"
            onClick={() =>
              trackEvent(
                'Clicked a shipment link in shipment search results on cse',
                { shipmentID: shipmentID }
              )
            }
            className={classes.link}
          >
            <ButtonV2
              variant="text"
              className={classes.textLink}
              endIcon={<Launch />}
            >
              {shipmentID}
            </ButtonV2>
          </Link>
        </Grid>
        <Grid item xs={6}>
          {type ? (
            <Typography noWrap className={classes.distanceText}>
              {getDistanceText(type)}
            </Typography>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Divider orientation="horizontal" />
        </Grid>
        <Grid item xs={5}>
          {originAddress && startDateDetailed ? (
            <DetailRoute
              location={`${originAddress.city}, ${originAddress.region}`}
              date={formatDateDisplay(
                startDateDetailed.date,
                startDateDetailed.timeZone
              )}
            />
          ) : null}
        </Grid>
        <Grid item xs={3} className={classes.arrowIcon}>
          <ArrowLong></ArrowLong>
        </Grid>
        <Grid item xs={4}>
          {destinationAddress && endDateDetailed ? (
            <DetailRoute
              location={`${destinationAddress.city}, ${destinationAddress.region}`}
              date={formatDateDisplay(
                endDateDetailed.date,
                endDateDetailed.timeZone
              )}
            />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default OtherShipmentsItem;
