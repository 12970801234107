import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Theme,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import type { ShipmentDispositionData } from 'features/cse/disposition/api';
import DispositionComment from './DispositionComment';

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    fontSize: 20,
    textAlign: 'center',
  },
  carrierName: {
    fontSize: 16,
    textAlign: 'center',
    marginBotton: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    width: 560,
    heigth: 585,
  },
}));

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  carrierName?: string;
  dispositions: ShipmentDispositionData[];
};

const DispositionNotesModal = ({
  isOpen,
  handleClose,
  carrierName,
  dispositions = [],
}: Props) => {
  const classes = useStyles();

  const dispositionNotes = dispositions.filter(
    ({ disposition }: ShipmentDispositionData) =>
      disposition === 'OTHER' || disposition === 'DECLINE'
  );

  return (
    <>
      <Dialog open={isOpen} classes={{ paper: classes.content }}>
        <DialogTitle>
          <Typography variant="h3">Disposition Notes</Typography>
          <IconButton
            aria-label="close"
            data-testid="icon-closeDispositionNotes"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.subtitle}>Summary</Typography>
          <Typography className={classes.carrierName}>{carrierName}</Typography>
          {!dispositionNotes.length
            ? null
            : dispositionNotes.map(
                (disposition: ShipmentDispositionData, index: number) => (
                  <DispositionComment
                    key={`disposition-comment-${index}`}
                    {...disposition}
                  />
                )
              )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DispositionNotesModal;
