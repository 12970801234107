import { useState, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import { useQuery } from 'react-query';

import SkeletonWrapper from 'components/library/SkeletonWrapper';
import { getTopShipments } from 'features/cse/Carrier/api';
import NoResults from 'features/cse/Carrier/components/NoResults';
import { DispositionContextProvider } from 'features/cse/disposition/contexts/DispositionContext';
import { CarrierDetailResponse } from 'shipment/api';

import TopShipment from './components/TopShipment';
import TopShipmentsFilters from './components/TopShipmentsFilters';

import { useAuthorizationToken } from '@reibus/frontend-utility';
import SendTopShipments from './components/SendTopShipments';
import { DispositionsForShipmentContextProvider } from 'features/cse/disposition/contexts/DispositionsForShipmentContext';
import { ShipmentContextProvider } from 'shipment/ShipmentContext';

import type { ShipmentWithScores } from 'features/cse/Carrier/types';

type TopShipmentProps = {
  carrier: CarrierDetailResponse;
};

const TopShipments = ({ carrier }: TopShipmentProps) => {
  const authToken = useAuthorizationToken();
  const { cseCarrierID, carrierName } = carrier;
  const { isLoading, data = [] } = useQuery(
    ['shipments-scored-carriers', cseCarrierID],
    () => getTopShipments(authToken, cseCarrierID),
    { enabled: !!authToken }
  );

  const [filteredShipments, setFilteredShipments] = useState<
    ShipmentWithScores[]
  >([]);

  useEffect(() => {
    if (!data.length && filteredShipments.length) {
      setFilteredShipments([]);
    } else {
      if (JSON.stringify(data) !== JSON.stringify(filteredShipments)) {
        setFilteredShipments(data);
      }
    }
  }, [data]);

  return (
    <>
      <SkeletonWrapper isLoading={isLoading} height={232}>
        <Stack spacing={2}>
          <Box sx={{ fontWeight: 700, fontSize: 24, lineHeight: '125%' }}>
            Top Shipments
          </Box>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <TopShipmentsFilters
              allShipments={data}
              setFilteredShipments={setFilteredShipments}
              cseCarrierID={cseCarrierID}
            />
            <SendTopShipments
              carrierName={carrierName}
              shipmentsCount={filteredShipments.length}
              cseCarrierID={cseCarrierID}
              shipments={filteredShipments}
            />
          </Stack>

          {filteredShipments.length ? (
            filteredShipments.map((shipment: ShipmentWithScores) => (
              <DispositionContextProvider
                key={`top-shipment-${shipment.shipmentID}`}
              >
                <DispositionsForShipmentContextProvider
                  shipmentId={shipment.shipmentID}
                >
                  <TopShipment shipment={shipment} carrier={carrier} />
                </DispositionsForShipmentContextProvider>
              </DispositionContextProvider>
            ))
          ) : (
            <NoResults description="There are no top shipments to display." />
          )}
        </Stack>
      </SkeletonWrapper>
    </>
  );
};

export default TopShipments;
