import { createContext, ReactNode, useContext, useState } from 'react';
import {
  DispositionType,
  ShipmentDispositionData,
} from 'features/cse/disposition/api';

const DEFAULT_DISPOSITION_DATA = {
  carrierName: '',
  shipmentId: '',
  cseCarrierID: '',
  compositeScore: 0,
  carrierRankId: '',
  carrierStatus: 'ACTIVE',
  source: '' as ShipmentDispositionData['source'],
};

type SharedDispositionData = {
  carrierName: string;
  carrierStatus?: string | null;
} & Pick<
  ShipmentDispositionData,
  | 'shipmentId'
  | 'cseCarrierID'
  | 'compositeScore'
  | 'carrierRankId'
  | 'source'
  | 'equipmentIds'
>;

type DispositionFlow = DispositionType | 'ERROR' | null;
type DispositionStatus = 'declined' | 'covered' | 'other';

type DispositionContext = {
  dispositionFlow: DispositionFlow;
  setDispositionFlow: (dispositionFlow: DispositionFlow) => void;
  closeDispositionModal: (action?: string) => void;
  refetchNotes: boolean;
  setRefetchNotes: (refetchNotes: boolean) => void;
  setDispositionData: (data: SharedDispositionData) => void;
  dispositionStatus: DispositionStatus | null;
  setDispositionStatus: (status: DispositionStatus) => void;
} & SharedDispositionData;

const DispositionContext = createContext<DispositionContext | undefined>(
  undefined
);

type DispositionContextProviderProps = {
  children: ReactNode;
};

type DispositionContextProviderTestProps = {
  testData?: Partial<DispositionContext>;
};

const DispositionContextProvider = ({
  children,
  testData = {},
}: DispositionContextProviderProps & DispositionContextProviderTestProps) => {
  const [dispositionFlow, setDispositionFlow] = useState<DispositionFlow>(null);
  const [refetchNotes, setRefetchNotes] = useState(false);
  const [dispositionData, setDispositionData] = useState<SharedDispositionData>(
    { ...DEFAULT_DISPOSITION_DATA }
  );
  const [dispositionStatus, setDispositionStatus] =
    useState<DispositionStatus | null>(null);

  const handleClose = (action?: string) => {
    if (action === 'CANCEL') setRefetchNotes(false);
    setDispositionFlow(null);
  };

  return (
    <DispositionContext.Provider
      value={{
        dispositionFlow,
        setDispositionFlow,
        closeDispositionModal: handleClose,
        refetchNotes: refetchNotes,
        setRefetchNotes: setRefetchNotes,
        setDispositionData,
        dispositionStatus,
        setDispositionStatus,
        ...dispositionData,
        ...testData,
      }}
    >
      {children}
    </DispositionContext.Provider>
  );
};

const useDispositionContext = (): DispositionContext => {
  const context = useContext(DispositionContext);
  if (context === undefined) {
    throw new Error(
      'useDispositionContext must be used within a DispositionContextProvider'
    );
  }
  return context;
};

export {
  DispositionContextProvider,
  useDispositionContext,
  DEFAULT_DISPOSITION_DATA,
};
export type { DispositionFlow };
