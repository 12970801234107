import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useQuery } from 'react-query';
import { CarrierDetailResponse } from 'shipment/api';

import type { Shipment } from 'shipment/api';

import LoadingTable from 'features/cse/LoadingTable';

import { useEffect, useState } from 'react';
import Pagination from 'components/SteelUI/organisms/Pagination';
import NoResults from '../NoResults';

import {
  getHQ,
  formatCompaniesNames,
  formatCost,
  formatShipmentDate,
} from '../../utils/data';
import { getCarrierLaneHistory } from '../../api';
import { Sort } from 'features/cse/search/SearchByCarrier/SearchByCarrierContext';
import ColumnHeader from 'features/cse/search/SearchByCarrier/components/SearchByCarrierResults/components/ColumnHeader';
import { useAuthorizationToken } from '@reibus/frontend-utility';

type LaneHistoryProps = Pick<CarrierDetailResponse, 'cseCarrierID'>;

const useStyles = makeStyles(({ spacing, palette }) => ({
  title: {
    fontWeight: 700,
    marginBottom: 8,
    fontSize: spacing(3),
  },
  table: {
    border: `1px solid ${palette.grey[100]}`,
    marginBottom: spacing(3),
  },
  tableHead: {
    background: palette.grey[100],
    height: spacing(5.5),
  },
  columns: {
    fontWeight: 600,
    width: 125,
  },
  content: {
    border: `1px solid ${palette.grey[100]}`,
  },
  tableRow: {
    '&:hover': {
      backgroundColor: '#F2F7FF !important',
    },
  },
  tableCell: {
    padding: spacing(1.5, 2, 1.5, 2),
  },
}));

type Body = Pick<TableCellProps, 'align'> & {
  key: keyof LaneHistoryShipment;
  formatting?: (data: any) => string;
  header: string;
};

const RowsPerPage = 20;

const TableData: Body[] = [
  { header: 'Shipment ID', key: 'shipmentID' },
  { header: 'Origin', key: 'originAddress', formatting: getHQ },
  { header: 'Destination', key: 'destinationAddress', formatting: getHQ },
  {
    header: 'Customer',
    key: 'shipmentTMSCompanies',
    formatting: formatCompaniesNames,
  },
  {
    header: 'Carrier Rate',
    align: 'right',
    key: 'totalCarrierCost',
  },
  {
    header: 'Pickup Date',
    align: 'right',
    key: 'startDateDetailed',
    formatting: formatShipmentDate,
  },
];

type LaneHistoryShipment = {
  shipmentID: string;
  originAddress: string;
  destinationAddress: string;
  shipmentTMSCompanies: string;
  totalCarrierCost: number;
  startDateDetailed: string;
};

const LaneHistory = (props: LaneHistoryProps) => {
  const { cseCarrierID } = props;
  const authToken = useAuthorizationToken();

  const { isLoading, data: laneHistoryData = [] } = useQuery(
    ['carrierLaneHistory', cseCarrierID],
    () => getCarrierLaneHistory(authToken, cseCarrierID),
    { enabled: !!authToken }
  );

  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [laneHistory, setLaneHistory] = useState<LaneHistoryShipment[]>([]);
  const [laneHistoryRows, setLaneHistoryRows] = useState<LaneHistoryShipment[]>(
    []
  );
  const [currentSort, setCurrentSort] = useState<Sort>({
    sortBy: 'startDateDetailed',
    sortOrder: 'desc',
  });

  const getData = (
    data: Shipment,
    key: keyof Shipment,
    formatting?: (data: Shipment[keyof Shipment]) => string
  ) => {
    const attribute = data[key];
    const showData = formatting ? formatting(attribute) : attribute?.toString();
    return showData;
  };

  const paginateContent = () => {
    const begin = page * RowsPerPage;
    const end = begin + RowsPerPage;
    const laneHistoryRows = laneHistory?.slice(begin, end) ?? [];

    setLaneHistoryRows(laneHistoryRows as []);
  };

  useEffect(() => {
    if (!laneHistoryData || !laneHistoryData.length) return;
    const flatData = laneHistoryData.map((shipment) =>
      TableData.map(({ key, formatting }) => getData(shipment, key, formatting))
    );

    const flatLaneHistory = flatData.map((shipment) => ({
      shipmentID: shipment[0],
      originAddress: shipment[1],
      destinationAddress: shipment[2],
      shipmentTMSCompanies: shipment[3],
      totalCarrierCost: Number(shipment[4]),
      startDateDetailed: shipment[5],
    }));

    setLaneHistory(flatLaneHistory as []);
  }, [laneHistoryData]);

  useEffect(() => {
    paginateContent();
  }, [laneHistory, page]);

  const sortData = (sort: Sort) => {
    setCurrentSort(sort);
    sortArrayByField(
      laneHistory as [],
      sort.sortBy as keyof LaneHistoryShipment,
      sort.sortOrder
    );

    paginateContent();
  };

  function sortArrayByField(
    arr: LaneHistoryShipment[],
    field: keyof LaneHistoryShipment,
    order: 'asc' | 'desc'
  ) {
    const orderMultiplier = order === 'asc' ? 1 : -1;
    return arr.sort((a, b) => {
      const valueA = a[field];
      const valueB = b[field];
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        if (!isNaN(Date.parse(valueA)) || !isNaN(Date.parse(valueB))) {
          const dateA = new Date(valueA).getTime();
          const dateB = new Date(valueB).getTime();
          return (dateA - dateB) * orderMultiplier;
        }
        return valueA > valueB ? orderMultiplier : orderMultiplier * -1;
      } else if (typeof valueA === 'number' && typeof valueB === 'number') {
        return (valueA - valueB) * orderMultiplier;
      } else {
        return 0;
      }
    });
  }

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <Typography className={classes.title}>Lane History</Typography>
      </Grid>

      {!isLoading && laneHistoryRows.length === 0 ? (
        <NoResults description="There are no top shipments to display." />
      ) : null}

      {isLoading ? <LoadingTable /> : null}
      {laneHistoryRows.length >= 1 ? (
        <Grid item xs={12}>
          <Grid>
            <Table className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {TableData.map(({ header, align, key }) => (
                    <TableCell
                      className={clsx(classes.tableCell, classes.columns)}
                      align={align}
                      key={header}
                    >
                      {key === 'shipmentID' ? (
                        header
                      ) : (
                        <ColumnHeader
                          label={header}
                          sortBy={key}
                          align={align}
                          currentSort={currentSort}
                          setSort={sortData}
                        />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {laneHistoryRows.map((shipment: LaneHistoryShipment) => (
                  <TableRow
                    key={`Lane-history-${shipment.shipmentID}`}
                    className={clsx(classes.tableRow)}
                  >
                    {TableData.map(({ key, align }) => (
                      <TableCell
                        className={clsx(classes.content, classes.tableCell)}
                        align={align}
                        key={key}
                      >
                        {key === 'totalCarrierCost'
                          ? formatCost(shipment[key])
                          : shipment[key]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              count={laneHistory?.length ?? 0}
              rowsPerPage={RowsPerPage}
              page={page}
              changePage={setPage}
            />
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default LaneHistory;
