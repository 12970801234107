import { DialogContent, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ButtonV2 from 'components/library/ButtonV2';
import { useDispositionContext } from 'features/cse/disposition/contexts/DispositionContext';
import theme from 'theme';
import { CarrierStatus as CarrierStatusEnum } from '../../../../../../../shipment/api';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(3),
  },
  button: {
    padding: theme.spacing(2, 0),
  },
}));

const EXTERNAL = 'EXTERNAL';

const Success = () => {
  const classes = useStyles();
  const { closeDispositionModal, carrierStatus } = useDispositionContext();

  const title =
    carrierStatus?.toUpperCase() === EXTERNAL
      ? 'Disposition Saved'
      : 'Shipment Covered';

  const getSubText = () => {
    if (carrierStatus?.toUpperCase() === EXTERNAL) {
      return 'A note has been added in Turvo indicating this shipment is covered pending RMIS. The shipment is still in tendered status.';
    }
    if (carrierStatus?.toUpperCase() === CarrierStatusEnum.INACTIVE) {
      return 'Your shipment has been set as Covered in Turvo, but the carrier was not added since it is inactive in Turvo.';
    }

    return 'This shipment has been set to Covered in Turvo. Additional details may need to be added.';
  };

  return (
    <DialogContent>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
        textAlign="center"
      >
        <Grid item>
          <CheckCircleIcon
            style={{ color: theme.palette.success.main }}
            fontSize="large"
          />
        </Grid>
        <Grid item>
          <Typography variant="h3">{title}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{getSubText()}</Typography>
        </Grid>
        <Grid item className={classes.button}>
          <ButtonV2
            data-testid="success-cover-close"
            onClick={() => closeDispositionModal()}
          >
            Close
          </ButtonV2>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default Success;
