import axios from 'axios';
import { getApiRoot } from 'utils/apiUtils';

enum LoadBoardType {
  DAT = 'DAT',
  ITS = 'ITS',
  REIBUS = 'REIBUS',
  REIBUS_MANUAL = 'REIBUS_MANUAL',
  REIBUS_AUTOMATIC = 'REIBUS_AUTOMATIC',
}

enum EquipmentLocationType {
  AREA = 'AREA',
  PLACE = 'PLACE',
}

type EquipmentLocation = {
  id?: string;
  city?: string;
  stateProvince?: string;
  latitude?: number;
  longitude?: number;
  county?: string;
  postalCode?: string;
  zone?: string;
  locationType: EquipmentLocationType;
};

type ContactInformation = {
  name?: string;
  phone?: string;
  email?: string;
};

type CreateEquipmentPayload = {
  equipmentType: number;
  startDate: string;
  origin: EquipmentLocation;
  destination?: EquipmentLocation;
  loadBoardType: LoadBoardType;
  comments?: string;
  weight?: number;
  length?: number;
  contactInfo?: ContactInformation;
};

const validatePayload = (payload: CreateEquipmentPayload): boolean => {
  const { equipmentType, startDate, origin, destination } = payload;
  const destinationValid = destination
    ? !!destination.stateProvince &&
      !!destination.latitude &&
      !!destination.longitude &&
      !!destination.locationType
    : true;
  return (
    !!equipmentType &&
    !!startDate &&
    !!origin.city &&
    !!origin.stateProvince &&
    !!origin.latitude &&
    !!origin.longitude &&
    !!origin.locationType &&
    destinationValid
  );
};

const validateUpdatePayload = (payload: CreateEquipmentPayload): boolean => {
  const { equipmentType, startDate, origin, destination } = payload;
  const destinationValidCreate = destination
    ? !!destination.stateProvince &&
      !!destination.latitude &&
      !!destination.longitude &&
      !!destination.locationType
    : true;

  const destinationValid = destination?.id
    ? !!destination?.id
    : destinationValidCreate;

  const originValidCreate = origin
    ? !!origin.city &&
      !!origin.stateProvince &&
      !!origin.latitude &&
      !!origin.longitude &&
      !!origin.locationType
    : true;
  return (
    !!equipmentType && !!startDate && originValidCreate && destinationValid
  );
};

const createEquipment = async (
  authorization: string,
  cseCarrierID: string,
  payload: CreateEquipmentPayload
): Promise<{
  impactedShipments: number;
  equipment?: { id: string };
  result: string;
}> => {
  const url = `${getApiRoot()}/carriers/${cseCarrierID}/equipment`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  if (!validatePayload(payload)) {
    throw new Error('Invalid payload');
  }
  const body = JSON.stringify({ ...payload, loadBoardType: 'REIBUS_MANUAL' });
  const response = await axios.post(url, body, config);

  return response.data;
};

const updateEquipment = async (
  authorization: string,
  id: string,
  payload: CreateEquipmentPayload
): Promise<{
  impactedShipments: number;
  equipment?: { id: string };
  result: string;
}> => {
  const url = `${getApiRoot()}/carriers/equipment/${id}`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const validUpdatePayload = validateUpdatePayload(payload);
  if (!validUpdatePayload) {
    throw new Error('Invalid payload');
  }
  const body = JSON.stringify({ ...payload });
  const response = await axios.put(url, body, config);

  return response.data;
};

export {
  createEquipment,
  EquipmentLocationType,
  validatePayload,
  updateEquipment,
  validateUpdatePayload,
  LoadBoardType,
};
export type { CreateEquipmentPayload, EquipmentLocation };
