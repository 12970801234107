import { DialogContent, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ErrorIcon from '@mui/icons-material/Error';
import ButtonV2 from 'components/library/ButtonV2';
import theme from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(3),
  },
  content: {
    paddingTop: theme.spacing(3),
  },
  button: {
    padding: theme.spacing(3, 0),
  },
}));

type Props = {
  tryAgain: () => void;
};

const Error = ({ tryAgain }: Props) => {
  const classes = useStyles();

  return (
    <DialogContent>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
      >
        <Grid item>
          <ErrorIcon
            style={{ color: theme.palette.error.main }}
            fontSize="large"
          />
        </Grid>
        <Grid item>
          <Typography variant="h3">Oops - That Didn&apos;t Work</Typography>
        </Grid>
        <Grid item className={classes.content}>
          <Typography variant="body2" align="center">
            Your disposition code for this carrier wasn&apos;t logged. Please
            try again. If problems persist, please contact your administrator.
          </Typography>
        </Grid>
        <Grid item className={classes.button}>
          <ButtonV2 onClick={tryAgain}>Try Again</ButtonV2>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default Error;
