import { regions } from '@reibus/reibus-core-utils';
import { SelectOption } from './types';

export const CanadaRegions = Object.entries(
  // eslint-disable-next-line
  // @ts-ignore
  regions.canadianRegionsByAbbreviation
).map((region) => {
  return {
    id: region[0],
    optionName: region[0],
    label: region[1],
  } as SelectOption;
});

export const MexicanRegions = Object.entries(
  regions.mexicanRegionsByAbbreviation
).map((region) => {
  return {
    id: region[0],
    optionName: region[0],
    label: region[1],
  };
});

export const UniteStatesRegions = Object.entries(
  // eslint-disable-next-line
  // @ts-ignore
  regions.stateLabelsByAbbreviation
).map((region) => {
  return {
    id: region[0],
    optionName: region[0],
    label: region[1],
  } as SelectOption;
});
