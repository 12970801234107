export const ArrowLong = () => {
  return (
    <svg
      width="70"
      height="11"
      viewBox="0 0 70 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M30 5.5L0 5.5" stroke="#64676B" strokeWidth="1.2" />
      <path
        d="M39.9983 5.5L35.3659 9.81657L35.1532 9.61842L38.504 6.4905L39.4312 5.625H38.1628H30.5V5.375H38.1628H39.4312L38.504 4.5095L35.1532 1.38158L35.3659 1.18343L39.9983 5.5Z"
        fill="#64676B"
        stroke="#64676B"
      />
    </svg>
  );
};
