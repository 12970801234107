import { deleteEquipment } from '../api';
import { useMutation } from 'react-query';

type useDeleteEquipmentProps = {
  onSuccess: (data: string) => void;
};

const useDeleteEquipment = ({ onSuccess }: useDeleteEquipmentProps) => {
  return useMutation(deleteEquipment, {
    onSuccess,
  });
};

export default useDeleteEquipment;
