import { Box, Stack, Divider, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CarrierContactInfo } from 'shipment/api';
import ButtonV2 from 'components/library/ButtonV2';
import { Launch } from '@mui/icons-material';
import Email from 'shipment/carrier-contact-info-modal/Email';
import Phone from 'shipment/carrier-contact-info-modal/Phone';

const useStyles = makeStyles((theme: Theme) => ({
  textLink: {
    fontSize: 18,
    fontWeight: 400,
    padding: 0,
    '&:hover': {
      backgroundColor: '#fff',
      textDecoration: 'underline',
    },
  },
  icon: {
    color: '#64676B',
  },
}));

const TurvoContact = ({
  carrierContactInfo,
}: {
  carrierContactInfo: CarrierContactInfo;
}) => {
  const classes = useStyles();
  const { carrierID, email, phoneNumber } = carrierContactInfo;
  const turvoLink = `${process.env.REACT_APP_TURVO_URL}/#/accounts/${carrierID}/contacts`;
  return (
    <Stack spacing={1}>
      <Box>
        <a href={turvoLink} target="_blank" rel="noreferrer">
          <ButtonV2
            variant="text"
            endIcon={<Launch />}
            className={classes.textLink}
          >
            View Turvo Contacts
          </ButtonV2>
        </a>
      </Box>
      <Phone phone={phoneNumber} iconClassName={classes.icon} />
      <Email email={email} iconClassName={!email ? classes.icon : ''} />
    </Stack>
  );
};

export default TurvoContact;
