import { ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';
import { Theme } from '@mui/material';
import { newTheme } from 'theme';
import FooterDisplay from './Footer';
import Loader from 'components/Loader';
import clsx from 'clsx';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Helmet } from 'react-helmet-async';
import useCurrentUser from 'hooks/useCurrentUser';

type PageWrapperProps = {
  children?: ReactNode;
  fullFooter?: boolean;
  showDemo?: boolean;
  showFooter?: boolean;
  showShortFooter?: boolean;
  isBackColorWhite?: boolean;
  isNewTheme?: boolean;
  title?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    height: '100%',
    minHeight: 'calc(100vh - 64px)', // subtracting the top nav
    overflow: 'hidden',
    position: 'relative',
  },
  shortFooter: {
    paddingBottom: theme.spacing(15),
    width: '100%',
  },
  footer: {
    width: '100%',
  },
  whiteBackground: {
    backgroundColor: '#ffffff',
  },
  grayBackground: {
    backgroundColor: '#f5f5f5',
  },
  [theme.breakpoints.up('xl')]: {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    childWrapper: {
      overflow: 'hidden',
      position: 'relative',
      maxWidth: 1920,
      width: '100%',
    },
  },
}));

export type UserProfile = {
  firstName: string;
  lastName: string;
  companyName: string;
  companyId: string;
};

const PageWrapper = ({
  children,
  fullFooter,
  showDemo = true,
  showFooter = true,
  showShortFooter = false,
  isBackColorWhite = false,
  isNewTheme = false,
  title = 'Logistics | Reibus International',
}: PageWrapperProps) => {
  const { rl2220CsePagesUrlsAndTitles } = useFlags();
  const { isLoading } = useCurrentUser();

  const classes = useStyles();

  const footerColorBackground = isBackColorWhite
    ? classes.whiteBackground
    : classes.grayBackground;

  return (
    <div className={clsx(classes.wrapper, footerColorBackground)}>
      <Helmet title={title}>
        <title>
          {!rl2220CsePagesUrlsAndTitles ? 'Home - Reibus International' : title}
        </title>
      </Helmet>
      <Loader hasLoaded={!isLoading}>
        <>
          <div className={classes.childWrapper}>
            {!isNewTheme ? (
              children
            ) : (
              <ThemeProvider theme={newTheme}>{children}</ThemeProvider>
            )}
          </div>
          {showFooter ? (
            <div
              className={clsx(
                showShortFooter
                  ? [classes.shortFooter, footerColorBackground]
                  : [classes.footer]
              )}
            >
              <FooterDisplay
                showShortFooter={showShortFooter}
                fullFooter={fullFooter}
                showDemo={showDemo}
              />
            </div>
          ) : null}
        </>
      </Loader>
    </div>
  );
};

export default PageWrapper;
