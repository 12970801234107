import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ShipmentDisposition } from 'features/cse/disposition/api';
import useActivityLog from 'features/cse/ActivityLog/useActivityLog';

type DispositionsForShipmentContext = {
  dispositions: ShipmentDisposition[];
  shipmentId: string;
  dispositionsByCarrier: any;
  refetch: () => void;
  isLoadingDispositions: boolean;
};

type Props = {
  shipmentId: string;
  children: ReactNode;
};

const DispositionsForShipmentContext = createContext<
  DispositionsForShipmentContext | undefined
>(undefined);

const DispositionsForShipmentContextProvider = ({
  shipmentId,
  children,
}: Props) => {
  const [dispositions, setDispositions] = useState<ShipmentDisposition[]>([]);
  const [dispositionsByCarrier, setDispositionsByCarrier] = useState<any>(
    new Map<string, ShipmentDisposition[]>()
  );

  const { data = [], refetch, isLoading } = useActivityLog(shipmentId);

  useEffect(() => {
    if (!data.length) return;
    setDispositions([]);
    const newDispositionsByCarrier = new Map();

    data.forEach((activityLogEntry) => {
      if (
        !(
          'cseCarrierID' in activityLogEntry &&
          'disposition' in activityLogEntry
        )
      )
        return;
      setDispositions((prev) => [...prev, activityLogEntry]);
      const { cseCarrierID } = activityLogEntry;
      const current = newDispositionsByCarrier.get(cseCarrierID) ?? [];
      newDispositionsByCarrier.set(cseCarrierID, [
        ...current,
        { ...activityLogEntry, shipmentId: activityLogEntry.shipmentID },
      ]);
    });

    setDispositionsByCarrier(newDispositionsByCarrier);
  }, [data]);

  return (
    <DispositionsForShipmentContext.Provider
      value={{
        dispositions,
        shipmentId,
        dispositionsByCarrier,
        refetch,
        isLoadingDispositions: isLoading,
      }}
    >
      {children}
    </DispositionsForShipmentContext.Provider>
  );
};

const useDispositionsForShipmentContext = () => {
  const context = useContext(DispositionsForShipmentContext);
  if (context === undefined) {
    throw new Error(
      'useDispositionsForShipmentContext must be used within a DispositionsForShipmentContextProvider'
    );
  }

  return context;
};

export {
  DispositionsForShipmentContextProvider,
  useDispositionsForShipmentContext,
};
