import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import ButtonV2 from 'components/library/ButtonV2';
import ContactDrawer from './ContactDrawer';

import type { Equipment } from 'features/cse/Carrier/types';

type Props = {
  cseCarrierID: string;
  mcNumber?: string;
  dotNumber?: string;
  equipmentWithContact?: Equipment[];
  equipmentLoading: boolean;
  isTurvoContact: boolean;
};

const useStyles = makeStyles(({ spacing }: Theme) => ({
  button: {
    marginRight: spacing(3),
  },
}));

const ContactButton = ({
  cseCarrierID,
  equipmentWithContact,
  equipmentLoading,
  isTurvoContact,
}: Props) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <>
      <ButtonV2
        className={classes.button}
        variant="outlined"
        size="large"
        onClick={() => setOpen(!open)}
      >
        Contact
      </ButtonV2>
      <ContactDrawer
        isOpen={open}
        handleClose={() => setOpen(false)}
        cseCarrierID={cseCarrierID}
        equipmentWithContact={equipmentWithContact}
        equipmentLoading={equipmentLoading}
        isTurvoContact={isTurvoContact}
      />
    </>
  );
};

export default ContactButton;
