import { useQuery } from 'react-query';
import { useAuthorizationToken } from '@reibus/frontend-utility';

import { getCarrierAvailableEquipment } from 'features/cse/Carrier/api';

import type { Equipment } from 'features/cse/Carrier/types';

export default function useAvailableEquipment(cseCarrierId: string) {
  const authorization = useAuthorizationToken();

  return useQuery<Equipment[], Error>(
    ['availableEquipment', cseCarrierId],
    async () => await getCarrierAvailableEquipment(authorization, cseCarrierId),
    {
      enabled: !!authorization,
    }
  );
}
