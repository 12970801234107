import { makeStyles } from '@mui/styles';
import { EquipmentDetails } from '../api';

type Props = Pick<EquipmentDetails, 'comments'>;

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 700,
  },
  body: {
    fontWeight: 400,
  },
}));

const EquipmentDetailsComments = ({ comments }: Props) => {
  const classes = useStyles();

  return !comments ? null : (
    <>
      <div className={classes.title}>Comments</div>
      <div className={classes.body}>{comments}</div>
    </>
  );
};

export default EquipmentDetailsComments;
