import {
  Paper,
  Table,
  TableCell,
  TableRow,
  Theme,
  TableBody,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { EquipmentDetails } from '../api';
import { formatInTimeZone } from 'date-fns-tz';
import { useShipmentContext } from 'shipment/ShipmentContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  displayFormattingValue,
  valuesFormatList,
} from '@reibus/reibus-core-utils';

const useClasses = makeStyles((theme: Theme) => ({
  labelCell: {
    borderRight: '1px solid',
    borderRightColor: theme.palette.grey['100'],
    width: '50%',
    fontWeight: 700,
  },
}));

const EARTH_RADIUS_MILES = 3969.0;
const DEGREES_OF_STRAIGHT_LINE = 180;

export const distanceInMiles = (
  lat1: number,
  lat2: number,
  lon1: number,
  lon2: number
): number => {
  const lon1Radians = (lon1 * Math.PI) / DEGREES_OF_STRAIGHT_LINE;
  const lon2Radians = (lon2 * Math.PI) / DEGREES_OF_STRAIGHT_LINE;
  const lat1Radians = (lat1 * Math.PI) / DEGREES_OF_STRAIGHT_LINE;
  const lat2Radians = (lat2 * Math.PI) / DEGREES_OF_STRAIGHT_LINE;
  const dLon = lon2Radians - lon1Radians;
  const dLat = lat2Radians - lat1Radians;
  const a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.cos(lat1Radians) *
      Math.cos(lat2Radians) *
      Math.pow(Math.sin(dLon / 2), 2);
  const c = 2 * Math.asin(Math.sqrt(a));

  return c * EARTH_RADIUS_MILES;
};

type Props = {
  loadboard: 'DAT' | 'REIBUS' | 'ITS';
} & Pick<
  EquipmentDetails,
  | 'postingDateTime'
  | 'availableOn'
  | 'availableUntil'
  | 'equipmentType'
  | 'origin'
  | 'length'
  | 'weight'
>;

const EquipmentDetailsTable = ({
  loadboard,
  postingDateTime,
  availableOn,
  availableUntil,
  equipmentType,
  origin: equipmentOrigin,
  length,
  weight,
}: Props) => {
  const { rl2111AddTheDhOInTheDatModal } = useFlags();
  const classes = useClasses();
  const { locations: shipmentLocation } = useShipmentContext();
  const equipmentTimezone = equipmentOrigin.timezone ?? 'America/New_York';

  const postingDateTimeFormatted = !postingDateTime
    ? 'N/A'
    : formatInTimeZone(
        postingDateTime,
        equipmentTimezone,
        'MM/dd/yyyy HH:mm zzz'
      );

  // Backend does special conversion for available on timestamp.
  // Display date as if user and/or equipment is UTC.
  const availableOnFormatted = !availableOn
    ? 'N/A'
    : formatInTimeZone(availableOn, 'UTC', 'MM/dd/yyyy');

  const availableUntilFormatted = !availableUntil
    ? 'N/A'
    : formatInTimeZone(availableUntil, equipmentTimezone, 'MM/dd/yyyy');

  const distance =
    equipmentOrigin.latitude &&
    equipmentOrigin.longitude &&
    shipmentLocation?.pickup.coordinates.lat &&
    shipmentLocation?.pickup.coordinates.lon
      ? distanceInMiles(
          equipmentOrigin.latitude,
          shipmentLocation?.pickup.coordinates.lat,
          equipmentOrigin.longitude,
          shipmentLocation?.pickup.coordinates.lon
        )
      : null;

  const {
    listings: { weight: commaSeparated },
  } = valuesFormatList;

  const weightFormatted = !weight
    ? 'N/A'
    : `${displayFormattingValue(weight, commaSeparated)} lbs`;

  const lengthFormatted = !length
    ? 'N/A'
    : `${displayFormattingValue(length, commaSeparated)} ft`;

  return (
    <Paper variant="outlined">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className={classes.labelCell}>
              Posting Date / Time
            </TableCell>
            <TableCell>{postingDateTimeFormatted}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.labelCell}>Available On</TableCell>
            <TableCell>{availableOnFormatted}</TableCell>
          </TableRow>
          {loadboard === 'DAT' ? (
            <TableRow>
              <TableCell className={classes.labelCell}>
                Available Until
              </TableCell>
              <TableCell>{availableUntilFormatted}</TableCell>
            </TableRow>
          ) : null}
          {loadboard === 'ITS' ? (
            <TableRow>
              <TableCell className={classes.labelCell}>
                Available Until
              </TableCell>
              <TableCell>{availableUntilFormatted}</TableCell>
            </TableRow>
          ) : null}
          <TableRow>
            <TableCell className={classes.labelCell}>Equipment Type</TableCell>
            <TableCell>{equipmentType}</TableCell>
          </TableRow>
          {rl2111AddTheDhOInTheDatModal ? (
            <TableRow>
              <TableCell className={classes.labelCell}>DH-O</TableCell>
              <TableCell>
                {distance ? `${distance.toFixed(1)} mi` : 'N/A'}
              </TableCell>
            </TableRow>
          ) : null}
          {loadboard === 'REIBUS' ? (
            <>
              <TableRow>
                <TableCell className={classes.labelCell}>Weight</TableCell>
                <TableCell>{weightFormatted}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.labelCell}>Length</TableCell>
                <TableCell>{lengthFormatted}</TableCell>
              </TableRow>
            </>
          ) : null}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default EquipmentDetailsTable;
