import { makeStyles } from '@mui/styles';
import { useUserSession } from '@reibus/frontend-utility';

import FormSection from './FormSection';
import { TextField } from 'components/library/form/TextField';
import { SingleSelect } from 'components/library/form/SingleSelect';
import { PhoneField } from 'components/library/form/PhoneField';
import { EmailField } from 'components/library/form/EmailField';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles({
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    columnGap: '24px',
    rowGap: '40px',
  },
});

const ContactInformation = () => {
  const classes = useStyles();
  const { ct389FqrFormUpdates } = useFlags();
  const { data: userSession = {} } = useUserSession();
  const { isReibusUser = false } = userSession;

  return (
    <FormSection title="Contact Information">
      <div className={classes.content}>
        <TextField label="Contact Name" name="contactName" />
        <PhoneField name="contactPhone" label="Contact Phone" required />
        <EmailField label="Contact Email" name="contactEmail" required />
        {isReibusUser ? <TextField label="Deal ID" name="dealID" /> : null}
      </div>
    </FormSection>
  );
};

export default ContactInformation;
