import { Menu, MenuItem, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import ButtonV2 from 'components/library/ButtonV2';
import { useState, MouseEvent } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispositionContext } from 'features/cse/disposition/contexts/DispositionContext';
import {
  DispositionReason,
  DispositionType,
} from 'features/cse/disposition/api';

const useStyles = makeStyles((theme: Theme) => ({
  buttonPressed: {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
  },
  list: {
    padding: 0,
  },
  option: {
    color: theme.palette.primary.main,
    fontSize: 12,
  },
}));

type Props = {
  disabled?: boolean;
  onClick: () => void;
  reason?: DispositionReason;
  type?: DispositionType;
};

const DispositionButton = ({ disabled, onClick, reason, type }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const { setDispositionFlow } = useDispositionContext();

  const label =
    type === 'DECLINE' && reason === 'PRICE' ? 'Declined' : 'Disposition';

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCoverShipment = () => {
    handleClose();
    setDispositionFlow('COVER');
    onClick();
  };

  const handleDeclineShipment = () => {
    handleClose();
    setDispositionFlow('DECLINE');
    onClick();
  };

  const handleOther = () => {
    handleClose();
    setDispositionFlow('OTHER');
    onClick();
  };

  return (
    <div>
      <ButtonV2
        id="basic-button"
        aria-controls={isMenuOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        variant="outlined"
        className={clsx({ [classes.buttonPressed]: isMenuOpen })}
        disableRipple
        disabled={disabled}
      >
        {label}
      </ButtonV2>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        classes={{ list: classes.list }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem className={classes.option} onClick={handleCoverShipment}>
          Cover Shipment
        </MenuItem>
        <MenuItem className={classes.option} onClick={handleDeclineShipment}>
          Decline Shipment
        </MenuItem>
        <MenuItem className={classes.option} onClick={handleOther}>
          Other
        </MenuItem>
      </Menu>
    </div>
  );
};

export default DispositionButton;
