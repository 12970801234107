import { useState, useEffect } from 'react';
import {
  TextField,
  Stack,
  Theme,
  InputAdornment,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import NumberFormat from 'react-number-format';
import { displayFormattingValue } from '@reibus/reibus-core-utils';

import Tooltip from 'components/SteelUI/atoms/Tooltip';
import { trackEvent } from 'utils/mixpanel';

const useStyles = makeStyles((theme: Theme) => ({
  inputAdornement: {
    fontSize: 16,
  },
  root: {
    '& .MuiOutlinedInput-root': {
      fontSize: '16px',
      borderRadius: '0.5rem',
      height: '40px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '0.5rem',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#2D6CCB',
        borderWidth: '2px',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#2D6CCB',
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
        borderWidth: '2px',
      },
      '&.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
      },
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  percentage: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px 0px 0px 8px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '8px 0px 0px 8px',
      },
    },
  },
  markup: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px 0px 0px 8px',
      borderLeft: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '0px 8px 8px 0px',
        borderLeft: 'none',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderLeft: '2px solid #2D6CCB',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderLeft: '2px solid #2D6CCB',
      },
    },
  },
  infoIcon: {
    width: '16px',
    height: '16px',
  },
}));

type Props = {
  blendedAverage: number;
  freightQuoteRequestId?: string;
};

const TargetRate = ({ blendedAverage, freightQuoteRequestId }: Props) => {
  const classes = useStyles();

  const [rateValues, setRateValues] = useState({
    totalRate: blendedAverage * 0.15 + blendedAverage,
    percentage: 15,
    markup: blendedAverage * 0.15,
  });
  const [hasInteracted, setHasInteracted] = useState(false);

  const infoIcon = icon({ name: 'square-info', style: 'solid' });

  const getDecimalValue = (value: number) => parseFloat(value.toFixed(2));

  const handleChange = (field: keyof typeof rateValues, value: string) => {
    if (!hasInteracted) {
      setHasInteracted(true);
    }
    const numericValue = Number(value);
    if (value === '') {
      setRateValues({ totalRate: '', percentage: '', markup: '' });
      return;
    }
    if (field === 'totalRate') {
      const totalRate = numericValue;
      setRateValues({
        totalRate: getDecimalValue(numericValue),
        percentage: getDecimalValue(
          ((totalRate - blendedAverage) / blendedAverage) * 100
        ),
        markup: getDecimalValue(totalRate - blendedAverage),
      });
    } else if (field === 'percentage') {
      const percentage = numericValue / 100;
      setRateValues({
        totalRate: getDecimalValue(
          blendedAverage + blendedAverage * percentage
        ),
        percentage: getDecimalValue(numericValue),
        markup: getDecimalValue(blendedAverage * percentage),
      });
    } else if (field === 'markup') {
      const markup = numericValue;
      setRateValues({
        totalRate: getDecimalValue(blendedAverage + markup),
        percentage: getDecimalValue((markup / blendedAverage) * 100),
        markup: getDecimalValue(numericValue),
      });
    }
  };

  useEffect(() => {
    // This prevents us from tracking the event on the initial render
    if (!hasInteracted) {
      return;
    }
    // Wait until the user stops typing before tracking the event
    const handler = setTimeout(() => {
      trackEvent('Updated Target Rate', {
        freightQuoteRequestId,
        ...rateValues,
      });
    }, 2000);

    return () => clearTimeout(handler);
  }, [rateValues, freightQuoteRequestId]);

  return (
    <Stack>
      <NumberFormat
        label="Target Sell Rate"
        variant="outlined"
        fullWidth
        customInput={TextField}
        value={rateValues.totalRate}
        thousandSeparator
        decimalScale={2}
        allowNegative={false}
        onValueChange={(values) => {
          handleChange('totalRate', values.floatValue?.toString() || '');
        }}
        className={classes.root}
        InputLabelProps={{
          shrink: true,
          sx: { fontSize: '14px' },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              className={classes.inputAdornement}
            >
              $
            </InputAdornment>
          ),
        }}
      />

      <Stack direction="row" alignItems="center" marginBottom={2}>
        <Tooltip
          placement="top"
          arrow
          title={
            'Starting Rate is the Average of the Blended Target Rate Range'
          }
        >
          <FontAwesomeIcon
            icon={infoIcon}
            color="#5DD2FC"
            className={classes.infoIcon}
          />
        </Tooltip>
        <Typography
          fontSize="12px"
          marginLeft="8px"
        >{`* Starting rate is ${displayFormattingValue(
          blendedAverage,
          { decimalPlaces: 0, trailingZeros: false },
          'USD'
        )}`}</Typography>
      </Stack>

      <Stack direction="row">
        <TextField
          label="Markup"
          variant="outlined"
          value={rateValues.percentage}
          onChange={(e) => {
            handleChange('percentage', e.target.value);
          }}
          type="number"
          className={clsx(classes.root, classes.percentage)}
          InputLabelProps={{
            shrink: true,
            sx: {
              fontSize: '14px',
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ fontSize: '16px', color: 'blue' }}
              >
                %
              </InputAdornment>
            ),
          }}
        />

        <NumberFormat
          label=""
          variant="outlined"
          fullWidth
          customInput={TextField}
          value={rateValues.markup}
          thousandSeparator
          decimalScale={2}
          onValueChange={(values) => {
            handleChange('markup', values.floatValue?.toString() || '');
          }}
          className={clsx(classes.root, classes.markup)}
          InputLabelProps={{
            shrink: true,
            sx: { fontSize: '14px' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                className={classes.inputAdornement}
              >
                $
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Stack>
  );
};

export default TargetRate;
