import Carrier from 'features/cse/Carrier';
import { GoogleMapsScriptProvider } from 'hooks/useGoogleMapsScript';

const CarrierPage = () => {
  return (
    <GoogleMapsScriptProvider>
      <Carrier />
    </GoogleMapsScriptProvider>
  );
};

export default CarrierPage;
