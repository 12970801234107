import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ResultsTable from 'search-shipments/search-shipments-results/ResultsTable';
import { useSearchShipmentsContext } from 'search-shipments/SearchShipmentsContext';

const useStyles = makeStyles(() => ({
  container: {
    padding: 24,
  },
}));

const ShipmentsResults = () => {
  const classes = useStyles();
  const { results } = useSearchShipmentsContext();

  if (typeof results === 'undefined') {
    return null;
  }

  return (
    <Grid container className={classes.container}>
      <Grid item>
        <Typography variant="h2">Shipments Results</Typography>
      </Grid>
      <Grid item>
        <ResultsTable />
      </Grid>
    </Grid>
  );
};

export default ShipmentsResults;
