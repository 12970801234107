import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Skeleton, Stack } from '@mui/material';

import { Body, H2 } from 'components/SteelUI/atoms/typography';
import {
  HistoricalRate,
  RateSource,
  RateType,
} from 'features/LogisticsQuoteManager/hooks/useGetHistoricalRates';

import Chart, { ChartData } from './Chart';

type Props = {
  isLoading: boolean;
  hasNeverFetched: boolean;
  historicalRates: HistoricalRate[];
};

const DATASET_CONFIG = {
  REIBUS: {
    color: '#2D6CCB',
    name: 'Reibus Rate',
    id: 'reibus',
    source: 'REIBUS' as RateSource,
  },
  DAT_SPOT: {
    color: '#F98522',
    name: 'DAT - Broker to Carrier Spot',
    id: 'datB2C',
    source: 'DAT' as RateSource,
    rateType: 'SPOT' as RateType,
  },
  DAT_CONTRACT: {
    color: '#F34E7C',
    name: 'DAT - Carrier to Contract',
    id: 'datC2C',
    source: 'DAT' as RateSource,
    rateType: 'CONTRACT' as RateType,
  },
};

const RateChart = ({ isLoading, hasNeverFetched, historicalRates }: Props) => {
  const chartIcon = icon({ name: 'chart-line', style: 'light' });
  const warningIcon = icon({ name: 'exclamation-triangle', style: 'solid' });

  if (hasNeverFetched) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        direction="column"
        height="100%"
        spacing="48px"
      >
        <H2 textAlign="center">Rate History Will Appear Here</H2>
        <H2>
          <FontAwesomeIcon color="#CACDD1" icon={chartIcon} size="6x" />
        </H2>
      </Stack>
    );
  }

  if (isLoading) {
    return (
      <Skeleton
        variant="rounded"
        sx={{
          width: '100%',
          height: '100%',
        }}
      />
    );
  }

  if (!historicalRates?.length) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        direction="row"
        height="100%"
        spacing="24px"
      >
        <Body size="large">
          <FontAwesomeIcon color="#FFA500" icon={warningIcon} />
        </Body>
        <Body size="large" weight="semibold">
          No Rate Data Available For This Lane
        </Body>
      </Stack>
    );
  }

  const reibusDataset: ChartData = {
    ...DATASET_CONFIG.REIBUS,
    rates: [],
  };

  const datB2CDataset: ChartData = {
    ...DATASET_CONFIG.DAT_SPOT,
    rates: [],
  };

  const datC2CDataset: ChartData = {
    ...DATASET_CONFIG.DAT_CONTRACT,
    rates: [],
    destination: { locationFit: undefined },
  };

  historicalRates.forEach((rate) => {
    const chartRates = rate.monthlyRates.map((point) => ({
      date: point.date,
      rate: point.rate,
      high: point.highRate,
      low: point.lowRate,
    }));

    if (rate.source === 'REIBUS') {
      reibusDataset.rates = chartRates;
    } else if (rate.source === 'DAT' && rate.rateType === 'SPOT') {
      datB2CDataset.rates = chartRates;
      datB2CDataset.origin = { locationFit: rate.origin.locationFit };
      datB2CDataset.destination = { locationFit: rate.destination.locationFit };
    } else if (rate.source === 'DAT' && rate.rateType === 'CONTRACT') {
      datC2CDataset.rates = chartRates;
      datC2CDataset.origin = { locationFit: rate.origin.locationFit };
      datC2CDataset.destination = { locationFit: rate.destination.locationFit };
    }
  });

  const datasets = [reibusDataset, datB2CDataset, datC2CDataset];

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={1} alignItems="baseline">
        <Body size="large" weight="semibold">
          Lane Rate History:
        </Body>
        <Body weight="medium" textTransform="uppercase">
          Dallas, TX
        </Body>
        <Body>to</Body>
        <Body weight="medium" textTransform="uppercase">
          Atlanta, GA
        </Body>
      </Stack>
      <Chart datasets={datasets} />
      <Stack>
        <Body size="small" color="#64676B">
          * Select Rate Source to View Rates on Chart for Comparison
        </Body>
      </Stack>
    </Stack>
  );
};

export default RateChart;
