import { useState } from 'react';

import { Box, Card, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import RouteDetails from 'components/SteelUI/organisms/RouteDetails';
import RouteMap from 'components/SteelUI/organisms/RouteMap';

import useQueryAutoAward from 'features/cse/AutoAward/hooks/useQueryAutoAward';
import Accept from 'features/cse/AutoAward/Confirmation/components/Accept';
import AdditionalInformation from 'features/cse/AutoAward/Confirmation/components/AdditionalInformation';
import PayInformation from 'features/cse/AutoAward/Confirmation/components/PayInformation';
import RouteNotes from 'features/cse/AutoAward/Confirmation/components/RouteNotes';
import { usePageView } from 'utils/mixpanel';
import Decline from './components/Decline/Decline';

type Props = {
  id: string;
};

const Confirmation = ({ id }: Props) => {
  const { data, isLoading: isLoadingAutoAward } = useQueryAutoAward(id);
  usePageView(`Page View: Auto Award`, { type: 'Confirmation' });

  const [showActionButtons, setShowActionButtons] = useState(true);

  if (!data || isLoadingAutoAward) {
    return null;
  }

  const {
    shipment: {
      commodities,
      equipmentNeededDesc,
      services,
      weight,
      rate,
      route: { pickup, delivery, distance },
    },
  } = data;

  const stops = [
    {
      stopType: 'Pickup',
      coordinate: pickup.coordinates,
    },
    {
      stopType: 'Delivery',
      coordinate: delivery.coordinates,
    },
  ];

  return (
    <Card variant="outlined">
      <Box sx={{ p: '24px' }}>
        <Grid container>
          <Grid xs={6} paddingRight="24px" borderRight="1px solid #EDEEF0">
            <RouteMap stops={stops} height="100%" />
          </Grid>
          <Grid container xs={6} paddingLeft="24px">
            <Grid xs={12}>
              <RouteDetails
                pickup={pickup}
                delivery={delivery}
                distance={distance}
              />
            </Grid>
            <Grid
              container
              xs={12}
              paddingTop="24px"
              borderTop="1px solid #EDEEF0"
            >
              <Grid xs={6}>
                <AdditionalInformation
                  weight={weight}
                  services={services}
                  equipmentNeededDesc={equipmentNeededDesc}
                  commodities={commodities}
                />
              </Grid>
              <Grid xs={6} paddingLeft="24px" borderLeft="1px solid #EDEEF0">
                <PayInformation rate={rate} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {pickup.notes || delivery.notes ? (
          <Grid container xs={12} paddingTop="24px">
            <RouteNotes
              pickupNotes={pickup.notes}
              deliveryNotes={delivery.notes}
            />
          </Grid>
        ) : null}
        <Stack
          direction="row"
          justifyContent="flex-end"
          paddingTop="24px"
          paddingRight="24px"
        >
          <Decline
            id={id}
            showActionButtons={showActionButtons}
            onAction={setShowActionButtons}
          />
          <Accept
            id={id}
            showActionButtons={showActionButtons}
            onAction={setShowActionButtons}
          />
        </Stack>
      </Box>
    </Card>
  );
};

export default Confirmation;
