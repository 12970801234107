import { TextBoxField } from 'components/library/form/TextBoxField';
import FormSection from './FormSection';

const AdditionalInformation = () => {
  return (
    <FormSection title="Additional Information">
      <div style={{ width: '50%' }}>
        <TextBoxField
          placeholder="Please Enter Your Description"
          name="additionalInformation"
          label="Let Us Know More About This Shipment"
          required={false}
          maxLength={5000}
        />
      </div>
    </FormSection>
  );
};

export default AdditionalInformation;
