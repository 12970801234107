import { algoliaClient, ALGOLIA_INDEX_SHIPMENTS } from '../../utils/algolia';

type SearchResponse = {
  hits: HitsProps[];
  nbHits: number;
  page: number;
  nbPages: number;
  hitsPerPage: number;
};

type GlobalRouteStop = {
  stopType: {
    key: string;
    value: string;
  };
  address: {
    city: string;
    state: string;
    lat: number;
    lon: number;
    locationLabel: string;
    zip?: string;
  };
  id: string;
};

type HitsProps = {
  shipmentID: string;
  startLocationLabel: string;
  endLocationLabel: string;
  pickupDate: string;
  deliveryDate: string;
  distance?: number;
  equipmentType: number[];
  customerId: number;
  globalRoute: GlobalRouteStop[];
  originDH: string;
};

type SearchShipmentDetails = {
  equipmentType?: string[];
  pickupDate?: string;
  deliveryDate?: string;
  originAddress?: GoogleAddressComponent[];
  destinationAddress?: GoogleAddressComponent[];
  shipmentsByRadius?: string[];
};
type GoogleAddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};
type GoogleMapAddress = {
  postal_code?: string;
  country?: string;
  administrative_area_level_1?: string;
  locality?: string;
  route?: string;
  street_number?: string;
  sublocality_level_1?: string;
};
type AlgoliaAddressComponents = {
  address1?: string;
  city?: string;
  region?: string;
};

const HITS_PER_PAGE = 20;

const searchShipments = (
  query = '',
  filters = '',
  page = 0,
  index = ALGOLIA_INDEX_SHIPMENTS
): Promise<SearchResponse> => {
  const shipmentsAlgoliaIndex = algoliaClient.initIndex(index);
  return shipmentsAlgoliaIndex.search(query, {
    filters: filters,
    hitsPerPage: HITS_PER_PAGE,
    attributesToRetrieve: [
      'shipmentID',
      'startLocationLabel',
      'endLocationLabel',
      'pickupDate',
      'deliveryDate',
      'distance',
      'equipmentType',
      'customerId',
      'globalRoute',
    ],
    page,
  });
};

const searchShipmentsById = async (
  shipmentId: string
): Promise<SearchResponse> =>
  searchShipments(shipmentId, `shipmentID:${shipmentId}`);

const searchShipmentsByDetails = async (
  details: SearchShipmentDetails,
  page = 0,
  index = 'shipments'
): Promise<SearchResponse> =>
  searchShipments('', formatFiltersForSearch(details), page, index);

const formatFiltersForSearch = ({
  equipmentType,
  pickupDate,
  deliveryDate,
  originAddress,
  destinationAddress,
  shipmentsByRadius,
}: SearchShipmentDetails): string => {
  const filterEquipmentTypes = !equipmentType
    ? ''
    : equipmentType
        .map((selection) => `equipmentType:"${selection}"`)
        .filter(Boolean)
        .join(' AND ');
  const filterPickupDate = !pickupDate
    ? ''
    : `pickupDate:${new Date(pickupDate).toISOString().split('T')[0]}`;
  const filterDeliveryDate = !deliveryDate
    ? ''
    : `deliveryDate:${new Date(deliveryDate).toISOString().split('T')[0]}`;
  const filterAddress = formatAddressFilters(originAddress, destinationAddress);
  const filterStatus = `label:"Tendered"`;
  const filterShipmentsByRadius =
    !shipmentsByRadius || shipmentsByRadius.length === 0
      ? ''
      : '(' +
        shipmentsByRadius
          .map((shipmentID) => `shipmentID: "${shipmentID}"`)
          .join(' OR ') +
        ')';
  return [
    filterStatus,
    filterEquipmentTypes,
    filterPickupDate,
    filterDeliveryDate,
    filterAddress,
    filterShipmentsByRadius,
  ]
    .filter(Boolean)
    .join(' AND ');
};

export const transformAddress = (
  components?: GoogleAddressComponent[]
): AlgoliaAddressComponents => {
  if (!components) {
    return {
      address1: '',
      city: '',
      region: '',
    };
  }
  //These are the fields we care about returned by Google API
  const addressFields = [
    'street_number',
    'route',
    'locality',
    'sublocality_level_1',
    'administrative_area_level_1',
  ];

  const address: GoogleMapAddress = {};
  for (const field of addressFields) {
    for (const component of components) {
      if (component.types.includes(field)) {
        address[field as keyof GoogleMapAddress] = component.short_name;
      }
    }
  }

  const address1 = [address.street_number, address.route]
    .filter(Boolean)
    .join(' ');
  const city = address.locality || address.sublocality_level_1 || '';
  const region = address.administrative_area_level_1 ?? '';

  return {
    address1,
    city,
    region,
  };
};

const formatAddressFilters = (
  originAddress?: GoogleAddressComponent[],
  destinationAddress?: GoogleAddressComponent[]
): string => {
  const {
    address1: originAddress1,
    city: originCity,
    region: originRegion,
  } = transformAddress(originAddress);
  const {
    address1: destinationAddress1,
    city: destinationCity,
    region: destinationRegion,
  } = transformAddress(destinationAddress);

  const filterOriginAddress1 = !originAddress1
    ? ''
    : `originAddress.address1:"${originAddress1}"`;
  const filterOriginCity = !originCity
    ? ''
    : `originAddress.city:"${originCity}"`;
  const filterOriginRegion = !originRegion
    ? ''
    : `originAddress.region:${originRegion}`;
  const filterDestinationAddress1 = !destinationAddress1
    ? ''
    : `destinationAddress.address1:"${destinationAddress1}"`;
  const filterDestinationCity = !destinationCity
    ? ''
    : `destinationAddress.city:"${destinationCity}"`;
  const filterDestinationRegion = !destinationRegion
    ? ''
    : `destinationAddress.region:${destinationRegion}`;
  return [
    filterOriginAddress1,
    filterOriginCity,
    filterOriginRegion,
    filterDestinationAddress1,
    filterDestinationCity,
    filterDestinationRegion,
  ]
    .filter(Boolean)
    .join(' AND ');
};

const checkValidFormData = (formData: Record<string, string | string[]>) =>
  Object.values(formData).some((value) =>
    Array.isArray(value) ? value.length > 0 : !!value
  );

export { searchShipmentsById, searchShipmentsByDetails, checkValidFormData };
export type {
  SearchShipmentDetails,
  SearchResponse,
  HitsProps,
  GlobalRouteStop,
  GoogleAddressComponent,
};
export const exportedForTesting = { formatFiltersForSearch };
