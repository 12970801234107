import axios from 'axios';
import { algoliaClient } from 'utils/algolia';
import { getApiRoot } from 'utils/apiUtils';

const ALGOLIA_INDEX_CARRIERS = 'carriers';
const HITS_PER_PAGE = 20;

type SearchByCarrierResponse = {
  hits: SearchByCarrierHit[];
  nbHits: number;
  page: number;
  nbPages: number;
  hitsPerPage: number;
};

type SearchByCarrierHit = {
  cseCarrierID: string;
  carrierName?: string;
  carrierMCNumber?: string;
  carrierDOTNumber?: string;
  headquarterLabel?: string;
  status?: string;
  accountOwnerName?: string;
};

type SearchByCarrierDetails = {
  carrierMCNumber?: string;
  carrierDOTNumber?: string;
  carrierName?: string;
  cseCarrierIdsWithinRadius?: string[];
  headquarterPlaceId?: string;
};

type Coordinates = { latitude: string; longitude: string };

const getFilterForCarriersByRadius = (cseCarrierIdsWithinRadius?: string[]) =>
  !cseCarrierIdsWithinRadius || cseCarrierIdsWithinRadius.length === 0
    ? ''
    : '(' +
      cseCarrierIdsWithinRadius
        .map((carrierId) => `cseCarrierID: "${carrierId}"`)
        .join(' OR ') +
      ')';

const getFilters = (formData: SearchByCarrierDetails) => {
  const filtercarrierMCNumber = !formData.carrierMCNumber
    ? ''
    : `carrierMCNumber: "${formData.carrierMCNumber}"`;
  const filtercarrierDOTNumber = !formData.carrierDOTNumber
    ? ''
    : `carrierDOTNumber: "${formData.carrierDOTNumber}"`;
  const filterCseCarrierIDsByRadius = getFilterForCarriersByRadius(
    formData.cseCarrierIdsWithinRadius
  );

  return [
    filtercarrierMCNumber,
    filtercarrierDOTNumber,
    filterCseCarrierIDsByRadius,
  ]
    .filter(Boolean)
    .join(' AND ');
};

const searchCarriers = (
  details = {} as SearchByCarrierDetails,
  page = 0,
  index = ALGOLIA_INDEX_CARRIERS
): Promise<SearchByCarrierResponse> => {
  const carriersAlgoliaIndex = algoliaClient.initIndex(index);
  const query = details.carrierName ?? '';
  const filters = getFilters(details);

  return carriersAlgoliaIndex.search(query, {
    filters,
    hitsPerPage: HITS_PER_PAGE,
    attributesToRetrieve: [
      'cseCarrierID',
      'carrierName',
      'carrierMCNumber',
      'carrierDOTNumber',
      'headquarterLabel',
      'status',
      'accountOwnerName',
    ],
    page,
  });
};

const getCarriersByRadius = async (
  authorization: string,
  coordinates: Coordinates
): Promise<string[]> => {
  const url = `${getApiRoot()}/carriers/by-radius`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const body = JSON.stringify({ headquarter: coordinates });
  const response = await axios.post(url, body, config);

  return response.data.carriers;
};

export { searchCarriers, ALGOLIA_INDEX_CARRIERS, getCarriersByRadius };
export type { SearchByCarrierResponse, SearchByCarrierHit, Coordinates };
