import { faXmarkLarge } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  xIcon: {
    color: '#64676B',
    width: '1rem',
    height: '1rem',
    cursor: 'pointer',
  },
}));

type Props = { onClick: () => void };

const ModalCloseIcon = ({ onClick }: Props) => {
  const { classes } = useStyles();

  return (
    <FontAwesomeIcon
      onClick={onClick}
      className={classes.xIcon}
      icon={faXmarkLarge}
    />
  );
};

export default ModalCloseIcon;
