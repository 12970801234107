import {
  InputMeta,
  FormSection,
  FormConfig,
} from 'components/library/form/layoutTypes';
import { FieldProps } from 'components/library/form/types';

export const CARRIER_SEARCH_FORM_FIELDS: FieldProps[] = [
  {
    id: 'carrierMCNumber',
    databaseName: 'carrierMCNumber',
    label: 'MC#',
    inputType: 'TEXT',
    required: false,
    displayForReibusPersonnelOnly: false,
  },
  {
    id: 'carrierDOTNumber',
    databaseName: 'carrierDOTNumber',
    label: 'DOT#',
    inputType: 'TEXT',
    required: false,
    displayForReibusPersonnelOnly: false,
  },
  {
    id: 'carrierName',
    databaseName: 'carrierName',
    label: 'Name',
    inputType: 'TEXT',
    required: false,
    displayForReibusPersonnelOnly: false,
  },
  {
    id: 'headquarter',
    databaseName: 'headquarter',
    label: 'HQ Location',
    inputType: 'ADDRESS',
    required: false,
    displayForReibusPersonnelOnly: false,
  },
];

export const CARRIER_DETAILS_INPUTS: InputMeta[] = [
  {
    databaseName: 'carrierMCNumber',
    colWidth: 3,
  },
  {
    databaseName: 'carrierDOTNumber',
    colWidth: 3,
  },
  {
    databaseName: 'carrierName',
    colWidth: 3,
  },
  {
    databaseName: 'headquarter',
    colWidth: 3,
    showManualLabel: false,
  },
];

export const CARRIER_SEARCH_FORM_SECTION: FormSection = {
  title: 'Carrier Details',
  subtitle: 'Not all fields are required.',
  fields: CARRIER_DETAILS_INPUTS,
};

export const searchFormConfig: FormConfig = {
  formSections: [CARRIER_SEARCH_FORM_SECTION],
  formStyle: 'right',
  submitButtonText: 'Search',
  cancelButtonText: 'Clear search',
};
