import { Dialog, DialogProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  paper: {
    backgroundColor: 'transparent !important',
  },
  xIcon: {
    width: '1rem',
    height: '1rem',
    color: '#64676B',
    cursor: 'pointer',
  },
});

const ModalContainer = ({
  open,
  onClose,
  children,
  ...restOfProps
}: DialogProps) => {
  const { classes } = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      {...restOfProps}
    >
      {children}
    </Dialog>
  );
};

export default ModalContainer;
