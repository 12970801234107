import { TypographyProps, Weight, Color } from './types';

const WEIGHT_MAP: Record<Weight, number> = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

const COLOR_MAP: Record<Color, string> = {
  default: '#212224',
  black: '#212224',
  white: '#FFFFFF',
  gray50: '#F6F7F7',
  gray100: '#EDEEF0',
  gray200: '#DCDEE0',
  gray300: '#CACDD1',
  gray400: '#B9BDC1',
  gray500: '#A7ACB2',
  gray600: '#868A8E',
  gray700: '#64676B',
  gray800: '#434547',
  gray900: '#212224',
};

const getFontWeight = (weight: Weight) => WEIGHT_MAP[weight];
const getColor = (color: Color) => COLOR_MAP[color] || color;

export const sharedStyles = ({
  weight = 'regular',
  color = 'default',
}: TypographyProps) => ({
  padding: 0,
  margin: 0,
  fontFamily: 'Montserrat',
  color: getColor(color),
  fontWeight: getFontWeight(weight),
});
