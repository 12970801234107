import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Theme,
  Typography,
  Grid,
  Paper,
  useMediaQuery,
  Container,
} from '@mui/material';
import HitsDisplay from './ShipmentList/ListItem/HitsDisplay';
import { AlgoliaShipmentProps } from 'api/shipment-visibility-dashboard';
import ShipmentItem from './Shipment/ShipmentItem';
import { ToggleBar, ShipmentTabOption } from '../components/library/ToggleBar';
import AlgoliaSearchBar from 'components/library/AlgoliaSearchBar';

const DEFAULT_TOGGLE_OPTION = ShipmentTabOption.IN_PROGRESS;
type QueryParamsKeys = 'status' | 'shipmentId';
type handleSetQueryParams = (value: string) => void;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(1),
    padding: 0,
    overflow: 'auto',
    width: '100%',
  },
  containerDiv: {
    margin: `0 ${theme.spacing(2)}`,
  },
  listContainer: {
    width: '100%',
    padding: theme.spacing(2),
    height: '690px',
  },
  statusContainer: {
    height: '690px',
    overflowY: 'auto',
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  },
  shipmentTitle: {
    fontSize: 20,
    fontWeight: 600,
    color: '#090A0D',
    textAlign: 'start',
  },
  listShipments: {
    overflowY: 'auto',
    height: '76%',
  },
  [theme.breakpoints.down('xl')]: {
    statusContainer: {
      height: '565px',
    },
    listContainer: {
      height: '565px',
    },
    container: {},
    listShipments: {
      height: '70%',
    },
  },
  [theme.breakpoints.down(900)]: {
    statusContainer: {
      padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
    },
    container: {
      height: '100%',
      overflow: 'hidden',
    },
    listContainer: {
      height: 'initial',
    },
  },
  [theme.breakpoints.down('sm')]: {
    gridContainer: {
      padding: 0,
    },
    listContainer: {
      padding: 0,
    },
  },
}));

type Props = {
  handleCurrentStatusList: (value: ShipmentTabOption) => void;
};

const ShipmentsListContainer = ({ handleCurrentStatusList }: Props) => {
  const mobile = useMediaQuery('(max-width: 1175px)');
  const [selectedShipment, setSelectedShipment] = useState<
    AlgoliaShipmentProps | undefined
  >(undefined);

  const [firstShipment, setFirstShipment] = useState<
    AlgoliaShipmentProps | undefined
  >(undefined);

  const classes = useStyles();

  const handleShipmentSelect = async (
    shipment: AlgoliaShipmentProps | undefined | null
  ) => {
    if (mobile && selectedShipment?.id === shipment?.id) {
      setSelectedShipment(undefined);
    }
    setSelectedShipment(shipment as AlgoliaShipmentProps | undefined);
  };

  useEffect(() => {
    if (mobile) {
      const listValue = document.getElementById(
        selectedShipment?.shipmentID || ''
      );
      listValue?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [selectedShipment]);

  const handleQueryParams =
    (paramName: QueryParamsKeys) => (setValue: handleSetQueryParams) => {
      const queryParams = new URLSearchParams(window.location.search);
      const param = queryParams.get(paramName);
      if (param) setValue(param);
    };

  return (
    <Grid container item spacing={2} className={classes.containerDiv}>
      <Grid item xs={mobile ? 12 : 4}>
        <Paper className={classes.listContainer} elevation={mobile ? 0 : 1}>
          <Typography className={classes.shipmentTitle}>
            My Shipments
          </Typography>
          <ToggleBar
            color="primary"
            attribute="status"
            defaultRefinement={[DEFAULT_TOGGLE_OPTION]}
            handleShipmentSelect={handleShipmentSelect}
            handleCurrentStatusList={handleCurrentStatusList}
            handleQueryParams={handleQueryParams('status')}
          />
          <AlgoliaSearchBar
            handleQueryParams={handleQueryParams('shipmentId')}
          />
          <div className={classes.listShipments}>
            <Container className={classes.container}>
              <HitsDisplay
                handleShipmentSelect={handleShipmentSelect}
                setFirstShipment={setFirstShipment}
                selectedShipment={selectedShipment}
                firstShipment={firstShipment}
              />
            </Container>
          </div>
        </Paper>
      </Grid>
      {!mobile ? (
        <Grid data-testid="test-shipmentStatus" item xs={8}>
          <Paper className={classes.statusContainer}>
            <ShipmentItem selectedShipment={selectedShipment} />
          </Paper>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ShipmentsListContainer;
