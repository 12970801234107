import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { sharedStyles } from './shared';

import type { TypographyProps } from './types';

const useStyles = makeStyles<TypographyProps>()((_, props) => {
  return {
    root: {
      ...sharedStyles(props),
      fontSize: 36,
      lineHeight: '45px',
      textTransform: 'uppercase',
    },
  };
});

const Title = ({ className, ...rest }: TypographyProps) => {
  const { classes, cx } = useStyles(rest);
  return <Typography className={cx(classes.root, className)} {...rest} />;
};

export default Title;
