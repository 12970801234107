import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { EquipmentLocation, EquipmentLocationType } from './api';
type PlaceResult = google.maps.places.PlaceResult;
type GeocoderAddressComponent = google.maps.GeocoderAddressComponent;

type GoogleMapAddress = {
  postal_code?: string;
  country?: string;
  administrative_area_level_1?: string;
  locality?: string;
  route?: string;
  street_number?: string;
  sublocality_level_1?: string;
};

type Coordinates = { latitude: string; longitude: string };

const getCoordinatesGeocode = async (
  placeDetails: PlaceResult | null
): Promise<Coordinates> => {
  const geocodes = await geocodeByAddress(
    placeDetails?.formatted_address as string
  );
  const { lat, lng } = await getLatLng(geocodes[0]);
  return {
    latitude: String(lat),
    longitude: String(lng),
  };
};

export const transformAddress = (components?: GeocoderAddressComponent[]) => {
  if (!components) {
    return {
      address1: '',
      city: '',
      region: '',
    };
  }
  //These are the fields we care about returned by Google API
  const addressFields = [
    'street_number',
    'route',
    'locality',
    'sublocality_level_1',
    'administrative_area_level_1',
  ];

  const address: GoogleMapAddress = {};
  for (const field of addressFields) {
    for (const component of components) {
      if (component.types.includes(field)) {
        address[field as keyof GoogleMapAddress] = component.short_name;
      }
    }
  }

  const address1 = [address.street_number, address.route]
    .filter(Boolean)
    .join(' ');
  const city = address.locality || address.sublocality_level_1 || '';
  const stateProvince = address.administrative_area_level_1 ?? '';

  return {
    address1,
    city,
    stateProvince,
  };
};

const convertPlaceIdToEquipmentLocation = (
  placeId: string,
  placesService: google.maps.places.PlacesService | null,
  setLocation: (location: EquipmentLocation) => void,
  setIsLoadingLocation: (isLoading: boolean) => void
) => {
  placesService?.getDetails({ placeId }, async (placeDetails) => {
    setIsLoadingLocation(true);
    const { address1, city, stateProvince } = transformAddress(
      placeDetails?.address_components
    );
    const coords = await getCoordinatesGeocode(placeDetails);
    const location = {
      city,
      stateProvince,
      latitude: Number(coords.latitude),
      longitude: Number(coords.longitude),
      locationType: !address1
        ? EquipmentLocationType.AREA
        : EquipmentLocationType.PLACE,
    };
    setLocation(location);

    setIsLoadingLocation(false);
  });
};

export { convertPlaceIdToEquipmentLocation };
