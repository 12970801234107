import { displayFormattingValue } from '@reibus/reibus-core-utils';
import {
  LaneRateLocationFit,
  RateSource,
} from 'features/LogisticsQuoteManager/hooks/useGetHistoricalRates';

/**
 * Calculates the appropriate y-axis domain and tick values for the chart
 *
 * @param allRates - Array of all rate values to be displayed
 * @returns Object containing yDomain, yTicks, and yTickInterval
 */
export const calculateYAxisTicks = (allRates: number[]) => {
  if (allRates.length === 0) {
    return { yDomain: [0, 1000], yTicks: [0, 500, 1000], yTickInterval: 500 };
  }

  const intervalMultiple = 100;
  const maxTickCount = 11;

  const yMinValue = Math.min(...allRates);
  const yMaxValue = Math.max(...allRates);

  // Round to nearest intervalMultiple below and above min/max
  const yMinDomain =
    Math.floor((yMinValue - intervalMultiple) / intervalMultiple) *
      intervalMultiple +
    intervalMultiple;
  const yMaxDomain =
    Math.ceil((yMaxValue + intervalMultiple) / intervalMultiple) *
      intervalMultiple -
    intervalMultiple;
  const yDomain = [yMinDomain, yMaxDomain];

  const yRange = yMaxDomain - yMinDomain;

  const yTickInterval = Math.max(
    intervalMultiple,
    Math.ceil(yRange / maxTickCount / intervalMultiple) * intervalMultiple
  );

  const yTicks = Array.from(
    { length: Math.floor(yRange / yTickInterval) + 1 },
    (_, i) => yMinDomain + i * yTickInterval
  );

  return { yDomain, yTicks, yTickInterval };
};

/**
 * Formats a rate value as a currency string
 *
 * @param rate - The rate value to format
 * @returns Formatted rate as USD currency string
 */
export const formatRate = (rate: number) => {
  return displayFormattingValue(
    rate,
    {
      trailingZeros: true,
      decimalPlaces: 0,
    },
    'USD'
  );
};

/**
 * Formats the location fit value into a user-friendly string
 *
 * @param locationFit - The location fit enum value
 * @returns Formatted location fit string
 */
export const formatLocationFit = (locationFit: LaneRateLocationFit) => {
  const locationFitMap: Record<LaneRateLocationFit, string> = {
    '3_DIGIT_ZIP': '3 Digit Zip',
    MARKET_AREA: 'Market Area',
    EXTENDED_MARKET_AREA: 'X-MKT',
    REGION: 'Region',
    STATE: 'State',
    '100_MILE_RADIUS': '100 Mile Radius',
  };

  return locationFitMap[locationFit];
};
