import { makeStyles } from '@mui/styles';
import { Typography, Container, Theme } from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: 3,
    padding: 0,
  },
  textToRight: {
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  textToLeft: {
    textAlign: 'left',
    justifyContent: 'flex-start',
  },
  location: {
    fontSize: 14,
    width: '100%',
    fontWeight: 'bold',
    color: '#333333',
  },
  date: {
    color: '#939393',
    fontSize: 14,
    marginTop: 5,
    width: '100%',
  },
  time: {
    color: '#939393',
    fontSize: 10,
    marginTop: 5,
    width: '100%',
  },
  [theme.breakpoints.down(1300)]: {
    container: {
      paddingRight: theme.spacing(1),
    },
  },
  [theme.breakpoints.down(900)]: {
    container: {
      paddingTop: theme.spacing(2),
      paddingRight: 0,
    },
  },
  [theme.breakpoints.down('sm')]: {
    location: {
      fontSize: 12,
    },
  },
}));

type DetailType = {
  location: string;
  date: string;
  isOriginAddress?: boolean;
};

const DetailRoute = ({
  location,
  date,
  isOriginAddress = false,
}: DetailType) => {
  const classes = useStyles();
  const dateShipment = new Date(date);

  const displayDate = `${
    dateShipment.getMonth() + 1
  }/${dateShipment.getDate()}`;

  return (
    <Container
      className={clsx([
        classes.container,
        isOriginAddress ? classes.textToRight : classes.textToLeft,
      ])}
    >
      <Typography noWrap className={classes.location}>
        {location}
      </Typography>
      <Typography noWrap className={classes.date}>
        {displayDate}
      </Typography>
    </Container>
  );
};

export default DetailRoute;
