import { format } from 'date-fns';
import { Box, Stack } from '@mui/material';
import { displayFormattingValue } from '@reibus/reibus-core-utils';
import _ from 'lodash';

import { Body } from 'components/SteelUI/atoms/typography';

type Props = {
  rate?: number;
  date?: string;
  status?: string;
};

const AutoAwardTooltipDetails = ({ rate, date, status }: Props) => {
  const options = {
    trailingZeros: true,
    decimalPlaces: 2,
  };
  const dateFormatted = !date ? 'N/A' : format(new Date(date), 'p P');

  const rateFormatted = !rate
    ? 'N/A'
    : `$${displayFormattingValue(rate, options)}`;
  const statusFormatted = !status ? 'N/A' : _.capitalize(status);
  return (
    <Stack spacing={2}>
      <Box>
        <Body size="small" color="white">
          Carrier Auto Award
        </Body>
      </Box>
      <Box>
        <Body size="small" color="white">
          Status: {statusFormatted}
        </Body>
      </Box>
      <Box>
        <Body size="small" color="white">
          Rate: {rateFormatted}
        </Body>
      </Box>
      <Box>
        <Body size="small" color="white">
          Date: {dateFormatted}
        </Body>
      </Box>
    </Stack>
  );
};

export default AutoAwardTooltipDetails;
