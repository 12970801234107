import { useState, useEffect } from 'react';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';

import { AddressField } from 'components/library/form/AddressField';
import { convertPlaceIdToCoordinates } from 'utils/googlePlaces';

import type { Coordinates } from 'utils/googlePlaces';
import { formatEquipmentType } from 'features/cse/Carrier/utils/data';
import useAvailableEquipment from 'features/cse/Carrier/hooks/useAvailableEquipment';

import FiltersButton from './components/FiltersButton';
import FiltersDrawer from './components/FiltersDrawer';
import FiltersForm from './components/FiltersForm';
import CollapsibleField from './components/CollapsibleField';

import type {
  Equipment,
  TopShipmentsFilters,
} from 'features/cse/Carrier/types';

type Props = {
  setFilters: (filters: TopShipmentsFilters) => void;
  cseCarrierID: string;
};

const EQUIPMENT_TYPE_CODES = [
  {
    code: 1,
    label: 'Flatbed',
  },
  {
    code: 2,
    label: 'Dry Van',
  },
  {
    code: 3,
    label: 'Reefer',
  },
  {
    code: 4,
    label: 'Container',
  },
  {
    code: 5,
    label: 'Hopper Bottom',
  },
  {
    code: 6,
    label: 'Conestoga',
  },
  {
    code: 7,
    label: 'Tanker, Steel',
  },
  {
    code: 8,
    value: 'Pneumatic',
  },
  {
    code: 9,
    label: 'Step Deck',
  },
  {
    code: 10,
    label: 'Dump Trailer',
  },
  {
    code: 11,
    label: 'Hotshot',
  },
  {
    code: 12,
    label: 'Drop Deck, Landoll',
  },
  {
    code: 13,
    label: 'Removable Gooseneck',
  },
  {
    code: 14,
    label: 'Stretch Trailer',
  },
  {
    code: 15,
    label: 'Double Drop',
  },
  {
    code: 16,
    label: 'Auto Carrier',
  },
  {
    code: 17,
    label: 'Power Only',
  },
  {
    code: 18,
    label: 'Container Trailer',
  },
  {
    code: 19,
    label: 'Van, Hotshot',
  },
  {
    code: 20,
    label: 'Van w/ Curtains',
  },
  {
    code: 21,
    label: 'Van, Vented',
  },
  {
    code: 22,
    label: 'Other',
  },
];

const FilterTopShipmentsV2 = ({ setFilters, cseCarrierID }: Props) => {
  const { placesService } = usePlacesAutocompleteService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
  });

  const { data: equipmentList, isLoading: isEquipmentLoading } =
    useAvailableEquipment(cseCarrierID);

  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const equipmentIdQueryParam = searchParams.get('equipmentId');

  const [open, setOpen] = useState(false);
  const [renderKey, setRenderKey] = useState(new Date().toISOString());

  const [filterCount, setFilterCount] = useState(0);
  const [equipmentListingFilterCount, setEquipmentListingFilterCount] =
    useState(0);
  const [equipmentTypeFilterCount, setEquipmentTypeFilterCount] = useState(0);

  const [isFetchingOriginCoordinates, setIsFetchingOriginCoordinates] =
    useState(false);
  const [originPlaceId, setOriginPlaceId] = useState('');
  const [originCoordinates, setOriginCoordinates] =
    useState<Coordinates | null>(null);

  const [
    isFetchingDestinationCoordinates,
    setIsFetchingDestinationCoordinates,
  ] = useState(false);
  const [destinationPlaceId, setDestinationPlaceId] = useState('');
  const [destinationCoordinates, setDestinationCoordinates] =
    useState<Coordinates | null>(null);

  // the key for availableEquipmentListings is a "name" that is a combination of the equipment type and the city/state
  // since there is overlap, we store an array of equipment ids that match the "name" used as a key
  const [availableEquipmentListings, setAvailableEquipmentListings] = useState<{
    [key: string]: string[];
  }>({});
  const [
    selectedAvailableEquipmentListingKeys,
    setSelectedAvailableEquipmentListingKeys,
  ] = useState<string[]>([]);

  const [availableEquipmentTypeCodes, setAvailableEquipmentTypeCodes] =
    useState<number[]>([]);
  const [
    selectedAvailableEquipmentTypeCodes,
    setSelectedAvailableEquipmentTypeCodes,
  ] = useState<number[]>([]);

  const isLoading =
    isFetchingOriginCoordinates ||
    isFetchingDestinationCoordinates ||
    isEquipmentLoading;

  // these keys are the "name" of the equipment listings
  // format is "Equipment Type - City, State"
  // not guaranteed to be unique to a single equipment listing
  // but collisions are generally unlikely
  const getEquipmentListingKeyFromEquipment = (equipment: Equipment) => {
    const {
      equipmentType: { description },
      EquipmentLocation_EquipmentToEquipmentLocation: { city },
      EquipmentLocation_EquipmentToEquipmentLocation: { stateProvince },
    } = equipment;

    return `${formatEquipmentType({
      description,
    })} - ${city}, ${stateProvince}`;
  };

  const buildFiltersOptionsForAvailableEquipment = () => {
    const availableEquipmentTypeCodes = new Set<number>();
    const availableEquipmentListingKeys: { [key: string]: string[] } = {};

    equipmentList?.forEach((equipment) => {
      const {
        id,
        equipmentType: { code },
      } = equipment;

      const key = getEquipmentListingKeyFromEquipment(equipment);

      availableEquipmentTypeCodes.add(code);
      if (!availableEquipmentListingKeys[key])
        availableEquipmentListingKeys[key] = [];
      availableEquipmentListingKeys[key].push(id);
    });
    setAvailableEquipmentTypeCodes([...availableEquipmentTypeCodes]);
    setAvailableEquipmentListings(availableEquipmentListingKeys);
  };

  // get all the equipment ids for the selected equipment listings
  const getEquipmentIdsFromListingKeys = (listingKeys: string[]) =>
    listingKeys.reduce((acc: string[], key: string) => {
      const equipmentIdsForListing = availableEquipmentListings[key];
      return [...acc, ...equipmentIdsForListing];
    }, []);

  const handleSubmit = () => {
    const equipmentListing = selectedAvailableEquipmentListingKeys.length
      ? getEquipmentIdsFromListingKeys(selectedAvailableEquipmentListingKeys)
      : undefined;

    const equipmentType = selectedAvailableEquipmentTypeCodes.length
      ? selectedAvailableEquipmentTypeCodes
      : undefined;

    setFilters({
      originCoordinates: originCoordinates || undefined,
      destinationCoordinates: destinationCoordinates || undefined,
      equipmentListing,
      equipmentType,
    });
  };

  const clearFilterStates = () => {
    setOriginCoordinates(null);
    setDestinationCoordinates(null);
    setSelectedAvailableEquipmentTypeCodes([]);
    setSelectedAvailableEquipmentListingKeys([]);
    setEquipmentListingFilterCount(0);
    setEquipmentTypeFilterCount(0);
  };

  const handleClear = () => {
    clearQueryParams();
    clearFilterStates();
    setRenderKey(new Date().toISOString());
    setFilters({});
  };

  //  The form uses a google maps placeId but the payload needs a location object
  //  so we use a callback to update the state of originPlaceId/destinationPlaceId
  //  and use a hook to convert them to location objects whenever a change is detected
  const onChange = (values: Record<string, string>) => {
    setOriginPlaceId(values?.originPlaceId || '');
    setDestinationPlaceId(values?.destinationPlaceId || '');
  };

  const onChangeEquipmentListing = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedAvailableEquipmentListingKeys((prev) => [...prev, value]);
    } else {
      setSelectedAvailableEquipmentListingKeys((prev) =>
        prev.filter((key) => key !== value)
      );
    }
  };

  const onChangeEquipmentType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedAvailableEquipmentTypeCodes((prev) => [
        ...prev,
        parseInt(value),
      ]);
    } else {
      setSelectedAvailableEquipmentTypeCodes((prev) =>
        prev.filter((key) => key !== parseInt(value))
      );
    }
  };

  const clearQueryParams = () => {
    if (equipmentIdQueryParam) {
      history.push({
        pathname: location.pathname,
        search: `tab=overview`,
      });
    }
  };

  useEffect(() => {
    if (isEquipmentLoading || equipmentList?.length === 0) return;
    buildFiltersOptionsForAvailableEquipment();
  }, [equipmentList, isEquipmentLoading]);

  // convert the origin placeId to coordinates
  useEffect(() => {
    if (!placesService) return;
    if (!originPlaceId) {
      setOriginCoordinates(null);
      return;
    }
    convertPlaceIdToCoordinates(
      originPlaceId,
      placesService,
      setIsFetchingOriginCoordinates,
      setOriginCoordinates
    );
  }, [originPlaceId, placesService]);

  // convert the destination placeId to coordinates
  useEffect(() => {
    if (!placesService) return;
    if (!destinationPlaceId) {
      setDestinationCoordinates(null);
      return;
    }
    convertPlaceIdToCoordinates(
      destinationPlaceId,
      placesService,
      setIsFetchingDestinationCoordinates,
      setDestinationCoordinates
    );
  }, [destinationPlaceId, placesService]);

  // setting the filter counts
  useEffect(() => {
    if (
      isFetchingOriginCoordinates ||
      isFetchingDestinationCoordinates ||
      isEquipmentLoading
    )
      return;

    setFilterCount(
      (originCoordinates ? 1 : 0) +
        (destinationCoordinates ? 1 : 0) +
        (selectedAvailableEquipmentListingKeys.length ? 1 : 0) +
        (selectedAvailableEquipmentTypeCodes.length ? 1 : 0)
    );

    setEquipmentListingFilterCount(
      selectedAvailableEquipmentListingKeys.length
    );
    setEquipmentTypeFilterCount(selectedAvailableEquipmentTypeCodes.length);
  }, [
    isFetchingOriginCoordinates,
    isFetchingDestinationCoordinates,
    originCoordinates,
    destinationCoordinates,
  ]);

  // auto-filter if equipmentIdQueryParam is present
  // this is triggered by adding equipment to the carrier
  useEffect(() => {
    if (!isLoading && equipmentIdQueryParam) {
      clearFilterStates();
      const equipment = equipmentList?.find(
        (equipment) => equipment.id === equipmentIdQueryParam
      );
      if (!equipment) return;

      const equipmentKey = getEquipmentListingKeyFromEquipment(equipment);
      setSelectedAvailableEquipmentListingKeys([equipmentKey]);
      setFilters({
        equipmentListing: [equipment.id],
      });
    }
  }, [isLoading, equipmentIdQueryParam]);

  return (
    <>
      <FiltersButton open={open} setOpen={setOpen} filterCount={filterCount} />
      <FiltersDrawer open={open} setOpen={setOpen}>
        <FiltersForm
          handleSubmit={handleSubmit}
          handleClear={handleClear}
          isLoading={isLoading}
          onChange={onChange}
        >
          <CollapsibleField label="Origin">
            <AddressField
              name="origin"
              label="Origin"
              showManualLabel={false}
              required={false}
              key={`filter-origin-${renderKey}`}
            />
          </CollapsibleField>
          <CollapsibleField label="Destination">
            <AddressField
              name="destination"
              label="Destination"
              showManualLabel={false}
              required={false}
              key={`filter-destination-${renderKey}`}
            />
          </CollapsibleField>
          <CollapsibleField
            label={`Equipment Listings ${
              equipmentListingFilterCount
                ? `(${equipmentListingFilterCount})`
                : ''
            }`}
          >
            {Object.keys(availableEquipmentListings || {}).map((key) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      checked={selectedAvailableEquipmentListingKeys.includes(
                        key
                      )}
                      onChange={onChangeEquipmentListing}
                      value={key}
                      key={`check-equipment-${key}`}
                    />
                  }
                  label={key}
                  key={`filter-equipment-${key}`}
                />
              );
            })}
          </CollapsibleField>
          <CollapsibleField
            label={`Equipment Type ${
              equipmentTypeFilterCount ? `(${equipmentTypeFilterCount})` : ''
            }`}
          >
            {[...availableEquipmentTypeCodes].map((code) => {
              return (
                <Grid key={`grid-${code}`} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked
                        checked={selectedAvailableEquipmentTypeCodes.includes(
                          code
                        )}
                        onChange={onChangeEquipmentType}
                        value={code}
                        key={`check-equipment-type-${code}`}
                      />
                    }
                    label={
                      EQUIPMENT_TYPE_CODES.find((el) => code === el.code)
                        ?.label ?? 'N/A'
                    }
                    key={`filter-equipment-type-${code}`}
                  />
                </Grid>
              );
            })}
          </CollapsibleField>
        </FiltersForm>
      </FiltersDrawer>
    </>
  );
};

export default FilterTopShipmentsV2;
