import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Dialog, IconButton, Divider } from '@mui/material';
import { Body, H1, H3, H2 } from 'components/SteelUI/atoms/typography';

import { makeStyles } from 'tss-react/mui';

import { LocationRoute } from 'quote-details/quote-information/Location';
import { getStopInfo } from 'quote-details/quote-estimate/QuoteEstimate';
import { EstimateTotal } from 'quote-details/quote-estimate/PriceDetail';
import ShipmentMap from 'shipment-visibility-dashboard/Shipment/ShipmentMap';
import { FreightQuoteRequest } from 'api/request-for-quote';

const useStyles = makeStyles()(({ spacing }) => ({
  paper: {
    minWidth: 1257,
    borderRadius: '8px !important',
    backgroundColor: '#fff',
    padding: spacing(3),
    paddingBottom: spacing(9),
  },
  iconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  quoteId: {
    marginLeft: spacing(1),
  },
  subtitle: {
    marginTop: spacing(2),
    marginBottom: spacing(4),
  },
  details: {
    marginTop: spacing(3),
    marginBottom: spacing(4),
  },
  link: {
    color: '#2D6CCB',
    textDecoration: 'none',
  },
  phone: { fontWeight: 700, textDecoration: 'none', color: '#212224' },
  divider: {
    height: 190,
    marginLeft: spacing(4),
    marginRight: spacing(4),
  },
  locationsLabel: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
  mapWrapper: {
    width: 310,
    marginLeft: spacing(4),
    marginRight: spacing(4),
  },
}));

type AutoBookedShipmentProps = {
  open: boolean;
  handleClose: () => void;
  shipmentDetails: FreightQuoteRequest;
};

const AutoBookedShipmentModal = ({
  open,
  handleClose,
  shipmentDetails,
}: AutoBookedShipmentProps) => {
  const { classes } = useStyles();
  const { id, originAddress, destinationAddress, priceEstimate, weight } =
    shipmentDetails;

  const routes = getStopInfo(originAddress, destinationAddress);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Stack display="flex" direction="row" justifyContent="space-between">
        <Stack display="flex" direction="row">
          <H1 weight="semibold">Quote Confirmation -</H1>
          <H1
            color="#64676B"
            weight="semibold"
            className={classes.quoteId}
          >{`Quote ID: ${id}`}</H1>
        </Stack>
        <IconButton onClick={handleClose} className={classes.iconButton}>
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      </Stack>
      <H3 weight="medium" className={classes.subtitle}>
        You have successfully submitted your request!
      </H3>
      <Divider orientation="horizontal" />
      <H2 weight="semibold" className={classes.details}>
        Details
      </H2>
      <Stack direction="row">
        <Stack direction="column" rowGap="24px" width="415px">
          <Body>
            An email has been sent to you regarding this request for your
            personal records.
          </Body>
          <Body>
            A Reibus Logistics Representative will reach out to you shortly to
            confirm the load details and the rate.
          </Body>
          <Body>
            Please contact us at{' '}
            <a className={classes.phone} href="tel:+18888580648">
              (888) 858-0648
            </a>{' '}
            or email us at{' '}
            <a className={classes.link} href="mailto:shippersupport@reibus.com">
              shippersupport@reibus.com
            </a>{' '}
            and refer to this quote ID if you have any questions.
          </Body>
        </Stack>
        <Divider orientation="vertical" className={classes.divider} />
        <Stack direction="column">
          <Body
            size="small"
            weight="semibold"
            className={classes.locationsLabel}
          >
            LOCATIONS
          </Body>
          <LocationRoute
            originAddress={originAddress}
            destinationAddress={destinationAddress}
          />
        </Stack>
        <div className={classes.mapWrapper}>
          <ShipmentMap routes={routes} height="190px" />
        </div>
        <Stack display="flex" alignItems="center" justifyContent="center">
          <EstimateTotal priceEstimate={priceEstimate} weight={weight} />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default AutoBookedShipmentModal;
