import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import SkeletonWrapper from 'components/library/SkeletonWrapper';
import { useEffect, useState } from 'react';
import { EquipmentContactInfo } from 'shipment/api';
import EquipmentContactInfoRow from 'shipment/carrier-contact-info-modal/EquipmentContactInfoRow';
import TurvoContact from 'shipment/carrier-contact-info-modal/TurvoContact';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useCarrierContactInfo from 'hooks/useCarrierContactInfo';
import useEquipmentContactInfo from 'hooks/useEquipmentContactInfo';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 8,
    padding: theme.spacing(3),
    minWidth: '25vw',
  },
  title: {
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 500,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    paddingBottom: theme.spacing(3),
    '& > *': {
      marginTop: theme.spacing(2),
    },
    '& > *:first-child': {
      marginTop: 0,
    },
  },
  [theme.breakpoints.down('xl')]: {
    root: {
      minWidth: '40vw',
    },
  },
}));

type Props = {
  isOpen: boolean;
  carrierName: string;
  handleClose: () => void;
  carrierId: string;
  equipmentIDs: string[];
};

const getContactForIndicatorType = (data: EquipmentContactInfo[]) => {
  if (!data) {
    return {
      dat: null,
      its: null,
      req: null,
    };
  }

  const dat = data.find((d) => d.capacityIndicatorType === 'DAT') ?? null;
  const its = data.find((d) => d.capacityIndicatorType === 'ITS') ?? null;
  const req =
    data.find((d) =>
      ['REIBUS_MANUAL', 'REIBUS_AUTOMATIC'].includes(d.capacityIndicatorType)
    ) ?? null;

  return { dat, its, req };
};

const CarrierContactInfoModal = ({
  isOpen,
  handleClose,
  carrierName,
  carrierId,
  equipmentIDs,
}: Props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [dat, setDAT] = useState<EquipmentContactInfo | null>(null);
  const [its, setITS] = useState<EquipmentContactInfo | null>(null);
  const [req, setREQ] = useState<EquipmentContactInfo | null>(null);
  const { rl2471ReqContact } = useFlags();

  const { data: carrierContactInfo, isLoading: carrierLoading } =
    useCarrierContactInfo(carrierId, isOpen);

  const { data: equipmentContactInfo, isLoading: equipmentLoading } =
    useEquipmentContactInfo(carrierId, equipmentIDs, isOpen);

  useEffect(() => {
    if (!carrierLoading && !equipmentLoading) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [carrierLoading, equipmentLoading]);

  useEffect(() => {
    if (!equipmentContactInfo) return;
    const contactInfo = getContactForIndicatorType(equipmentContactInfo);
    setDAT(contactInfo.dat);
    setITS(contactInfo.its);
    setREQ(contactInfo.req);
  }, [equipmentContactInfo]);

  return (
    <Dialog open={isOpen} maxWidth={false}>
      <Box className={classes.root}>
        <DialogTitle>
          <Typography className={classes.title}>
            {carrierName} Contacts
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <SkeletonWrapper isLoading={isLoading}>
          <DialogContent className={classes.content}>
            {!carrierContactInfo ? null : (
              <TurvoContact {...carrierContactInfo} />
            )}
            {req && rl2471ReqContact ? (
              <EquipmentContactInfoRow {...req} carrierName={carrierName} />
            ) : null}
            {!dat ? null : (
              <EquipmentContactInfoRow {...dat} carrierName={carrierName} />
            )}
            {its ? (
              <EquipmentContactInfoRow {...its} carrierName={carrierName} />
            ) : null}
          </DialogContent>
        </SkeletonWrapper>
      </Box>
    </Dialog>
  );
};

export default CarrierContactInfoModal;
