import { Stack } from '@mui/material';
import { Body } from 'components/SteelUI/atoms/typography';
import { TooltipEllipsis } from 'components/TooltipEllipsis';

type Props = {
  label: string;
  value: string;
};

const AdditionalInformationSection = ({ label, value }: Props) => {
  return (
    <Stack direction="column" width="100%" spacing={1}>
      <Body size="medium" weight="bold">
        {label}
      </Body>
      <Body size="medium">
        <TooltipEllipsis textValue={value} />
      </Body>
    </Stack>
  );
};

export default AdditionalInformationSection;
