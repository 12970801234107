import { Grid, TextField, Theme, Typography } from '@mui/material';
import { Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getCarrierDetailInfo } from 'shipment/api';
import type { CarrierDetailResponse } from 'shipment/api';
import { useShipmentContext } from 'shipment/ShipmentContext';
import Carrier from 'shipment/top-carriers/Carrier';

import type { CompositeCarrierScore } from 'shipment/api';
import { useEffect, useState } from 'react';
import { DispositionContextProvider } from 'features/cse/disposition/contexts/DispositionContext';
import DispositionModal from '../../features/cse/disposition/disposition-modal';
import NoResults from 'search-shipments/search-shipments-results/NoResults';
import { useFlags } from 'launchdarkly-react-client-sdk';
import SearchBar from 'components/library/SearchBar';
import { useAuthorizationToken } from '@reibus/frontend-utility';

import MultiCheckBoxButton from 'components/MultiCheckBoxButton';
import SearchBarNoAutocomplete from 'components/library/SearchBarNoAutocomplete';

const useStyles = makeStyles((theme: Theme) => ({
  topCarriersTitle: {
    paddingTop: theme.spacing(2),
    fontWeight: 600,
  },
  container: {
    maxHeight: 600,
    overflow: 'auto',
    marginBottom: theme.spacing(1),
  },
  item: {
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    flexGrow: 1,
  },
  filterTitle: {
    padding: theme.spacing(1),
    fontSize: 16,
  },
  filterBy: {
    marginTop: theme.spacing(3),
  },
}));

type TopCarriersProps = {
  reibusId: string;
  shipmentId: string;
};

type CapacityIndicatorFilter = {
  [key: string]: boolean;
};

type CarrierDetails = CarrierDetailResponse & { carrierNameLowercase?: string };

const TopCarriers = ({ shipmentId, reibusId }: TopCarriersProps) => {
  const classes = useStyles();

  const { rl1781CseCompositeScoreIts, rl3367SearchTopCarriersByName } =
    useFlags();
  const {
    isLoadingTopCarriers,
    topCarriers,
    filteredTopCarriers,
    handleSearchFilterCarrier,
  } = useShipmentContext();
  const [carriersDetails, setCarriersDetails] = useState(
    [] as CarrierDetails[]
  );
  const [searchResults, setSearchResults] = useState<string[]>(['']);
  const [filters, setFilters] = useState<CapacityIndicatorFilter>({
    all: true,
  });
  const authToken = useAuthorizationToken();

  const getData = async (carriers: CompositeCarrierScore[]) => {
    if (!topCarriers.length) return;

    const ids = carriers
      .map((carrier: CompositeCarrierScore) => carrier.cseCarrierID)
      .join(',');
    const resp = await getCarrierDetailInfo(authToken, ids);
    const carriersDetails = resp.map((carrier) => {
      return {
        ...carrier,
        carrierNameLowercase: carrier.carrierName?.toLowerCase(),
      };
    });

    setCarriersDetails(carriersDetails);
  };

  const getCarrierDetails = (carrierId: string) => {
    const carrier = carriersDetails.find(
      (carrier) => carrier.cseCarrierID === carrierId
    );
    return carrier || {};
  };

  useEffect(() => {
    getData(topCarriers);
  }, [topCarriers]);

  const [searchValue, setSearchValue] = useState('' as string);

  const filterCarrierIdsForSearch = (searchValue: string) => {
    if (!searchValue) {
      return topCarriers.map((carrier) => carrier.cseCarrierID);
    }

    return carriersDetails
      .filter((carrier) => {
        return !rl3367SearchTopCarriersByName
          ? carrier.carrierMCNumber?.indexOf(searchValue) !== -1
          : carrier.carrierNameLowercase?.indexOf(searchValue.toLowerCase()) !==
              -1;
      })
      .map((carrier) => carrier.cseCarrierID);
  };

  const handleSearch = (valueSearched: string) => {
    setSearchValue(valueSearched);
    const cseCarrierIdsSearched = filterCarrierIdsForSearch(valueSearched);

    setSearchResults(cseCarrierIdsSearched);
    handleSearchFilterCarrier(filters, cseCarrierIdsSearched);
  };

  const handleFilter = (capactiyIndicatorFilter: CapacityIndicatorFilter) => {
    setFilters(capactiyIndicatorFilter);
    const searched =
      searchResults[0] === ''
        ? topCarriers.map((carrier) => carrier.cseCarrierID)
        : searchResults;
    handleSearchFilterCarrier(capactiyIndicatorFilter, searched);
  };

  return (
    <DispositionContextProvider>
      {isLoadingTopCarriers ? (
        <Skeleton height={230} />
      ) : (
        <>
          <Typography variant="h3" className={classes.topCarriersTitle}>
            Top Carriers for Shipment
          </Typography>
          <Grid container className={classes.container} justifyContent="center">
            <Grid item xs={6}>
              {!rl3367SearchTopCarriersByName ? (
                <SearchBar
                  id="top-carriers-searchBar-mc"
                  label="Search MC#"
                  handleChange={handleSearch}
                  options={carriersDetails.map(
                    (carrier) => carrier.carrierMCNumber || ''
                  )}
                />
              ) : (
                <SearchBarNoAutocomplete
                  id="top-carriers-searchBar-name"
                  label="Search by Carrier Name"
                  handleChange={handleSearch}
                  value={searchValue}
                />
              )}
            </Grid>
            <>
              <Grid
                item
                container
                justifyContent="flex-end"
                xs={6}
                className={classes.filterBy}
              >
                <Typography className={classes.filterTitle}>
                  Filter By
                </Typography>
                <MultiCheckBoxButton
                  options={[
                    'DAT',
                    'HQ',
                    rl1781CseCompositeScoreIts ? 'ITS' : '',
                    'LANE',
                    'REQ',
                  ].filter((v) => v)}
                  onClick={handleFilter}
                />
              </Grid>
            </>
          </Grid>
          <Grid container className={classes.container} justifyContent="center">
            <Grid item className={classes.item}>
              {!filteredTopCarriers.length ? (
                <NoResults />
              ) : (
                filteredTopCarriers.map((carrier: CompositeCarrierScore) => (
                  <Grid
                    item
                    className={classes.item}
                    key={`carrier-${carrier.cseCarrierID}`}
                  >
                    <Carrier
                      name={carrier.carrierName}
                      totalScore={carrier.totalScore}
                      scores={carrier.componentScores}
                      carrierId={carrier.cseCarrierID}
                      shipmentId={shipmentId}
                      rankedEquipment={carrier.rankedEquipment ?? []}
                      carrierDetails={getCarrierDetails(carrier.cseCarrierID)}
                      reibusId={reibusId}
                      carrierRankId={carrier.carrierRankId}
                      scoringTime={carrier.scoringTime}
                      indicators={carrier.indicators}
                      labels={carrier.labels}
                      digitalFreightAward={carrier.digitalFreightAward}
                    />
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>
        </>
      )}
      <DispositionModal />
    </DispositionContextProvider>
  );
};

export default TopCarriers;
