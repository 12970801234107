import { AxiosError } from 'axios';
import { Stack } from '@mui/material';
import { Body } from 'components/SteelUI/atoms/typography';
import ModalCloseIcon from 'components/SteelUI/molecules/modals/components/ModalIcons/ModalCloseIcon';
import ModalErrorIcon from 'components/SteelUI/molecules/modals/components/ModalIcons/ModalErrorIcon';

const body = 'If you have questions, please call +1 (888) 663-0785';

type Props = {
  onClose: () => void;
  error?: AxiosError;
};

const ErrorScreen = ({ onClose, error }: Props) => {
  let title = 'An error has occurred';

  if (
    error?.response?.data?.error?.message === 'SHIPMENT_NO_LONGER_AVAILABLE'
  ) {
    title = 'Sorry, this shipment is no longer available.';
  }

  return (
    <Stack direction="row" spacing={3}>
      <ModalErrorIcon />
      <Stack direction="column" spacing={2}>
        <Body size="large" weight="bold" paddingTop="8px">
          {title}
        </Body>
        <Body size="small">{body}</Body>
      </Stack>
      <ModalCloseIcon onClick={onClose} />
    </Stack>
  );
};

export default ErrorScreen;
