import { Grid, Typography } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { getHQ, formatCarrierStatus } from 'features/cse/Carrier/utils/data';
import { CarrierDetailResponse } from 'shipment/api';

const DEFAULT_VALUE = 'N/A';

type CarrierDetailsProps = {
  details: Pick<
    CarrierDetailResponse,
    | 'accountOwnerName'
    | 'carrierDOTNumber'
    | 'carrierMCNumber'
    | 'headquarter'
    | 'status'
    | 'source'
  >;
};

const Labels = {
  MC: 'MC #',
  DOT: 'DOT #',
  Status: 'Status',
  HQ: 'HQ Location',
  AccountOwner: 'Account Owner',
};

type ItemProps = { label: string; value?: string | number };

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 700,
    fontSize: 16,
    marginBottom: 8,
  },
}));

const CarrierDetails = (props: CarrierDetailsProps) => {
  const {
    details: {
      accountOwnerName = null,
      carrierDOTNumber = null,
      carrierMCNumber = null,
      status = null,
      headquarter,
      source,
    },
  } = props;

  const classes = useStyles();

  const HQ = getHQ(headquarter);

  const dValue = (v: string | number | null): string | number =>
    v ?? DEFAULT_VALUE;

  const Item = ({ label, value }: ItemProps) => (
    <Grid item xs={6} sm={4}>
      <Typography className={classes.title}>{label}</Typography>
      <span>{value}</span>
    </Grid>
  );

  return (
    <Grid container rowSpacing={3}>
      <Item label={Labels.MC} value={dValue(carrierMCNumber)} />
      <Item label={Labels.DOT} value={dValue(carrierDOTNumber)} />
      <Item label={Labels.Status} value={formatCarrierStatus(source, status)} />
      <Item label={Labels.HQ} value={HQ} />
      <Item label={''} />
      <Item label={Labels.AccountOwner} value={dValue(accountOwnerName)} />
    </Grid>
  );
};
export default CarrierDetails;
