import { ChangeEvent } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField as MuiTextField } from '@mui/material';
import { Field } from 'react-final-form';
import { FormFieldError } from './FormFieldError';
import { stringValidator } from './fieldValidators';

const useTextFieldStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
  asterisk: {
    color: '#c62828',
    fontSize: '1.2rem',
  },
  textboxWrap: {
    whiteSpace: 'nowrap',
    height: '100%',
    '& > div': {
      height: '100%',
      '& textarea': {
        height: '100%',
      },
    },
  },
}));

type TextBoxFieldProps = {
  placeholder?: string;
  name: string;
  label: string;
  required?: boolean;
  validator?: (value: string) => string | void;
  maxLength?: number;
  value?: string;
  disabled?: boolean;
  multiline?: boolean;
};

export const TextBoxInput = ({
  name,
  placeholder,
  label,
  required = true,
  validator,
  maxLength = 200,
  value,
  disabled,
}: TextBoxFieldProps) => {
  const classes = useTextFieldStyles();

  return (
    <Field name={name} validate={validator}>
      {({ input, meta }) => {
        const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
          input.onChange(event.target.value);

        const noPlaceholderProps = !placeholder ? {} : { shrink: true };

        return (
          <div className={classes.container}>
            <MuiTextField
              id={name}
              label={label}
              placeholder={placeholder}
              fullWidth
              variant="outlined"
              required={required}
              name={input.name}
              disabled={disabled}
              value={value ?? input.value}
              onChange={handleChange}
              error={false}
              inputProps={{ maxLength }}
              InputLabelProps={{
                ...noPlaceholderProps,
                classes: {
                  asterisk: classes.asterisk,
                },
              }}
              onFocus={input.onFocus}
              onBlur={input.onBlur}
              rows={4}
              multiline
              classes={{
                root: classes.textboxWrap,
              }}
            />
            <FormFieldError meta={meta} />
          </div>
        );
      }}
    </Field>
  );
};

export const TextBoxInputRequired = ({
  name,
  placeholder,
  label,
  required = true,
  validator = stringValidator,
  maxLength = 200,
  value,
  disabled,
}: TextBoxFieldProps) => {
  const classes = useTextFieldStyles();

  return (
    <Field name={name} validate={validator}>
      {({ input, meta }) => {
        const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
          input.onChange(event.target.value);

        const placeholderProps = placeholder ? { shrink: true } : {};

        return (
          <div>
            <div className={classes.container}>
              <MuiTextField
                id={name}
                label={label}
                placeholder={placeholder}
                fullWidth
                variant="outlined"
                required={required}
                name={input.name}
                disabled={disabled}
                value={value ?? input.value}
                onChange={handleChange}
                error={false}
                inputProps={{ maxLength }}
                InputLabelProps={{
                  ...placeholderProps,
                  classes: {
                    asterisk: classes.asterisk,
                  },
                }}
                onFocus={input.onFocus}
                onBlur={input.onBlur}
                rows={4}
                multiline
                classes={{
                  root: classes.textboxWrap,
                }}
              />
              <FormFieldError meta={meta} />
            </div>
          </div>
        );
      }}
    </Field>
  );
};

// FIX ME
// whenever Field is rendered, validate is passed to the parent component (?) making it difficult to conditionally make a field required
// attempted to check for required like in the other inputs and set validate, but this isn't working even though validate is being updated
export const TextBoxField = (props: TextBoxFieldProps) => {
  return props.required ? (
    <TextBoxInputRequired {...props} />
  ) : (
    <TextBoxInput {...props} />
  );
};
