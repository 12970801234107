import { Box, Divider, Drawer, IconButton, Paper, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import AddEquipmentForm from './AddEquipmentForm';

import type { Equipment } from 'features/cse/Carrier/types';

type Props = {
  cseCarrierID: string;
  mcNumber?: string;
  dotNumber?: string;
  activeEquipment?: Equipment;
  equipmentType?: string;
  isOpen: boolean;
  handleClose: () => void;
  handleUpdated?: () => void;
};

const AddEquipmentDrawer = ({
  cseCarrierID,
  mcNumber,
  dotNumber,
  isOpen,
  activeEquipment,
  handleClose,
  handleUpdated,
}: Props) => {
  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose} hideBackdrop>
      <Paper sx={{ width: '400px' }}>
        <Stack
          justifyContent="space-between"
          divider={<Divider sx={{ backgroundColor: '#EDEEF0' }} />}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: '24px',
            }}
          >
            <Box sx={{ fontWeight: 700, fontSize: '18px', lineHeight: '125%' }}>
              {activeEquipment ? 'Edit' : 'Add'} Equipment
            </Box>
            <IconButton
              onClick={handleClose}
              sx={{ p: 0 }}
              data-testid="close-equipment-drawer"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ p: '24px' }}>
            <AddEquipmentForm
              cseCarrierID={cseCarrierID}
              mcNumber={mcNumber}
              dotNumber={dotNumber}
              activeEquipment={activeEquipment}
              onViewMatchedShipments={handleClose}
              onUpdated={handleUpdated}
            />
          </Box>
        </Stack>
      </Paper>
    </Drawer>
  );
};

export default AddEquipmentDrawer;
