import { Grid, Typography } from '@mui/material';

import Button from 'components/SteelUI/atoms/Button';
import { Check as CheckIcon } from 'components/icons/Check';
import { FormSubmitMessage } from 'components/library/form/FormSubmitMessage';

type Props = {
  open: boolean;
  id: string;
  onClose: () => void;
};
const SuccessDialog = ({ id, open, onClose }: Props) => {
  return (
    <FormSubmitMessage
      open={open}
      message={{
        message: (
          <>
            <Typography
              style={{ marginBottom: 16, wordBreak: 'break-all' }}
              variant="h4"
            >
              Quote ID: {id}
            </Typography>
            <p>
              Your quote request has been sent to a quote analyst. During
              business hours of <b>8:00 AM to 7:30 PM ET</b>, you can expect to
              receive a response within an hour of submittal.
            </p>
            <p>
              Please contact us at <b>(888) 858-0648</b> and refer to this quote
              ID if you have any questions.
            </p>
          </>
        ),
        title: (
          <Grid container justifyContent="center">
            <CheckIcon />
          </Grid>
        ),
        buttons: (
          <Grid container justifyContent="center">
            <Button variant="outlined" onClick={() => window.location.reload()}>
              CLOSE
            </Button>
          </Grid>
        ),
      }}
      onClose={onClose}
    />
  );
};

export default SuccessDialog;
