import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { CardHeader, Divider, IconButton, Stack } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Body } from 'components/SteelUI/atoms/typography';
import AutoAwardTooltip from 'features/cse/AutoAward/components/AutoAwardTooltip';
import AutoAwardModal from 'features/cse/AutoAward/components/AutoAwardModal';

import type { DigitalFreightAward } from 'features/cse/AutoAward/types';

type Props = {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  digitalFreightAward?: DigitalFreightAward;
  carrierId: string;
  shipmentId: string;
  isStrategic: boolean;
};

const Title = ({
  digitalFreightAward,
  carrierId,
  shipmentId,
  isStrategic,
}: {
  digitalFreightAward: Props['digitalFreightAward'];
  carrierId: string;
  shipmentId: string;
  isStrategic: boolean;
}) => {
  const { rl3808UpdateOfferSentDisplayInCse, rl3811ManualAwards } = useFlags();

  if (rl3811ManualAwards && isStrategic) {
    return (
      <Stack
        direction="row"
        spacing={2}
        height="16px"
        divider={<Divider orientation="vertical" />}
      >
        <Body size="small">Shipment ID</Body>
        <AutoAwardModal
          autoAward={digitalFreightAward}
          carrierId={carrierId}
          shipmentId={shipmentId}
        />
      </Stack>
    );
  } else if (digitalFreightAward?.notifiedAt) {
    return (
      <Stack
        direction="row"
        spacing={2}
        height="16px"
        divider={<Divider orientation="vertical" />}
      >
        <Body size="small">Shipment ID</Body>
        {rl3808UpdateOfferSentDisplayInCse && digitalFreightAward ? (
          <AutoAwardModal
            autoAward={digitalFreightAward}
            carrierId={carrierId}
            shipmentId={shipmentId}
          />
        ) : (
          <AutoAwardTooltip {...digitalFreightAward} />
        )}
      </Stack>
    );
  }
  return (
    <Stack
      direction="row"
      spacing={2}
      height="16px"
      divider={<Divider orientation="vertical" />}
    >
      <Body size="small">Shipment ID</Body>
    </Stack>
  );
};

const Expand = ({
  expanded,
  setExpanded,
}: {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}) => {
  return (
    <IconButton disableRipple onClick={() => setExpanded(!expanded)}>
      {expanded ? <ExpandLess /> : <ExpandMore />}
    </IconButton>
  );
};

const Header = ({
  expanded,
  setExpanded,
  digitalFreightAward,
  carrierId,
  shipmentId,
  isStrategic,
}: Props) => {
  return (
    <CardHeader
      title={
        <Title
          digitalFreightAward={digitalFreightAward}
          carrierId={carrierId}
          shipmentId={shipmentId}
          isStrategic={isStrategic}
        />
      }
      titleTypographyProps={{ fontSize: 14, lineHeight: '125%' }}
      action={<Expand expanded={expanded} setExpanded={setExpanded} />}
      sx={{ pb: 0 }}
    />
  );
};

export default Header;
