import { DialogContent, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ButtonV2 from 'components/library/ButtonV2';
import { useDispositionContext } from 'features/cse/disposition/contexts/DispositionContext';
import theme from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(3),
  },
  button: {
    padding: theme.spacing(2, 0),
  },
}));

const Success = () => {
  const classes = useStyles();
  const { closeDispositionModal } = useDispositionContext();

  return (
    <DialogContent>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
      >
        <Grid item>
          <CheckCircleIcon
            style={{ color: theme.palette.success.main }}
            fontSize="large"
          />
        </Grid>
        <Grid item>
          <Typography variant="h3">
            This interaction has been recorded
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            The carrier will continue to show in the Top Carriers list for this
            shipment.
          </Typography>
        </Grid>
        <Grid item className={classes.button}>
          <ButtonV2 onClick={() => closeDispositionModal()}>Close</ButtonV2>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default Success;
