import { Stack, Box } from '@mui/material';
import ShortFooter from 'components/ShortFooter';
import { PropsWithChildren } from 'react';

const PageBody = ({ children }: PropsWithChildren) => {
  return (
    <Stack direction="column" sx={{ p: '0 16px 16px' }}>
      <Box sx={{ paddingBottom: '24px' }}>{children}</Box>
      <ShortFooter />
    </Stack>
  );
};

export default PageBody;
