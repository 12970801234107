import axios from 'axios';
import { useMutation } from 'react-query';
import { useUserSession } from '@reibus/frontend-utility';
import { transformAddress } from '@reibus/reibus-core-utils';
import { addDays } from 'date-fns';

import { getApiRoot } from 'utils/apiUtils';

import type { AddressBreakdown } from 'request-for-quote/v2/form/AddressFieldWithPrefill';

enum CountryCodes {
  US = 'US',
  MX = 'MX',
  CA = 'CA',
}

const getCountryCode = (country?: string): CountryCodes => {
  switch (country) {
    case 'USA': {
      return CountryCodes.US;
    }
    case 'MEX': {
      return CountryCodes.MX;
    }
    case 'CAN': {
      return CountryCodes.CA;
    }
    default: {
      return CountryCodes.US;
    }
  }
};

export type Address = {
  address1?: string;
  address2?: string;
  postalCode?: string;
  city?: string;
  region?: string;
  country?: string;
};

export type QuickQuoteValues = {
  originAddressBreakdown?: AddressBreakdown;
  destinationAddressBreakdown?: AddressBreakdown;
  equipment: string[];
  pickupDate?: string;
  originAddress?: Address;
  destinationAddress?: Address;
};

type UseFreightEstimateProps = {
  onSuccess: (values: {
    estimate?: number;
    confidenceLevel: number;
    transformedOriginAddress: Address;
    transformedDestinationAddress: Address;
  }) => void;
  onError: () => void;
};

export const useFreightEstimate = ({
  onSuccess,
  onError,
}: UseFreightEstimateProps) => {
  const { data: userSession = {} } = useUserSession();
  const { isReibusUser = false } = userSession;

  return useMutation(
    async (quickQuoteValues: QuickQuoteValues) => {
      const {
        originAddressBreakdown,
        destinationAddressBreakdown,
        equipment,
        pickupDate,
        originAddress,
        destinationAddress,
      } = quickQuoteValues;

      const transformedOrigin = originAddress
        ? originAddress
        : await transformAddress(originAddressBreakdown?.addressParts, {
            location: {
              lat: originAddressBreakdown?.geometry?.location?.lat() ?? 0,
              lng: originAddressBreakdown?.geometry?.location?.lng() ?? 0,
            },
          });
      const transformedDestination = destinationAddress
        ? destinationAddress
        : await transformAddress(destinationAddressBreakdown?.addressParts, {
            location: {
              lat: destinationAddressBreakdown?.geometry?.location?.lat() ?? 0,
              lng: destinationAddressBreakdown?.geometry?.location?.lng() ?? 0,
            },
          });

      const requestData = {
        // customerName for this endpoint refers to Marketplace(Type A) or Non Marketplace(Type B) customer.  Marketplace customers are Reibus users.
        customerName: isReibusUser ? 'Type A Customer' : 'Type B Customer',
        transportType: equipment,
        currency: 'USD',
        pickupDateTime: pickupDate
          ? new Date(pickupDate).toISOString()
          : addDays(new Date(), 2).toISOString(),
        originAddress: {
          ...transformedOrigin,
          //   This lambda requires the country to be ISO31661Alpha2Code
          country: getCountryCode(transformedOrigin?.country),
        },
        destinationAddress: {
          ...transformedDestination,
          //   This lambda requires the country to be ISO31661Alpha2Code
          country: getCountryCode(transformedDestination?.country),
        },
      };

      const { data } = await axios.post(
        `${getApiRoot()}/freight-quotes/estimate`,
        requestData
      );
      return {
        ...data,
        transformedOriginAddress: transformedOrigin,
        transformedDestinationAddress: transformedDestination,
      };
    },
    {
      onSuccess,
      onError,
    }
  );
};
