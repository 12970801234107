import { useState } from 'react';
import { Launch } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import ButtonV2 from 'components/library/ButtonV2';

import SendTopShipmentsModal from './SendTopShipmentsModal';

import type { ShipmentWithScores } from 'features/cse/Carrier/types';

const useStyles = makeStyles(() => ({
  button: {
    fontWight: 400,
  },
}));

type Props = {
  carrierName?: string;
  shipmentsCount: number;
  cseCarrierID: string;
  shipments: ShipmentWithScores[];
};

const SendTopShipments = ({
  carrierName,
  shipmentsCount,
  cseCarrierID,
  shipments,
}: Props) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  return shipmentsCount > 0 ? (
    <>
      <ButtonV2
        className={classes.button}
        variant="text"
        onClick={() => setIsOpen(true)}
        endIcon={<Launch />}
      >
        Email Top Shipments
      </ButtonV2>
      <SendTopShipmentsModal
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        carrierName={carrierName}
        shipmentsCount={shipmentsCount}
        cseCarrierID={cseCarrierID}
        shipments={shipments}
      />
    </>
  ) : null;
};

export default SendTopShipments;
