import { Button, CircularProgress, Theme } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import { makeStyles } from '@mui/styles';
import { FONT_WEIGHT_BOLD } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: FONT_WEIGHT_BOLD,
    disabled: {
      color: theme.palette.grey['600'],
      backgroundColor: theme.palette.grey['100'],
    },
    borderRadius: 8,
    '&:active': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  containedPrimary: {
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  outlinedPrimary: {
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  disabled: {
    color: `${theme.palette.grey['600']} !important`,
    backgroundColor: `${theme.palette.grey['100']} !important`,
  },
  loading: {
    marginLeft: theme.spacing(2),
    color: theme.palette.grey['600'],
  },
}));

interface Props extends ButtonProps {
  isLoading?: boolean;
}

const Loading = ({ className }: { className: string }) => (
  <CircularProgress className={className} size={20} />
);

const ButtonV2 = ({
  variant = 'contained',
  disabled,
  endIcon,
  startIcon,
  isLoading = false,
  children,
  color = 'primary',
  ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <Button
      variant={variant}
      disabled={disabled || isLoading}
      startIcon={!isLoading ? startIcon : null}
      endIcon={!isLoading ? endIcon : null}
      color={color}
      disableFocusRipple
      disableElevation
      classes={{
        root: classes.root,
        containedPrimary: classes.containedPrimary,
        outlinedPrimary: classes.outlinedPrimary,
        disabled: classes.disabled,
      }}
      {...rest}
    >
      {children}
      {!isLoading ? null : <Loading className={classes.loading} />}
    </Button>
  );
};

export default ButtonV2;
