import { Box, Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  tooltip: {
    backgroundColor: '#212224',
    padding: '16px',
    borderRadius: '8px',
    fontSize: '14px',
  },
  tooltipArrow: {
    color: '#212224',
  },
}));

const Tooltip = ({ children, ...props }: TooltipProps) => {
  const { classes } = useStyles();
  return (
    <MuiTooltip
      placement="top"
      arrow
      classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
      {...props}
    >
      <Box>{children}</Box>
    </MuiTooltip>
  );
};

export default Tooltip;
