import { AxiosError } from 'axios';
import { respondToAutoAwardById } from 'features/cse/AutoAward/Confirmation/api';
import {
  DispositionReason,
  DispositionSubreason,
} from 'features/cse/disposition/api';
import { useMutation } from 'react-query';

export type DeclineDispositionOptions = {
  reason?: DispositionReason;
  subreason?: DispositionSubreason;
  subreasonExplanation?: string;
  comments?: string;
};

type UseRespondProps = {
  onSuccess: () => void;
  onError: (error: AxiosError) => void;
  id: string;
};

const useRespondToAutoAward = ({ onSuccess, onError, id }: UseRespondProps) => {
  return {
    accept: useMutation(
      async () => {
        return await respondToAutoAwardById({
          id,
          disposition: 'COVER',
        });
      },
      {
        onSuccess,
        onError,
      }
    ),
    decline: useMutation(
      async (declineDispositionOptions: DeclineDispositionOptions) => {
        return await respondToAutoAwardById({
          id,
          disposition: 'DECLINE',
          declineDispositionOptions,
        });
      },
      {
        onSuccess,
        onError,
      }
    ),
  };
};

export default useRespondToAutoAward;
