import { useFlags } from 'launchdarkly-react-client-sdk';

import OfferSent from 'features/cse/AutoAward/components/AutoAwardModal/AutoAwardModalFlows/OfferSent';
import SendOffer from 'features/cse/AutoAward/components/AutoAwardModal/AutoAwardModalFlows/SendOffer';

import { DigitalFreightAward } from 'features/cse/AutoAward/types';

type Props = {
  autoAward?: DigitalFreightAward;
  carrierId: string;
  shipmentId: string;
  manualAwardSent: boolean;
  setManualAwardSent: (manualAwardSent: boolean) => void;
};

const AutoAwardModalFlows = ({
  autoAward,
  carrierId,
  shipmentId,
  manualAwardSent,
  setManualAwardSent,
}: Props) => {
  const { notifiedAt = '' } = autoAward || {};
  const { rl3811ManualAwards } = useFlags();

  if (autoAward && notifiedAt) {
    return <OfferSent autoAward={autoAward} />;
  }

  if (rl3811ManualAwards && !notifiedAt) {
    return (
      <SendOffer
        autoAward={autoAward}
        carrierId={carrierId}
        shipmentId={shipmentId}
        manualAwardSent={manualAwardSent}
        setManualAwardSent={setManualAwardSent}
      />
    );
  }
  return null;
};

export default AutoAwardModalFlows;
