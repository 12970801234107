import { Tab as MuiTab } from '@mui/material';
import { TabProps } from '@mui/material/Tab/Tab';

const Tab = (props: TabProps) => {
  return (
    <MuiTab
      disableRipple
      {...props}
      sx={{
        textTransform: 'none',
        color: '#212224',
        fontWeight: 400,
        '&.Mui-selected': {
          color: '#212224',
          fontWeight: 700,
        },
        '&.Mui-disabled': {
          color: '#CACDD1',
          fontWeight: 400,
        },
        ...props.sx,
      }}
    />
  );
};

export default Tab;
