import { useState } from 'react';
import { ShipmentDispositionData } from 'features/cse/disposition/api';
import { trackEvent } from 'utils/mixpanel';
import useRespondToAutoAward from '../../hooks/useMutateAutoAward';
import DeclineShipmentForm from './DeclineShipmentForm';
import StateScreen from './StateScreen';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type Screen = 'submit' | 'success' | 'error';

const useStyles = makeStyles()({
  innerContent: {
    backgroundColor: '#FFF',
    borderRadius: '8px',
    padding: '24px',
  },
  stateScreen: {
    backgroundColor: '#FFF',
    borderRadius: '8px',
  },
});

const DeclineShipment = ({
  id,
  onClose,
}: {
  id: string;
  onClose: (show: boolean) => void;
}) => {
  const [screen, setScreen] = useState<Screen>('submit');
  const { classes } = useStyles();

  const {
    decline: { mutate: submit },
  } = useRespondToAutoAward({
    onSuccess: () => {
      setScreen('success');
    },
    onError: () => {
      setScreen('error');
    },
    id: id,
  });

  const handleDeclineShipment = async ({
    reason,
    subreason,
    subreasonExplanation,
    comments,
  }: Pick<
    ShipmentDispositionData,
    'reason' | 'subreason' | 'subreasonExplanation' | 'comments'
  >) => {
    submit({
      reason,
      subreason,
      subreasonExplanation,
      comments,
    });
    trackEvent('Award Confirmation - Decline Shipment', {
      reason,
      subreason,
      subreasonExplanation,
      comments,
    });
  };

  switch (screen) {
    case 'submit':
      return (
        <Box className={classes.innerContent}>
          <DeclineShipmentForm
            declineShipmentFunction={handleDeclineShipment}
            onClose={() => onClose(true)}
          />
        </Box>
      );
    case 'success':
      return (
        <Box className={classes.stateScreen}>
          <StateScreen onClose={() => onClose(false)} screen={screen} />
        </Box>
      );
    case 'error':
    default:
      return (
        <Box className={classes.stateScreen}>
          <StateScreen onClose={() => onClose(true)} screen={screen} />
        </Box>
      );
  }
};

export default DeclineShipment;
