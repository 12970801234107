import { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Stack,
  Theme,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import useCarrierContactInfo from 'hooks/useCarrierContactInfo';
import TurvoContact from './TurvoContact';
import EquipmentContact from './EquipmentContact';
import { makeStyles } from '@mui/styles';

import type { Equipment } from 'features/cse/Carrier/types';

const useStyles = makeStyles((theme: Theme) => ({
  contactItem: {
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  },
}));

const contactIndicatorTypes: Record<
  string,
  { indicators: string[]; order: number }
> = {
  dat: { indicators: ['DAT'], order: 1 },
  its: { indicators: ['ITS'], order: 2 },
  req: {
    indicators: ['REIBUS_MANUAL', 'REIBUS_AUTOMATIC'],
    order: 0,
  },
};

type Props = {
  cseCarrierID: string;
  mcNumber?: string;
  dotNumber?: string;
  equipmentWithContact?: Equipment[];
  isOpen: boolean;
  handleClose: () => void;
  equipmentLoading: boolean;
  isTurvoContact: boolean;
};

const ContactDrawer = ({
  cseCarrierID,
  equipmentWithContact,
  isOpen,
  handleClose,
  equipmentLoading,
  isTurvoContact,
}: Props) => {
  const classes = useStyles();

  const { data: carrierContactInfo } = useCarrierContactInfo(
    cseCarrierID,
    isOpen
  );
  const [equipmentContacts, setEquipmentContacts] = useState<Equipment[]>([]);

  useEffect(() => {
    if (!equipmentLoading && equipmentWithContact?.length) {
      const sortedContacts: Equipment[] = [];
      Object.keys(contactIndicatorTypes)
        .sort(
          (a, b) =>
            contactIndicatorTypes[a].order - contactIndicatorTypes[b].order
        )
        .forEach((key) => {
          const { indicators } = contactIndicatorTypes[key];
          const equipmentTypeContacts = equipmentWithContact?.filter(
            (equipment) => indicators.includes(equipment.capacityIndicatorType)
          );
          if (equipmentTypeContacts?.length) {
            sortedContacts.push(...equipmentTypeContacts);
          }
        });

      setEquipmentContacts(sortedContacts);
    }
  }, [equipmentLoading, equipmentWithContact]);

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose} hideBackdrop>
      <Paper sx={{ width: '400px', boxShadow: 'none' }}>
        <Stack
          justifyContent="space-between"
          divider={<Divider sx={{ backgroundColor: '#EDEEF0' }} />}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: '16px',
            }}
          >
            <Box sx={{ fontWeight: 500, fontSize: '24px' }}>
              Contact Information
            </Box>
            <IconButton onClick={handleClose} sx={{ p: 0 }}>
              <FontAwesomeIcon icon={faXmark} />
            </IconButton>
          </Box>
          <Stack divider={<Divider sx={{ backgroundColor: '#EDEEF0' }} />}>
            {carrierContactInfo && isTurvoContact ? (
              <Box className={classes.contactItem}>
                <TurvoContact carrierContactInfo={carrierContactInfo} />
              </Box>
            ) : null}
            {equipmentContacts?.map((equipmentContact) => (
              <Box
                className={classes.contactItem}
                key={`equip-contact-${equipmentContact.id}`}
              >
                <EquipmentContact equipmentContact={equipmentContact} />
              </Box>
            ))}
            <Box />
          </Stack>
        </Stack>
      </Paper>
    </Drawer>
  );
};

export default ContactDrawer;
