import { useJsApiLoader } from '@react-google-maps/api';
import { createContext, ReactNode, useContext } from 'react';

type GoogleMapsScriptContextType = {
  isLoaded: boolean;
};

const GoogleMapsScriptContext = createContext<GoogleMapsScriptContextType>({
  isLoaded: false,
});

type Library =
  | 'places'
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'visualization';
const LIBRARIES: Library[] = ['places'];

const GoogleMapsScriptProvider = ({ children }: { children: ReactNode }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    libraries: LIBRARIES,
  });

  return (
    <GoogleMapsScriptContext.Provider value={{ isLoaded }}>
      {children}
    </GoogleMapsScriptContext.Provider>
  );
};

const useGoogleMapsScript = () => {
  const context = useContext(GoogleMapsScriptContext);
  if (context === undefined) {
    throw new Error(
      'useGoogleMapsScript must be used within a GoogleMapsScriptProvider'
    );
  }
  return context;
};

export { GoogleMapsScriptProvider, useGoogleMapsScript };
