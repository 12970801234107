import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from 'tss-react/mui';

import { Body } from 'components/SteelUI/atoms/typography';

type SupportingTextProps = {
  text?: string;
  error?: string;
  disabled?: boolean;
  required?: boolean;
};

const useStyles = makeStyles<Pick<SupportingTextProps, 'error' | 'disabled'>>()(
  ({ palette }, { error, disabled }) => ({
    root: {
      padding: '0 1rem',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 1.25,
      color: error
        ? palette.error.main
        : disabled
        ? palette.text.disabled
        : palette.text.primary,
    },
    icon: {
      paddingRight: 8,
      '& svg': {
        verticalAlign: '-0.2em', // line up inline icon with text
      },
    },
  })
);

const SupportingText = ({
  error,
  disabled,
  required,
  text,
}: SupportingTextProps) => {
  const { classes } = useStyles({ error, disabled });
  return (
    <Body size="small" className={classes.root}>
      {!error ? null : (
        <FontAwesomeIcon
          icon={faCircleExclamation}
          fontSize={16}
          className={classes.icon}
        />
      )}
      {!!error && required && <span>*&nbsp;</span>}
      {error || text}
    </Body>
  );
};

export default SupportingText;
