import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useState } from 'react';

export type Data<T> = Pick<TableCellProps, 'align'> & {
  key?: keyof T;
  formatting?: (data: unknown) => string | JSX.Element | null;
  header: string;
  trunkText?: boolean;
};

type DataTableProps<T> = {
  data: Data<T>[];
  rows: T[];
  keyData: keyof T;
};

type RowTableProps<T> = Pick<DataTableProps<T>, 'data'> & {
  row: T;
};

const useStyles = makeStyles(({ spacing, palette }) => ({
  title: {
    fontWeight: 700,
    marginBottom: 8,
    fontSize: spacing(3),
  },
  table: {
    border: `1px solid ${palette.grey[100]}`,
    marginBottom: spacing(3),
  },
  tableHead: {
    background: palette.grey[100],
    height: spacing(5.5),
  },
  columns: {
    fontWeight: 600,
    width: 125,
  },
  content: {
    border: `1px solid ${palette.grey[100]}`,
  },
  tableRow: {
    '&:hover': {
      backgroundColor: '#F2F7FF',
    },
  },
  tableCell: {
    padding: spacing(1.5, 2, 1.5, 2),
    wordWrap: 'break-word',
  },
  tableActionsCell: {
    width: '3%',
    padding: 0,
    textAlign: 'center',
  },
  cellExpanded: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
}));

const Row = <T,>({ row, data }: RowTableProps<T>) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const getData = <T,>(row: T, data: Data<T>) => {
    const { key, formatting } = data;
    const attribute = key ? row[key] : row;
    return formatting ? formatting(attribute) : attribute?.toString();
  };

  return (
    <TableRow className={clsx(classes.tableRow)}>
      {data.map((cell) => (
        <TableCell
          className={clsx(
            classes.content,
            classes.tableCell,
            cell.header === 'actions' && classes.tableActionsCell
          )}
          align={cell.align}
          key={String(cell.key)}
          onClick={() => setExpanded(!expanded)}
        >
          <div
            className={clsx(
              cell.trunkText && !expanded && classes.cellExpanded
            )}
          >
            {getData<T>(row, cell)}
          </div>
        </TableCell>
      ))}
    </TableRow>
  );
};

export const DataTable = <T,>({ data, rows, keyData }: DataTableProps<T>) => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Grid>
        <Table className={classes.table} style={{ tableLayout: 'fixed' }}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {data.map(({ header, align }) => (
                <TableCell
                  className={clsx(
                    classes.tableCell,
                    classes.columns,
                    header === 'actions' && classes.tableActionsCell
                  )}
                  align={align}
                  key={header}
                >
                  {header !== 'actions' ? header : null}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <Row key={`table-row-${row[keyData]}`} data={data} row={row} />
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};
