import { useState } from 'react';
import { Theme, Grid, Typography, Paper, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageWrapper from 'components/PageWrapper';
import { trackEvent } from 'utils/mixpanel';
import QuoteEstimate from './quote-estimate';
import QuoteInformation from './quote-information';
import { useHistory, useLocation } from 'react-router-dom';
import { FreightQuoteRequest } from 'api/request-for-quote';
import {
  postBookShipmentPublic,
  postBookShipmentPrivate,
} from 'api/quote-details';
import {
  FormSubmitMessage,
  Message,
} from 'components/library/form/FormSubmitMessage';
import Button from 'components/library/Button';
import { Check as CheckIcon } from 'components/icons/Check';
import { useAuthorizationToken } from '@reibus/frontend-utility';
import { Body } from 'components/SteelUI/atoms/typography';

const useStyles = makeStyles((theme: Theme) => ({
  pageWrap: {
    position: 'relative',
    backgroundColor: '#f5f5f5',
    paddingBottom: theme.spacing(4),
  },
  header: {
    zIndex: 1,
    padding: `${theme.spacing(2)} ${theme.spacing(11)}`,
    backgroundColor: '#fff',
    width: '100%',
    height: theme.spacing(16),
  },
  container: {
    marginTop: theme.spacing(3),
    padding: `0px ${theme.spacing(10)}`,
  },
  listContainer: {
    height: '100%',
  },
  estimateContainer: {
    padding: `${theme.spacing(3)} 0px`,
    height: '100%',
  },
  successButton: {
    padding: `${theme.spacing(1)} ${theme.spacing(5)}`,
  },
  link: {
    color: '#2D6CCB',
    textDecoration: 'none',
  },
  title: {
    margin: 0,
    marginTop: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(1),
  },
  [theme.breakpoints.down(900)]: {
    container: {
      position: 'relative',
      zIndex: 1,
      top: `-${theme.spacing(10)}`,
    },
    header: {
      height: theme.spacing(20),
    },
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      padding: `0 ${theme.spacing(2)}`,
      top: `-${theme.spacing(12)}`,
    },
    header: {
      padding: theme.spacing(2),
    },
    title: {
      marginTop: 0,
      fontSize: 24,
    },
    estimateContainer: {
      padding: `${theme.spacing(0)} 0px`,
    },
    pageWrap: {
      paddingBottom: 0,
    },
  },
}));

type location = {
  state: FreightQuoteRequest;
};

const QuoteDetails = () => {
  const classes = useStyles();
  const [message, setMessage] = useState<Message | undefined>();
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { state }: location = useLocation();
  const mobile = useMediaQuery('(max-width: 900px)');
  const history = useHistory();
  const authToken = useAuthorizationToken();

  const clickBookIt = async () => {
    if (state.quickForm) {
      history.push({
        pathname: `/request-for-quote`,
        state: {
          bookShipment: state.quickForm,
          originSystem: state.originSystem,
          equipment: state.equipment,
          originAddressPlaceId: state.originAddressPlaceId,
          destinationAddressPlaceId: state.destinationAddressPlaceId,
          pickupDate: state.pickupDate,
        },
      });
    } else {
      try {
        setSubmitting(true);
        const data = {
          id: state.id || '',
        };
        let res;
        if (authToken) {
          res = await postBookShipmentPrivate(authToken, data);
        } else {
          res = await postBookShipmentPublic(data);
        }
        const successMessage = generateSuccessMessage(res.data.id);
        setMessage(successMessage);
        setMessageDialogOpen(true);
        trackEvent('Booked a Freight Quote', {
          data,
        });
      } catch (e) {
        handleErrorDialogOpen(new Error('Unable to Book Freight Quote'));
      } finally {
        setSubmitting(false);
      }
    }
  };

  const generateSuccessMessage = (id: string) => ({
    message: (
      <>
        <Typography
          style={{ marginBottom: 16, wordBreak: 'break-all' }}
          variant="h4"
        >
          Quote ID: {id}
        </Typography>
        <p>
          An email has been sent to you regarding this request for your personal
          records.
        </p>
        <p>
          A Reibus Logistics Representative will reach out to you shortly to
          confirm the load details and the rate.
        </p>
        <p>
          Please contact us at <b>(888) 858-0648</b> or
          <a className={classes.link} href="mailto:shippersupport@reibus.com">
            {' '}
            shippersupport@reibus.com{' '}
          </a>{' '}
          and refer to this quote ID if you have any questions.
        </p>
      </>
    ),
    title: (
      <Grid container justifyContent="center">
        <CheckIcon />
      </Grid>
    ),
    buttons: (
      <Grid container justifyContent="center">
        <Button
          kind="secondary"
          color="secondary"
          type="button"
          onClick={() => handleMessageDialogClose()}
          className={classes.successButton}
        >
          CLOSE
        </Button>
      </Grid>
    ),
  });

  const handleErrorDialogOpen = (err: Error) => {
    setMessage({
      title: (
        <Grid container justifyContent="center">
          Error
        </Grid>
      ),
      message: (
        <>
          <p>Error Booking request: {err.message}</p>
        </>
      ),
    });
    setMessageDialogOpen(true);
  };

  const handleMessageDialogClose = () => {
    setMessageDialogOpen(false);
    setMessage(undefined);
    history.push({
      pathname: `/request-for-quote`,
    });
  };

  return (
    <PageWrapper showShortFooter>
      <div className={classes.pageWrap}>
        <Grid container>
          <header className={classes.header}>
            <div>
              <Typography className={classes.title} variant="h2">
                QUOTE DETAILS
              </Typography>
              {state.quickForm ? (
                <Body size="large" className={classes.subtitle}>
                  Review Quote Details
                </Body>
              ) : null}
            </div>
          </header>
          <Grid
            container
            className={classes.container}
            spacing={mobile ? 3 : 2}
          >
            <Grid item xs={mobile ? 12 : 4}>
              <Paper className={classes.listContainer}>
                <QuoteInformation quoteData={state} />
              </Paper>
            </Grid>
            <Grid item xs={mobile ? 12 : 8}>
              <Paper className={classes.estimateContainer}>
                <QuoteEstimate
                  quoteData={state}
                  bookit={clickBookIt}
                  submitting={submitting}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {message ? (
        <FormSubmitMessage
          open={messageDialogOpen}
          message={message}
          onClose={handleMessageDialogClose}
        />
      ) : null}
    </PageWrapper>
  );
};

export default QuoteDetails;
