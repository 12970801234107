import { useFlags } from 'launchdarkly-react-client-sdk';
import LaneHistoryV1 from './LaneHistory';
import LaneHistoryV2 from './LaneHistoryV2';
import { CarrierDetailResponse } from 'shipment/api';

type LaneHistoryProps = Pick<CarrierDetailResponse, 'cseCarrierID'>;

const LaneHistory = ({ cseCarrierID }: LaneHistoryProps) => {
  const {
    rl2951OptimizeCarrierPagePaginateCseTopShipments: backendPaginationFlag,
  } = useFlags();
  return backendPaginationFlag ? (
    <LaneHistoryV2 cseCarrierID={cseCarrierID} />
  ) : (
    <LaneHistoryV1 cseCarrierID={cseCarrierID} />
  );
};

export default LaneHistory;
