import { ChangeEvent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, TextField as MuiTextField } from '@mui/material';
import NumberFormat from 'react-number-format';
import { Field } from 'react-final-form';
import { FormFieldError } from './FormFieldError';
import { stringValidator } from './fieldValidators';

const useNumberFieldStyles = makeStyles((theme: Theme) => ({
  input: {
    width: '100%',
  },
  inputLabel: {
    fontFamily:
      'Lato, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    fontWeight: 700,
    marginBottom: '1rem',
    color: '#939393',
    width: '100%',
    fontSize: theme.typography.body2.fontSize,
    '&.MuiInputLabel-shrink': {
      transform: 'scale(1.0)',
    },
    '&.MuiInputLabel-formControl': {
      top: '-0.75rem',
    },

    '&.Mui-focused': {
      color: '#939393',
    },
  },
  asterisk: {
    color: '#c62828',
    fontSize: '1.2rem',
  },
}));

type NumberFormatCustomProps = {
  inputRef: (ref: HTMLInputElement | null) => void;
  onChange: (event: { target: { name: string; value: number } }) => void;
  name: string;
  decimalScale: number;
  thousandSeparator: boolean;
  allowNegative: boolean;
  prefix: string;
};

const NumberFormatCustom = ({
  inputRef,
  onChange,
  name,
  decimalScale,
  thousandSeparator,
  allowNegative,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  prefix,
  ...other
}: NumberFormatCustomProps) => {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: Number(values.value),
          },
        });
      }}
      name={name}
      thousandSeparator={thousandSeparator}
      allowNegative={allowNegative}
      prefix={prefix}
      decimalScale={decimalScale}
    />
  );
};

type NumberFieldProps = {
  placeholder?: string;
  name: string;
  label: string;
  required?: boolean;
  validator?: (value: string) => string | void;
  maxLength?: number;
  thousandSeparator?: boolean;
  prefix?: string;
  decimalScale?: number;
  hasMissingFields?: boolean;
  allowNegative?: boolean;
  value?: number;
  disabled?: boolean;
};

export const NumberField = ({
  name,
  placeholder,
  label,
  required = true,
  validator = stringValidator,
  maxLength = 200,
  thousandSeparator,
  prefix,
  decimalScale,
  allowNegative = false,
  value = null,
  disabled = false,
}: NumberFieldProps) => {
  const classes = useNumberFieldStyles();
  const validate = required ? validator : undefined;
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
          input.onChange(event.target.value || '');

        const placeholderProps = placeholder ? { shrink: true } : {};

        return (
          <div>
            <MuiTextField
              id={name}
              label={label}
              placeholder={placeholder}
              className={classes.input}
              variant="outlined"
              required={required}
              name={input.name}
              value={value || input.value}
              disabled={disabled}
              onChange={handleChange}
              error={false}
              inputProps={{ maxLength }}
              InputProps={{
                // eslint-disable-next-line
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                  name,
                  thousandSeparator,
                  prefix,
                  decimalScale,
                  allowNegative,
                },
              }}
              InputLabelProps={{
                ...placeholderProps,
                classes: {
                  asterisk: classes.asterisk,
                },
              }}
              onFocus={input.onFocus}
              onBlur={input.onBlur}
            />

            <FormFieldError meta={meta} />
          </div>
        );
      }}
    </Field>
  );
};
