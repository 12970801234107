import { Divider, Stack, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { H1, H3, H4, H5 } from 'components/SteelUI/atoms/typography';
import { displayFormattingValue } from '@reibus/reibus-core-utils';
import TargetRate from './TargetRate';

import type { CurrentRate } from 'features/LogisticsQuoteManager/hooks/useGetCurrentRates';

const displayFormattingOptions = {
  trailingZeros: false,
  decimalPlaces: 0,
};

const formatRate = (rate?: number) => {
  return !rate
    ? 'N/A'
    : displayFormattingValue(rate, displayFormattingOptions, 'USD');
};

const useStyles = makeStyles(({ spacing }: Theme) => ({
  mileage: {
    marginTop: spacing(2),
  },
}));

type Props = {
  datRate?: CurrentRate;
  reibusRate?: CurrentRate;
  distance?: number;
  freightQuoteRequestId?: string;
};

const getAverage = (
  fieldToAverage: 'lowRate' | 'highRate',
  datRate?: CurrentRate,
  reibusRate?: CurrentRate
) => {
  const valuesToAverage = [
    datRate?.[fieldToAverage],
    reibusRate?.[fieldToAverage],
  ].filter((rate): rate is number => rate !== undefined);

  if (!valuesToAverage.length) {
    return { displayValue: 'N/A', value: 0 };
  }
  const average =
    valuesToAverage.reduce((sum, rate) => sum + rate, 0) /
    valuesToAverage.length;
  return { displayValue: `${formatRate(average)}`, value: average };
};

const Rates = ({
  datRate,
  reibusRate,
  distance,
  freightQuoteRequestId,
}: Props) => {
  const classes = useStyles();

  const blendedLow = getAverage('lowRate', datRate, reibusRate);
  const blendedHigh = getAverage('highRate', datRate, reibusRate);
  const blendedAverage = Math.round((blendedHigh.value + blendedLow.value) / 2);

  return (
    <Stack direction="column" padding="24px 0 0 0" spacing="24px">
      <Stack direction="column">
        <H5 weight="medium" color="#64676B">
          DAT - BROKER TO CARRIER SPOT
        </H5>
        <H5 color="#64676B">Includes Fuel</H5>
        <Stack
          direction="row"
          spacing="24px"
          paddingTop="12px"
          alignItems="baseline"
        >
          <H1 weight="semibold">{formatRate(datRate?.rate)}</H1>
          <H3>
            ({formatRate(datRate?.lowRate)}
            &nbsp;-&nbsp;
            {formatRate(datRate?.highRate)})
          </H3>
        </Stack>
      </Stack>
      <Stack direction="column">
        <H5 weight="medium" color="#64676B">
          REIBUS RATES
        </H5>
        <H5 color="#64676B">Includes Fuel</H5>
        <Stack
          direction="row"
          spacing="24px"
          paddingTop="12px"
          alignItems="baseline"
        >
          <H1 weight="semibold">{formatRate(reibusRate?.rate)}</H1>
          <H3>
            ({formatRate(reibusRate?.lowRate)}
            &nbsp;-&nbsp;
            {formatRate(reibusRate?.highRate)})
          </H3>
        </Stack>
      </Stack>
      <Divider />
      <Stack direction="column">
        <H5 color="#64676B">BLENDED TARGET RATE RANGE</H5>
        <H1 weight="semibold">
          {blendedLow.displayValue} - {blendedHigh.displayValue}
        </H1>
        <H4 weight="semibold" color="#64676B" className={classes.mileage}>
          Mileage
        </H4>
        <H4>
          {!distance
            ? 'N/A'
            : displayFormattingValue(distance, {
                trailingZeros: false,
                decimalPlaces: 0,
              }) ?? 'N/A'}
        </H4>
      </Stack>
      <TargetRate
        blendedAverage={blendedAverage}
        freightQuoteRequestId={freightQuoteRequestId}
      />
    </Stack>
  );
};

export default Rates;
