import LoadingTable from 'features/cse/LoadingTable';
import NoResults from '../../NoResults';
import { DataTable, Data } from 'components/SteelUI/organisms/DataTable';
import Pagination from 'components/SteelUI/organisms/Pagination';
import { useEffect, useState } from 'react';
import {
  formatDate,
  formatEquipmentType,
  formatEquipmentLength,
  formatSource,
  formatEquipmentLocation,
} from 'features/cse/Carrier/utils/data';
import EquipmentDropDownTableActions from './EquipmentDropDownTableActions';
import DeleteEquipmentModal from './DeleteEquipmentModal';
import { ContactTooltip } from './ContactTooltip';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AddEquipmentDrawer from 'features/cse/AddEquipment/AddEquipmentDrawer';
import { LoadBoardType } from 'features/cse/AddEquipment/api';

import type { Equipment } from 'features/cse/Carrier/types';
const RowsPerPage = 20;

type AvailableEquipmentTableProps = {
  isLoading: boolean;
  equipment: Equipment[];
  cseCarrierID: string;
  dotNumber?: string;
  mcNumber?: string;
};

export const AvailableEquipmentTable = ({
  isLoading,
  equipment,
  cseCarrierID,
  dotNumber,
  mcNumber,
}: AvailableEquipmentTableProps) => {
  const {
    rl2154CarrierPageEnableDeleteReqEquipment = false,
    rl2398CseReqShowContactInformationPerEquipment = false,
    rl2145CseReqEdit = false,
  } = useFlags();
  const [page, setPage] = useState(0);
  const [activeEquipment, setActiveEquipment] = useState<Equipment | undefined>(
    equipment[0]
  );
  const [updatedEquipment, setUpdatedEquipment] = useState<
    string | undefined
  >();
  const [isDeleteEquipmentModalOpen, setIsDeleteEquipmentModalOpen] =
    useState(false);
  const [isEditingEquipmentModalOpen, setIsEditingEquipmentModalOpen] =
    useState(false);
  const [availableEquipmentRows, setAvailableEquipmentRows] = useState<
    Equipment[]
  >([]);

  const showTableActions = rl2154CarrierPageEnableDeleteReqEquipment;

  const getMenuItems = (data: Equipment): string[] => {
    const allowedEquipmentToEdit = [
      LoadBoardType.REIBUS_AUTOMATIC,
      LoadBoardType.REIBUS_MANUAL,
    ];
    const menuItems: string[] = [];
    if (rl2154CarrierPageEnableDeleteReqEquipment) {
      menuItems.push('Delete');
    }
    if (
      rl2145CseReqEdit &&
      allowedEquipmentToEdit.includes(data.capacityIndicatorType)
    ) {
      menuItems.push('Edit');
    }
    return menuItems;
  };

  const handleActionsSelection = (
    selectedEquipment: Equipment,
    action: string
  ) => {
    if (action === 'Delete') {
      setActiveEquipment(selectedEquipment);
      setIsDeleteEquipmentModalOpen(true);
    }
    if (action === 'Edit') {
      setActiveEquipment(selectedEquipment);
      setIsEditingEquipmentModalOpen(true);
    }
  };

  useEffect(() => {
    const begin = page * RowsPerPage;
    const end = begin + RowsPerPage;
    const rows = equipment?.slice(begin, end) ?? [];
    setAvailableEquipmentRows(rows);
  }, [page, equipment]);

  useEffect(() => {
    if (equipment?.length && updatedEquipment) {
      setActiveEquipment(
        equipment?.find((equipment) => equipment.id === updatedEquipment)
      );
    }
  }, [updatedEquipment, equipment]);

  const flaggedColumns = [
    rl2398CseReqShowContactInformationPerEquipment ? '' : 'Contact',
    showTableActions ? '' : 'actions',
  ];

  const tableData: Data<Equipment & { actions?: string }>[] = [
    {
      header: 'Equipment Type',
      key: 'equipmentType',
      formatting: formatEquipmentType,
    },
    {
      header: 'Origin',
      key: 'EquipmentLocation_EquipmentToEquipmentLocation',
      formatting: formatEquipmentLocation,
    },
    {
      header: 'Desired Destination',
      key: 'EquipmentLocation_DestinationEquipment',
      formatting: formatEquipmentLocation,
    },
    {
      header: 'Equipment Length',
      align: 'right',
      key: 'length',
      formatting: formatEquipmentLength,
    },
    {
      header: 'Date Available',
      key: 'startDate',
      formatting: formatDate,
      align: 'right',
    },
    {
      header: 'Source',
      key: 'capacityIndicatorType',
      formatting: formatSource,
    },
    {
      header: 'Contact',
      formatting: ContactTooltip,
    },
    {
      header: 'Comments',
      key: 'comments',
      trunkText: true,
    },
    {
      header: 'actions',
      formatting: (data: Equipment) => (
        <EquipmentDropDownTableActions
          equipment={data}
          handleSelection={handleActionsSelection}
          menuItems={getMenuItems(data)}
        />
      ),
    },
  ].filter(
    ({ header }) => header && !flaggedColumns.includes(header)
  ) as Data<Equipment>[];

  return (
    <>
      {isLoading ? (
        <LoadingTable columns={tableData.length} />
      ) : equipment.length === 0 ? (
        <NoResults description="No available equipment." />
      ) : (
        <>
          <DataTable
            data={tableData}
            rows={availableEquipmentRows}
            keyData="id"
          />
          <Pagination
            count={equipment?.length ?? 0}
            page={page}
            changePage={setPage}
            rowsPerPage={RowsPerPage}
          />
        </>
      )}
      <DeleteEquipmentModal
        isOpen={isDeleteEquipmentModalOpen}
        onClose={() => setIsDeleteEquipmentModalOpen(false)}
        equipment={activeEquipment as Equipment}
        cseCarrierID={cseCarrierID}
      />

      <AddEquipmentDrawer
        cseCarrierID={cseCarrierID}
        mcNumber={mcNumber}
        dotNumber={dotNumber}
        isOpen={isEditingEquipmentModalOpen}
        handleClose={() => {
          setIsEditingEquipmentModalOpen(false);
          setActiveEquipment(undefined);
          setUpdatedEquipment(undefined);
        }}
        activeEquipment={activeEquipment}
        handleUpdated={() => setUpdatedEquipment(activeEquipment?.id)}
      />
    </>
  );
};
