import { ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, DialogTitle, DialogContent, Dialog, Theme } from '@mui/material';
import Button from '../Button';

const useStyles = makeStyles((theme: Theme) => ({
  titleWrap: {
    paddingTop: theme.spacing(6),
  },
  contentWrap: {
    padding: `0px ${theme.spacing(6)} ${theme.spacing(8)} ${theme.spacing(6)}`,
  },
  errors: {
    marginBottom: theme.spacing(3),
    '& > p': {
      marginBottom: theme.spacing(3),
      marginTop: 0,
    },
  },
  [theme.breakpoints.down('sm')]: {
    contentWrap: {
      padding: `0px ${theme.spacing(3)} ${theme.spacing(8)} ${theme.spacing(
        3
      )}`,
    },
  },
}));

export type Message = {
  message: ReactNode;
  title: ReactNode;
  buttons?: ReactNode;
};

type FormSubmitMessageProps = {
  message: Message;
  open: boolean;
  onClose: () => void;
};

export const FormSubmitMessage = ({
  message,
  open,
  onClose,
}: FormSubmitMessageProps) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      aria-labelledby="form-dialog-title"
      data-testid="form-message"
      onClose={onClose}
    >
      <DialogTitle className={classes.titleWrap} id="form-dialog-title">
        {message.title}
      </DialogTitle>
      <DialogContent className={classes.contentWrap}>
        <Box className={classes.errors}>{message.message}</Box>
        <Box>
          {message.buttons ? (
            message.buttons
          ) : (
            <Button
              onClick={onClose}
              kind="primary"
              color="primary"
              type="submit"
            >
              Close
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
