import axios from 'axios';
import { ALGOLIA_INDEX_SHIPMENTS, algoliaClient } from 'utils/algolia';

import { getApiRoot } from 'utils/apiUtils';

import type { Coordinate } from 'api/types';
import type { DigitalFreightAward } from 'features/cse/AutoAward/types';

type WeightUnit = 'POUND';

export type CarrierLabel = 'STRATEGIC';

export type ShipmentDetailsResponse = {
  id: string;
  shipmentTMSCompanies: {
    tmsID: number;
    tmsName: string;
  };
  label: string;
  equipmentNeeded: EquipmentServices[];
  equipmentNeededDesc: string;
  services: EquipmentServices[];
  commodities: Commodities[];
  netWeight?: number;
  netWeightUnit: WeightUnit;
  minPrice: number;
  maxPrice: number;
  targetPay: number;
  totalShipperCost?: number;
  loadboardPosting: LoadboardPosting[];
};

type EquipmentServices = {
  code: number;
  description: string;
};

type Commodities = {
  name: string;
  quantity: string;
};

type OtherShipmentsQueryType = 'similar' | 'multi';

type LoadboardPosting = {
  datePosted: string;
  loadboard: string;
  postingId: string;
  rate: string;
  shipmentId: string;
  status: string;
};

const getShipmentDetails = async (
  authorization: string,
  shipmentId: string
): Promise<ShipmentDetailsResponse> => {
  const url = `${getApiRoot()}/shipments/${shipmentId}`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const response = await axios.get(url, config);

  if (response.status !== 200) {
    throw new Error(response.statusText);
  }

  return response.data;
};

type CompositeScore = {
  shipmentId: string;
  carriers: CompositeCarrierScore[];
};

type ScoreResult = {
  [calculationName: string]: number | null;
};

type RankedEquipment = {
  equipmentID: string;
  loadBoardType: 'DAT' | 'ITS' | 'REIBUS';
};

type CompositeCarrierScore = {
  cseCarrierID: string;
  carrierName: string;
  totalScore: number;
  componentScores: ScoreResult[];
  assetIDs?: string[];
  carrierRankId: string;
  scoringTime: string;
  indicators: {
    key: string;
    name: string;
    weight: number;
    totalScore: number;
  }[];
  rankedEquipment: RankedEquipment[];
  labels: CarrierLabel[];
  digitalFreightAward?: DigitalFreightAward;
};

const getCompositeScore = async (
  authorization: string,
  shipmentId: string,
  filterBy: string[] = []
): Promise<CompositeScore> => {
  const filters = filterBy.length ? '?filter=' + filterBy.join(',') : '';
  const url = `${getApiRoot()}/carriers/composite-score/${shipmentId}${filters}`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const { data } = await axios.get(url, config);

  return {
    shipmentId: data.shipmentId,
    carriers: data.carriers.slice(0, 50),
  };
};

type OfferContact = { email: string };

type CarrierContactInfo = {
  cseCarrierID: string;
  carrierID: string;
  carrierName: string;
  accountOwnerName: string;
  phoneNumber: string;
  email: string;
  offerContacts: OfferContact[];
};

const getCarrierContactInfo = async (
  authorization: string,
  cseCarrierID: string
): Promise<CarrierContactInfo> => {
  const url = `${getApiRoot()}/carriers/${cseCarrierID}/contact-info`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const { status, statusText, data } = await axios.get(url, config);

  if (status !== 200) {
    throw new Error(statusText);
  }

  return data;
};

type EquipmentContactInfo = {
  assetID: string;
  posterName: string;
  callbackPhone: string;
  contactEmail: string;
  capacityIndicatorType: 'DAT' | 'ITS' | 'REIBUS_MANUAL' | 'REIBUS_AUTO';
  carrierName?: string;
};

const getEquipmentContactInfo = async (
  authorization: string,
  assetIDs: string[]
): Promise<EquipmentContactInfo[]> => {
  if (!assetIDs || !assetIDs.length) {
    return [];
  }

  const url = `${getApiRoot()}/carriers/equipment/contact-info`;
  const config = {
    headers: {
      Authorization: authorization,
    },
    params: {
      equipmentIDs: assetIDs.join(','),
    },
  };
  const { status, statusText, data } = await axios.get(url, config);

  if (status !== 200) {
    throw new Error(statusText);
  }

  return data;
};

enum CarrierStatus {
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SUSPENDED = 'SUSPENDED',
}

export type CarrierSource = 'DAT' | 'ITS' | 'TURVO' | 'External';

export type SchedulingType =
  | 'BY_APPOINTMENT'
  | 'FCFS'
  | 'TURVO_APPOINTMENT_SCHEDULING';

export type HeadquarterDetail = {
  address1: string;
  city: string;
  region: string;
  postalCode: string;
  country: string;
};

type CarrierDetailResponse = {
  carrierID?: string;
  cseCarrierID: string;
  carrierMCNumber?: string;
  carrierDOTNumber?: string;
  accountOwnerName?: string;
  headquarter: HeadquarterDetail;
  status?: CarrierStatus | null;
  source: CarrierSource;
  carrierName?: string;
  labels?: string[];
};

const getCarrierDetailInfo = async (
  authorization: string,
  cseCarrierIDs: string
): Promise<CarrierDetailResponse[]> => {
  const url = `${getApiRoot()}/carriers/${cseCarrierIDs}/detail-info`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const { status, statusText, data } = await axios.get(url, config);

  if (status !== 200) {
    throw new Error(statusText);
  }

  return data;
};

type Address = {
  address1: string;
  city: string;
  region: string;
  postalCode: string;
};

type DateTimeDetailed = {
  date?: string;
  datetime: string;
  timezone: string;
  flex?: number;
  schedulingType?: SchedulingType;
};

type DateDetailed = {
  date: string;
  timeZone: string;
  flex?: number;
  schedulingType: SchedulingType;
};

type Stop = {
  address: Address;
  coordinates: Coordinate;
  date: DateTimeDetailed;
  name: string;
  notes?: string;
};

type MultiStop = Pick<Stop, 'address'> & {
  address: Pick<Address, 'city' | 'region' | 'postalCode'>;
};

type LocationDetails = {
  pickup: Stop;
  delivery: Stop;
  distance: number;
  route: MultiStop[];
};

const getLocationDetails = async (
  authorization: string,
  shipmentId: string
): Promise<LocationDetails> => {
  const url = `${getApiRoot()}/shipments/${shipmentId}/location-details`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const { status, statusText, data } = await axios.get(url, config);

  if (status !== 200) {
    throw new Error(statusText);
  }

  const { pickup, delivery, distance, route } = data;
  return {
    pickup: {
      address: pickup.address,
      coordinates: {
        lat: Number(pickup.coordinates.lat),
        lon: Number(pickup.coordinates.lon),
      },
      date: {
        datetime: pickup.date.datetime,
        flex: Number(pickup.date.flex),
        timezone: pickup.date.timezone,
        schedulingType: pickup.date.schedulingType,
      },
      name: pickup.name,
      notes: pickup.notes,
    },
    delivery: {
      address: delivery.address,
      coordinates: {
        lat: Number(delivery.coordinates.lat),
        lon: Number(delivery.coordinates.lon),
      },
      date: {
        datetime: delivery.date.datetime,
        flex: Number(delivery.date.flex),
        timezone: delivery.date.timezone,
        schedulingType: delivery.date.schedulingType,
      },
      name: delivery.name,
      notes: delivery.notes,
    },
    distance: Number(distance),
    route,
  };
};

type Shipment = {
  shipmentID: string;
  originAddress: {
    city: string;
    region: string;
  } | null;
  destinationAddress: {
    city: string;
    region: string;
  } | null;
  shipmentTMSCompanies: Customer[];
  startDate: string;
  endDate?: string;
  totalCarrierCost: number;
  startDateDetailed?: DateDetailed;
  endDateDetailed?: DateDetailed;
  distanceOrigin?: number;
  distanceDropOff?: number;
};

type Customer = {
  tmsName: string;
};

const getLaneHistoryShipments = async (
  authorization: string,
  cseCarrierId: string,
  featureFlag: boolean,
  prismaId?: string
): Promise<Shipment[]> => {
  const endpointRoute = featureFlag
    ? `/carriers/${cseCarrierId}/lane-history/shipment/${prismaId}`
    : `/shipments/carrier/${cseCarrierId}`;
  const url = `${getApiRoot()}${endpointRoute}`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const { data } = await axios.get(url, config);

  data.sort((a: Shipment, b: Shipment) =>
    b.startDate.localeCompare(a.startDate)
  );

  return data.sort();
};

const getOtherShipments = async (
  authorization: string,
  shipmentId: string,
  queryType: OtherShipmentsQueryType
): Promise<Shipment[]> => {
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const route = `shipments/${shipmentId}/other/${queryType}`;

  const url = `${getApiRoot()}/${route}`;
  try {
    const { data } = await axios.get(url, config);

    return data;
  } catch (e) {
    console.error(
      `Error fetching other shipments: id/query type - ${shipmentId}/${queryType} - ${e}`
    );
    return [];
  }
};

const getAlgoliaShipment = (shipmentId: string) => {
  const shipmentsAlgoliaIndex = algoliaClient.initIndex(
    ALGOLIA_INDEX_SHIPMENTS
  );
  return shipmentsAlgoliaIndex.search(shipmentId, {
    filters: `shipmentID:${shipmentId}`,
    attributesToRetrieve: [
      'tentativelyCoveredUntil',
      'tentativelyCoveredBy',
      'globalRoute',
    ],
  });
};

export {
  getShipmentDetails,
  getCompositeScore,
  getCarrierContactInfo,
  getEquipmentContactInfo,
  getCarrierDetailInfo,
  getLocationDetails,
  getLaneHistoryShipments,
  getAlgoliaShipment,
  getOtherShipments,
  CarrierStatus,
};
export type {
  OtherShipmentsQueryType,
  CompositeScore,
  CompositeCarrierScore,
  CarrierContactInfo,
  ScoreResult,
  EquipmentContactInfo,
  CarrierDetailResponse,
  LocationDetails,
  Address,
  DateTimeDetailed,
  EquipmentServices,
  Commodities,
  WeightUnit,
  Shipment,
  DateDetailed,
  RankedEquipment,
  MultiStop,
  LoadboardPosting,
};
