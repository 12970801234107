import { GoogleMapsScriptProvider } from 'hooks/useGoogleMapsScript';
import Shipment from 'shipment';

const CSEShipmentPage = () => {
  return (
    <GoogleMapsScriptProvider>
      <Shipment />
    </GoogleMapsScriptProvider>
  );
};

export default CSEShipmentPage;
