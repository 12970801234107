import { Container, Theme, Box, Grid } from '@mui/material';
import type { ShipmentWithScores } from 'features/cse/Carrier/types';
import { makeStyles } from '@mui/styles';
import { TooltipEllipsis } from 'components/TooltipEllipsis';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: 'rgba(246,247,247, 0.4)',
    maxWidth: '100%',
    borderRadius: 8,
    padding: theme.spacing(2),
  },
  header: {
    fontWeight: 600,
    fontSize: 16,
    marginBottom: theme.spacing(1),
  },
}));

type TopShipments = {
  shipment: ShipmentWithScores;
};

const TopShipmentExpanded = ({ shipment }: TopShipments) => {
  const classes = useStyles();
  const {
    services,
    commodities,
    netWeight,
    netWeightUnit,
    minPrice,
    maxPrice,
  } = shipment;

  return (
    <Container className={classes.container}>
      <Grid
        container
        columns={7}
        justifyContent="space-between"
        sx={{ pr: '64px' }}
      >
        <Grid item xs={1}>
          <Box className={classes.header}>Services:</Box>
          <Box>
            {services.length > 0 ? (
              <TooltipEllipsis
                hasTwoLines
                textValue={services
                  .map((service) => service.description)
                  .join(', ')}
              />
            ) : (
              'N/A'
            )}
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box className={classes.header}>Commodity:</Box>
          <Box>
            {commodities.length > 0 ? (
              <TooltipEllipsis
                hasTwoLines
                textValue={commodities
                  .map((commodity) => commodity.name)
                  .join(', ')}
              />
            ) : (
              'N/A'
            )}
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box className={classes.header}>Weight:</Box>
          <Box>{netWeight ? `${netWeight} ${netWeightUnit}` : 'N/A'}</Box>
        </Grid>
        <Grid item xs={1}>
          <Grid container>
            <Grid item xs>
              <Box className={classes.header}>Min pay:</Box>
              <Box>{minPrice ? `$${minPrice}` : 'N/A'}</Box>
            </Grid>
            <Grid item xs>
              <Box className={classes.header}>Max pay:</Box>
              <Box>{maxPrice ? `$${maxPrice}` : 'N/A'}</Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1} />
        <Grid item xs={1} />
      </Grid>
    </Container>
  );
};

export default TopShipmentExpanded;
