import { Theme, Container, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { faTruck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    justifyContent: 'center',
    width: 'auto',
    marginTop: theme.spacing(3),
    padding: 0,
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
  },
  equipmentContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  equipmentValue: {
    marginLeft: theme.spacing(2),
    textTransform: 'capitalize',
  },
  listContainer: {
    padding: 0,
    display: 'flex',
  },
  containerTablet: {
    width: '50%',
  },
  truck: {
    color: '#2D6CCB',
    width: '1.5rem',
    height: '1.5em',
  },
  [theme.breakpoints.down(900)]: {
    container: {
      marginTop: 0,
    },
  },
}));

type props = {
  equipment: string[];
};

const Equipment = ({ equipment }: props) => {
  const classes = useStyles();
  const tablet = useMediaQuery('(min-width:500px) and (max-width:900px)');
  const tabletClass = tablet ? classes.containerTablet : null;
  return (
    <Container className={clsx(classes.container, tabletClass)}>
      <Typography className={classes.title} variant="h5">
        Equipment
      </Typography>
      <Container className={classes.listContainer}>
        {equipment.map((value) => (
          <div key={value} className={classes.equipmentContainer}>
            <FontAwesomeIcon className={classes.truck} icon={faTruck} />
            <span className={classes.equipmentValue}>
              {value.toLowerCase().replace('_', ' ')}
            </span>
          </div>
        ))}
      </Container>
    </Container>
  );
};

export default Equipment;
