import { ChangeEvent } from 'react';
import { makeStyles } from '@mui/styles';
import {
  FormControlLabel as MuiFormControlLabel,
  RadioGroup as MuiRadioGroup,
  Radio as MuiRadio,
  FormLabel as MuiFormLabel,
  Theme,
} from '@mui/material';
import { Field } from 'react-final-form';
import { Typography } from '@mui/material';
import { booleanValidator } from './fieldValidators';
import { RadioOption } from './types';

const useRadioStyle = makeStyles((theme: Theme) => ({
  asterisk: {
    color: '#c62828',
    fontSize: '1.2rem',
  },
}));

type RadioProps = {
  name: string;
  label: string;
  options: RadioOption[];
  displayAsRow?: boolean;
  validator?: (value: boolean) => string | void;
  labelClassName?: string;
  required?: boolean;
};

export const Radio = ({
  name,
  label,
  options,
  displayAsRow = true,
  validator = booleanValidator,
  labelClassName,
  required = false,
}: RadioProps) => {
  const classes = useRadioStyle();
  const validate = required ? validator : undefined;

  return (
    <Field name={name} validate={validate}>
      {({ input }) => {
        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
          const val = event.target.value;
          // if the value is a string 'true', then make it that
          // if the value is a string 'false', then make it that
          // otherwise, keep it as whatever type it was, and set the value (this covers everything else)
          input.onChange(val === 'true' ? true : val === 'false' ? false : val);
        };

        return (
          <div>
            <MuiFormLabel
              className={labelClassName}
              id="demo-radio-buttons-group-label"
              required={required}
              classes={{
                asterisk: classes.asterisk,
              }}
            >
              {label}
            </MuiFormLabel>
            <MuiRadioGroup
              name={label}
              value={input.value}
              onChange={handleChange}
              row={displayAsRow}
            >
              {options.map((option) => {
                const { value, label } = option;
                return (
                  <MuiFormControlLabel
                    value={value}
                    key={label}
                    control={<MuiRadio />}
                    label={<Typography variant="caption">{label}</Typography>}
                  />
                );
              })}
            </MuiRadioGroup>
          </div>
        );
      }}
    </Field>
  );
};
