import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

import NoResults from 'search-shipments/search-shipments-results/NoResults';
import Pagination from 'search-shipments/search-shipments-results/Pagination';
import { useSearchShipmentsContext } from 'search-shipments/SearchShipmentsContext';

import type { HitsProps } from 'search-shipments/search-shipments-form/utils';
import Sorting, {
  SortingProps,
} from 'search-shipments/search-shipments-results/Sorting';
import { trackEvent } from 'utils/mixpanel';
import { Launch } from '@mui/icons-material';
import ButtonV2 from 'components/library/ButtonV2';
import { uniq } from 'lodash';
import { TooltipEllipsis } from 'components/TooltipEllipsis';
import { useFlags } from 'launchdarkly-react-client-sdk';

const shortMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    padding: theme.spacing(3),
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
  },
  tableRow: {
    backgroundColor: '#FFFFFF !important',
    '&:hover': {
      backgroundColor: '#F2F7FF !important',
    },
  },
  tableHead: {
    backgroundColor: `${theme.palette.grey[100]} !important`,
  },
  tableCell: {
    padding: `12px 16px !important`,
  },
  table: {
    border: `1px solid ${theme.palette.grey[50]}`,
    tableLayout: 'fixed',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  textLink: {
    fontWeight: 400,
  },
}));

const headCells: SortingProps[] = [
  {
    id: 'shipmentID',
    label: 'Shipment ID',
    align: 'right',
    sortable: true,
  },
  {
    id: 'startLocationLabel',
    label: 'Origin',
    align: 'left',
    sortable: true,
  },
  {
    id: 'endLocationLabel',
    label: 'Destination',
    align: 'left',
    sortable: true,
  },
  {
    id: 'pickupDate',
    label: 'Pickup',
    align: 'right',
    sortable: true,
  },
  {
    id: 'deliveryDate',
    label: 'Delivery',
    align: 'right',
    sortable: true,
  },
  {
    id: 'distance',
    label: 'Distance',
    align: 'right',
    sortable: true,
  },
  {
    id: 'equipmentType',
    label: 'Equipment Type',
    align: 'left',
    sortable: true,
  },
  {
    id: 'customerName',
    label: 'Customer',
    align: 'left',
    sortable: false,
  },
];

const equipmentNames = [
  {
    code: 1,
    value: 'Flatbed',
  },
  {
    code: 2,
    value: 'Dry Van',
  },
  {
    code: 3,
    value: 'Reefer',
  },
  {
    code: 4,
    value: 'Container',
  },
  {
    code: 5,
    value: 'Hopper Bottom',
  },
  {
    code: 6,
    value: 'Conestoga',
  },
  {
    code: 7,
    value: 'Tanker, Steel',
  },
  {
    code: 8,
    value: 'Pneumatic',
  },
  {
    code: 9,
    value: 'Step Deck',
  },
  {
    code: 10,
    value: 'Dump Trailer',
  },
  {
    code: 11,
    value: 'Hotshot',
  },
  {
    code: 12,
    value: 'Drop Deck, Landoll',
  },
  {
    code: 13,
    value: 'Removable Gooseneck',
  },
  {
    code: 14,
    value: 'Stretch Trailer',
  },
  {
    code: 15,
    value: 'Double Drop',
  },
  {
    code: 16,
    value: 'Auto Carrier',
  },
  {
    code: 17,
    value: 'Power Only',
  },
  {
    code: 18,
    value: 'Container Trailer',
  },
  {
    code: 19,
    value: 'Van, Hotshot',
  },
  {
    code: 20,
    value: 'Van w/ Curtains',
  },
  {
    code: 21,
    value: 'Van, Vented',
  },
  {
    code: 22,
    value: 'Other',
  },
];

function CustomTableHead() {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow className={classes.tableHead}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            colSpan={1}
            className={classes.tableCell}
          >
            <Sorting {...headCell} />
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const formatDate = (date: string) => {
  const newDate = new Date(date);
  return `${
    shortMonths[newDate.getUTCMonth()]
  } ${newDate.getUTCDate()}, ${newDate.getUTCFullYear()}`;
};

const getEquipmentName = (equipments: number[]) => {
  if (!equipments) {
    return 'N/A';
  }

  const response = equipments
    .map((codeEquipment: number) => {
      const equipment = equipmentNames.find(
        (name) => name.code === codeEquipment
      );
      if (equipment) return equipment.value;
    })
    .filter((a) => a);
  return uniq(response).join(', ');
};

const ResultsTable = () => {
  const { results, customers } = useSearchShipmentsContext();
  const { rl2220CsePagesUrlsAndTitles } = useFlags();

  if (results === undefined) {
    return null;
  }
  const { hits = [], nbHits, hitsPerPage } = results;
  const classes = useStyles();

  const getCustomerName = (customerId: number) => {
    return customers.get(String(customerId)) ?? 'N/A';
  };

  return !hits.length ? (
    <NoResults />
  ) : (
    <Paper className={classes.tableContainer}>
      <TableContainer>
        <Table className={classes.table}>
          <CustomTableHead />
          <TableBody>
            {hits.map((row: HitsProps) => {
              return (
                <TableRow
                  hover
                  key={row.shipmentID}
                  className={classes.tableRow}
                >
                  <TableCell className={classes.tableCell} align={'right'}>
                    <Link
                      to={
                        !rl2220CsePagesUrlsAndTitles
                          ? `/shipment/${row.shipmentID}`
                          : `/cse/shipment/${row.shipmentID}`
                      }
                      target="_blank"
                      onClick={() =>
                        trackEvent(
                          'Clicked a shipment link in shipment search results on cse',
                          { shipmentID: row.shipmentID }
                        )
                      }
                      className={classes.link}
                    >
                      <ButtonV2
                        variant="text"
                        className={classes.textLink}
                        endIcon={<Launch />}
                      >
                        {row.shipmentID}
                      </ButtonV2>
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.startLocationLabel}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.endLocationLabel}
                  </TableCell>
                  <TableCell className={classes.tableCell} align={'right'}>
                    {formatDate(row.pickupDate)}
                  </TableCell>
                  <TableCell className={classes.tableCell} align={'right'}>
                    {formatDate(row.deliveryDate)}
                  </TableCell>
                  <TableCell className={classes.tableCell} align={'right'}>
                    {row.distance ? row.distance : 'N/A'}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <TooltipEllipsis
                      textValue={getEquipmentName(row.equipmentType)}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {getCustomerName(row.customerId)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination count={nbHits} rowsPerPage={hitsPerPage} />
    </Paper>
  );
};

export default ResultsTable;
export { equipmentNames, getEquipmentName, formatDate };
