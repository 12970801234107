import { Grid, Paper, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  icon: {
    fontSize: '5rem',
  },
}));

const NoResults = () => {
  const classes = useStyles();

  return (
    <Paper>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid item>
          <SearchIcon className={classes.icon} />
        </Grid>
        <Grid item>
          <Typography variant="h3">No Results Found</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            No results match the search criteria. Please try again.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default NoResults;
