export type ShipmentStatusType = {
  displayValue: string;
  code: string;
};

export const getShipmentStatusCode = (shipmentStatus: ShipmentStatusType) =>
  shipmentStatus.code;

class ShipmentStatus {
  public static QuoteActive: ShipmentStatusType = {
    displayValue: 'Quote active',
    code: '2100',
  };
  public static Tendered: ShipmentStatusType = {
    displayValue: 'Tendered',
    code: '2101',
  };
  public static Covered: ShipmentStatusType = {
    displayValue: 'Covered',
    code: '2102',
  };
  public static Dispatched: ShipmentStatusType = {
    displayValue: 'Dispatched',
    code: '2103',
  };
  public static AtPickup: ShipmentStatusType = {
    displayValue: 'At pickup',
    code: '2104',
  };
  public static EnRoute: ShipmentStatusType = {
    displayValue: 'En route',
    code: '2105',
  };
  public static AtDelivery: ShipmentStatusType = {
    displayValue: 'At delivery',
    code: '2106',
  };
  public static RouteComplete: ShipmentStatusType = {
    displayValue: 'Route complete',
    code: '2116',
  };
  public static Delivered: ShipmentStatusType = {
    displayValue: 'Delivered',
    code: '2107',
  };
  public static ReadyForBilling: ShipmentStatusType = {
    displayValue: 'Ready for billing',
    code: '2108',
  };
  public static Processing: ShipmentStatusType = {
    displayValue: 'Processing',
    code: '2109',
  };
  public static CarrierPaid: ShipmentStatusType = {
    displayValue: 'Carrier paid',
    code: '2110',
  };
  public static CustomerPaid: ShipmentStatusType = {
    displayValue: 'Customer paid',
    code: '2111',
  };
  public static Completed: ShipmentStatusType = {
    displayValue: 'Completed',
    code: '2112',
  };
  public static Void: ShipmentStatusType = {
    displayValue: 'Void',
    code: '2113',
  };
  public static QuoteInactive: ShipmentStatusType = {
    displayValue: 'Quote-inactive',
    code: '2114',
  };
  public static PickedUp: ShipmentStatusType = {
    displayValue: 'Picked up',
    code: '2115',
  };
  public static TenderOffered: ShipmentStatusType = {
    displayValue: 'Tender-offered',
    code: '2117',
  };
  public static TenderAccepted: ShipmentStatusType = {
    displayValue: 'Tender-accepted',
    code: '2118',
  };
  public static TenderRejected: ShipmentStatusType = {
    displayValue: 'Tender-rejected',
    code: '2119',
  };
  public static Draft: ShipmentStatusType = {
    displayValue: 'Draft',
    code: '2120',
  };

  public static InProgress = [
    ShipmentStatus.Tendered,
    ShipmentStatus.Covered,
    ShipmentStatus.Dispatched,
    ShipmentStatus.AtPickup,
    ShipmentStatus.EnRoute,
    ShipmentStatus.AtDelivery,
    ShipmentStatus.Delivered,
    ShipmentStatus.PickedUp,
  ];
  public static MoveCompleted = [
    ShipmentStatus.ReadyForBilling,
    ShipmentStatus.Processing,
    ShipmentStatus.CarrierPaid,
    ShipmentStatus.CustomerPaid,
    ShipmentStatus.Completed,
    ShipmentStatus.RouteComplete,
  ];
  public static Excluded = [
    ShipmentStatus.Void,
    ShipmentStatus.TenderOffered,
    ShipmentStatus.TenderAccepted,
    ShipmentStatus.TenderRejected,
    ShipmentStatus.QuoteInactive,
    ShipmentStatus.QuoteActive,
    ShipmentStatus.Draft,
  ];
  public static ReadyForBill = [
    ShipmentStatus.EnRoute,
    ShipmentStatus.AtDelivery,
    ShipmentStatus.Delivered,
    ShipmentStatus.RouteComplete,
    ShipmentStatus.ReadyForBilling,
    ShipmentStatus.Processing,
    ShipmentStatus.Completed,
  ];
  public static DeliveredStatuses: ShipmentStatusType[] = [
    ShipmentStatus.Delivered,
    ShipmentStatus.ReadyForBilling,
    ShipmentStatus.Processing,
    ShipmentStatus.CarrierPaid,
    ShipmentStatus.CustomerPaid,
    ShipmentStatus.Completed,
    ShipmentStatus.Void,
    ShipmentStatus.QuoteInactive,
    ShipmentStatus.RouteComplete,
  ];

  public static InProgressStatusCodes = ShipmentStatus.InProgress.map(
    getShipmentStatusCode
  );
  public static MoveCompletedStatusCodes = ShipmentStatus.MoveCompleted.map(
    getShipmentStatusCode
  );
  public static ExcludedStatusCodes = ShipmentStatus.Excluded.map(
    getShipmentStatusCode
  );
  public static DeliveredStatusCodes = ShipmentStatus.DeliveredStatuses.map(
    getShipmentStatusCode
  );
  public static ReadyForBillStatusCodes = ShipmentStatus.ReadyForBill.map(
    getShipmentStatusCode
  );

  public static getByCode = (code: string): ShipmentStatus => {
    const match = ShipmentStatus.All.find((status) => status.code === code);
    if (!match) {
      throw new Error(`Could not find ShipmentStatus with code ${code}`);
    }
    return match;
  };

  static All: ShipmentStatusType[] = [
    ShipmentStatus.QuoteActive,
    ShipmentStatus.Tendered,
    ShipmentStatus.Covered,
    ShipmentStatus.Dispatched,
    ShipmentStatus.AtPickup,
    ShipmentStatus.EnRoute,
    ShipmentStatus.AtDelivery,
    ShipmentStatus.RouteComplete,
    ShipmentStatus.Delivered,
    ShipmentStatus.ReadyForBilling,
    ShipmentStatus.Processing,
    ShipmentStatus.CarrierPaid,
    ShipmentStatus.CustomerPaid,
    ShipmentStatus.Completed,
    ShipmentStatus.Void,
    ShipmentStatus.QuoteInactive,
    ShipmentStatus.PickedUp,
    ShipmentStatus.TenderOffered,
    ShipmentStatus.TenderAccepted,
    ShipmentStatus.TenderRejected,
    ShipmentStatus.Draft,
  ];
}

export default ShipmentStatus;
