import QuoteDetails from 'quote-details';
import { GoogleMapsScriptProvider } from 'hooks/useGoogleMapsScript';

const FreightQuoteDetailsPage = () => {
  return (
    <GoogleMapsScriptProvider>
      <QuoteDetails />
    </GoogleMapsScriptProvider>
  );
};

export default FreightQuoteDetailsPage;
