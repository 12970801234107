import { Form, FormSpy } from 'react-final-form';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Box, Stack } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router-dom';
import { useAuthorizationToken } from '@reibus/frontend-utility';
import { FormApi } from 'final-form';
import { format } from 'date-fns';

import ButtonV2 from 'components/library/ButtonV2';
import { TextField } from 'components/library/form/TextField';
import { createEquipment, EquipmentLocation, updateEquipment } from './api';
import { AddressField } from 'components/library/form/AddressField';
import { useEffect, useState } from 'react';
import { NumberField } from 'components/library/form/NumberField';
import { DateField } from 'components/library/form/DateField';
import { TextBoxField } from 'components/library/form/TextBoxField';
import { AutoSelectSingle } from 'components/library/form/AutoSelectSingle';
import { PhoneField } from 'components/library/form/PhoneField';
import { EmailField } from 'components/library/form/EmailField';
import { NameField } from 'components/library/form/NameField';
import LoadingModal from 'components/library/LoadingModal';
import { formatEquipmentLocation } from '../Carrier/utils/data';
import { trackEvent } from 'utils/mixpanel';

import { EQUIPMENT_TYPE_OPTIONS } from './constants';
import { convertPlaceIdToEquipmentLocation } from './utils';
import AddEquipmentNotification from './AddEquipmentNotification';

import type { CreateEquipmentPayload } from './api';
import type {
  Notification,
  NotificationResult,
} from './AddEquipmentNotification';
import type { Equipment } from 'features/cse/Carrier/types';

type Props = {
  cseCarrierID: string;
  mcNumber?: string;
  dotNumber?: string;
  onViewMatchedShipments: () => void;
  activeEquipment: Equipment | undefined;
  onUpdated?: () => void;
};

const AddEquipmentForm = ({
  cseCarrierID,
  mcNumber,
  dotNumber,
  activeEquipment,
  onViewMatchedShipments,
  onUpdated,
}: Props) => {
  const { rl2951OptimizeCarrierPagePaginateCseTopShipments = false } =
    useFlags();
  const [originPlaceId, setOriginPlaceId] = useState('');
  const [destinationPlaceId, setDestinationPlaceId] = useState('');
  const [origin, setOrigin] = useState<EquipmentLocation | undefined>(
    activeEquipment?.EquipmentLocation_EquipmentToEquipmentLocation
  );
  const [destination, setDestination] = useState<EquipmentLocation | undefined>(
    activeEquipment?.EquipmentLocation_DestinationEquipment?.[0]
  );
  const [isLoadingOrigin, setIsLoadingOrigin] = useState(false);
  const [isLoadingDestination, setIsLoadingDestination] = useState(false);
  const [notification, setNotification] = useState<Notification | null>();
  const [submitting, setSubmitting] = useState(false);
  const [renderKey, setRenderKey] = useState(new Date().toISOString());
  const queryClient = useQueryClient();
  const {
    rl2588AddEquipmentFormModifications = false,
    rl2499ViewMatchedShipments = false,
    rl2671CseAllowMultipleDesiredDestinations = false,
  } = useFlags();
  const authToken = useAuthorizationToken();
  const [isScoring, setIsScoring] = useState(false);

  const { placesService } = usePlacesAutocompleteService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
  });
  const history = useHistory();

  const handleSubmit = async (
    values: Record<string, string>,
    form: FormApi<
      Record<string, string>,
      {
        mcNumber: string | undefined;
        dotNumber: string | undefined;
        availableDate: string;
        equipmentType: string | undefined;
        name: string | undefined;
        phone: string | undefined;
        email: string | undefined;
        comments: string | undefined;
        length: string | undefined;
        weight: string | undefined;
      }
    >
  ) => {
    setSubmitting(true);
    setIsScoring(true);
    const payload = await getPayloadFromFormValues(values);
    try {
      const {
        impactedShipments: scoreCount,
        equipment,
        result,
      } = !activeEquipment
        ? await createEquipment(authToken, cseCarrierID, payload)
        : await updateEquipment(authToken, String(activeEquipment.id), payload);

      trackEvent('CSE Add Equipment', { payload, scoreCount });
      const equipmentId = activeEquipment ? activeEquipment?.id : equipment?.id;
      setNotification({
        status: 'success',
        result: result as NotificationResult,
        scoreCount,
        payload,
        equipmentId,
      });
      if (activeEquipment) onUpdated?.();
      form.reset();
      setRenderKey(new Date().toISOString());
      queryClient.invalidateQueries(['availableEquipment', cseCarrierID]);
      queryClient.invalidateQueries([
        'shipments-scored-carriers',
        cseCarrierID,
      ]);
    } catch (error) {
      setNotification({ status: 'error' });
      setOriginPlaceId('');
      setDestinationPlaceId('');
    } finally {
      setSubmitting(false);
      setIsScoring(false);
      const notify = document.getElementById('req-notification');
      if (notify) notify.scrollIntoView({ block: 'end', inline: 'nearest' });
    }
  };

  const getPayloadFromFormValues = async (
    values: Record<string, string>
  ): Promise<CreateEquipmentPayload> => {
    const startDate = new Date(values.availableDate).toISOString();
    const contactInfo = getContactInfoFromFormValues(values);
    const destination = getDestinationValue();

    const payload = {
      origin,
      destination,
      startDate,
      equipmentType: Number(values.equipmentType),
      comments: values.comments ?? null,
      weight: values.weight ? Number(values.weight) : null,
      length: values.length ? Number(values.length) : null,
      ...(contactInfo && { contactInfo }),
    } as CreateEquipmentPayload;
    return payload;
  };

  const getContactInfoFromFormValues = (values: Record<string, string>) => ({
    name: values.name ?? null,
    phone: values.phone ?? null,
    email: values.email ?? null,
  });

  const getDestinationValue = (): Partial<EquipmentLocation> | undefined => {
    if (
      activeEquipment?.EquipmentLocation_DestinationEquipment?.length &&
      !destinationPlaceId &&
      !destination
    )
      return {
        id: activeEquipment?.EquipmentLocation_DestinationEquipment[0].id,
      };
    if (activeEquipment?.EquipmentLocation_DestinationEquipment?.length)
      return {
        id: activeEquipment?.EquipmentLocation_DestinationEquipment?.[0]?.id,
        ...destination,
      };
    return destination;
  };

  //  The form uses a google maps placeId but the payload needs a location object
  //  so we use a callback to update the state of originPlaceId/destinationPlaceId
  //  and use a hook to convert them to location objects whenever a change is detected
  const onChange = (values: Record<string, string>) => {
    setOriginPlaceId(values.originPlaceId);
    setDestinationPlaceId(values.destinationPlaceId);
  };

  useEffect(() => {
    if (originPlaceId) {
      convertPlaceIdToEquipmentLocation(
        originPlaceId,
        placesService,
        setOrigin,
        setIsLoadingOrigin
      );
    }
    if (destinationPlaceId) {
      convertPlaceIdToEquipmentLocation(
        destinationPlaceId,
        placesService,
        setDestination,
        setIsLoadingDestination
      );
    }
  }, [originPlaceId, destinationPlaceId]);

  const onViewNotification = (
    data?: CreateEquipmentPayload,
    equipmentId?: string
  ) => {
    if (data) {
      const { origin: { stateProvince = '', city = '' } = {}, equipmentType } =
        data;

      const [{ label }] = EQUIPMENT_TYPE_OPTIONS.filter(
        (option) => option.id === String(equipmentType)
      );

      const queryParams = rl2951OptimizeCarrierPagePaginateCseTopShipments
        ? new URLSearchParams({
            tab: 'overview',
            equipmentId: String(equipmentId),
          }).toString()
        : new URLSearchParams({
            tab: 'overview',
            stateProvince,
            city,
            equipmentType: label,
            equipmentId: String(equipmentId),
          }).toString();
      if (location.pathname.includes('/shipment')) {
        const carrierPageLink = `/logistics/cse/carrier/${cseCarrierID}?${queryParams}`;
        window.open(carrierPageLink, '_blank');
      } else {
        history.push({
          pathname: location.pathname.replace('/logistics', ''),
          search: queryParams,
        });
      }

      onViewMatchedShipments();
    }
  };

  useEffect(() => {
    if (activeEquipment)
      setRenderKey((key) => key.concat(JSON.stringify(activeEquipment)));
  }, [activeEquipment]);

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        mcNumber,
        dotNumber,
        equipmentType: activeEquipment?.equipmentType?.code
          ? String(activeEquipment?.equipmentType?.code)
          : undefined,
        name: activeEquipment?.posterName,
        phone: activeEquipment?.callbackPhone,
        email: activeEquipment?.contactEmail,
        comments: activeEquipment?.comments,
        length: activeEquipment?.length,
        weight: activeEquipment?.weight,
        availableDate: format(
          activeEquipment?.startDate
            ? new Date(activeEquipment?.startDate)
            : new Date(),
          'MM/dd/yyyy'
        ),
      }}
    >
      {({ handleSubmit, values: dataValues }) => {
        return (
          <>
            <LoadingModal
              isOpen={rl2499ViewMatchedShipments && isScoring}
              message={[
                'Matching and scoring shipments for your equipment.',
                'This may take a few seconds.',
              ]}
            />
            <FormSpy onChange={({ values }) => onChange(values)} />
            <form onSubmit={handleSubmit}>
              <Stack spacing={2}>
                {!notification ? null : (
                  <AddEquipmentNotification
                    notification={notification}
                    closeNotification={() => setNotification(null)}
                    onView={onViewNotification}
                  />
                )}
                <TextField
                  name="mcNumber"
                  label="MC #"
                  required={false}
                  disabled
                  key={`add-equipment-form-mc-${renderKey}`}
                />
                <TextField
                  name="dotNumber"
                  label="DOT #"
                  required={false}
                  disabled
                  key={`add-equipment-form-dot-${renderKey}`}
                />
                <AutoSelectSingle
                  name="equipmentType"
                  label="Equipment Type"
                  placeholder="Equipment Type"
                  required
                  options={EQUIPMENT_TYPE_OPTIONS}
                  initialValue={EQUIPMENT_TYPE_OPTIONS.find(
                    ({ id }) =>
                      id === String(activeEquipment?.equipmentType?.code)
                  )}
                  key={`add-equipment-form-equipment-type-${renderKey}`}
                />
                <AddressField
                  name="origin"
                  label="Origin"
                  showManualLabel={false}
                  required={
                    activeEquipment && !dataValues.originPlaceId
                      ? !origin && !originPlaceId
                      : true
                  }
                  searchTypes={['(cities)', 'address']}
                  maxResults={6}
                  key={`add-equipment-form-origin-${renderKey}`}
                  initialDescription={
                    activeEquipment?.EquipmentLocation_EquipmentToEquipmentLocation
                      ? formatEquipmentLocation(
                          activeEquipment?.EquipmentLocation_EquipmentToEquipmentLocation
                        )
                      : ''
                  }
                  onClear={() => setOrigin(undefined)}
                />
                {rl2671CseAllowMultipleDesiredDestinations ? (
                  <AddressField
                    name="destination"
                    label="Desired Destination"
                    showManualLabel={false}
                    // If flag on, make this field not required
                    required={!rl2588AddEquipmentFormModifications}
                    // Regions allows the user to select a state/region without city/address
                    searchTypes={['(regions)', '(cities)', 'address']}
                    maxResults={6}
                    key={`add-equipment-form-destination-${renderKey}`}
                    initialDescription={
                      activeEquipment?.EquipmentLocation_DestinationEquipment
                        ?.length
                        ? formatEquipmentLocation(
                            activeEquipment?.EquipmentLocation_DestinationEquipment
                          )
                        : ''
                    }
                    onClear={() => setDestination(undefined)}
                  />
                ) : null}
                <NumberField
                  name="weight"
                  label="Weight (Lbs)"
                  required={false}
                  key={`add-equipment-form-weight-${renderKey}`}
                />
                <NumberField
                  name="length"
                  label="Equipment Length (ft)"
                  required={false}
                  key={`add-equipment-form-length-${renderKey}`}
                />
                <DateField
                  name="availableDate"
                  label="Available Date"
                  required
                  key={`add-equipment-form-available-${renderKey}`}
                />
                <TextBoxField
                  name="comments"
                  label="Comments"
                  maxLength={300}
                  required={false}
                  key={`add-equipment-form-comments-${renderKey}`}
                />
                <Box
                  sx={{
                    fontWeight: 700,
                    fontSize: '18px',
                    lineHeight: '125%',
                  }}
                >
                  Contact Information
                </Box>
                <NameField
                  name="name"
                  label="Name"
                  required={false}
                  key={`add-equipment-form-name-${renderKey}`}
                />
                <PhoneField
                  name="phone"
                  label="Phone"
                  required={false}
                  key={`add-equipment-form-phone-${renderKey}`}
                />
                <EmailField
                  name="email"
                  label="Email"
                  required={false}
                  key={`add-equipment-form-email-${renderKey}`}
                />
                <ButtonV2
                  type="submit"
                  disabled={isLoadingOrigin || isLoadingDestination}
                  isLoading={submitting}
                >
                  {activeEquipment ? 'Update' : 'Save'}
                </ButtonV2>
              </Stack>
            </form>
          </>
        );
      }}
    </Form>
  );
};

export default AddEquipmentForm;
