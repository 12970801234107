import { ReactNode, useState, useEffect } from 'react';
import { Grid, Card, Theme, Box, Stack, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TabContext, TabPanel } from '@mui/lab';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Launch } from '@mui/icons-material';
import theme from 'theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import PageWrapper from 'components/PageWrapper';
import PageTitle from 'components/PageTitle';
import SkeletonWrapper from 'components/library/SkeletonWrapper';
import { Tabs, Tab } from 'components/SteelUI/atoms';
import Loader from 'components/Loader';

import { trackEvent, usePageView } from 'utils/mixpanel';
import CarrierOverview from './components/CarrierOverview';
import LaneHistory from './components/LaneHistory';
import AddEquipment from 'features/cse/AddEquipment';
import AvailableEquipment from './components/AvailableEquipment';
import ButtonV2 from 'components/library/ButtonV2';
import ContactButton from './components/CarrierContactInfo/ContactButton';
import useAvailableEquipment from 'features/cse/Carrier/hooks/useAvailableEquipment';
import { useCarrier } from './hooks/useCarrier';

import type { Equipment } from './types';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  item: {
    paddingBottom: spacing(2),
  },
  card: {
    borderRadius: '8px !important',
    padding: spacing(3),
  },
  cardPanel: {
    '&.MuiTabPanel-root': {
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
    },
  },
  notFound: {
    textAlign: 'center',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  textLink: {
    fontWeight: 400,
    fontSize: 16,
    paddingTop: 0,
    paddingLeft: 0,
    lineHeight: '125%',
    marginTop: '-8px',
    '&:hover': {
      backgroundColor: '#fff',
      textDecoration: 'underline',
    },
  },
  star: {
    color: '#FFD257',
  },
}));

enum TABS_PANELS {
  OVERVIEW = 'overview',
  LANE_HISTORY = 'laneHistory',
  AVAILABLE_EQUIPMENT = 'availableEquipment',
}

const TABS = [
  {
    label: 'Overview',
    value: TABS_PANELS.OVERVIEW,
  },
  {
    label: 'Available Equipment',
    value: TABS_PANELS.AVAILABLE_EQUIPMENT,
  },
  {
    label: 'Lane History',
    value: TABS_PANELS.LANE_HISTORY,
  },
];

const CarrierPageWrapper = ({
  children,
  carrierName = '',
  cseCarrierID,
  carrierID,
  tabSelected,
  carrierSource,
  equipmentWithContact,
  equipmentLoading,
  rl2140AddEquipmentFlow = false,
  mcNumber,
  dotNumber,
  labels = [],
}: {
  children: React.ReactNode;
  carrierName?: string;
  cseCarrierID?: string;
  carrierID?: string;
  tabSelected?: string;
  carrierSource?: string;
  equipmentWithContact?: Equipment[];
  equipmentLoading: boolean;
  rl2140AddEquipmentFlow?: boolean;
  mcNumber?: string;
  dotNumber?: string;
  labels?: string[];
}) => {
  const classes = useStyles();
  const onOverviewTab = tabSelected === TABS_PANELS.OVERVIEW;
  const isTurvoContact = carrierSource === 'TURVO';

  const action =
    cseCarrierID && carrierName && rl2140AddEquipmentFlow ? (
      <>
        {onOverviewTab ? (
          <ContactButton
            cseCarrierID={cseCarrierID}
            equipmentWithContact={equipmentWithContact}
            equipmentLoading={equipmentLoading}
            isTurvoContact={isTurvoContact}
          />
        ) : null}
        <AddEquipment
          cseCarrierID={cseCarrierID}
          mcNumber={mcNumber}
          dotNumber={dotNumber}
        />
      </>
    ) : null;

  const carrierLink = `${process.env.REACT_APP_TURVO_URL}/#/accounts/${carrierID}/summary`;

  const turvoLink = (
    <a
      target="_blank"
      rel="noreferrer"
      href={carrierLink}
      className={classes.link}
    >
      <ButtonV2
        variant="text"
        className={classes.textLink}
        endIcon={<Launch />}
      >
        See in Turvo
      </ButtonV2>
    </a>
  );

  return (
    <PageWrapper showShortFooter isNewTheme title={carrierName}>
      <PageTitle
        title={
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box>{carrierName}</Box>
            {!labels.includes('STRATEGIC') ? null : (
              <Tooltip title="Strategic Carrier" placement="top">
                <FontAwesomeIcon
                  icon={icon({ name: 'star' })}
                  className={classes.star}
                  size="2xs"
                />
              </Tooltip>
            )}
          </Stack>
        }
        action={action}
        description={onOverviewTab && isTurvoContact ? turvoLink : null}
      />
      {children}
    </PageWrapper>
  );
};

const CarrierPageCard = ({
  isLoading = false,
  children,
}: {
  isLoading?: boolean;
  children: ReactNode;
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.item}>
      <Card className={classes.card}>
        <SkeletonWrapper isLoading={isLoading}>{children}</SkeletonWrapper>
      </Card>
    </Grid>
  );
};

const Carrier = () => {
  const { rl2140AddEquipmentFlow } = useFlags();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramTab = searchParams.get('tab');
  const [tabSelected, setTabSelected] = useState<string>(
    paramTab ?? TABS_PANELS.OVERVIEW
  );
  const [equipmentWithContact, setEquipmentWithContact] = useState<Equipment[]>(
    []
  );
  const { cseCarrierId: carrierIdParam } = useParams() as {
    cseCarrierId: string;
  };
  const cseCarrierId = carrierIdParam.includes('?')
    ? carrierIdParam.split('?')[0]
    : carrierIdParam;

  usePageView('Page View: Carrier', { cseCarrierId: cseCarrierId });
  const classes = useStyles();
  const history = useHistory();
  const { data: equipment, isLoading: equipmentLoading } =
    useAvailableEquipment(cseCarrierId);

  useEffect(() => {
    if (!equipmentLoading && equipment) {
      setEquipmentWithContact(
        equipment?.filter((equip) => equip.callbackPhone || equip.contactEmail)
      );
    }
  }, [equipmentLoading, equipment]);

  const handleTabChange = (_event: React.SyntheticEvent, tab: string) => {
    setTabSelected(tab);
    history.push({
      pathname: location.pathname.concat(`?tab=${tab}`),
    });
    trackEvent('Clicked a carrier tab', {
      tab: TABS.find((t) => t.value === tab)?.label,
    });
  };

  useEffect(() => {
    paramTab && setTabSelected(paramTab);
  }, [paramTab]);

  const { data: carrier, isLoading: isLoadingCarrier } =
    useCarrier(cseCarrierId);

  if (!carrier && !isLoadingCarrier) {
    return (
      <CarrierPageWrapper carrierName={'Not Found'} equipmentLoading={false}>
        <Loader hasLoaded={!isLoadingCarrier}>
          <Grid container justifyContent="center">
            <Grid item className={classes.item}>
              <Card className={classes.card}>
                <h2 className={classes.notFound}>404 Carrier not found</h2>
              </Card>
            </Grid>
          </Grid>
        </Loader>
      </CarrierPageWrapper>
    );
  }

  return (
    <CarrierPageWrapper
      carrierName={carrier?.carrierName}
      cseCarrierID={cseCarrierId}
      carrierID={carrier?.carrierID}
      tabSelected={tabSelected}
      carrierSource={carrier?.source}
      equipmentLoading={equipmentLoading}
      equipmentWithContact={equipmentWithContact}
      rl2140AddEquipmentFlow={rl2140AddEquipmentFlow}
      mcNumber={carrier?.carrierMCNumber}
      dotNumber={carrier?.carrierDOTNumber}
      labels={carrier?.labels}
    >
      <Loader hasLoaded={!isLoadingCarrier}>
        <TabContext value={tabSelected}>
          <Grid container>
            <Tabs value={tabSelected} onChange={handleTabChange}>
              {TABS.map(({ label, value }) => (
                <Tab label={label} value={value} key={`tab-${label}`} />
              ))}
            </Tabs>
            <Grid item xs={12}>
              <Box
                sx={{
                  backgroundColor: theme.palette.grey['100'],
                  padding: '24px',
                }}
              >
                <TabPanel
                  value={TABS_PANELS.OVERVIEW}
                  className={classes.cardPanel}
                >
                  <CarrierOverview cseCarrierID={cseCarrierId} />
                </TabPanel>
                <TabPanel
                  value={TABS_PANELS.LANE_HISTORY}
                  className={classes.cardPanel}
                >
                  <CarrierPageCard>
                    <LaneHistory cseCarrierID={cseCarrierId} />
                  </CarrierPageCard>
                </TabPanel>
                <TabPanel
                  value={TABS_PANELS.AVAILABLE_EQUIPMENT}
                  className={classes.cardPanel}
                >
                  <CarrierPageCard>
                    <AvailableEquipment
                      cseCarrierID={cseCarrierId}
                      equipment={equipment}
                      isLoading={equipmentLoading}
                      dotNumber={carrier?.carrierDOTNumber}
                      mcNumber={carrier?.carrierMCNumber}
                    />
                  </CarrierPageCard>
                </TabPanel>
              </Box>
            </Grid>
          </Grid>
        </TabContext>
      </Loader>
    </CarrierPageWrapper>
  );
};

export default Carrier;
