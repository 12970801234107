import { ReactNode, useEffect, useState } from 'react';

import Button from 'components/SteelUI/atoms/Button';
import { ModalContainer } from 'components/SteelUI/molecules/modals';

import useRespondToAutoAward from 'features/cse/AutoAward/Confirmation/hooks/useMutateAutoAward';
import SuccessScreen from 'features/cse/AutoAward/Confirmation/components/Accept/SuccessScreen';
import ErrorScreen from 'features/cse/AutoAward/Confirmation/components/Accept/ErrorScreen';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type Props = {
  id: string;
  showActionButtons: boolean;
  onAction: (show: boolean) => void;
};

const useStyles = makeStyles()({
  innerContent: {
    backgroundColor: '#FFF',
    borderRadius: '8px',
    padding: '24px',
  },
});

const Accept = ({ id, showActionButtons, onAction }: Props) => {
  const { classes } = useStyles();

  const handleClose = () => setStatusScreen(undefined);

  const [statusScreen, setStatusScreen] = useState<ReactNode>();

  const {
    accept: { mutate: submit, isLoading: isSubmitting },
  } = useRespondToAutoAward({
    onSuccess: () => {
      setStatusScreen(<SuccessScreen onClose={handleClose} />);
    },
    onError: (error) => {
      setStatusScreen(<ErrorScreen onClose={handleClose} error={error} />);
    },
    id: id,
  });

  return (
    <>
      {showActionButtons ? (
        <Button
          onClick={() => {
            submit();
            onAction(false);
          }}
          loading={isSubmitting}
        >
          Confirm Acceptance
        </Button>
      ) : null}
      <ModalContainer open={!!statusScreen} onClose={handleClose}>
        <Box className={classes.innerContent}>{statusScreen}</Box>
      </ModalContainer>
    </>
  );
};

export default Accept;
