import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import ButtonV2 from 'components/library/ButtonV2';

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    fontSize: 24,
    fontWeight: 400,
    color: '#212224',
    lineHeight: '30px',
    marginTop: spacing(1),
  },
  text: {
    fontSize: 16,
    fontWeight: 400,
    color: '#212224',
    lineHeight: '20px',
    marginTop: spacing(1),
    textAlign: 'center',
  },
  button: {
    width: 188,
    height: 42,
  },
  buttonContainer: {
    marginTop: spacing(4),
  },
  confirmButton: {
    marginLeft: spacing(2),
  },
  icon: {
    marginTop: spacing(2),
  },
}));

type Props = {
  handleClose: () => void;
  handleDeleteEquipment: () => void;
};

const Submit = ({ handleClose, handleDeleteEquipment }: Props) => {
  const classes = useStyles();

  return (
    <Stack justifyContent="center" alignItems="center">
      <Box className={classes.icon}>
        <FontAwesomeIcon
          icon={faCircleExclamation}
          fontSize={32}
          color={'#BB121A'}
        />
      </Box>
      <Box className={classes.title}>Delete Equipment</Box>
      <Box className={classes.text}>
        This equipment will be no longer available. Are you sure that you want
        to delete it?
      </Box>
      <Stack direction="row" className={classes.buttonContainer}>
        <ButtonV2
          className={classes.button}
          variant="outlined"
          onClick={handleClose}
        >
          No
        </ButtonV2>
        <ButtonV2
          className={clsx(classes.button, classes.confirmButton)}
          onClick={handleDeleteEquipment}
        >
          Yes
        </ButtonV2>
      </Stack>
    </Stack>
  );
};

export default Submit;
