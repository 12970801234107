import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { displayFormattingValue } from '@reibus/reibus-core-utils';
import { LoadboardPosting } from 'shipment/api';
import { H3, H5 } from 'components/SteelUI/atoms/typography';
import { format } from 'date-fns';

const LABELS = {
  LOADBOARD_POSTINGS: 'Loadboard Postings',
  DATE_POSTED: 'Date Posted',
  LOADBOARD: 'Loadboard',
  RATE: 'Rate',
  STATUS: 'Status',
};

const useStyles = makeStyles(({ breakpoints, palette: { grey } }: Theme) => ({
  loadboardPostings: {
    borderLeft: `1px solid ${grey['100']}`,
    paddingLeft: 24,
    [breakpoints.down('sm')]: {
      borderLeft: 'none',
      paddingLeft: 0,
    },
  },
}));

type Props = {
  postings: LoadboardPosting[];
};

export const LoadboardPostings = ({ postings }: Props) => {
  const classes = useStyles();

  const formatDate = (dateString: string) =>
    format(new Date(dateString), 'MMM dd, yyyy');

  return (
    <Grid item className={classes.loadboardPostings} xs={12} md={12}>
      <Grid container direction="column">
        <Grid item>
          <H3 weight="semibold">{LABELS.LOADBOARD_POSTINGS}</H3>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={3}>
            <H5 weight="semibold">{LABELS.LOADBOARD}</H5>
          </Grid>
          <Grid item xs={3}>
            <H5 weight="semibold">{LABELS.RATE}</H5>
          </Grid>
          <Grid item xs={3}>
            <H5 weight="semibold">{LABELS.DATE_POSTED}</H5>
          </Grid>
        </Grid>
        {postings.length > 0 ? (
          postings.map((posting, index) => (
            <Grid
              key={index}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item xs={3}>
                <H5>{posting.loadboard}</H5>
              </Grid>
              <Grid item xs={3}>
                <H5>
                  $
                  {displayFormattingValue(Number(posting.rate), {
                    decimalPlaces: 2,
                    trailingZeros: true,
                  })}
                </H5>
              </Grid>
              <Grid item xs={3}>
                <H5>{formatDate(posting.datePosted)}</H5>
              </Grid>
            </Grid>
          ))
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={3}>
              <H5>N/A</H5>
            </Grid>
            <Grid item xs={3}>
              <H5>N/A</H5>
            </Grid>
            <Grid item xs={3}>
              <H5>N/A</H5>
            </Grid>
            <Grid item xs={3}>
              <H5>N/A</H5>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
