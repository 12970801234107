import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  circleExclamation: {
    color: '#BB121A',
    width: '2rem',
    height: '2em',
  },
}));

const ModalErrorIcon = () => {
  const { classes } = useStyles();

  return (
    <FontAwesomeIcon
      className={classes.circleExclamation}
      icon={faCircleExclamation}
    />
  );
};

export default ModalErrorIcon;
