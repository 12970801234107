import {
  InputMeta,
  FormSection,
  FormConfig,
} from 'components/library/form/layoutTypes';

// based off of a grid system of 12

export const shipmentIdInput: InputMeta[] = [
  {
    databaseName: 'shipmentId',
    colWidth: 12,
  },
];

export const shipmentDetailsInputs: InputMeta[] = [
  {
    databaseName: 'originAddress',
    colWidth: 4,
    showManualLabel: false,
  },
  {
    databaseName: 'destinationAddress',
    colWidth: 4,
    showManualLabel: false,
  },
  {
    databaseName: 'equipmentType',
    colWidth: 4,
  },
  {
    databaseName: 'pickupDate',
    colWidth: 4,
  },
  {
    databaseName: 'deliveryDate',
    colWidth: 4,
  },
  {
    databaseName: 'customer',
    colWidth: 4,
  },
];

export const FormSections: FormSection[] = [
  {
    title: 'Shipment ID',
    fields: shipmentIdInput,
    hasDivider: true,
  },
  {
    title: 'Shipment Details',
    subtitle: 'Not all fields are required.',
    fields: shipmentDetailsInputs,
  },
];

export const searchFormConfig: FormConfig = {
  formSections: FormSections,
  formStyle: 'right',
  submitButtonText: 'Search',
  cancelButtonText: 'Clear search',
};

export const FORM_NAME = 'LOGISTICS_SEARCH_SHIPMENT';
