import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Checkbox as MuiCheckbox, CheckboxProps } from '@mui/material';

type Props = CheckboxProps;

const Checkbox = ({ ...rest }: Props) => {
  const uncheckedIcon = icon({ name: 'square', style: 'light' });
  const checkedIcon = icon({ name: 'check-square', style: 'solid' });
  const indeterminateIcon = icon({ name: 'minus-square', style: 'solid' });

  return (
    <MuiCheckbox
      {...rest}
      icon={<FontAwesomeIcon icon={uncheckedIcon} color="#2D6CCB" />}
      checkedIcon={<FontAwesomeIcon icon={checkedIcon} />}
      indeterminateIcon={<FontAwesomeIcon icon={indeterminateIcon} />}
      sx={{ paddingLeft: 0 }}
    />
  );
};

export default Checkbox;
