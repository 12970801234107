import { Switch, useLocation, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import RequestForQuote from 'request-for-quote';
import QuickFreightQuuote from 'quick-freight-quote';
import SearchShipments from 'search-shipments';
import Shipment from 'shipment';
import { PrivateRoute } from './PrivateRoute';
import useMixpanelIdentify from 'hooks/useMixpanelIdentify';
import SentryRoute from './SentryRoute';
import * as Sentry from '@sentry/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CSESearchPage from 'pages/CSESearchPage';
import CarrierPage from 'pages/CarrierPage';
import CSEShipmentPage from 'pages/CSEShipmentPage';
import SVDPage from 'pages/SVDPage';
import FreightQuoteDetailsPage from 'pages/FreightQuoteDetailsPage';
import SharedShipmentPage from 'pages/SharedShipmentPage';
import PageNotFound from 'pages/PageNotFound';
import { useUserSession } from '@reibus/frontend-utility';
import AutoAwardConfirmationPage from 'pages/AutoAwardConfirmationPage';
import useLaunchDarklyIdentify from 'hooks/useLaunchDarklyIdentify';
import LogisticsQuoteManagerPage from 'pages/LogisticsQuoteManagerPage';

const PATHFINDER = /#!\/logistics\/(.*)/i;

function App() {
  useLaunchDarklyIdentify();
  useMixpanelIdentify();

  const history = useHistory();
  const { hash, pathname } = useLocation();
  const { data: userSession, isLoading: isUserSessionLoading } =
    useUserSession();

  if (!isUserSessionLoading && userSession) {
    const { cognitoGroups = [] } = userSession;
    if (cognitoGroups.length && cognitoGroups.includes('limited-access')) {
      window.location.pathname = '/login';
      return null;
    }
  }

  useEffect(() => {
    const match = PATHFINDER.exec(hash);

    if (match) {
      history.replace(`/${match[1]}`);
    }
  }, [history, hash]);

  Sentry.setTags({
    traceId: Sentry.getCurrentHub().getScope()?.getTransaction()?.traceId,
  });
  const {
    rl1237CarrierPage,
    rl1209Rl1210CseSearchByCarrier,
    rl2220CsePagesUrlsAndTitles,
    rl3123DigitalCoverageAutoAwardsUi,
  } = useFlags();

  useEffect(() => {
    if (!rl2220CsePagesUrlsAndTitles) return;
    const routeParams = pathname.split('/').filter(Boolean);
    if (routeParams[0] === 'capacity-sourcing-engine') {
      history.push('/cse/search');
    }
    if (routeParams[0] === 'shipment') {
      history.push(`/cse/shipment/${routeParams[1]}`);
    }
  }, [rl2220CsePagesUrlsAndTitles, pathname]);

  return (
    <Switch>
      <SentryRoute path="/request-for-quote">
        <RequestForQuote />
      </SentryRoute>
      <SentryRoute path="/quick-freight-quote">
        <QuickFreightQuuote />
      </SentryRoute>
      <PrivateRoute path="/shipments/public/:shipmentIdToken">
        <SharedShipmentPage isDecoded />
      </PrivateRoute>
      <SentryRoute path="/shipments/:shipmentIdToken">
        <SharedShipmentPage />
      </SentryRoute>
      <SentryRoute path="/quote-details">
        <FreightQuoteDetailsPage />
      </SentryRoute>
      <PrivateRoute path="/shipment-visibility-dashboard">
        <SVDPage />
      </PrivateRoute>
      <PrivateRoute path="/capacity-sourcing-engine">
        {rl1209Rl1210CseSearchByCarrier ? (
          <CSESearchPage />
        ) : (
          <SearchShipments />
        )}
      </PrivateRoute>
      <PrivateRoute path="/shipment/:shipmentId">
        <Shipment />
      </PrivateRoute>
      {!rl1237CarrierPage || !rl2220CsePagesUrlsAndTitles ? null : (
        <PrivateRoute path="/cse/carrier/:cseCarrierId">
          <CarrierPage />
        </PrivateRoute>
      )}
      {!rl1209Rl1210CseSearchByCarrier ||
      !rl2220CsePagesUrlsAndTitles ? null : (
        <PrivateRoute path="/cse/search">
          <CSESearchPage />
        </PrivateRoute>
      )}
      {!rl2220CsePagesUrlsAndTitles ? null : (
        <PrivateRoute path="/cse/shipment/:shipmentId">
          <CSEShipmentPage />
        </PrivateRoute>
      )}
      {!rl3123DigitalCoverageAutoAwardsUi ? null : (
        <SentryRoute path="/auto-award/:autoAwardId">
          <AutoAwardConfirmationPage />
        </SentryRoute>
      )}
      <PrivateRoute path="/quote-manager">
        <LogisticsQuoteManagerPage />
      </PrivateRoute>
      <SentryRoute path="/">
        <PageNotFound />
      </SentryRoute>
    </Switch>
  );
}

export default App;
