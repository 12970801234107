import { Stack } from '@mui/material';
import { Body } from 'components/SteelUI/atoms/typography';
import ModalCloseIcon from 'components/SteelUI/molecules/modals/components/ModalIcons/ModalCloseIcon';
import ModalSuccessIcon from 'components/SteelUI/molecules/modals/components/ModalIcons/ModalSuccessIcon';

const title = 'Thank you for accepting this shipment';
const body =
  'A Reibus Carrier Sales Rep will be contacting you soon to confirm shipment details. If you have questions or can no longer move this freight, please call +1 (888) 663-0785';

type Props = {
  onClose: () => void;
};

const SuccessScreen = ({ onClose }: Props) => {
  return (
    <Stack direction="row" spacing={3}>
      <ModalSuccessIcon />
      <Stack direction="column" spacing={2}>
        <Body size="large" weight="bold" paddingTop="8px">
          {title}
        </Body>
        <Body size="small">{body}</Body>
      </Stack>
      <ModalCloseIcon onClick={onClose} />
    </Stack>
  );
};

export default SuccessScreen;
