export enum MixpanelTag {
  //PO Flow
  ClickedTopNav = 'Click - TopMenu Nav Link',
  // Download documents
  DownloadPOD = 'Download_Proof_Of_Delivery',
  DownloadInvoice = 'Download_Invoice',
  // Contact Carriers by email link
  EmailCarrier = 'Email_Carrier',
  // CSE
  CarrierSearch = 'CSE Carrier Search by',
  ClickResultCseCarrier = 'Clicked on a result for CSE Search By Carrier',
  ClickSendTopShipments = 'Clicked send top shipments email',
}

export enum cseFields {
  dat = 'DAT Load Board Capacity',
  its = 'ITS Load Board Capacity',
  lane = 'Lane History',
  headquarters = 'Headquarters',
}
