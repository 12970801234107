import { Box, Stack, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { H3, Title } from 'components/SteelUI/atoms/typography';

type Props = {
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  action?: React.ReactNode;
  link?: React.ReactNode;
};

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    backgroundColor: '#FFFFFF',
    color: '#212224',
  },
}));

const PageTitle = ({ title, description, action }: Props) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="column">
          <Title weight="bold">{title}</Title>
          <H3>{description}</H3>
        </Stack>
        <H3>{action}</H3>
      </Stack>
    </Box>
  );
};

export default PageTitle;
