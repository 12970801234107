import { useState } from 'react';
import { Card, Grid, Theme, SvgIcon, Stack, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CarrierContactInfoModal from 'shipment/carrier-contact-info-modal';
import { useShipmentContext } from 'shipment/ShipmentContext';
import DispositionButton from 'features/cse/disposition/disposition-modal/DispositionButton';
import { trackEvent } from 'utils/mixpanel';
import { CarrierLabel, ScoreResult } from 'shipment/api';
import type { CarrierDetailResponse, RankedEquipment } from 'shipment/api';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import CarrierDetail from './CarrierDetail';
import LaneHistoryModal from 'features/cse/modals/LaneHistoryModal';
import HQDetailsModal from 'features/cse/modals/HQDetailsModal';
import EquipmentDetailsModal from 'features/cse/modals/EquipmentDetailsModal';
import CompositeScoreDetailsModal from 'features/cse/modals/CompositeScoreDetailsModal';
import { useDispositionContext } from 'features/cse/disposition/contexts/DispositionContext';
import DispositionNotesModal from 'features/cse/disposition/disposition-notes-modal/DispositionNotesModal';
import { Launch, Add } from '@mui/icons-material';

import ButtonV2 from 'components/library/ButtonV2';
import { Link } from 'react-router-dom';
import { formatCarrierStatus } from 'features/cse/Carrier/utils/data';
import Chip from 'components/library/Chip';

import CapacityIndicators from 'features/cse/Carrier/components/CarrierOverview/components/TopShipments/components/TopShipment/components/CapacityIndicators';

import { useDispositionsForShipmentContext } from 'features/cse/disposition/contexts/DispositionsForShipmentContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import AddEquipmentDrawer from '../../features/cse/AddEquipment/AddEquipmentDrawer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Button from 'components/SteelUI/atoms/Button';
import Tooltip from 'components/SteelUI/atoms/Tooltip';
import AutoAwardModal from 'features/cse/AutoAward/components/AutoAwardModal';
import AutoAwardTooltip from 'features/cse/AutoAward/components/AutoAwardTooltip';

import type { DigitalFreightAward } from 'features/cse/AutoAward/types';

type Props = {
  name: string;
  carrierId: string;
  shipmentId: string;
  totalScore: number;
  scores: ScoreResult[];
  rankedEquipment: RankedEquipment[];
  carrierDetails: CarrierDetailResponse | Record<string, never>;
  reibusId: string;
  carrierRankId: string;
  scoringTime: string;
  indicators: {
    key: string;
    name: string;
    weight: number;
    totalScore: number;
  }[];
  labels?: CarrierLabel[];
  digitalFreightAward?: DigitalFreightAward;
};

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(2),
  },
  name: {
    fontWeight: 600,
  },
  dispositionButton: {
    paddingLeft: theme.spacing(2),
  },
  showDetailsButton: {
    cursor: 'pointer',
    paddingLeft: theme.spacing(2),
  },
  noteLink: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(5),
  },
  carrierPageLink: {
    display: 'flex',
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 18,
    padding: 0,
  },
  strategicText: {
    marginRight: theme.spacing(1),
  },
  strategicContainer: {
    marginBottom: theme.spacing(1.5),
  },
  star: {
    color: '#FFD257',
  },
  inactiveCarrierIcon: {
    color: '#BB121A',
  },
  equipment: {
    fontSize: 16,
    fontWeight: 400,
    textTransform: 'none',
    paddingRight: theme.spacing(2),
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
  startIcon: { marginRight: 2 },
}));
export type TopShipmentModals =
  | 'compositeScoreDetails'
  | 'lane'
  | 'hq'
  | 'dat'
  | 'req'
  | 'addEquipment'
  | 'its';

const Carrier = ({
  name,
  carrierId,
  shipmentId,
  scores,
  totalScore,
  rankedEquipment,
  carrierDetails,
  reibusId,
  carrierRankId,
  scoringTime,
  indicators,
  labels,
  digitalFreightAward,
}: Props) => {
  const { rl3808UpdateOfferSentDisplayInCse, rl3811ManualAwards } = useFlags();
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openModal, setOpenModal] = useState<TopShipmentModals | null>(null);
  const [isCarrierDetailOpen, setIsCarrierDetailOpen] = useState(false);
  const [isDispositionNotesOpen, setIsDispositionNotesOpen] = useState(false);

  const { setDispositionData, refetchNotes, setRefetchNotes } =
    useDispositionContext();
  const { isTentativelyCovered, tentativelyCoveredBy } = useShipmentContext();
  const { dispositionsByCarrier } = useDispositionsForShipmentContext();
  const isStrategic = labels?.includes('STRATEGIC');

  const { cseCarrierID, carrierMCNumber, carrierDOTNumber, status } =
    carrierDetails;

  if (refetchNotes) {
    setRefetchNotes(false);
  }

  const handleClickContact = () => {
    const properties = {
      shipmentId,
      carrierId,
    };
    trackEvent(
      'Opened contact modal for a top carrier on Shipment Page',
      properties
    );
    propagateDispositionData();
    setIsModalOpen(true);
  };

  const handleClickCarrierDetails = () => {
    const properties = {
      shipmentId,
      carrierId,
    };
    trackEvent(
      'Opened carrier detail for a top carrier on Shipment Page',
      properties
    );
    setIsCarrierDetailOpen(!isCarrierDetailOpen);
  };

  const handleOpenDispositionModal = () => {
    setIsDispositionNotesOpen(true);
  };

  const getEquipmentID = (source: string) =>
    rankedEquipment.find((equipment) => equipment.loadBoardType === source)
      ?.equipmentID;

  const propagateDispositionData = () =>
    setDispositionData({
      carrierName: name,
      cseCarrierID: carrierId,
      compositeScore: totalScore,
      shipmentId: shipmentId,
      carrierRankId: carrierRankId,
      carrierStatus: formatCarrierStatus(
        carrierDetails.source,
        carrierDetails.status
      ),
      source: 'SHIPMENT',
      equipmentIds: rankedEquipment.map(({ equipmentID }) => equipmentID),
    });

  const cleanShipmentPageScore = Object.assign({}, ...(scores || []));
  const {
    'DAT Load Board Capacity': datShipmentScore,
    'ITS Load Board Capacity': itsShipmentScore,
    'Lane History': laneShipmentScore,
    Headquarters: hqShipmentScore,
    'Carrier availability': reqShipmentScore,
  } = cleanShipmentPageScore || {};

  const getWeightsFromIndicator = (key: string): number => {
    const indicator = indicators.find((indicator) => indicator.key === key);
    return indicator?.weight ?? 0;
  };

  const weights = {
    datWeight: String(getWeightsFromIndicator('DAT_LOAD_BOARD')),
    itsWeight: String(getWeightsFromIndicator('ITS_LOAD_BOARD')),
    laneWeight: String(getWeightsFromIndicator('LANE_HISTORY')),
    hqWeight: String(getWeightsFromIndicator('HQ')),
    availableEquipmentWeight: String(
      getWeightsFromIndicator('REIBUS_LOAD_BOARD')
    ),
    lanePreferenceWeight: '0',
  };

  const compositeScore = {
    id: carrierRankId,
    shipmentId,
    createdAt: scoringTime,
    carrierId,
    totalScore: String(totalScore),
    datScore: datShipmentScore,
    itsScore: itsShipmentScore,
    laneScore: laneShipmentScore,
    hqScore: hqShipmentScore,
    availableEquipmentScore: reqShipmentScore,
    rankedEquipment,
    lanePreferenceScore: '0',
    weights,
  };

  const reqEquipmentID = getEquipmentID('REIBUS');
  const datEquipmentID = getEquipmentID('DAT');
  const itsEquipmentID = getEquipmentID('ITS');

  const dispositions = dispositionsByCarrier.get(carrierId) || [];

  const getAutoAwardsDisplay = () => {
    if (rl3811ManualAwards && isStrategic) {
      return (
        <Grid item>
          <Stack direction="row" spacing={1} height="24px">
            <Divider orientation="vertical" />
            <AutoAwardModal
              autoAward={digitalFreightAward}
              carrierId={carrierId}
              shipmentId={shipmentId}
            />
          </Stack>
        </Grid>
      );
    } else if (digitalFreightAward && digitalFreightAward.notifiedAt) {
      return (
        <Grid item>
          <Stack direction="row" spacing={1} height="24px">
            <Divider orientation="vertical" />
            {!rl3808UpdateOfferSentDisplayInCse ? (
              <AutoAwardTooltip {...digitalFreightAward} />
            ) : (
              <AutoAwardModal
                autoAward={digitalFreightAward}
                carrierId={carrierId}
                shipmentId={shipmentId}
              />
            )}
          </Stack>
        </Grid>
      );
    }
    return null;
  };

  return (
    <Card variant="outlined" className={classes.card}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Stack
            direction="row"
            spacing={2}
            paddingBottom="8px"
            alignItems="center"
          >
            {!isStrategic ? null : (
              <Tooltip title="Strategic Carrier">
                <FontAwesomeIcon
                  data-testid="strategic-carrier-icon"
                  icon={icon({ name: 'star' })}
                  className={classes.star}
                />
              </Tooltip>
            )}
            {status !== 'INACTIVE' ? null : (
              <Tooltip title="Inactive Carrier">
                <FontAwesomeIcon
                  data-testid="inactive-carrier-icon"
                  icon={icon({
                    name: 'circle-exclamation',
                    style: 'solid',
                  })}
                  className={classes.inactiveCarrierIcon}
                />
              </Tooltip>
            )}
            <Link
              to={`/cse/carrier/${carrierId}`}
              target="_blank"
              onClick={() =>
                trackEvent(
                  'Clicked a carrier link in top carriers on shipment page',
                  { cseCarrierID: carrierId }
                )
              }
            >
              <Button
                variant="text"
                className={classes.carrierPageLink}
                endIcon={<Launch />}
              >
                {name}
              </Button>
            </Link>
            {getAutoAwardsDisplay()}
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Chip
              label={`Score ${Number(totalScore).toFixed(3)}`}
              color="secondary"
              onClick={() => setOpenModal('compositeScoreDetails')}
              sx={{
                background: 'rgba(45, 157, 120, 0.2)',
                '&:hover, &:focus': {
                  backgroundColor: 'rgba(45, 157, 120, 0.2)',
                  cursor: 'pointer',
                },
              }}
            />
            <CapacityIndicators
              setOpenModal={setOpenModal}
              scores={compositeScore}
            />
          </Stack>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <ButtonV2
                  variant="text"
                  onClick={() => setOpenModal('addEquipment')}
                  className={classes.equipment}
                  startIcon={<Add />}
                  classes={{
                    startIcon: classes.startIcon,
                  }}
                >
                  Equipment
                </ButtonV2>
              </Grid>
              <Grid item>
                <ButtonV2 onClick={handleClickContact}>Contact</ButtonV2>
              </Grid>
              <Grid item className={classes.dispositionButton}>
                <DispositionButton
                  disabled={
                    isTentativelyCovered &&
                    carrierId !== tentativelyCoveredBy?.cseCarrierID
                  }
                  type={dispositions?.[0]?.disposition}
                  reason={dispositions?.[0]?.reason}
                  onClick={propagateDispositionData}
                />
              </Grid>
              <Grid item className={classes.showDetailsButton}>
                {!isCarrierDetailOpen ? (
                  <SvgIcon
                    data-testid="show-details-button"
                    component={KeyboardArrowDown}
                    onClick={handleClickCarrierDetails}
                  />
                ) : (
                  <SvgIcon
                    data-testid="show-details-button"
                    component={KeyboardArrowUp}
                    onClick={handleClickCarrierDetails}
                  />
                )}
              </Grid>
            </Grid>
            {!dispositions.length ? null : (
              <Grid container item xs={12} justifyContent="flex-end">
                <ButtonV2
                  variant="text"
                  className={classes.noteLink}
                  onClick={handleOpenDispositionModal}
                >
                  View Notes
                </ButtonV2>
              </Grid>
            )}
          </Grid>
        </Grid>
        {isCarrierDetailOpen ? (
          <Grid item sm={12}>
            <CarrierDetail carrierDetails={carrierDetails} />
          </Grid>
        ) : null}
      </Grid>
      <CarrierContactInfoModal
        isOpen={isModalOpen}
        carrierName={name}
        carrierId={carrierId}
        equipmentIDs={rankedEquipment.map((equipment) => equipment.equipmentID)}
        handleClose={() => setIsModalOpen(false)}
      />
      <LaneHistoryModal
        isOpen={openModal === 'lane'}
        carrierName={name}
        carrierID={carrierId}
        handleClose={() => setOpenModal(null)}
        reibusId={reibusId}
      />
      <DispositionNotesModal
        isOpen={isDispositionNotesOpen}
        handleClose={() => setIsDispositionNotesOpen(false)}
        carrierName={name}
        dispositions={dispositions}
      />
      {!datEquipmentID ? null : (
        <EquipmentDetailsModal
          isOpen={openModal === 'dat'}
          handleClose={() => setOpenModal(null)}
          equipmentID={datEquipmentID}
          loadboard={'DAT'}
        />
      )}
      {!itsEquipmentID ? null : (
        <EquipmentDetailsModal
          isOpen={openModal === 'its'}
          handleClose={() => setOpenModal(null)}
          equipmentID={itsEquipmentID}
          loadboard={'ITS'}
        />
      )}
      {!reqEquipmentID ? null : (
        <EquipmentDetailsModal
          isOpen={openModal === 'req'}
          handleClose={() => setOpenModal(null)}
          equipmentID={reqEquipmentID}
          loadboard={'REIBUS'}
        />
      )}
      <CompositeScoreDetailsModal
        isOpen={openModal === 'compositeScoreDetails'}
        handleClose={() => setOpenModal(null)}
        totalScore={totalScore}
        scoringTime={scoringTime}
        indicators={indicators}
      />
      <HQDetailsModal
        isOpen={openModal === 'hq'}
        handleClose={() => setOpenModal(null)}
        headquarter={carrierDetails.headquarter}
      />

      <AddEquipmentDrawer
        cseCarrierID={cseCarrierID}
        mcNumber={carrierMCNumber}
        dotNumber={carrierDOTNumber}
        isOpen={openModal === 'addEquipment'}
        handleClose={() => setOpenModal(null)}
      />
    </Card>
  );
};

export default Carrier;
