import { useParams } from 'react-router-dom';
import { Box, ThemeProvider } from '@mui/material';

import { newTheme } from 'theme';
import BlankNavBar from 'components/SteelUI/organisms/NavBar/BlankNavBar';
import Confirmation from 'features/cse/AutoAward/Confirmation';
import NotAvailable from 'features/cse/AutoAward/NotAvailable';
import PageTitle from 'components/PageTitle';
import Button from 'components/SteelUI/atoms/Button';
import useQueryAutoAward from 'features/cse/AutoAward/hooks/useQueryAutoAward';
import PageNotFound from 'pages/PageNotFound';
import PageBody from 'components/PageBody';
import Loader from 'components/Loader';

const AutoAwardConfirmationPage = () => {
  const { autoAwardId } = useParams<{ autoAwardId: string }>();
  const { data, isLoading } = useQueryAutoAward(autoAwardId);

  const awardStatus = data?.digitalFreightAward?.status;
  const isShipmentAvailable =
    data?.shipment?.label === 'Tendered' && awardStatus === 'OPEN';

  const downloadRateConfirmation = (autoAwardId: string) => {
    console.log(
      `placeholder download rate confirmation for auto award ${autoAwardId}`
    );
  };

  const getDescription = (shipmentId: string) => {
    return <Box>Shipment ID: {shipmentId}</Box>;
  };

  const getAction = (autoAwardId: string) => {
    return (
      <Button
        variant="outlined"
        onClick={() => downloadRateConfirmation(autoAwardId)}
      >
        Download Rate Confirmation
      </Button>
    );
  };

  if (!isLoading && !data) {
    return <PageNotFound />;
  }

  return (
    <Box sx={{ backgroundColor: '#F6F7F7', minHeight: '100vh' }}>
      <BlankNavBar />
      <ThemeProvider theme={newTheme}>
        <Loader hasLoaded={!isLoading}>
          {!data ? (
            <PageNotFound />
          ) : (
            <>
              <PageTitle
                title={
                  isShipmentAvailable
                    ? 'Shipment Confirmation'
                    : 'Shipment Not Available'
                }
                description={getDescription(data.shipment.id)}
                // TODO: This is future functionality that we do not want to launch with, so commenting out for now
                // action={getAction(autoAwardId)}
              />
              <PageBody>
                {isShipmentAvailable ? (
                  <Confirmation id={autoAwardId} />
                ) : (
                  <NotAvailable id={autoAwardId} />
                )}
              </PageBody>
            </>
          )}
        </Loader>
      </ThemeProvider>
    </Box>
  );
};

export default AutoAwardConfirmationPage;
