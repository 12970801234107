import { Box, IconButton, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-light-svg-icons';
import { Stack } from '@mui/system';
import ButtonV2 from 'components/library/ButtonV2';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CreateEquipmentPayload } from './api';

type NotificationState = 'success' | 'error' | null;
export type NotificationResult =
  | 'EQUIPMENT_CREATED_SCORING_FINISHED'
  | 'EQUIPMENT_CREATED_SCORING_IN_PROGRESS'
  | 'EQUIPMENT_UPDATED_SCORING_FINISHED'
  | 'EQUIPMENT_UPDATED_SCORING_IN_PROGRESS';

export type Notification = {
  status: NotificationState;
  scoreCount?: number;
  payload?: CreateEquipmentPayload;
  equipmentId?: string;
  result?: NotificationResult;
};

type Props = {
  notification: Notification;
  closeNotification: () => void;
  onView?: (data?: CreateEquipmentPayload, equipmentId?: string) => void;
};

const AddEquipmentNotification = ({
  notification: { status, scoreCount, payload, equipmentId, result },
  closeNotification,
  onView,
}: Props) => {
  const [slideIn, setSlideIn] = useState(true);
  const handleClose = () => {
    setSlideIn(false);
    setTimeout(() => {
      closeNotification();
    }, 500);
  };

  const { rl2499ViewMatchedShipments } = useFlags();
  const statusColor = status === 'success' ? '#237960' : '#BB121A';
  const statusIcon = status === 'success' ? faCheckCircle : faExclamationCircle;

  const getMessage = () => {
    if (status === 'success') {
      if (rl2499ViewMatchedShipments) {
        if (!result) {
          return scoreCount && scoreCount > 0
            ? `Equipment Saved! ${scoreCount} shipments match this equipment.`
            : `Equipment Saved! No tendered shipments currently match this equipment.`;
        }
        const isScoringFinished =
          result === 'EQUIPMENT_CREATED_SCORING_FINISHED' ||
          result === 'EQUIPMENT_UPDATED_SCORING_FINISHED';
        const isScoringPending =
          result === 'EQUIPMENT_CREATED_SCORING_IN_PROGRESS' ||
          result === 'EQUIPMENT_UPDATED_SCORING_IN_PROGRESS';
        if (isScoringFinished) {
          return scoreCount && scoreCount > 0
            ? `Equipment Saved! ${scoreCount} shipments match this equipment.`
            : `Equipment Saved! No tendered shipments currently match this equipment.`;
        }
        if (isScoringPending) {
          return 'Equipment added successfully but unable to match shipments';
        }
      }
      return 'Equipment Added!';
    }

    return 'Failed to add equipment.';
  };

  const showViewButton =
    rl2499ViewMatchedShipments && status === 'success' && scoreCount;

  return (
    <Slide
      direction="left"
      in={slideIn}
      mountOnEnter
      unmountOnExit
      id="req-notification"
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box
          sx={{
            borderColor: 'rgba(0, 0, 0, 0.38)',
            borderStyle: 'solid',
            borderWidth: '1px',
            minHeight: '50px',
            width: '100%',
            display: 'flex',
            borderRadius: '8px',
            borderLeft: `7px solid ${statusColor}`,
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              padding: '16px 16px 16px 0',
              alignItems: 'flex-start',
            }}
          >
            <FontAwesomeIcon
              icon={statusIcon}
              color={statusColor}
              style={{
                padding: '0 16px',
                paddingTop: '3px',
                marginRight: '3px',
              }}
            />
            <Box>{getMessage()}</Box>
          </Box>

          {showViewButton ? (
            <Box
              sx={{
                borderColor: 'rgba(0, 0, 0, 0.38)',
                borderStyle: 'solid',
                borderRight: 'none',
                borderTop: 'none',
                borderBottom: 'none',
                borderWidth: '1px',
                width: '90px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  borderColor: 'rgba(0, 0, 0, 0.38)',
                  borderStyle: 'solid',
                  borderRight: 'none',
                  borderLeft: 'none',
                  borderTop: 'none',
                  borderWidth: '1px',
                  display: 'flex',
                  height: '50%',
                }}
              >
                <ButtonV2
                  variant="text"
                  onClick={() => onView?.(payload, equipmentId)}
                  sx={{ width: '100%' }}
                >
                  View
                </ButtonV2>
              </Box>
              <Box
                sx={{
                  border: 'none',
                  display: 'flex',
                  height: '50%',
                }}
              >
                <ButtonV2
                  variant="text"
                  onClick={handleClose}
                  sx={{ width: '100%' }}
                >
                  Dismiss
                </ButtonV2>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                minHeight: '50px',
                display: 'flex',
                alignItems: 'flex-start',
              }}
            >
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </Stack>
    </Slide>
  );
};

export default AddEquipmentNotification;
