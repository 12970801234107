import {
  Address,
  CarrierSource,
  CarrierStatus,
  EquipmentServices,
  HeadquarterDetail,
} from 'shipment/api';
import type { Shipment, DateTimeDetailed, SchedulingType } from 'shipment/api';
import numeral from 'numeral';
import { formatDateDisplay } from 'shipment-visibility-dashboard/Shipment/ShipmentUtils';
import {
  EquipmentLocation,
  LoadBoardType,
} from 'features/cse/AddEquipment/api';
import { formatInTimeZone } from 'date-fns-tz';

const DEFAULT_VALUE = 'N/A';

const EQUIPMENT_TYPE: Record<string, string> = {
  'Flatbed, Hotshot': 'Hotshot',
};

type ShipmentDate = Shipment['startDateDetailed' | 'endDateDetailed'];

export const formatEquipmentLength = (length: string) =>
  length ? `${length} ft` : 'N/A';

export const formatEquipmentWeight = (weight?: number) =>
  weight ? `${numeral(weight).format('0,0')} lbs` : 'N/A';

export const formatAddress = (location: Address) => {
  const { city, region } = location ?? {};
  const address = [city, region].filter((x) => x);
  return address.length ? address.join(', ') : 'N/A';
};

export const formatEquipmentLocation = (
  location: EquipmentLocation | EquipmentLocation[]
) => {
  const { city, stateProvince } =
    (Array.isArray(location) ? location[0] : location) ?? {};
  const address = [city, stateProvince].filter((x) => x);
  return address.length ? address.join(', ') : 'N/A';
};

export const getHQ = (headquarter: HeadquarterDetail) => {
  const { city, region } = headquarter ?? {};
  const HQ = [city, region].filter((x) => x);
  return HQ.length ? HQ.join(', ') : 'N/A';
};

export const initCap = (s: string): string => {
  const firstLetter = s.slice(0, 1);
  const rest = s.slice(1);
  return firstLetter.toUpperCase().concat(rest.toLowerCase());
};

export const formatCarrierStatus = (
  source: CarrierSource,
  status: CarrierStatus | null | undefined
) => {
  if (source !== 'TURVO') {
    return 'External';
  }
  return status ? initCap(status.toString()) : 'Inactive';
};

export const formatCompaniesNames = (
  companies: Shipment['shipmentTMSCompanies']
) =>
  companies.length
    ? companies?.map((tms) => tms.tmsName).join(', ')
    : DEFAULT_VALUE;

export const formatDate = (date: string) =>
  formatInTimeZone(new Date(date), 'UTC', 'MM/dd/yyyy');

export const formatSource = (source: LoadBoardType): string => {
  switch (source) {
    case 'DAT':
    case 'ITS':
      return source;
    case 'REIBUS_MANUAL':
      return 'REQ';
    case 'REIBUS_AUTOMATIC':
      return 'REQ (Auto)';
    default:
      return 'N/A';
  }
};

export const formatEquipmentType = (
  equipmentType: Pick<EquipmentServices, 'description'>
) => EQUIPMENT_TYPE[equipmentType.description] ?? equipmentType.description;

export const formatCost = (totalCarrierCost: number) =>
  totalCarrierCost || typeof totalCarrierCost === 'number'
    ? numeral(totalCarrierCost).format('$0,0.00')
    : DEFAULT_VALUE;

export const formatShipmentDate = (date: ShipmentDate) =>
  date
    ? formatDate(formatDateDisplay(date.date, date.timeZone, date.flex))
    : DEFAULT_VALUE;

const formatShipmentTime = (dateDetailed: ShipmentDate): string | undefined => {
  if (!dateDetailed || isMidnight(dateDetailed.date, dateDetailed.timeZone)) {
    return;
  }
  const { date, timeZone, flex } = dateDetailed;
  const datetime = new Date(date);
  const starTime = datetime.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    timeZone,
  });
  const flexTime = getFlexDatetime({
    datetime: date,
    timezone: timeZone,
    flex,
  });
  const shortTimezone = datetime
    .toLocaleTimeString('en-us', { timeZone, timeZoneName: 'short' })
    .split(' ')[2];

  return `${starTime} ${
    Number(flex) !== 0 ? `- ${flexTime}` : ''
  } ${shortTimezone}`;
};

export const isMidnight = (datetime: string, timezone: string): boolean =>
  new Date(datetime)
    .toLocaleString('en-US', {
      timeZone: timezone,
    })
    .includes('12:00:00 AM');

export const getFlexDatetime = ({
  datetime,
  timezone,
  flex = 0,
}: DateTimeDetailed) => {
  const flexMs = flex * 1000;
  const flexDateTime = new Date(new Date(datetime).getTime() + flexMs);
  return new Date(flexDateTime).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    timeZone: timezone,
  });
};

export const formatSchedulingType = (schedulingType?: SchedulingType) => {
  if (schedulingType) {
    switch (schedulingType) {
      case 'FCFS':
        return schedulingType;
      case 'BY_APPOINTMENT':
      case 'TURVO_APPOINTMENT_SCHEDULING':
        return 'Appt';
      default:
        return '';
    }
  }
};

export const getStopDate = (date: ShipmentDate): string =>
  [
    formatShipmentDate(date),
    formatShipmentTime(date),
    formatSchedulingType(date?.schedulingType),
  ]
    .filter((x) => x)
    .join(', ');
