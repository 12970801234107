import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { AddressFieldsTypes } from './AddressFieldTypes';
import { Box } from '@mui/material';
import theme from 'theme';
import { TextField } from './TextField';
import { SingleSelect } from './SingleSelect';
import { RegionSelect } from './RegionSelect';
import { GoogleAutoCompleteField } from './GoogleAutoCompleteField';

const useStyles = makeStyles({
  link: {
    transform: 'translate(10px, -25px) scale(0.75)',
    float: 'right',
    height: 0,
    margin: 0,
  },
  selectedLink: {
    float: 'right',
    height: 0,
    margin: 0,
    transform: 'translate(10px, -25px) scale(0.75)',
    color: '#5d0549D5',
  },
});

type AddressFieldGroupProp = {
  name: string;
};

export const AddressFieldGroup = (props: AddressFieldGroupProp) => {
  const { name } = props;

  const addressFields = AddressFieldsTypes;

  return (
    <div id={`${name}-fieldContainer`}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridGap: theme.spacing(4),
          [theme.breakpoints.down('lg')]: {
            gridGap: theme.spacing(2),
          },
          [theme.breakpoints.down('md')]: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
        style={{ width: 'auto' }}
      >
        {addressFields.map((field, i) => {
          switch (field.inputType) {
            case 'SELECT':
              return (
                <Box
                  key={field.name}
                  sx={{
                    gridRow: `span ${field.rowHeight || 1}`,
                    gridColumn: `span ${field.colWidth}`,
                  }}
                >
                  <SingleSelect
                    placeholder={field.label}
                    name={`${name}.${field.databaseName}`}
                    label={field.label}
                    required={field.required}
                    options={field.options || []}
                    key={`${name}.${field.databaseName}`}
                  />
                </Box>
              );
            case 'REGION_SELECT':
              return (
                <Box
                  key={field.name}
                  sx={{
                    gridRow: `span ${field.rowHeight || 1}`,
                    gridColumn: `span ${field.colWidth}`,
                  }}
                >
                  <RegionSelect
                    placeholder={field.label}
                    name={`${name}.${field.databaseName}`}
                    label={field.label}
                    required={field.required}
                    key={`${name}.${field.databaseName}`}
                    parentDBName={name}
                  />
                </Box>
              );
            case 'TEXT':
              return (
                <Box
                  key={field.name}
                  sx={{
                    gridRow: `span ${field.rowHeight || 1}`,
                    gridColumn: `span ${field.colWidth}`,
                  }}
                >
                  <TextField
                    name={`${name}.${field.databaseName}`}
                    label={field.label}
                    required={field.required}
                    key={i}
                  />
                </Box>
              );
          }
        })}
      </Box>
    </div>
  );
};

type GooglePlaceSearchType = '(cities)' | 'address' | '(regions)';

export type GoogleAutoCompleteProps = {
  name: string;
  label: string;
  required: boolean;
  searchTypes?: GooglePlaceSearchType[];
  maxResults?: number;
  validator?: (value: string) => string | void;
  initialDescription?: string;
  onClear?: () => void;
};

type AddressFieldProps = {
  placeholder?: string;
  maxLength?: number;
  value?: string;
  disabled?: boolean;
  showManualLabel?: boolean;
} & GoogleAutoCompleteProps;

export const AddressField = ({
  name,
  required = false,
  showManualLabel = true,
  label,
  searchTypes,
  maxResults,
  initialDescription,
  onClear,
}: AddressFieldProps) => {
  const classes = useStyles();

  //is address form hidden?
  const [isHidden, setIsHidden] = useState(true);

  const toggleHidden = () => {
    setIsHidden(!isHidden);
  };

  return (
    <div>
      {showManualLabel ? (
        <label
          className={isHidden ? classes.link : classes.selectedLink}
          onClick={() => toggleHidden()}
        >
          Enter Manually
        </label>
      ) : null}
      {isHidden ? (
        <div>
          <GoogleAutoCompleteField
            name={`${name}PlaceId`}
            label={label ?? 'Address'}
            required={required}
            searchTypes={searchTypes}
            maxResults={maxResults}
            initialDescription={initialDescription}
            onClear={onClear}
          />
        </div>
      ) : (
        <AddressFieldGroup name={name} />
      )}
    </div>
  );
};
