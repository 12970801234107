import { captureException } from '@sentry/react';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  CompositeCarrierScore,
  getCompositeScore,
  getShipmentDetails,
  ShipmentDetailsResponse,
  getLocationDetails,
  LocationDetails,
  getAlgoliaShipment,
} from 'shipment/api';
import { cseFields } from 'utils/constants';
import { useAuthorizationToken } from '@reibus/frontend-utility';

type Dictionary = {
  [key: string]: string;
};

const filterDictionary: Dictionary = {
  dat: cseFields.dat,
  its: cseFields.its,
  lane: cseFields.lane,
  hq: cseFields.headquarters,
};

const filterDictionaryBEFiltering: Dictionary = {
  dat: 'DAT',
  its: 'ITS',
  lane: 'LANE_HISTORY',
  hq: 'HQ',
  req: 'REIBUS',
};

type TentativelyCoveredBy = { cseCarrierID: string };

type ShipmentContext = {
  topCarriers: CompositeCarrierScore[];
  fetchTopCarriers: () => void;
  isLoadingTopCarriers: boolean;

  shipmentDetails: ShipmentDetailsResponse | undefined;
  isLoadingShipmentDetails: boolean;
  fetchShipmentDetails: () => void;

  locations: LocationDetails | undefined;
  fetchLocations: () => void;
  setIsTentativelyCovered: (
    isTentativelyCovered: boolean,
    tentativelyCoveredBy: TentativelyCoveredBy
  ) => void;
  isTentativelyCovered: boolean;
  tentativelyCoveredBy: TentativelyCoveredBy | undefined;
  filteredTopCarriers: CompositeCarrierScore[];
  handleSearchFilterCarrier: (
    filterdSelected: CapacityIndicatorFilter,
    CseCarrierIdsSearched: string[]
  ) => void;
};

const ShipmentContext = createContext<ShipmentContext>({} as ShipmentContext);

type ShipmentContextProviderProps = {
  children: ReactNode;
  shipmentId: string;
};

type ShipmentContextProviderTestProps = {
  testData?: Partial<ShipmentContext>;
};

type Hit = {
  tentativelyCoveredUntil: string;
  tentativelyCoveredBy: TentativelyCoveredBy;
};

type CapacityIndicatorFilter = {
  [key: string]: boolean;
};

const ShipmentContextProvider = ({
  children,
  shipmentId,
  testData = {},
}: ShipmentContextProviderProps & ShipmentContextProviderTestProps) => {
  const [topCarriers, setTopCarriers] = useState<CompositeCarrierScore[]>([]);
  const [shipmentDetails, setShipmentDetails] = useState<
    ShipmentDetailsResponse | undefined
  >();
  const [locations, setLocations] = useState<LocationDetails>();
  const [filteredTopCarriers, setFilteredTopCarriers] = useState<
    CompositeCarrierScore[]
  >([]);
  const [isLoadingTopCarriers, setIsLoadingTopCarriers] = useState(true);
  const [isLoadingShipmentDetails, setIsLoadingShipmentDetails] =
    useState(true);
  const [isTentativelyCovered, setIsTentativelyCovered] = useState(false);
  const [tentativelyCoveredBy, setTentativelyCoveredBy] = useState<
    TentativelyCoveredBy | undefined
  >();
  const authToken = useAuthorizationToken();

  const fetchTopCarriers = async () => {
    try {
      setIsLoadingTopCarriers(true);
      const topCarriers = await getCompositeScore(authToken, shipmentId);
      setTopCarriers(topCarriers.carriers);
      setFilteredTopCarriers(topCarriers.carriers);
    } catch {
      setTopCarriers([]);
    } finally {
      setIsLoadingTopCarriers(false);
    }
  };

  const fetchShipmentDetails = async () => {
    try {
      setIsLoadingShipmentDetails(true);
      const shipmentDetails = await getShipmentDetails(authToken, shipmentId);
      setShipmentDetails(shipmentDetails);
    } catch (e) {
      captureException(e);
      setShipmentDetails(undefined);
    } finally {
      setIsLoadingShipmentDetails(false);
    }
  };

  const fetchLocations = async () => {
    try {
      const locationDetails = await getLocationDetails(authToken, shipmentId);
      setLocations(locationDetails);
    } catch (e) {
      captureException(e);
      setLocations(undefined);
    }
  };

  const fetchTentativelyCoveredUntil = async () => {
    const resp = await getAlgoliaShipment(shipmentId);
    const { tentativelyCoveredUntil = '', tentativelyCoveredBy } = resp
      .hits[0] as unknown as Hit;

    if (!tentativelyCoveredUntil) {
      setIsTentativelyCovered(false);
      setTentativelyCoveredBy(undefined);
      return;
    }

    try {
      const tentativelyCoveredUntilDate = new Date(tentativelyCoveredUntil);
      const now = new Date();
      if (tentativelyCoveredUntilDate.getTime() > now.getTime()) {
        setIsTentativelyCovered(
          tentativelyCoveredUntilDate.getTime() > now.getTime()
        );
        setTentativelyCoveredBy(tentativelyCoveredBy);
        setTimeout(() => {
          setIsTentativelyCovered(false);
          setTentativelyCoveredBy(undefined);
        }, tentativelyCoveredUntilDate.getTime() - now.getTime());
      }
    } catch (e) {
      captureException(e);
      setIsTentativelyCovered(false);
      setTentativelyCoveredBy(undefined);
    }
  };

  const handleSetIsTentativelyCovered = (
    isTentativelyCovered: boolean,
    tentativelyCoveredBy: TentativelyCoveredBy
  ) => {
    if (!isTentativelyCovered) {
      setIsTentativelyCovered(false);
      setTentativelyCoveredBy(undefined);
      return;
    }

    setIsTentativelyCovered(true);
    setTentativelyCoveredBy(tentativelyCoveredBy);
    const TEN_MINUTES_IN_MS = 1000 * 60 * 10;
    setTimeout(() => {
      setIsTentativelyCovered(false);
      setTentativelyCoveredBy(undefined);
    }, TEN_MINUTES_IN_MS);
  };

  useEffect(() => {
    fetchShipmentDetails();
    fetchTopCarriers();
    fetchTentativelyCoveredUntil();
    fetchLocations();
  }, []);

  const filterByCapacityIndicator = async (
    selectedCapacityIndicators: CapacityIndicatorFilter,
    carriersToFilter: CompositeCarrierScore[]
  ) => {
    if (selectedCapacityIndicators.all) {
      return carriersToFilter;
    }

    const selectedFilters = Object.keys(selectedCapacityIndicators).filter(
      (k) => selectedCapacityIndicators[k]
    );

    const filterBy = [];
    for (const indicator of selectedFilters) {
      const compositeField = filterDictionaryBEFiltering[indicator];
      filterBy.push(compositeField);
    }

    const { carriers } = await getCompositeScore(
      authToken,
      shipmentId,
      filterBy
    );

    return carriers;
  };

  const handleSearch = (
    carriersToSearch: CompositeCarrierScore[],
    carriersSearched: string[]
  ): CompositeCarrierScore[] => {
    return carriersToSearch.filter((carrier) =>
      carriersSearched.includes(carrier.cseCarrierID)
    );
  };

  const handleSearchFilterCarrier = async (
    selectedCapacityIndicators: CapacityIndicatorFilter,
    CseCarrierIdsSearched: string[]
  ) => {
    const filteredCarriers = await filterByCapacityIndicator(
      selectedCapacityIndicators,
      topCarriers
    );

    const searchedCarriers = handleSearch(
      filteredCarriers,
      CseCarrierIdsSearched
    );
    setFilteredTopCarriers(searchedCarriers);
  };

  return (
    <ShipmentContext.Provider
      value={{
        topCarriers,
        fetchTopCarriers,
        isLoadingTopCarriers,
        shipmentDetails,
        fetchShipmentDetails,
        isLoadingShipmentDetails,
        locations,
        fetchLocations,
        setIsTentativelyCovered: handleSetIsTentativelyCovered,
        isTentativelyCovered,
        tentativelyCoveredBy,
        filteredTopCarriers,
        handleSearchFilterCarrier,
        ...testData,
      }}
    >
      {children}
    </ShipmentContext.Provider>
  );
};

const useShipmentContext = (): ShipmentContext => useContext(ShipmentContext);

export { ShipmentContext, ShipmentContextProvider, useShipmentContext };
