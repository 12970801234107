import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  compositeScore: number;
  datetime: string;
};

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#E9F9FF',
    borderRadius: 8,
    padding: 18,
  },
  title: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '125%',
  },
  compositeScore: {
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '48px',
  },
  datetime: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '125%',
  },
}));

const CompositeScore = ({ compositeScore, datetime }: Props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      className={classes.container}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item className={classes.title}>
        Composite Score
      </Grid>
      <Grid item className={classes.compositeScore}>
        {compositeScore.toFixed(3)}
      </Grid>
      <Grid item className={classes.datetime}>
        {datetime}
      </Grid>
    </Grid>
  );
};

export default CompositeScore;
