import axios from 'axios';
import { getApiRoot } from 'utils/apiUtils';

type Address = {
  city: string;
  region: string;
  zone?: string;
  timezone?: string;
  latitude: number;
  longitude: number;
};

type EquipmentDetails = {
  origin: Address;
  destination: Address[];
  postingDateTime: string;
  availableOn: string;
  availableUntil: string;
  equipmentType: string;
  comments: string | null;
  length: number | null;
  weight: number | null;
};

const getEquipmentDetails = async (
  authorization: string,
  equipmentID: string
): Promise<EquipmentDetails> => {
  const url = `${getApiRoot()}/carriers/equipment/${equipmentID}`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };

  const { data } = await axios.get(url, config);

  const cleanData = Object.entries(data)
    .filter(([_, v]) => !!v)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

  return cleanData as EquipmentDetails;
};

export { getEquipmentDetails };
export type { EquipmentDetails };
