import { Chip, Divider, Theme, Stack, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useShipmentContext } from 'shipment/ShipmentContext';
import ButtonV2 from '../components/library/ButtonV2';
import { Launch } from '@mui/icons-material';
import ActivityLog from 'features/cse/ActivityLog';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    marginRight: theme.spacing(2),
  },
  customer: {
    paddingRight: theme.spacing(2),
  },
  shipment: {
    paddingLeft: theme.spacing(2),
  },
  label: {
    fontWeight: 700,
  },
  chip: {
    borderColor: theme.palette.success.main,
  },
  turvoButton: {
    marginLeft: theme.spacing(1),
  },
  link: {
    textDecoration: 'none',
  },
}));

type Props = {
  customerName: string;
  shipmentId: string;
  turvoId: string;
  statusLabel: string;
};

const BasicInfo = ({
  customerName,
  shipmentId,
  turvoId,
  statusLabel,
}: Props) => {
  const classes = useStyles();
  const TURVO_LINK = process.env.REACT_APP_SHIPMENTS_TURVO_LINK;
  const { isTentativelyCovered } = useShipmentContext();
  /*
    Label should always be Tentatively Covered if the shipment is tentatively
    covered. Only hide the chip if both flags are off. If flag is on and not
    tentatively covered, show the shipment status.
   */
  const shipmentStatusLabel = isTentativelyCovered
    ? 'Tentatively Covered'
    : statusLabel;

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        <Box className={classes.item}>
          <span className={classes.label}>Customer: </span>
          {customerName}
        </Box>
        <Divider orientation="vertical" flexItem className={classes.item} />
        <Box className={classes.item}>
          <span className={classes.label}>Shipment ID: </span>
          {shipmentId}
        </Box>
        <Box className={classes.item}>
          <Chip
            label={shipmentStatusLabel}
            variant="outlined"
            size="small"
            className={classes.chip}
          />
        </Box>
        <Box className={classes.turvoButton}>
          <a
            href={`${TURVO_LINK}/${turvoId}`}
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            <ButtonV2 variant="outlined" endIcon={<Launch />}>
              See in Turvo
            </ButtonV2>
          </a>
        </Box>
      </Stack>
      <ActivityLog shipmentId={shipmentId} />
    </Stack>
  );
};

export default BasicInfo;
