import { useState, MouseEvent } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Typography,
  MenuItem,
  Popper,
  Paper,
  MenuList,
  ClickAwayListener,
  IconButton,
} from '@mui/material';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

import type { Equipment } from 'features/cse/Carrier/types';

const useStyles = makeStyles(({ spacing }) => ({
  dotsButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: 0,
  },
  popper: {
    width: 160,
    zIndex: 1,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: spacing(1),
  },
  paper: {
    width: 160,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: spacing(1),
  },
  menuItemText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#2D6CCB',
  },
  menuItem: {
    height: 38,
  },
}));

export type EquipmentDropDownTableActionsProps = {
  equipment: Equipment;
  handleSelection: (selectedEquipment: Equipment, action: string) => void;
  menuItems: string[];
};

const EquipmentDropDownTableActions = ({
  equipment,
  handleSelection,
  menuItems,
}: EquipmentDropDownTableActionsProps) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget as HTMLDivElement);
  };

  const handleClick = (value: string) => {
    handleSelection(equipment, value);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return menuItems.length > 0 ? (
    <>
      <IconButton
        className={classes.dotsButton}
        onClick={handleOpenMenu}
        data-testid={'equipment-actions-menu'}
        size="large"
        aria-describedby={'equipment-menu'}
      >
        <MoreVertOutlinedIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorEl}
        id={'equipment-menu'}
        placement="bottom-start"
        className={classes.popper}
      >
        <Paper className={classes.paper}>
          <ClickAwayListener
            onClickAway={() => {
              handleClose();
            }}
          >
            <MenuList>
              {menuItems.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={(event) => {
                      handleClick(item);
                      event.stopPropagation();
                    }}
                    className={classes.menuItem}
                  >
                    <Typography className={classes.menuItemText}>
                      {item}
                    </Typography>
                  </MenuItem>
                );
              })}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  ) : (
    <></>
  );
};

export default EquipmentDropDownTableActions;
