import { useEffect, useState } from 'react';

export default function useContainerDimensions(
  myRef: React.MutableRefObject<HTMLDivElement | null>
) {
  const getDimensions = () => ({
    width: myRef.current?.offsetWidth || 0,
    scrollWidth: myRef.current?.scrollWidth || 0,
    height: myRef.current?.offsetHeight || 0,
    scrollHeight: myRef.current?.scrollHeight || 0,
  });

  const [dimensions, setDimensions] = useState({
    width: 0,
    scrollWidth: 0,
    height: 0,
    scrollHeight: 0,
  });

  useEffect(() => {
    const handleResize = () => setDimensions(getDimensions());

    if (myRef.current) handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef]);

  return dimensions;
}
