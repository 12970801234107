import { emailValidator } from './fieldValidators';

type TextFieldProps = {
  placeholder?: string;
  name: string;
  label: string;
  required?: boolean;
  validator?: (value: string) => string | void;
  maxLength?: number;
  value?: string;
  disabled?: boolean;
  rows?: number;
  multiline?: boolean;
};

import { ChangeEvent } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField as MuiTextField } from '@mui/material';
import { Field } from 'react-final-form';
import { FormFieldError } from './FormFieldError';
import { stringValidator as defaultStringValidator } from './fieldValidators';

const useTextFieldStyles = makeStyles(() => ({
  asterisk: {
    color: '#c62828',
    fontSize: '1.2rem',
  },
}));

// Email needs to always use a validator even when it is not required
// but didn't want to introduce any side effects since TextField is prolific
//  so it is copied here with the validator always set
const TextField = ({
  name,
  placeholder,
  label,
  required = true,
  validator = defaultStringValidator,
  maxLength = 200,
  disabled,
  multiline,
}: TextFieldProps) => {
  const classes = useTextFieldStyles();
  return (
    <Field name={name} validate={validator}>
      {({ input, meta }) => {
        const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
          input.onChange(event.target.value);

        const placeholderProps = placeholder ? { shrink: true } : {};
        return (
          <div>
            <MuiTextField
              id={name}
              label={label}
              placeholder={placeholder}
              fullWidth
              variant="outlined"
              required={required}
              name={input.name}
              value={input.value}
              disabled={disabled}
              onChange={handleChange}
              error={false}
              inputProps={{ maxLength }}
              InputLabelProps={{
                ...placeholderProps,
                classes: {
                  asterisk: classes.asterisk,
                },
              }}
              onFocus={input.onFocus}
              onBlur={input.onBlur}
              multiline={multiline}
            />
            <FormFieldError meta={meta} />
          </div>
        );
      }}
    </Field>
  );
};

export const EmailField = (props: TextFieldProps) => {
  const validator = props.required
    ? (value?: string) => defaultStringValidator(value) || emailValidator(value)
    : emailValidator;

  return <TextField {...props} validator={validator} />;
};
