import { useState, useEffect } from 'react';

import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CircularProgress, Divider, Stack, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAuthorizationToken } from '@reibus/frontend-utility';
import { displayFormattingValue } from '@reibus/reibus-core-utils';
import { Field as FinalFormField, Form, FormSpy } from 'react-final-form';

import Button from 'components/library/Button';
import { NumberField } from 'components/library/form/NumberField';
import { Body, H1, H2 } from 'components/SteelUI/atoms/typography';
import Field from 'features/cse/AutoAward/components/AutoAwardModal/Field';

import { getMarginPercentage } from 'features/cse/AutoAward/components/AutoAwardModal/utils';
import useCarrierContactInfo from 'hooks/useCarrierContactInfo';
import useShipmentDetails from 'shipment/hooks/useShipmentDetails';
import OfferSent from '../OfferSent';
import { useManualAward } from './hooks/useManualOffer';
import { useSendManualOffer } from './hooks/useSendManualOffer';

import ButtonDropdown from 'components/SteelUI/molecules/ButtonDropdown';
import type { DigitalFreightAward } from 'features/cse/AutoAward/types';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  title: {
    marginBottom: spacing(2),
  },
  topTitle: {
    marginRight: spacing(1),
  },
  offerLabel: {
    marginBottom: spacing(1),
  },
  marginLabel: {
    marginBottom: spacing(3),
  },
  button: {
    width: '100%',
    marginTop: 80,
    height: 42,
    '&:hover': {
      backgroundColor: palette.primary.dark,
    },
  },
  spinWrap: {
    width: 400,
    height: 200,
  },
  error: {
    marginTop: spacing(2),
    textAlign: 'center',
  },
}));

type SendOfferProps = {
  autoAward?: DigitalFreightAward;
  carrierId: string;
  shipmentId: string;
  manualAwardSent: boolean;
  setManualAwardSent: (manualAwardSent: boolean) => void;
};

type FormData = {
  offerRate: number;
};

const SendOffer = ({
  autoAward,
  carrierId,
  shipmentId,
  manualAwardSent,
  setManualAwardSent,
}: SendOfferProps) => {
  const { rm15188SelectDigitalOfferEmails } = useFlags();
  const classes = useStyles();
  const { data: carrierContactInfo, isLoading: isContactLoading } =
    useCarrierContactInfo(carrierId, true);
  const { data: shipmentDetails, isLoading: isShipmentLoading } =
    useShipmentDetails(shipmentId);
  const authorization = useAuthorizationToken();
  const [allContacts, setAllContacts] = useState<string[]>([]);
  const [selectedContacts, setSelectedContacts] =
    useState<string[]>(allContacts);

  useEffect(() => {
    if (!isContactLoading && carrierContactInfo?.offerContacts?.length) {
      const allContacts = carrierContactInfo.offerContacts.map(
        ({ email }) => email
      );
      setAllContacts(allContacts);
      setSelectedContacts(allContacts);
    }
  }, [carrierContactInfo?.offerContacts, isContactLoading]);

  const { mutate: sendManualOffer, isLoading: isSubmitting } =
    useSendManualOffer({
      onSuccess: () => {
        setManualAwardSent(true);
      },
      onError: (error) => {
        setError(error?.response?.data?.error?.message);
      },
    });

  const { carrierName, offerContacts, offerRate, customerPay } = useManualAward(
    carrierContactInfo,
    shipmentDetails,
    autoAward
  );

  const [marginPercentage, setMarginPercentage] = useState(
    getMarginPercentage({ customerPay, offerAmount: offerRate })
  );
  const [currentOfferRate, setCurrentOfferRate] = useState(offerRate);
  const [error, setError] = useState('');

  const onChangeRate = (values: Record<string, number>) => {
    setMarginPercentage(
      getMarginPercentage({ customerPay, offerAmount: values.offerRate })
    );
    setCurrentOfferRate(values.offerRate);
  };

  const onSubmit = (values: FormData) => {
    const offerContacts = selectedContacts.map((email) => ({ email }));
    sendManualOffer({
      carrierId,
      shipmentId,
      offerAmount: values.offerRate,
      authorization,
      offerContacts,
    });
  };

  if (isContactLoading || isShipmentLoading) {
    return (
      <Box
        className={classes.spinWrap}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size={40} />
      </Box>
    );
  }

  if (manualAwardSent) {
    return (
      <OfferSent
        autoAward={{
          status: 'OPEN',
          notifiedAt: `${new Date()}`,
          offerAmount: currentOfferRate,
          customerPay,
          carrier: {
            carrierName,
            offerContacts: carrierContactInfo?.offerContacts ?? [],
          },
          shipment: {
            shipmentID: shipmentId,
          },
          sentTo: selectedContacts,
        }}
      />
    );
  }

  if (error) {
    return (
      <Box
        className={classes.spinWrap}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          size="3x"
          color="#FFB507"
        />
        <Body size="large" weight="semibold" className={classes.error}>
          {error}
        </Body>
      </Box>
    );
  }

  return (
    <Form initialValues={{ offerRate }} onSubmit={onSubmit}>
      {({ handleSubmit }) => {
        return (
          <Stack direction="column">
            <H1 weight="semibold" className={classes.title}>
              Send Offer
            </H1>
            <Stack
              direction="column"
              spacing={3}
              divider={<Divider orientation="horizontal" />}
            >
              <Stack direction="column" spacing={3}>
                <Stack direction="row" spacing={3}>
                  <Box display="flex">
                    <H2 weight="semibold" className={classes.topTitle}>
                      Carrier:
                    </H2>
                    <H2>{carrierName}</H2>
                  </Box>
                  <Box display="flex">
                    <H2 weight="semibold" className={classes.topTitle}>
                      Shipment ID:
                    </H2>
                    <H2>{shipmentId}</H2>
                  </Box>
                </Stack>
                {!rm15188SelectDigitalOfferEmails ? (
                  <Field label="Offer Sent To Email" value={offerContacts} />
                ) : (
                  <>
                    <ButtonDropdown
                      label="Select Email Recipients"
                      variant="outlined"
                      value={selectedContacts}
                      options={allContacts.map((contact) => ({
                        label: contact,
                        value: contact,
                      }))}
                      onSelect={setSelectedContacts}
                    />
                    <Field
                      label="Send To Email"
                      value={
                        !selectedContacts.length
                          ? 'None selected'
                          : selectedContacts.join(', ')
                      }
                      orientation="vertical"
                    />
                  </>
                )}
              </Stack>
              <Stack direction="column" spacing={2}>
                <H2 weight="semibold">Pay Information</H2>
                <FormSpy onChange={({ values }) => onChangeRate(values)} />
                <form onSubmit={handleSubmit}>
                  <Stack direction="row" spacing={7}>
                    <FinalFormField name="offerRate">
                      {() => {
                        return (
                          <Box display="flex" flexDirection="column">
                            <Body
                              size="large"
                              weight="semibold"
                              className={classes.offerLabel}
                            >
                              Offer Rate:
                            </Body>
                            <NumberField
                              label="Offer Rate"
                              name="offerRate"
                              thousandSeparator
                              prefix={'$'}
                              required={false}
                              decimalScale={2}
                              allowNegative={false}
                            />
                          </Box>
                        );
                      }}
                    </FinalFormField>
                    <Box display="flex" flexDirection="column">
                      <Body
                        size="large"
                        weight="semibold"
                        className={classes.marginLabel}
                      >
                        Margin:
                      </Body>
                      <Body size="large">
                        {customerPay && currentOfferRate
                          ? `${displayFormattingValue(marginPercentage, {
                              decimalPlaces: 0,
                              trailingZeros: false,
                            })}%`
                          : 'N/A'}
                      </Body>
                    </Box>
                  </Stack>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={
                      !currentOfferRate ||
                      !selectedContacts.length ||
                      isSubmitting
                    }
                  >
                    Send Offer
                  </Button>
                </form>
              </Stack>
            </Stack>
          </Stack>
        );
      }}
    </Form>
  );
};

export default SendOffer;
