import * as React from 'react';

import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import {
  Grid,
  Theme,
  Typography,
  Paper,
  Button as MuiButton,
  Box,
  useMediaQuery,
} from '@mui/material';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import theme from 'theme';

import { KeyAndUntypedValue } from 'api/types';
import Button from 'components/library/Button';
import ButtonV2 from 'components/library/ButtonV2';
import { HorizontalDivider } from 'components/icons/HorizontalDivider';

import { FormInput } from 'components/library/form/FormInput';
import {
  InputMeta,
  FormSection,
  FormConfig,
  FormStyle,
} from 'components/library/form/layoutTypes';

import type { FieldProps } from 'components/library/form/types';

const useStyles = makeStyles((theme: Theme) => ({
  enclosedButtonWrap: {
    padding: `0 ${theme.spacing(4)} ${theme.spacing(12)} ${theme.spacing(4)}`,
  },
  openFormRow: {
    padding: `${theme.spacing(4)} ${theme.spacing(8)}`,
  },
  enclosedFormRow: {
    padding: `${theme.spacing(4)}`,
  },
  formRowHeader: {
    marginBottom: theme.spacing(2),
  },
  formRowSubtitle: {
    marginBottom: theme.spacing(2),
    fontSize: theme.spacing(2),
  },
  enclosedRowHeader: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: 1.5,
    color: 'rgba(58, 58, 58, 1)',
  },
  formPaper: {
    zIndex: 1,
    backgroundColor: '#fff',
    marginBottom: theme.spacing(3),
  },
  enclosedFormPaper: {
    marginRight: theme.spacing(7),
  },
  formButtons: {
    paddingTop: 0,
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  cancelButton: {
    textTransform: 'none',
    fontSize: 16,
    textDecoration: 'underline',
    fontWeight: 700,
    color: 'rgba(146, 20, 12, 1)',
    '&.MuiButton-root:hover': {
      backgroundColor: 'transparent',
    },
  },
  outlinedCancel: {
    margin: theme.spacing(1),
    color: theme.palette.info.dark,
    borderColor: theme.palette.info.dark,
  },
  submitButton: {
    padding: `${theme.spacing(1)} ${theme.spacing(5)}`,
  },
  submitButtonv2: {
    margin: theme.spacing(1),
    padding: `${theme.spacing(1)} ${theme.spacing(5)}`,
  },
  enclosedSubmitButton: {
    width: '100%',
  },
  divider: {
    alignSelf: 'center',
    textAlign: 'center',
    color: '#868A8E',
  },
  dividerText: {
    margin: theme.spacing(1),
  },
  [theme.breakpoints.down('lg')]: {
    openFormRow: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
    },
  },
  [theme.breakpoints.down('md')]: {
    formRowHeader: {
      fontSize: '24px',
    },
    formButtons: {
      padding: `0px ${theme.spacing(3)}`,
    },
  },
}));

// maybe instead of filtering here, submit with data
const FormLayoutSection = ({
  inputDataForSection,
  fields,
  isReibusUser,
}: {
  inputDataForSection: FieldProps[];
  fields: InputMeta[];
  isReibusUser: boolean;
}) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(4),
        [theme.breakpoints.down('lg')]: {
          gridGap: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          gridTemplateColumns: 0,
        },
      }}
    >
      {fields.map((field, i) => {
        const inputDataForField = inputDataForSection.find(
          (el) => el.databaseName === field.databaseName
        ) as FieldProps;

        return isFieldDisplayed(inputDataForField, isReibusUser) ? (
          <FormInput
            key={i}
            inputDataForField={inputDataForField}
            field={field}
          />
        ) : null;
      })}
    </Box>
  );
};

const isFieldDisplayed = (
  inputDataForField: FieldProps,
  isReibusUser: boolean
) => {
  return inputDataForField
    ? (inputDataForField.displayForReibusPersonnelOnly && isReibusUser) ||
        !inputDataForField.displayForReibusPersonnelOnly
    : false;
};

const FormLayout = ({
  formLayoutData,
  inputData,
  formStyle,
  isReibusUser,
}: {
  formLayoutData: FormSection[];
  inputData: FieldProps[];
  formStyle: FormStyle;
  isReibusUser: boolean;
}) => {
  const classes = useStyles();
  const mobile = useMediaQuery('(max-width:450px)');

  const formRowClasses =
    formStyle === 'open' ? classes.openFormRow : classes.enclosedFormRow;
  const formElements = formLayoutData.map((section, i) => {
    const { fields } = section;
    const sectionFieldNames = fields.map((field) => field.databaseName);
    const inputDataForSection = inputData.filter((input) =>
      sectionFieldNames.includes(input.databaseName)
    );

    return (
      <React.Fragment key={section.title}>
        <Grid
          className={formRowClasses}
          container
          direction={mobile ? 'row' : 'column'}
          key={i}
        >
          <Typography
            className={clsx(classes.formRowHeader, {
              [classes.enclosedRowHeader]: formStyle === 'enclosed',
            })}
            variant="h3"
          >
            {section.title}
          </Typography>
          {section.subtitle ? (
            <Typography
              className={clsx(classes.formRowSubtitle, {
                [classes.enclosedRowHeader]: formStyle === 'enclosed',
              })}
            >
              {section.subtitle}
            </Typography>
          ) : null}

          <FormLayoutSection
            fields={fields}
            inputDataForSection={inputDataForSection}
            isReibusUser={isReibusUser}
          />
        </Grid>

        {section.hasDivider ? (
          <Box className={classes.divider}>
            <HorizontalDivider />
            &nbsp;
            <span className={classes.dividerText}>Or</span>
            &nbsp;
            <HorizontalDivider />
          </Box>
        ) : null}
      </React.Fragment>
    );
  });

  return <>{formElements}</>;
};

interface FormProps {
  onSubmit: (values: Record<string, string>) => void;
  onChange: (values: Record<string, string>) => void;
  onCancel?: () => void;
  inputData: FieldProps[];
  formConfig: FormConfig;
  submitting: boolean;
  formName: string;
  initialValues?: Partial<KeyAndUntypedValue>;
  isReibusUser?: boolean;
  disableSubmit?: boolean;
}

const FULL_WIDTH_FORMS = ['search-shipment', 'search-carrier'];

// pass in layout data and actual form data separately
export const Form = ({
  onSubmit,
  onChange,
  onCancel,
  formConfig,
  inputData,
  formName,
  submitting = false,
  initialValues,
  isReibusUser = false,
  disableSubmit = false,
}: FormProps) => {
  const classes = useStyles();
  const { formSections, formStyle, submitButtonText = 'SUBMIT' } = formConfig;
  const mobile = useMediaQuery('(max-width:450px)');

  return (
    <FinalForm initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, form: formState }) => (
        <>
          <FormSpy onChange={({ values }) => onChange(values)} />
          <FormSpy
            subscription={{
              values: true,
              hasValidationErrors: true,
            }}
          >
            {({ hasValidationErrors }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  id={formName}
                  noValidate
                  style={
                    FULL_WIDTH_FORMS.includes(formName)
                      ? { maxWidth: 'none' }
                      : {}
                  }
                >
                  <Paper
                    component="main"
                    className={clsx(classes.formPaper, {
                      [classes.enclosedFormPaper]:
                        formStyle === 'enclosed' && !mobile,
                    })}
                  >
                    <FormLayout
                      formLayoutData={formSections}
                      inputData={inputData}
                      formStyle={formStyle}
                      isReibusUser={isReibusUser}
                    />
                    {formStyle === 'enclosed' ? (
                      <div className={classes.enclosedButtonWrap}>
                        <Button
                          kind="alternate"
                          color="primary"
                          type="submit"
                          className={clsx(
                            classes.submitButton,
                            classes.enclosedSubmitButton
                          )}
                          data-testid="enclosed-button"
                          disabled={submitting || disableSubmit}
                        >
                          {submitButtonText}
                        </Button>
                      </div>
                    ) : null}
                    {formStyle === 'right' ? (
                      <Grid
                        justifyContent="flex-end"
                        className={classes.formButtons}
                        container
                      >
                        {/* TODO: Clear Search functionality
                         {onCancel ? (
                          <Button
                            kind="secondary"
                            onClick={() => {
                              formState.reset();
                              onCancel();
                            }}
                            hidden={true}
                            className={classes.outlinedCancel}
                          >
                            {cancelButtonText}
                          </Button>
                        ) : null} */}
                        <ButtonV2
                          type="submit"
                          className={classes.submitButtonv2}
                          data-testid="open-button"
                          isLoading={submitting}
                          disabled={submitting || !formState || disableSubmit}
                          onClick={() => {
                            if (hasValidationErrors) {
                              window.scroll({
                                top: 0,
                                behavior: 'smooth',
                              });
                            }
                          }}
                        >
                          {submitButtonText}
                        </ButtonV2>
                      </Grid>
                    ) : null}
                  </Paper>
                  {formStyle === 'open' ? (
                    <Grid
                      justifyContent="space-between"
                      className={classes.formButtons}
                      container
                    >
                      {onCancel ? (
                        <MuiButton
                          color="primary"
                          onClick={onCancel}
                          className={classes.cancelButton}
                        >
                          CANCEL
                        </MuiButton>
                      ) : null}
                      <Button
                        kind="primary"
                        color="primary"
                        type="submit"
                        className={classes.submitButton}
                        data-testid="open-button"
                        isLoading={submitting}
                        disabled={submitting || disableSubmit}
                        onClick={() => {
                          if (hasValidationErrors) {
                            window.scroll({
                              top: 0,
                              behavior: 'smooth',
                            });
                          }
                        }}
                      >
                        {submitButtonText}
                      </Button>
                    </Grid>
                  ) : null}
                </form>
              );
            }}
          </FormSpy>
        </>
      )}
    </FinalForm>
  );
};
