import { Stack, Theme, Box } from '@mui/material';
import PageWrapper from 'components/PageWrapper';
import Button from 'components/SteelUI/atoms/Button';
import { makeStyles } from '@mui/styles';
import { Launch } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginLeft: theme.spacing(1),
  },
  link: {
    textDecoration: 'none',
  },
}));

const PageNotFound = () => {
  const classes = useStyles();
  return (
    <PageWrapper isNewTheme>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ height: '800px' }}
      >
        <h1>Page Not Found</h1>
        <Box className={classes.button}>
          <a
            target="_blank"
            href="/logistics/request-for-quote"
            className={classes.link}
          >
            <Button variant="outlined" endIcon={<Launch />}>
              Do you want to get a freight quote?
            </Button>
          </a>
        </Box>
      </Stack>
    </PageWrapper>
  );
};

export default PageNotFound;
