import { Launch } from '@mui/icons-material';
import { Box, Card, CardContent, Collapse, Grid } from '@mui/material';
import { useState } from 'react';
import TopShipmentExpanded from './components/TopShipmentExpanded';
import Stop from './components/Stop';
import Distance from './components/Distance';
import { TooltipEllipsis } from 'components/TooltipEllipsis';
import Chip from 'components/library/Chip';
import CompositeScoreDetailsModal from 'features/cse/modals/CompositeScoreDetailsModal';
import EquipmentDetailsModal from 'features/cse/modals/EquipmentDetailsModal';
import HQDetailsModal from 'features/cse/modals/HQDetailsModal';
import LaneHistoryModal from 'features/cse/modals/LaneHistoryModal';
import { CarrierDetailResponse } from 'shipment/api';
import { Link } from 'react-router-dom';
import { trackEvent } from 'utils/mixpanel';
import ButtonV2 from 'components/library/ButtonV2';
import DispositionButton from 'features/cse/disposition/disposition-modal/DispositionButton';
import { useDispositionContext } from 'features/cse/disposition/contexts/DispositionContext';
import { formatCarrierStatus } from 'features/cse/Carrier/utils/data';
import {
  DispositionType,
  getShipmentDispositions,
} from 'features/cse/disposition/api';
import { useQuery } from 'react-query';
import DispositionNotesModal from 'features/cse/disposition/disposition-notes-modal/DispositionNotesModal';
import DispositionModal from 'features/cse/disposition/disposition-modal';
import { useAuthorizationToken } from '@reibus/frontend-utility';
import CapacityIndicators from './components/CapacityIndicators';

import type { ShipmentWithScores } from 'features/cse/Carrier/types';
import Header from 'features/cse/Carrier/components/CarrierOverview/components/TopShipments/components/TopShipment/components/Header';
import { useShipmentContext } from 'shipment/ShipmentContext';

type TopShipmentModals =
  | 'compositeScoreDetails'
  | 'lane'
  | 'dat'
  | 'hq'
  | 'req'
  | 'its';

type TopShipmentProp = {
  shipment: ShipmentWithScores;
  carrier: CarrierDetailResponse;
};

const TopShipment = ({ shipment, carrier }: TopShipmentProp) => {
  const authToken = useAuthorizationToken();
  const [expanded, setExpanded] = useState(false);
  const [openModal, setOpenModal] = useState<TopShipmentModals | undefined>(
    undefined
  );
  const [isDispositionNotesOpen, setIsDispositionNotesOpen] = useState(false);

  const TURVO_LINK = process.env.REACT_APP_SHIPMENTS_TURVO_LINK;
  const turvoId = shipment.id;
  const isStrategic = carrier.labels?.includes('STRATEGIC') ?? false;

  const { setDispositionData, refetchNotes, setRefetchNotes } =
    useDispositionContext();

  const handleDispositionClick = () =>
    setDispositionData({
      carrierName: carrier.carrierName ?? '',
      cseCarrierID: carrier.cseCarrierID,
      compositeScore: Number(shipment.scores.totalScore),
      shipmentId: shipment.shipmentID,
      carrierRankId: shipment.scores.id,
      carrierStatus: formatCarrierStatus(carrier.source, carrier.status),
      source: 'CARRIER',
      equipmentIds: shipment.scores.rankedEquipment.map(
        ({ equipmentID }) => equipmentID
      ),
    });

  const {
    isLoading: isDispositionNotesLoading,
    data: dispositionNotes = [],
    refetch: refetchDispositionNotes,
  } = useQuery(
    [
      'shipmentDispositionDetails',
      carrier.cseCarrierID,
      shipment.shipmentID,
      ['OTHER', 'DECLINE'],
    ],
    () =>
      getShipmentDispositions(
        authToken,
        shipment.shipmentID,
        carrier.cseCarrierID,
        ['OTHER', 'DECLINE']
      ),
    { enabled: !!authToken }
  );

  if (refetchNotes) {
    setRefetchNotes(false);
    refetchDispositionNotes();
  }

  const handleOpenDispositionModal = () => {
    setIsDispositionNotesOpen(true);
  };

  const handleCloseDispositionModal = () => {
    setIsDispositionNotesOpen(false);
  };

  const getEquipmentID = (source: string) =>
    shipment.scores.rankedEquipment.find(
      (equipment) => equipment.loadBoardType === source
    )?.equipmentID;

  const reqEquipmentID = getEquipmentID('REIBUS');
  const datEquipmentID = getEquipmentID('DAT');
  const itsEquipmentID = getEquipmentID('ITS');

  return (
    <Card variant="outlined">
      <Header
        expanded={expanded}
        setExpanded={setExpanded}
        digitalFreightAward={shipment?.digitalFreightAward}
        carrierId={carrier.cseCarrierID}
        shipmentId={shipment.shipmentID}
        isStrategic={isStrategic}
      />
      <CardContent sx={{ pt: 0 }}>
        <Grid
          container
          columns={7}
          justifyContent="space-between"
          sx={{ pr: '64px' }}
        >
          <Grid item xs={1}>
            <Link
              to={`/shipment/${shipment.shipmentID}`}
              target="_blank"
              onClick={() =>
                trackEvent(
                  'Clicked a shipment link in CSE Carrier Top Shipments',
                  { shipmentID: shipment.shipmentID }
                )
              }
            >
              <ButtonV2 variant="text" endIcon={<Launch />} sx={{ p: 0 }}>
                {shipment.shipmentID}
              </ButtonV2>
            </Link>
            <Box sx={{ pt: '8px' }}>
              <a
                href={`${TURVO_LINK}/${turvoId}`}
                target="_blank"
                rel="noreferrer"
                style={{ fontSize: '12px', textDecoration: 'none' }}
              >
                <ButtonV2
                  variant="outlined"
                  endIcon={<Launch />}
                  sx={{ fontSize: '12px' }}
                >
                  See in Turvo
                </ButtonV2>
              </a>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Stop
              stopType="Pickup Date"
              address={shipment.originAddress}
              date={shipment.startDateDetailed}
            />
          </Grid>
          <Grid item xs={1}>
            <Distance distance={shipment.distance} />
          </Grid>
          <Grid item xs={1}>
            <Stop
              stopType="Delivery Date"
              address={shipment.destinationAddress}
              date={shipment.endDateDetailed}
              route={shipment.route}
            />
          </Grid>
          <Grid item xs={1}>
            <Box
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '125%',
                textAlign: 'center',
              }}
            >
              <TooltipEllipsis
                textValue={shipment.equipmentNeededDesc ?? 'N/A'}
              />
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Chip
              label={`Score ${Number(shipment.scores.totalScore).toFixed(3)}`}
              color="secondary"
              onClick={() => setOpenModal('compositeScoreDetails')}
              sx={{
                background: 'rgba(45, 157, 120, 0.2)',
                '&:hover, &:focus': {
                  backgroundColor: 'rgba(45, 157, 120, 0.2)',
                  cursor: 'pointer',
                },
              }}
            />
            <CapacityIndicators
              setOpenModal={setOpenModal}
              scores={shipment.scores}
            />
          </Grid>
          <Grid item xs={1}>
            <DispositionButton
              disabled={
                shipment.shipmentDispositions.length
                  ? shipment.shipmentDispositions[0].disposition ===
                    ('COVERED' as DispositionType)
                  : false
              }
              type={dispositionNotes?.[0]?.disposition}
              reason={dispositionNotes?.[0]?.reason}
              onClick={handleDispositionClick}
            />
            {!isDispositionNotesLoading && dispositionNotes.length ? (
              <Grid container item xs={12}>
                <ButtonV2
                  variant="text"
                  sx={{ ml: 2 }}
                  onClick={handleOpenDispositionModal}
                >
                  View Notes
                </ButtonV2>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </CardContent>
      <Collapse in={expanded}>
        <CardContent sx={{ width: '100%', padding: '0 !important' }}>
          <TopShipmentExpanded shipment={shipment} />
        </CardContent>
      </Collapse>
      <CompositeScoreDetailsModal
        isOpen={openModal === 'compositeScoreDetails'}
        handleClose={() => setOpenModal(undefined)}
        totalScore={Number(shipment.scores.totalScore)}
        scoringTime={shipment.scores.createdAt}
        indicators={shipment.indicators}
      />
      <LaneHistoryModal
        isOpen={openModal === 'lane'}
        handleClose={() => setOpenModal(undefined)}
        carrierName={carrier.carrierName}
        carrierID={carrier.cseCarrierID}
        reibusId={shipment.id}
      />
      {!datEquipmentID ? null : (
        <EquipmentDetailsModal
          isOpen={openModal === 'dat'}
          handleClose={() => setOpenModal(undefined)}
          equipmentID={datEquipmentID}
          loadboard={'DAT'}
        />
      )}
      {!itsEquipmentID ? null : (
        <EquipmentDetailsModal
          isOpen={openModal === 'its'}
          handleClose={() => setOpenModal(undefined)}
          equipmentID={itsEquipmentID}
          loadboard={'ITS'}
        />
      )}
      {!reqEquipmentID ? null : (
        <EquipmentDetailsModal
          isOpen={openModal === 'req'}
          handleClose={() => setOpenModal(undefined)}
          equipmentID={reqEquipmentID}
          loadboard={'REIBUS'}
        />
      )}
      <HQDetailsModal
        isOpen={openModal === 'hq'}
        handleClose={() => setOpenModal(undefined)}
        headquarter={carrier.headquarter}
      />
      <DispositionNotesModal
        isOpen={isDispositionNotesOpen}
        handleClose={handleCloseDispositionModal}
        carrierName={carrier.carrierName}
        dispositions={dispositionNotes ?? []}
      />
      <DispositionModal />
    </Card>
  );
};

export default TopShipment;
export type { TopShipmentModals };
