import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  svg: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
}));

export const HorizontalDivider = () => {
  const classes = useStyles();
  return (
    <svg
      width="41"
      height="1"
      viewBox="0 0 41 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.svg}
    >
      <path d="M0.5 0.5L40.5 0.5" stroke="#868A8E" />
    </svg>
  );
};
