import { makeStyles } from '@mui/styles';
import { Theme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import {
  SocialMediaDataType,
  SocialMediaButton,
} from 'components/SocialMediaButton';
import Button from 'components/library/Button';
import ShortFooter from './ShortFooter';
import BrandClearIcon from './icons/BrandClearIcon';
import { useFlags } from 'launchdarkly-react-client-sdk';

const currenYear = new Date().getFullYear();

const socialMediaData: SocialMediaDataType[] = [
  {
    link: 'https://www.linkedin.com/company/reibus-international',
    image:
      'https://res.cloudinary.com/reibus/image/upload/v1627057496/Reibus%20Finance/zlxbzppfi5qyw7rjynov.svg',
    label: 'linkedin',
  },
  {
    link: 'https://www.youtube.com/channel/UCBkSgdzBqtg5kjFxq1Js76Q',
    image:
      'https://res.cloudinary.com/reibus/image/upload/v1627057516/Reibus%20Finance/cjzigf8xmua92dszbqfs.svg',
    label: 'youtube',
  },
  {
    link: 'https://twitter.com/reibusinternat1',
    image:
      'https://res.cloudinary.com/reibus/image/upload/v1627057509/Reibus%20Finance/pywnmca3tpjirnkxhv1s.svg',
    label: 'twitter',
  },
];

const appsData: SocialMediaDataType[] = [
  {
    link: 'https://play.google.com/store/apps/details?id=com.reibus.app',
    image:
      'https://res.cloudinary.com/reibus/image/upload/v1674494205/Reibus%20Logistics/qtql4w9iynrdwqleuqvb.svg',
    label: 'Reibus International App on Google Play Store',
  },
  {
    link: 'https://apps.apple.com/us/app/reibus/id1567403369',
    image:
      'https://res.cloudinary.com/reibus/image/upload/v1674494212/Reibus%20Logistics/i0pvdnq18arq7tkukogb.svg',
    label: 'Reibus International App on Apple App Store',
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing(4)} 0 ${theme.spacing(8)}`,
  },
  gridWrap: {
    maxWidth: 1040,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexGrow: 1,
  },
  logoAndLinks: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.primary.contrastText,
  },
  link: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  resourcesList: {
    fontSize: 14,
    fontWeight: 700,
    listStyleType: 'none',
    whiteSpace: 'nowrap',
    marginLeft: 150,
    marginTop: 0,
    paddingLeft: 0,
    '& li': {
      marginBottom: (fullFooter) => (fullFooter ? 'calc(1.45rem / 2)' : null),
    },
  },
  policyLink: {
    opacity: 0.5,
    color: theme.palette.primary.contrastText,
    fontSize: 14,
    listStyleType: 'none',
    whiteSpace: 'nowrap',
    marginTop: 0,
    textDecoration: 'none',
    marginLeft: '1.45rem',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  policies: {
    listStyle: 'none',
    whiteSpace: 'nowrap',
    marginTop: -2,
    marginBottom: 11,
    paddingLeft: 0,
    '& li': {
      marginBottom: 8,
      marginTop: 0,
    },
  },
  tagline: {
    fontSize: 14,
    lineHeight: 1,
    letterSpacing: 0.5,
    marginTop: 10,
    whiteSpace: 'nowrap',
  },
  social: {
    margin: `${theme.spacing(4)} 0`,
  },
  socialIcon: {
    marginRight: 11,
  },
  footerCard: {
    minWidth: 262,
    alignItems: 'center',
    border: '1px solid #ddd',
    borderRadius: 8,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
    color: '#000',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    marginTop: -75,
    padding: '35px 30px 30px',
  },
  cardTitle: {
    fontSize: theme.typography.h4.fontSize,
    marginBottom: 16,
  },
  cardText: {
    lineHeight: '24px',
    marginBottom: 24,
    maxWidth: 200,
    letterSpacing: 0.5,
    textAlign: 'center',
    fontSize: theme.typography.body1.fontSize,
  },
  listWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  fullFooterHide: {
    visibility: 'hidden',
  },
  copyRights: {
    marginTop: 9,
    fontSize: 12.8,
    paddingTop: 18,
    borderTop: '1px solid hsla(0,0%,100%,.3)',
  },
  mobileLinks: {
    listStyleType: 'none',
    marginTop: 0,
    paddingLeft: 0,
  },
  mobileAppLink: {
    whiteSpace: 'nowrap',
    textAlign: 'center',
    marginLeft: '1.45rem',
    marginBottom: 4,
    '& li': {
      marginBottom: 5,
    },
  },
  mobileAppImage: {
    whiteSpace: 'nowrap',
    minWidth: 130,
  },
  logoContainer: {
    flexGrow: 0,
  },
  policiesColumn: {
    marginLeft: 100,
  },
  [theme.breakpoints.down('lg')]: {
    gridWrap: {
      maxWidth: '90vw',
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    root: {
      marginTop: 100,
    },
    cardTitle: {
      fontSize: theme.typography.h3.fontSize,
      fontWeight: 'bold',
    },
    cardText: {
      maxWidth: '100%',
    },
    footerCard: {
      width: '100%',
      marginTop: theme.spacing(-20),
      marginBottom: theme.spacing(5),
    },
    resourcesList: {
      marginLeft: theme.spacing(8),
    },
    policiesColumn: {
      marginLeft: theme.spacing(8),
    },
    linksContainer: {
      marginTop: theme.spacing(4),
    },
    mobileLinks: {
      marginTop: theme.spacing(3),
    },
    copyRights: {
      textAlign: 'center',
    },
    cardContainer: {
      padding: `0 10%`,
    },
  },
  [theme.breakpoints.down(725)]: {
    gridWrap: {
      maxWidth: '80vw',
    },
  },
  [theme.breakpoints.down(720)]: {
    listWrapper: {
      flexDirection: 'column',
    },
    linksContainer: {
      marginTop: 0,
    },
    policies: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
      margin: `${theme.spacing(2)} 0`,
      justifyContent: 'center',
    },
    resourcesList: {
      display: 'flex',
      flexDirection: 'row',
      gap: `0 ${theme.spacing(2)}`,
      flexWrap: 'wrap',
      margin: `0 auto`,
      justifyContent: 'center',
      padding: `0 ${theme.spacing(18)}`,
    },
    social: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: `0 ${theme.spacing(2)}`,
    },
    tagline: {
      marginTop: theme.spacing(3),
    },
    policiesColumn: {
      margin: `0 auto`,
    },
    policyLink: {
      marginLeft: 0,
    },
    mobileLinks: {
      padding: 0,
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
      margin: 0,
    },
    mobileAppLink: {
      marginLeft: 0,
    },
    copyRights: {
      textAlign: 'center',
    },
    cardTitle: {
      fontSize: 20,
    },
    cardContainer: {
      padding: `0 ${theme.spacing(1)}`,
    },
    root: {
      marginTop: theme.spacing(10),
    },
  },
  [theme.breakpoints.down(686)]: {
    resourcesList: {
      padding: `0 ${theme.spacing(15)}`,
    },
  },
  [theme.breakpoints.down(626)]: {
    resourcesList: {
      padding: `0 ${theme.spacing(12)}`,
    },
  },
  [theme.breakpoints.down(566)]: {
    resourcesList: {
      padding: `0 ${theme.spacing(10)}`,
    },
  },
  [theme.breakpoints.down(526)]: {
    resourcesList: {
      padding: `0 ${theme.spacing(8)}`,
    },
  },
  [theme.breakpoints.down(486)]: {
    resourcesList: {
      padding: `0 ${theme.spacing(5)}`,
    },
  },
  [theme.breakpoints.down(430)]: {
    resourcesList: {
      padding: `0 ${theme.spacing(3)}`,
    },
  },
}));

type FooterLink = {
  label: string;
  path: string;
  isAppLink?: boolean;
};

const mainFooterLinks: FooterLink[] = [
  { label: 'Buying', path: '/buying' },
  { label: 'Selling', path: '/selling' },
  { label: 'Logistics', path: '/', isAppLink: true },
  { label: 'Careers', path: '/careers' },
  { label: 'About Us', path: '/about-us' },
];

const tertiaryFooterLinks: FooterLink[] = [
  { label: 'Privacy Policy', path: '/privacy-policy' },
  { label: 'Terms of Use', path: '/terms-of-use' },
];

interface Props {
  fullFooter?: boolean;
  showDemo?: boolean;
  showShortFooter?: boolean;
}

const Footer = ({ fullFooter = true, showDemo = true }: Props) => {
  const classes = useStyles(fullFooter);
  const mobile = useMediaQuery('600px');
  const { rl413ShowStoreAppOnFooter } = useFlags();

  const MainLinks = () => {
    return (
      <ul className={classes.resourcesList}>
        {mainFooterLinks.map(({ path, label, isAppLink }, i) => (
          <li key={i}>
            {isAppLink ? (
              <Link className={classes.link} to={path}>
                {label}
              </Link>
            ) : (
              <a className={classes.link} href={path}>
                {label}
              </a>
            )}
          </li>
        ))}
      </ul>
    );
  };

  const TertiaryLinks = () => {
    return (
      <div className={classes.policiesColumn}>
        <ul className={classes.policies}>
          {tertiaryFooterLinks.map((footerLink, i) => (
            <li key={i}>
              <a className={classes.policyLink} href={footerLink.path}>
                {footerLink.label}
              </a>
            </li>
          ))}
        </ul>
        {rl413ShowStoreAppOnFooter ? (
          <ul className={classes.mobileLinks}>
            {appsData.map((soc, i) => (
              <li className={classes.mobileAppLink} key={i}>
                <SocialMediaButton socialMedia={soc} />
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    );
  };

  return (
    <footer className={classes.root}>
      <Grid className={classes.gridWrap}>
        <div className={classes.logoAndLinks}>
          <Grid
            container
            className={classes.linksContainer}
            direction={mobile ? 'column' : 'row'}
            alignItems={mobile ? 'center' : 'flex-start'}
            justifyContent={mobile ? 'flex-start' : 'center'}
          >
            <Grid item xs={mobile && 12} className={classes.logoContainer}>
              <BrandClearIcon width={207} />
              <div className={classes.tagline}>
                Reinventing Industrial Business
              </div>
              <div className={classes.social}>
                {socialMediaData.map((soc, i) => (
                  <SocialMediaButton key={i} socialMedia={soc} />
                ))}
              </div>
            </Grid>
            <Grid xs={mobile && 12} item className={classes.listWrapper}>
              <MainLinks />
              <TertiaryLinks />
            </Grid>
          </Grid>
          <div className={classes.copyRights}>
            © {currenYear} Reibus International, Inc. All Rights Reserved. Tel:
            (470) 482-1820 · 1 Glenlake Parkway Northeast, Suite 300 Atlanta, GA
            30328
          </div>
        </div>
        <div className={classes.cardContainer}>
          {fullFooter && showDemo ? (
            <div className={classes.footerCard}>
              <div className={classes.cardTitle}>Start for free!</div>
              <div className={classes.cardText}>
                Not just another platform, But real results for all sides of the
                industry.
              </div>
              <a className={classes.link} href="/sign-up">
                <Button
                  kind="primary"
                  color="primary"
                  style={{
                    width: 174,
                  }}
                >
                  Schedule A Demo
                </Button>
              </a>
            </div>
          ) : null}
        </div>
      </Grid>
    </footer>
  );
};

const FooterDisplay = ({ showShortFooter = false, ...props }: Props) => {
  return showShortFooter ? <ShortFooter /> : <Footer {...props} />;
};

export default FooterDisplay;
