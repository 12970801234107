import { Stack } from '@mui/material';
import { Body } from 'components/SteelUI/atoms/typography';

type Props = {
  label: string;
  value: string;
  orientation?: 'horizontal' | 'vertical';
};

const Field = ({ label, value, orientation = 'horizontal' }: Props) => {
  return orientation === 'horizontal' ? (
    <Stack direction="row" spacing={1}>
      <Body size="medium" weight="bold">
        {label}:
      </Body>
      <Body size="medium">{value}</Body>
    </Stack>
  ) : (
    <Stack direction="column" spacing={2}>
      <Body size="medium" weight="bold">
        {label}:
      </Body>
      <Body size="medium">{value}</Body>
    </Stack>
  );
};

export default Field;
