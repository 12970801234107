import axios from 'axios';

import { getApiRoot } from 'utils/apiUtils';

type ShipmentDisposition = {
  shipmentID: string;
  shipmentId: string;
  carrierId?: string;
  cseCarrierID: string;
  disposition: DispositionType;
  compositeScore: number;
  comments?: string;
  reason?: DispositionReason;
  subreason?: DispositionSubreason;
  subreasonExplanation?: string;
  createdAt?: string;
  user?: {
    id: string;
    name: string;
    email: string;
  };
  userEmail?: string;
  carrierRankId?: string;
  source: DispositionSource;
  carrier?: {
    cseCarrierID: string;
    name: string;
  };
};

type ShipmentDispositionData = {
  shipmentId: string;
  carrierId?: string;
  cseCarrierID: string;
  disposition: DispositionType;
  compositeScore: number;
  comments?: string;
  reason?: DispositionReason;
  subreason?: DispositionSubreason;
  subreasonExplanation?: string;
  createdAt?: string;
  user?: {
    name: string;
  };
  userEmail?: string;
  carrierRankId?: string;
  source: DispositionSource;
  equipmentIds?: string[];
  deleteEquipment?: boolean;
};

type DispositionType = 'COVER' | 'DECLINE' | 'OTHER';
type DispositionSource = 'SHIPMENT' | 'CARRIER' | 'DIGITAL_FREIGHT_AWARD';

type DispositionReason =
  | 'EQUIPMENT'
  | 'PREFERENCE'
  | 'PRICE'
  | 'CONTACTED'
  | 'WRONG_INFO'
  | 'OTHER';

type DispositionSubreason =
  | 'EQUIPMENT_NOT_AVAILABLE'
  | 'DOES_NOT_MATCH_REQUIREMENTS'
  | 'LANE'
  | 'ORIGIN'
  | 'DESTINATION'
  | 'COMMODITY_TYPE'
  | 'NO_ANSWER'
  | 'WILL_GET_BACK'
  | 'PHONE_NUMBER'
  | 'EMAIL';

const postShipmentDisposition = async (
  authorization: string,
  shipmentDispositionData: ShipmentDispositionData
) => {
  const { shipmentId, cseCarrierID, compositeScore, disposition, source } =
    shipmentDispositionData;
  if (
    !shipmentId ||
    !cseCarrierID ||
    !compositeScore ||
    !disposition ||
    !source
  ) {
    throw new Error('missing required data for postShipmentDisposition');
  }
  const url = `${getApiRoot()}/shipments/${shipmentId}/disposition`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };
  const data = JSON.stringify({
    ...shipmentDispositionData,
  });

  await axios.post(url, data, config);
};

const postCoverShipmentDisposition = async (
  authorization: string,
  data: ShipmentDispositionData
) => {
  await postShipmentDisposition(authorization, {
    ...data,
    disposition: 'COVER',
  });
};

const equipmentSubreasons: DispositionSubreason[] = [
  'EQUIPMENT_NOT_AVAILABLE',
  'DOES_NOT_MATCH_REQUIREMENTS',
];

const preferenceSubreasons: DispositionSubreason[] = [
  'LANE',
  'ORIGIN',
  'DESTINATION',
  'COMMODITY_TYPE',
];

const postDeclineShipmentDisposition = async (
  authorization: string,
  data: ShipmentDispositionData
) => {
  const { reason, subreason, subreasonExplanation, comments, carrierRankId } =
    data;
  if (!carrierRankId) {
    throw new Error('DECLINE requires carrierRankId');
  }
  switch (reason) {
    case 'EQUIPMENT':
      if (!subreason || !equipmentSubreasons.includes(subreason))
        throw new Error(
          `cannot decline shipment with EQUIPMENT of ${subreason}`
        );
      break;
    case 'PREFERENCE':
      if (!subreason || !preferenceSubreasons.includes(subreason))
        throw new Error(
          `cannot decline shipment with PREFERENCE of ${subreason}`
        );
      break;
    case 'PRICE':
      if (!subreasonExplanation)
        throw new Error(
          'must include explicit value if declining shipment with PRICE'
        );
      break;
    case 'OTHER':
      if (!comments)
        throw new Error(
          'must include comments if declining shipment with OTHER'
        );
      break;
    default:
      throw new Error('invalid reason for declining shipment');
  }

  await postShipmentDisposition(authorization, {
    ...data,
    disposition: 'DECLINE',
  });
};

const contactedSubreasons: DispositionSubreason[] = [
  'NO_ANSWER',
  'WILL_GET_BACK',
  'EMAIL',
];

const wrongInfoSubreasons: DispositionSubreason[] = ['PHONE_NUMBER', 'EMAIL'];

const postOtherShipmentDisposition = async (
  authorization: string,
  data: ShipmentDispositionData
) => {
  const { reason, subreason, comments } = data;
  switch (reason) {
    case 'CONTACTED':
      if (!subreason || !contactedSubreasons.includes(subreason))
        throw new Error(
          `subreason ${subreason} not valid with reason CONTACTED`
        );
      break;
    case 'WRONG_INFO':
      if (!subreason || !wrongInfoSubreasons.includes(subreason))
        throw new Error(
          `subreason ${subreason} not valid with reason WRONG_INFO`
        );
      break;
    case 'OTHER':
      if (!comments)
        throw new Error('must include comments if reason is OTHER');
      break;
    default:
      throw new Error('invalid reason for OTHER');
  }

  await postShipmentDisposition(authorization, {
    ...data,
    disposition: 'OTHER',
  });
};

const getShipmentDispositions = async (
  authorization: string,
  shipmentId: string,
  carrierId: string,
  disposition: DispositionType[]
) => {
  if (!shipmentId || !carrierId || !disposition) {
    throw new Error('missing required data for getShipmentDispositionDetails');
  }
  const url = `${getApiRoot()}/shipments/${shipmentId}/dispositions/carrier/${carrierId}?type=${disposition.join(
    ','
  )}`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };

  const { status, statusText, data } = await axios.get(url, config);

  if (status !== 200) {
    throw new Error(statusText);
  }

  return data;
};

const getDispositionsByShipmentId = async (
  authorization: string,
  shipmentId: string
): Promise<ShipmentDisposition[]> => {
  if (!authorization) {
    throw new Error('missing authorization when querying dispositions');
  }
  if (!shipmentId) {
    throw new Error('missing shipmentId when querying dispositions');
  }
  const url = `${getApiRoot()}/shipments/${shipmentId}/dispositions`;
  const config = {
    headers: {
      Authorization: authorization,
    },
  };

  const {
    data: { dispositions = [] },
  } = await axios.get(url, config);

  return dispositions;
};

export type {
  ShipmentDispositionData,
  DispositionType,
  DispositionReason,
  DispositionSubreason,
  DispositionSource,
  ShipmentDisposition,
};
export {
  postCoverShipmentDisposition,
  postDeclineShipmentDisposition,
  postOtherShipmentDisposition,
  getShipmentDispositions,
  getDispositionsByShipmentId,
};
