import React from 'react';
import SharedShipment from 'shared-shipment';
import { GoogleMapsScriptProvider } from 'hooks/useGoogleMapsScript';

export interface SharedShipmentPageProps {
  isDecoded?: boolean;
}

const SharedShipmentPage: React.FC<SharedShipmentPageProps> = ({
  isDecoded = false,
}) => {
  return (
    <GoogleMapsScriptProvider>
      <SharedShipment isDecoded={isDecoded} />
    </GoogleMapsScriptProvider>
  );
};

export default SharedShipmentPage;
