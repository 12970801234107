import { createTheme, adaptV4Theme } from '@mui/material';

const primary = 'rgba(9, 10, 13, 0.7)';
const f1 = '3.052rem';
const f2 = '2.441rem';
const f3 = '1.953rem';
const f4 = '1.563rem';
const f5 = '1.25rem';
const f6 = '1rem';
const f7 = '0.875rem';

const theme = createTheme({
  ...adaptV4Theme({
    breakpoints: {
      // setting to Mui4 values
      // new values here: https://mui.com/material-ui/migration/v5-component-changes/#breakpoint-sizes
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: {
      fontFamily: [
        `Montserrat`,
        `Lato`,
        `Mulish`,
        `-apple-system`,
        `BlinkMacSystemFont`,
        `"avenir next"`,
        `avenir`,
        `"helvetica neue"`,
        `helvetica`,
        `ubuntu`,
        `roboto`,
        `noto`,
        `"segoe ui"`,
        `arial`,
        `sans-serif`,
      ].join(','),
      h1: {
        fontSize: f1,
        fontWeight: 700,
        lineHeight: 1,
        marginBottom: '1.45rem',
        color: 'rgba(9, 10, 13, 1)',
      },
      h2: {
        fontSize: f2,
        fontWeight: 700,
        lineHeight: 1,
        marginBottom: '1.45rem',
        color: 'rgba(9, 10, 13, 1)',
      },
      h3: {
        fontSize: f3,
        fontWeight: 700,
        lineHeight: 1,
        marginBottom: '1.45rem',
      },
      h4: {
        fontSize: f4,
        fontWeight: 700,
        lineHeight: 1,
        marginBottom: '1.45rem',
      },
      h5: {
        fontSize: f5,
        fontWeight: 700,
        lineHeight: 1,
        marginBottom: '1.45rem',
      },
      h6: {
        fontSize: f6,
        fontWeight: 700,
        lineHeight: 1,
        marginBottom: '1.45rem',
      },
      body1: { fontSize: f6, fontWeight: 400, lineHeight: 1.5 },
      body2: {
        fontSize: f7,
        fontWeight: 400,
        lineHeight: 1.5,
      },
      fontWeightBold: 700,
      subtitle1: {
        fontSize: f5,
        fontWeight: 700,
        lineHeight: 1,
        marginBottom: '1.45rem',
        letterSpacing: '-0.01562em',
      },
      subtitle2: {
        fontSize: f6,
        fontWeight: 400,
        lineHeight: 1,
        marginBottom: '0.5rem',
        letterSpacing: '-0.01562em',
      },
    },
    overrides: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontSize: '1rem',
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#5d0549',
        contrastText: '#ffffff',
        light: '#5d0549d5',
      },
      secondary: {
        main: '#0d908d',
        contrastText: '#fff',
        light: '#0d908dd5',
      },
      error: {
        main: '#9c0633',
        contrastText: '#fff',
      },
      text: {
        primary: primary,
      },
      info: {
        main: '#707070',
        dark: '#2D6CCB',
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
  }),
});

// Implementing 2022 design system as a separate theme to minimize conflicts with older pages
const REIBUS_MENU_PURPLE = '#5D0549';
const REIBUS_MED_PURPLE = '#88076A';
const REIBUS_BLUE_STEEL = '#2D6CCB';
const REIBUS_BLUE_STEEL_LIGHT = '#EAF2FF';
const REIBUS_BLUE_STEEL_DARK = '#215199';
const REIBUS_MED_BLUE = '#215199';
const REIBUS_MARINE_BLUE = '#5DD2FC';
const REIBUS_GREEN = '#2D9D78';
const REIBUS_MED_GREEN = '#107154';
const REIBUS_RED = '#BB121A';
const REIBUS_YELLOW = '#FFB507';
const BLACK = '#212224';
const WHITE = '#FFFFFF';
const GRAY_800 = '#434547';
const GRAY_700 = '#64676B';
const GRAY_600 = '#868A8E';
const GRAY_500 = '#A7ACB2';
const GRAY_400 = '#B9BDC1';
const GRAY_300 = '#CACDD1';
const GRAY_200 = '#DCDEE0';
const GRAY_100 = '#EDEEF0';
const GRAY_50 = '#F6F7F7';
const DEFAULT_FONT_COLOR = '#34363B';
const FONT_WEIGHT_REGULAR = 400;
const FONT_WEIGHT_BOLD = 700;
const FONT_WEIGHT_LIGHT = 300;

const newTheme = createTheme({
  ...adaptV4Theme({
    breakpoints: {
      // setting to Mui4 values
      // new values here: https://mui.com/material-ui/migration/v5-component-changes/#breakpoint-sizes
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    overrides: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontSize: '1rem',
          },
        },
      },
    },
    typography: {
      fontFamily: [
        `Montserrat`,
        `Lato`,
        `Mulish`,
        `-apple-system`,
        `BlinkMacSystemFont`,
        `"avenir next"`,
        `avenir`,
        `"helvetica neue"`,
        `helvetica`,
        `ubuntu`,
        `roboto`,
        `noto`,
        `"segoe ui"`,
        `arial`,
        `sans-serif`,
      ].join(','),
      // h1 is TITLE DESKTOP
      h1: {
        fontSize: 32,
        fontWeight: FONT_WEIGHT_BOLD,
        letterSpacing: 0,
        lineHeight: '52px',
        color: DEFAULT_FONT_COLOR,
      },
      // h2 is Heading 1 Desktop
      h2: {
        fontSize: 32,
        fontWeight: FONT_WEIGHT_BOLD,
        letterSpacing: 0,
        lineHeight: '52px',
        color: DEFAULT_FONT_COLOR,
      },
      // h3 is Heading 2 Desktop
      h3: {
        fontSize: 24,
        fontWeight: FONT_WEIGHT_BOLD,
        letterSpacing: 0,
        lineHeight: '40px',
        color: DEFAULT_FONT_COLOR,
      },
      // h4 is Heading 3 Desktop
      h4: {
        fontSize: 20,
        fontWeight: FONT_WEIGHT_REGULAR,
        letterSpacing: 0,
        lineHeight: '32px',
        color: DEFAULT_FONT_COLOR,
      },
      // subtitle1 is SUBHEAD 1
      subtitle1: {
        fontSize: 16,
        fontWeight: FONT_WEIGHT_LIGHT,
        letterSpacing: 2.25,
        lineHeight: '24px',
        color: DEFAULT_FONT_COLOR,
        textTransform: 'uppercase',
      },
      // subtitle2 is SUBHEAD 2
      subtitle2: {
        fontSize: 14,
        fontWeight: FONT_WEIGHT_LIGHT,
        letterSpacing: 1.18,
        lineHeight: '22px',
        color: DEFAULT_FONT_COLOR,
        textTransform: 'uppercase',
      },
      // body1 is Paragraph 1
      body1: {
        fontSize: 18,
        fontWeight: FONT_WEIGHT_REGULAR,
        letterSpacing: 0,
        lineHeight: '26px',
        color: DEFAULT_FONT_COLOR,
      },
      // body2 is Paragraph 2
      body2: {
        fontSize: 16,
        fontWeight: FONT_WEIGHT_REGULAR,
        letterSpacing: 0,
        lineHeight: '24px',
        color: DEFAULT_FONT_COLOR,
      },
      // caption is Paragraph 3
      caption: {
        fontSize: 14,
        fontWeight: FONT_WEIGHT_REGULAR,
        letterSpacing: 0,
        lineHeight: '22px',
        color: DEFAULT_FONT_COLOR,
      },
    },
    palette: {
      common: {
        black: BLACK,
        white: WHITE,
      },
      primary: {
        main: REIBUS_BLUE_STEEL,
        light: REIBUS_BLUE_STEEL_LIGHT,
        dark: REIBUS_BLUE_STEEL_DARK,
        contrastText: WHITE,
      },
      secondary: {
        main: REIBUS_MED_BLUE,
        contrastText: WHITE,
      },
      error: {
        main: REIBUS_RED,
        contrastText: WHITE,
      },
      warning: {
        main: REIBUS_YELLOW,
        contrastText: WHITE,
      },
      info: {
        main: REIBUS_MARINE_BLUE,
        contrastText: WHITE,
      },
      success: {
        main: REIBUS_GREEN,
        contrastText: WHITE,
      },
      text: {
        primary: BLACK,
      },
      grey: {
        50: GRAY_50,
        100: GRAY_100,
        200: GRAY_200,
        300: GRAY_300,
        400: GRAY_400,
        500: GRAY_500,
        600: GRAY_600,
        700: GRAY_700,
        800: GRAY_800,
        900: DEFAULT_FONT_COLOR,
      },
    },
  }),
});

export default theme;
export { newTheme };
export { FONT_WEIGHT_BOLD };
