import { useState } from 'react';
import { Theme } from '@mui/material';
import { ModalContainer } from 'components/SteelUI/molecules/modals';
import Button from 'components/SteelUI/atoms/Button';
import { makeStyles } from '@mui/styles';
import DeclineShipment from './DeclineShipment';

type Props = {
  id: string;
  showActionButtons: boolean;
  onAction: (show: boolean) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  decline: {
    fontSize: 16,
    fontWeight: 400,
    textTransform: 'none',
    paddingRight: theme.spacing(2),
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
}));

const Decline = ({ id, showActionButtons, onAction }: Props) => {
  const classes = useStyles();

  const [declineShipment, setDeclineShipment] = useState(false);

  const handleDeclineShipment = () => {
    setDeclineShipment(true);
    onAction(false);
  };

  const handleCloseModal = (show: boolean) => {
    onAction(show);
    setDeclineShipment(false);
  };

  return (
    <>
      {showActionButtons ? (
        <Button
          variant="text"
          onClick={() => handleDeclineShipment()}
          className={classes.decline}
        >
          Decline Shipment
        </Button>
      ) : null}
      <ModalContainer open={declineShipment} onClose={handleCloseModal}>
        <DeclineShipment id={id} onClose={handleCloseModal} />
      </ModalContainer>
    </>
  );
};

export default Decline;
