import 'date-fns';
import { makeStyles } from '@mui/styles';
import { Theme, TextField } from '@mui/material';
import { Field, FormSpy } from 'react-final-form';
import { FormFieldError } from './FormFieldError';
import DateFnsAdapter from '@date-io/date-fns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { dateValidatorFactory } from './fieldValidators';
import { InputAndError } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  textfield: {
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '100%',
  },
}));

type FieldNameAndValue = {
  fieldName: string;
  value: string;
};

interface DateFieldProps {
  label: string;
  name: string;
  disablePast?: boolean;
  required?: boolean;
  placeholder?: string;
  onAutoSave?: (values: FieldNameAndValue) => void;
  minDateGreaterThanInput?: InputAndError;
}

export const DateField = (props: DateFieldProps) => {
  const { minDateGreaterThanInput } = props;

  if (minDateGreaterThanInput) {
    const { input } = minDateGreaterThanInput;
    return (
      <FormSpy
        subscription={{
          values: true,
        }}
      >
        {({ values }) => {
          const minDate = values[input];
          return <DateInput {...props} minDate={minDate} />;
        }}
      </FormSpy>
    );
  }

  return <DateInput {...props} />;
};

interface DateInputProps extends DateFieldProps {
  minDate?: string;
}

const DateInput = ({
  label,
  name,
  disablePast = false,
  required = true,
  placeholder = format(new Date(), 'MM/dd/yyyy'),
  onAutoSave = () => null,
  minDate,
  minDateGreaterThanInput,
}: DateInputProps) => {
  const classes = useStyles();
  const { errorMessage = '' } = minDateGreaterThanInput || {};

  return (
    <Field
      name={name}
      validate={dateValidatorFactory(
        required,
        disablePast,
        errorMessage,
        minDate
      )}
    >
      {({ input, meta }) => {
        const handleChange = (date: unknown) => input.onChange(date);

        const handleClose = () => {
          const { name: fieldName, value } = input;
          if (!value) {
            return;
          }
          onAutoSave({ fieldName, value });
        };

        return (
          <div className={classes.textfield}>
            <LocalizationProvider dateAdapter={DateFnsAdapter}>
              <DatePicker
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    name={input.name}
                    id={name}
                    error={false}
                    helperText={null}
                    autoComplete="off"
                    required={required}
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    placeholder={placeholder}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...params}
                  />
                )}
                value={input.value ? input.value : null}
                inputFormat="MM/dd/yyyy"
                onChange={handleChange}
                disablePast={disablePast}
                label={label}
                className={classes.input}
                minDate={minDate}
                InputProps={{
                  'aria-labelledby': name,
                }}
                onClose={handleClose}
              />
              <FormFieldError meta={meta} />
            </LocalizationProvider>
          </div>
        );
      }}
    </Field>
  );
};
