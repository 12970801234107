import { ChangeEvent } from 'react';
import {
  FormGroup as MuiFormGroup,
  FormControlLabel as MuiFormControlLabel,
  Checkbox as MuiCheckbox,
} from '@mui/material';
import { Field } from 'react-final-form';
import { FormFieldError } from './FormFieldError';

type CheckBoxProps = {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  validator?: (value: string) => string | void;
  labelClassName?: string;
};

export const Checkbox = ({
  name,
  label,
  disabled,
  required = true,
  validator,
  labelClassName,
}: CheckBoxProps) => {
  return (
    <Field name={name} validate={validator}>
      {({ input, meta }) => {
        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
          input.onChange(event.target.checked);
        };

        return (
          <div>
            <MuiFormGroup>
              <MuiFormControlLabel
                className={labelClassName}
                control={
                  <MuiCheckbox
                    required={required}
                    disabled={disabled}
                    onChange={handleChange}
                    id={name}
                    name={name}
                    color="primary"
                    checked={Boolean(input.value)}
                  />
                }
                label={label}
              />
            </MuiFormGroup>
            <FormFieldError meta={meta} />
          </div>
        );
      }}
    </Field>
  );
};
