import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';

import { getApiRoot } from 'utils/apiUtils';

type UseSendManualOfferProps = {
  onSuccess: () => void;
  onError: (error: AxiosError) => void;
};

type ManualOfferRequest = {
  carrierId: string;
  shipmentId: string;
  offerAmount: number;
  authorization: string;
  offerContacts?: { email: string }[];
};

export const useSendManualOffer = ({
  onSuccess,
  onError,
}: UseSendManualOfferProps) => {
  return useMutation(
    async ({ authorization, ...requestData }: ManualOfferRequest) => {
      const config = {
        headers: {
          Authorization: authorization,
        },
      };

      const { data } = await axios.post(
        `${getApiRoot()}/digital-freight/manual-awards`,
        requestData,
        config
      );

      return data;
    },
    {
      onSuccess,
      onError,
    }
  );
};
