import { Box, Card } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import RouteDetails from 'components/SteelUI/organisms/RouteDetails';
import RouteMap from 'components/SteelUI/organisms/RouteMap';
import { getDistanceInMiles } from 'utils/geography';
import { usePageView } from 'utils/mixpanel';

import useQueryAutoAward from 'features/cse/AutoAward/hooks/useQueryAutoAward';
import Description from 'features/cse/AutoAward/NotAvailable/Description';

type Props = {
  id: string;
};
const NotAvailable = ({ id }: Props) => {
  const { data, isLoading: isLoadingAutoAward } = useQueryAutoAward(id);
  usePageView(`Page View: Auto Award`, { type: 'Shipment Not Available' });

  if (!data || isLoadingAutoAward) {
    return null;
  }

  const {
    shipment: { route },
    digitalFreightAward: { status },
  } = data;

  const { pickup, delivery } = route;
  const stops = [
    {
      stopType: 'Pickup',
      coordinate: pickup.coordinates,
    },
    {
      stopType: 'Delivery',
      coordinate: delivery.coordinates,
    },
  ];

  const pickupCoordinates = {
    latitude: pickup.coordinates.lat,
    longitude: pickup.coordinates.lon,
  };

  const deliveryCoordinates = {
    latitude: delivery.coordinates.lat,
    longitude: delivery.coordinates.lon,
  };

  const distance = Math.trunc(
    getDistanceInMiles(pickupCoordinates, deliveryCoordinates)
  );

  return (
    <Card variant="outlined">
      <Box sx={{ p: '24px' }}>
        <Grid container>
          <Grid xs={6} paddingRight="24px" borderRight="1px solid #EDEEF0">
            <RouteMap stops={stops} height="100%" />
          </Grid>
          <Grid container xs={6} paddingLeft="24px">
            <Grid container xs={12}>
              <Description status={status} />
            </Grid>
            <Grid xs={12}>
              <RouteDetails
                pickup={route.pickup}
                delivery={route.delivery}
                distance={distance}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default NotAvailable;
