import React from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';
import * as Sentry from '@sentry/react';

import { getApiRoot } from 'utils/apiUtils';

import BUILD from 'build.json';

import Entry from './entry/Entry';

declare global {
  interface Window {
    reibus?: {
      logistics?: {
        version: string;
      };
    };
  }
}

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: Entry,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

const reibusVersionInit = async () => {
  window.reibus = window.reibus || {};
  window.reibus.logistics = {
    version: BUILD.VERSION,
  };
};

const sentryMount = async () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_STAGE,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        tracingOrigins: ['localhost', getApiRoot(), /^\//],
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
};

const sentryUnmount = async () => {
  return Sentry.close();
};

export const bootstrap = [reibusVersionInit, lifecycles.bootstrap];

export const mount = [sentryMount, lifecycles.mount];

export const unmount = [sentryUnmount, lifecycles.unmount];
