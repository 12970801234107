import { useQuery } from 'react-query';
import { getDocumentDownloadLinks } from 'api/shipment-visibility-dashboard';
import ButtonV2 from 'components/library/ButtonV2';
import { Download } from 'components/icons/Download';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { trackEvent } from 'utils/mixpanel';
import { MixpanelTag } from 'utils/constants';
import { useAuthorizationToken } from '@reibus/frontend-utility';

const useStyles = makeStyles(() => ({
  downloadBtn: {
    width: '100%',
  },
  link: {
    textDecoration: 'none',
  },
}));

enum fileTypes {
  PROOF_OF_DELIVERY = 'PROOF_OF_DELIVERY',
  CUSTOMER_INVOICE = 'CUSTOMER_INVOICE',
}

const ShipmentDocumentDownloads = ({ shipmentId }: { shipmentId: number }) => {
  const classes = useStyles();
  const authToken = useAuthorizationToken();

  const { data: podData } = useQuery(
    ['shipmentDocumentsPOD', shipmentId],
    () =>
      getDocumentDownloadLinks(
        authToken,
        shipmentId,
        fileTypes.PROOF_OF_DELIVERY
      ),
    { enabled: !!authToken }
  );

  const { data: invoiceData } = useQuery(
    ['shipmentInvoice', shipmentId],
    () =>
      getDocumentDownloadLinks(
        authToken,
        shipmentId,
        fileTypes.CUSTOMER_INVOICE
      ),
    { enabled: !!authToken }
  );

  const podDataAvailable = !!podData?.file;
  const invoiceDataAvailable = !!invoiceData?.file;

  const { rl1776SvdDownloadInvoice } = useFlags();

  return (
    <Grid container spacing={2}>
      {rl1776SvdDownloadInvoice ? (
        <Grid item xs={6}>
          <a href={invoiceData?.file} className={classes.link}>
            <ButtonV2
              aria-label="Invoice"
              className={classes.downloadBtn}
              color="secondary"
              variant="outlined"
              startIcon={<Download />}
              onClick={() => {
                trackEvent(MixpanelTag.DownloadInvoice, {
                  shipmentId,
                });
              }}
              disabled={!invoiceDataAvailable}
            >
              Invoice
            </ButtonV2>
          </a>
        </Grid>
      ) : null}
      <Grid item xs={6}>
        <a href={podData?.file} className={classes.link}>
          <ButtonV2
            aria-label="Proof of Delivery"
            className={classes.downloadBtn}
            color="secondary"
            variant="outlined"
            startIcon={<Download />}
            onClick={() => {
              trackEvent(MixpanelTag.DownloadPOD, {
                shipmentId,
              });
            }}
            disabled={!podDataAvailable}
          >
            Proof of Delivery
          </ButtonV2>
        </a>
      </Grid>
    </Grid>
  );
};

export default ShipmentDocumentDownloads;
