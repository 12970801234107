import { makeStyles } from 'tss-react/mui';

import SkeletonWrapper from 'components/library/SkeletonWrapper';
import useShipmentLocations from 'shipment/hooks/useShipmentLocations';

import NoData from './NoData';

import { LocationDetails } from 'shipment/api';

import type { StopInfo } from 'api/types';
import RouteDetails from 'components/SteelUI/organisms/RouteDetails';
import {
  MultiStop,
  Stop,
} from 'components/SteelUI/organisms/RouteDetails/RouteDetails';

import RouteMap from 'components/SteelUI/organisms/RouteMap';

const useStyles = makeStyles()({
  gridContainer: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px',
    justifyContent: 'center',
  },
});

type Props = {
  shipmentId: string;
};

const getStops = ({ pickup, delivery }: LocationDetails): StopInfo[] => {
  if (!pickup || !delivery) {
    return [];
  }
  return [
    {
      coordinate: {
        lat: Number(pickup.coordinates.lat),
        lon: Number(pickup.coordinates.lon),
      },
      stopType: 'Pickup',
    },
    {
      coordinate: {
        lat: Number(delivery.coordinates.lat),
        lon: Number(delivery.coordinates.lon),
      },
      stopType: 'Delivery',
    },
  ];
};

const Locations = ({ shipmentId }: Props) => {
  const { classes } = useStyles();
  const { isLoading, data } = useShipmentLocations(shipmentId);

  const hasNoData = !data || !data.pickup.address || !data.delivery.address;
  if (hasNoData) {
    return (
      <SkeletonWrapper isLoading={isLoading} height={232}>
        <NoData />
      </SkeletonWrapper>
    );
  }

  const pickup: Stop = {
    address: data.pickup.address,
    name: data.pickup.name,
    appointment: data.pickup.date,
    notes: data.pickup?.notes,
  } as Stop;

  const delivery: Stop = {
    address: data.delivery.address,
    name: data.delivery.name,
    appointment: data.delivery.date,
    notes: data.delivery?.notes,
  } as Stop;

  const route: MultiStop[] = data.route;

  return (
    <SkeletonWrapper isLoading={isLoading} height={232}>
      {!data || !data.pickup.address || !data.delivery.address ? (
        <NoData />
      ) : (
        <div className={classes.gridContainer}>
          <RouteDetails
            pickup={pickup}
            delivery={delivery}
            distance={data.distance}
            showNotes
            route={route}
          />
          <RouteMap stops={getStops(data)} width="100%" />
        </div>
      )}
    </SkeletonWrapper>
  );
};

export const exportedForTesting = { getStops };
export default Locations;
