import { AxiosError } from 'axios';

export const convertAxiosErrorToReibusApiError = (
  error: AxiosError
): ReibusApiError => {
  const { response } = error || {};
  if (!response || !response.data.error) {
    return ReibusApiError.GENERIC_API_ERROR;
  }

  const { name: reason, message: displayMessage } = response.data.error;
  const { status: statusCode } = response;
  const apiErrorByStatusCode = ReibusApiError.getByStatusCode(statusCode);

  if (apiErrorByStatusCode) {
    apiErrorByStatusCode.reason = reason;
    apiErrorByStatusCode.displayMessage = displayMessage;
    return apiErrorByStatusCode;
  }

  return ReibusApiError.GENERIC_API_ERROR;
};

interface Indexable {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export enum ApiErrorFailureReasons {
  ORIGIN_DEST_ADDRESS_SAME = 'ORIGIN_DEST_ADDRESS_SAME',
}

export class ReibusApiError {
  name: string;
  statusCode: number;
  displayMessage?: string;
  reason?: string;

  static readonly GENERIC_API_ERROR = new ReibusApiError(
    'GENERIC_API_ERROR',
    400
  );
  static readonly UNPROCESSABLE_ENTITY = new ReibusApiError(
    'UNPROCESSABLE_ENTITY',
    422
  );
  static readonly GENERIC_SERVER_ERROR = new ReibusApiError(
    'GENERIC_SERVER_ERROR',
    500
  );

  constructor(name: string, statusCode: number) {
    this.name = name;
    this.statusCode = statusCode;
  }

  public static getByStatusCode = (statusCode: number): ReibusApiError => {
    const results = ReibusApiError.getAll().filter(
      (member) => member.statusCode === statusCode
    );
    if (results.length === 0) {
      return new ReibusApiError('UNKNOWN_REIBUS_API_ERROR', statusCode);
    }
    return results[0];
  };

  private static getAll = (): ReibusApiError[] => {
    return Object.keys(ReibusApiError)
      .map((name) => (ReibusApiError as Indexable)[name])
      .filter((member) => typeof member === 'object');
  };
}
