import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form } from 'components/library/form';
import Loader from 'components/Loader';
import { useEffect, useState } from 'react';
import { useSearchShipmentsContext } from 'search-shipments/SearchShipmentsContext';
import { searchFormConfig } from 'search-shipments/search-shipments-form/formLayoutData';
import { trackEvent } from 'utils/mixpanel';
import { SHIPMENT_SEARCH_FORM_FIELDS } from 'features/cse/search/SearchByShipment/components/SearchByShipmentsForm/utils';

type SearchShipmentFormData = {
  shipmentId?: string;
  originAddressPlaceId?: string;
  destinationAddressPlaceId?: string;
  equipmentType?: string[];
  pickupDate?: string;
  deliveryDate?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}));

const emptyFormData: SearchShipmentFormData = {
  shipmentId: '',
  originAddressPlaceId: '',
  destinationAddressPlaceId: '',
  pickupDate: '',
  deliveryDate: '',
  equipmentType: [],
};

const SearchShipmentsForm = () => {
  const classes = useStyles();
  const { search, formData, setFormData, fetchingShipmentsWithinRadius } =
    useSearchShipmentsContext();
  const [formLoaded, setFormLoaded] = useState(false);
  const [submitting] = useState(false);
  const [isLoadingInitialFormData, setIsLoadingInitialFormData] =
    useState(true);

  const [initialFormData, setInitialFormData] =
    useState<SearchShipmentFormData>();

  const clearFields = () => {
    setFormData({});
  };

  const initFormData = async () => {
    try {
      setInitialFormData(emptyFormData);
    } catch (e) {
      setInitialFormData(emptyFormData);
    } finally {
      setIsLoadingInitialFormData(false);
      setFormLoaded(true);
    }
  };

  useEffect(() => {
    initFormData();
  }, []);

  const handleSubmit = () => {
    trackEvent('Searched for a shipment on cse', formData);
    search({ searchPage: 0, submitted: true });
  };

  return isLoadingInitialFormData ? null : (
    <div className={classes.container}>
      <Loader hasLoaded={formLoaded}>
        <Form
          submitting={submitting}
          onSubmit={handleSubmit}
          formConfig={searchFormConfig}
          inputData={SHIPMENT_SEARCH_FORM_FIELDS}
          formName="search-shipment"
          onChange={(values: SearchShipmentFormData) => {
            setFormData({ ...values });
          }}
          onCancel={() => clearFields()}
          initialValues={initialFormData}
          disableSubmit={fetchingShipmentsWithinRadius}
        />
      </Loader>
    </div>
  );
};

export default SearchShipmentsForm;
