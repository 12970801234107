import { useEffect } from 'react';
import mixpanel from 'utils/mixpanel';
import { useUserContext } from 'app/UserContext';

export default function useMixpanelIdentify() {
  const { user, isLoggedIn } = useUserContext();

  useEffect(() => {
    if (isLoggedIn) {
      const { email, firstName, lastName, companyName, userTypes } = user;
      mixpanel.register({
        email: email,
        reibusApplication: 'logistics',
      });
      mixpanel.people.set({
        $email: email,
        $first_name: firstName,
        $last_name: lastName,
        Company: companyName,
        $user_types: userTypes,
      });
      //email is the same as cognitoUser.userName
      mixpanel.identify(email);
    } else {
      mixpanel.register({
        reibusApplication: 'logistics',
      });
    }
  }, [user, isLoggedIn]);
}
