import { useShipmentContext } from 'shipment/ShipmentContext';
import { trackEvent } from 'utils/mixpanel';
import { useState } from 'react';
import {
  postCoverShipmentDisposition,
  ShipmentDispositionData,
} from 'features/cse/disposition/api';
import Error from 'features/cse/disposition/disposition-modal/Error';
import Submit from 'features/cse/disposition/disposition-modal/flows/cover-shipment/screens/Submit';
import Success from 'features/cse/disposition/disposition-modal/flows/cover-shipment/screens/Success';
import { useDispositionContext } from 'features/cse/disposition/contexts/DispositionContext';
import AlreadyCovered from './screens/AlreadyCovered';
import { useAuthorizationToken } from '@reibus/frontend-utility';
import { useQueryClient } from 'react-query';

type Screen = 'submit' | 'success' | 'error' | 'covered';

const CoverShipment = () => {
  const {
    cseCarrierID,
    shipmentId,
    compositeScore,
    source,
    dispositionStatus,
    setDispositionStatus,
    equipmentIds,
  } = useDispositionContext();
  const { setIsTentativelyCovered } = useShipmentContext();
  const queryClient = useQueryClient();
  const [screen, setScreen] = useState<Screen>('submit');
  const authToken = useAuthorizationToken();

  const handleCoverShipment = async (
    comments?: string,
    deleteEquipment?: boolean
  ) => {
    const shipmentDispositionData: ShipmentDispositionData = {
      disposition: 'COVER',
      cseCarrierID,
      carrierId: cseCarrierID,
      shipmentId,
      compositeScore,
      comments,
      source,
      equipmentIds: deleteEquipment ? equipmentIds : undefined,
      deleteEquipment,
    };

    try {
      if (dispositionStatus) {
        setScreen('covered');
        return;
      }

      await postCoverShipmentDisposition(authToken, shipmentDispositionData);
      source === 'SHIPMENT'
        ? setIsTentativelyCovered(true, { cseCarrierID })
        : null;
      setScreen('success');
      setDispositionStatus('covered');
      trackEvent('Shipment Disposition - Cover Shipment', {
        shipmentDispositionData,
      });
      if (deleteEquipment) {
        queryClient.invalidateQueries(['availableEquipment', cseCarrierID]);
      }
    } catch (e) {
      setScreen('error');
    }
  };

  switch (screen) {
    case 'submit':
      return <Submit coverShipment={handleCoverShipment} />;
    case 'success':
      return <Success />;
    case 'covered':
      return <AlreadyCovered />;
    case 'error':
    default:
      return <Error tryAgain={() => setScreen('submit')} />;
  }
};

export default CoverShipment;
