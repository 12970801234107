import { useHistory } from 'react-router-dom';

import {
  Theme,
  Grid,
  Typography,
  useMediaQuery,
  Stack,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FreightQuoteRequest } from 'api/request-for-quote';
import {
  displayFormattingValue,
  valuesFormatList,
  roundToNearest,
} from '@reibus/reibus-core-utils';
import { Body, H1, H2, H3 } from 'components/SteelUI/atoms/typography';

const {
  listings: { weight: commaSeparated },
} = valuesFormatList;

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: '#2D6CCB',
    textDecoration: 'none',
    paddingLeft: 4,
    paddingRight: 4,
  },
  estimated: {
    textAlign: 'center',
  },
  priceLine: {
    marginBottom: theme.spacing(1),
  },
  cancelButton: {
    color: '#2D6CCB',
    border: '2px solid #2D6CCB',
    borderRadius: '8px',
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 700,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '&:hover': {
      backgroundColor: '#EAF2FF',
      border: '2px solid transparent',
      boxShadow: '0px 1px 3px 1px #21222426',
    },
  },
  bookButton: {
    backgroundColor: '#2D6CCB',
    borderRadius: '8px',
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 700,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '&:hover': {
      backgroundColor: '#215199',
      boxShadow: '0px 1px 3px 1px rgba(33, 34, 36, 0.15)',
    },
  },
  buttonStack: {
    marginTop: theme.spacing(3),
  },
  line: {
    marginTop: 0,
  },
  estimatedTitle: {
    marginBottom: theme.spacing(3),
  },
  detailsWrap: {
    maxWidth: '65%',
  },
  estimatedSpan: {
    fontWeight: 600,
  },
  [theme.breakpoints.down(900)]: {
    estimated: {
      alignSelf: 'center',
    },
  },
  [theme.breakpoints.down('sm')]: {
    estimated: {
      textAlign: 'center',
    },
    button: {
      maxWidth: '100%',
      width: '100%',
    },
    estimatedPrice: {
      display: 'flex',
      justifyContent: 'space-evenly',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
    line: {
      display: 'inline',
    },
  },
}));

export const EstimateTotal = ({
  priceEstimate,
  weight,
}: {
  priceEstimate: number;
  weight: number;
}) => {
  const rawPriceEstimate = roundToNearest(
    parseFloat(priceEstimate.toFixed(2)),
    25
  );
  const displayedPrice = displayFormattingValue(
    rawPriceEstimate,
    commaSeparated
  );

  const priceCWT = (rawPriceEstimate / weight) * 100;
  const displayedCWTPrice = displayFormattingValue(
    parseFloat(priceCWT.toFixed(2)),
    commaSeparated
  );

  const showCWT = weight <= 48000;

  return (
    <Stack direction="column" rowGap="16px">
      <Body size="small" weight="semibold" color="#64676B">
        ESTIMATED TOTAL
      </Body>
      <H1 weight="semibold">${displayedPrice}</H1>
      {showCWT ? <H3 weight="semibold">${displayedCWTPrice} CWT</H3> : null}
    </Stack>
  );
};

export type PriceDetailProps = {
  quoteData: FreightQuoteRequest;
  bookit(): Promise<void>;
  submitting: boolean;
};

const PriceDetail = ({ quoteData, bookit, submitting }: PriceDetailProps) => {
  const classes = useStyles();
  const mobile = useMediaQuery('(max-width: 500px)');
  const history = useHistory();

  const { priceEstimate, refID, weight } = quoteData;
  const rawPriceEstimate = roundToNearest(
    parseFloat(priceEstimate.toFixed(2)),
    25
  );
  const displayedPrice = displayFormattingValue(
    rawPriceEstimate,
    commaSeparated
  );

  const getDetails = () => {
    if (quoteData.quickForm) {
      return (
        <Stack rowGap="24px" className={classes.detailsWrap}>
          <H2 color="#64676B" weight="semibold">
            Quote Request:
          </H2>
          <Body>{`The total estimated cost of your freight quote is $${displayedPrice}.
          Please click on the “Book Freight” option to secure this quote.`}</Body>
          <Body>
            <span className={classes.estimatedSpan}>Estimated Total: </span>
            {`$${displayedPrice} Per Truckload (linehaul + fuel only)
Based on pickup and delivery dates entered, else assumes 48 hours lead time.`}
          </Body>
          <Body weight="semibold">
            *All quotes assume no more than 5 non-skidded coils, and is not
            confirmed until logistics agent verifies all details.
          </Body>
        </Stack>
      );
    }
    return (
      <Grid className={classes.mobileParagaph} item xs={mobile ? 12 : 8}>
        <Typography variant="h4"> {`QUOTE REQUEST ID: ${refID}`}</Typography>
        <p>
          {' '}
          The total estimated cost of your freight quote is ${
            displayedPrice
          }.{' '}
          <p className={classes.line}>
            Please click on the “Book It” option to secure this quote.
          </p>{' '}
        </p>
        <p>
          {' '}
          Please call us at <b>(888) 858 0648</b> or email us at
          <a className={classes.link} href="mailto:shippersupport@reibus.com">
            shippersupport@reibus.com
          </a>
          and refer to this quote ID if you have any questions.{' '}
        </p>
        <p>
          {' '}
          Estimated total: <b>${displayedPrice}</b> Per Truckload (linehaul +
          fuel only){' '}
          <p className={classes.line}>
            Based on pickup and delivery dates entered, else assumes 48 hours
            lead time.
          </p>
        </p>
      </Grid>
    );
  };

  return (
    <>
      {getDetails()}
      <Grid
        item
        xs={mobile ? 12 : 4}
        className={classes.estimated}
        container
        direction="column"
        alignContent={'flex-end'}
        justifyContent={quoteData.quickForm ? 'flex-end' : 'center'}
      >
        <EstimateTotal priceEstimate={priceEstimate} weight={weight} />
        <Stack
          direction="row"
          columnGap={'24px'}
          className={classes.buttonStack}
          justifyContent={'center'}
        >
          {quoteData.quickForm ? (
            <Button
              className={classes.cancelButton}
              variant="outlined"
              onClick={() =>
                history.push({
                  pathname: `/quick-freight-quote`,
                })
              }
              data-testid="bookItBtn"
              disabled={submitting}
            >
              Cancel
            </Button>
          ) : null}
          <Button
            className={classes.bookButton}
            variant="contained"
            onClick={() => bookit()}
            data-testid="bookItBtn"
            disabled={submitting}
          >
            {quoteData.quickForm ? 'Book Freight' : 'Book It'}
          </Button>
        </Stack>
      </Grid>
    </>
  );
};

export default PriceDetail;
