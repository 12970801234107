import { makeStyles } from '@mui/styles';
import FormSection from './FormSection';
import { SingleSelect } from 'components/library/form/SingleSelect';
import { AutoSelect } from 'components/library/form/AutoSelect';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles({
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: '24px',
    rowGap: '40px',
  },
});

const EQUIPMENT_OPTIONS = [
  {
    id: '',
    label: 'Flatbed',
    optionName: 'FLATBED',
  },
  {
    id: '',
    label: 'Dry Van',
    optionName: 'DRY_VAN',
  },
  {
    id: '',
    label: 'Hot Shot',
    optionName: 'HOT_SHOT',
  },
];

export const EquipmentSingleSelect = ({
  quickForm = false,
}: {
  quickForm?: boolean;
}) => {
  const equipmentOptions = quickForm
    ? EQUIPMENT_OPTIONS.filter((option) => option.optionName !== 'HOT_SHOT')
    : EQUIPMENT_OPTIONS;

  return (
    <SingleSelect
      label="Equipment Type"
      name="equipment"
      options={equipmentOptions}
      required
      defaultValue="FLATBED"
    />
  );
};

const Equipment = () => {
  const classes = useStyles();
  const { ct389FqrFormUpdates } = useFlags();

  return (
    <FormSection title="Equipment">
      <div className={classes.content}>
        {!ct389FqrFormUpdates ? (
          <AutoSelect
            placeholder="Equipment Type"
            name="equipment"
            label="Select all that apply"
            required
            options={EQUIPMENT_OPTIONS}
            defaultValue="FLATBED"
          />
        ) : (
          <EquipmentSingleSelect />
        )}
      </div>
    </FormSection>
  );
};

export default Equipment;
