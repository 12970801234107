import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export interface StyleProps {
  width?: number;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  iconHeader: {
    display: 'block',
    width: ({ width }) => width,
    maxWidth: 'none',
  },
}));

//
function BrandClearIcon({ width = 130 }: StyleProps) {
  const classes = useStyles({ width });
  return (
    <img
      className={classes.iconHeader}
      src="https://res.cloudinary.com/reibus/image/upload/dcf9k06hltaj0ni8l3af.svg"
      alt="Reibus International Logo"
    />
  );
}

export default BrandClearIcon;
