const TENDERED_TURVO_CODE = '2101';
const FCFS_SCHEDULING_TYPE = '9400';

export const dateDisplay = (dateTime: string | number, timezone?: string) => {
  return new Date(dateTime).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    ...(timezone && { timeZone: timezone }),
  });
};

export const dateDisplayNoMidnight = (
  dateTime: string | number,
  timezone: string
) => {
  if (timeIsMidnight(dateTime, timezone)) {
    return new Date(dateTime).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      timeZone: timezone,
    });
  }

  return dateDisplay(dateTime, timezone);
};

const timeIsMidnight = (dateTime: string | number, timezone: string): boolean =>
  new Date(dateTime)
    .toLocaleString('en-US', {
      timeZone: timezone,
    })
    .includes('12:00:00 AM');

const generateNonTenderedDateString = (
  apptTime: Date,
  timezone: string,
  isFCFS: boolean,
  flex: number
) => {
  const dateisValid = apptTime.toLocaleString() !== 'Invalid Date';
  const shortTimezone = apptTime
    .toLocaleTimeString('en-us', { timeZone: timezone, timeZoneName: 'short' })
    .split(' ')[2];
  const shortDateStr = apptTime.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: timezone,
  });

  const apptType = `${isFCFS ? 'FCFS' : 'By Appt. Only'}`;
  const hasFlexTime = !!flex;

  if (hasFlexTime) {
    const flexTimeMs = flex * 1000;
    const flexDateTime = new Date(apptTime.getTime() + flexTimeMs);
    const flexTime = `${generateTimeString(
      apptTime,
      timezone
    )} - ${generateTimeString(flexDateTime, timezone)}`;

    return `${shortDateStr}, ${flexTime} ${shortTimezone}, ${apptType}`;
  }

  const hasTime = dateisValid
    ? !timeIsMidnight(apptTime.toISOString(), timezone)
    : false;
  const time = hasTime ? `, 8:00 AM ${shortTimezone}` : '';

  return `${shortDateStr}${time}, ${apptType}`;
};

const generateTimeString = (time: number | Date, timeZone: string) =>
  new Date(time).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    timeZone,
  });

export const formatDateDisplay = (
  dateTime: string,
  timezone: string,
  flex = 0,
  shipmentStatusCode?: string,
  schedulingTypeCode?: string
) => {
  if (
    shipmentStatusCode === TENDERED_TURVO_CODE ||
    !shipmentStatusCode ||
    !schedulingTypeCode
  ) {
    return dateDisplayNoMidnight(dateTime, timezone);
  }

  const isFCFS = schedulingTypeCode === FCFS_SCHEDULING_TYPE;
  const apptTime = new Date(dateTime);
  return generateNonTenderedDateString(apptTime, timezone, isFCFS, flex);
};
