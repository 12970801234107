import { useState } from 'react';
import { Box } from '@mui/material';

import PageWrapper from 'components/PageWrapper';

import { Tab, Tabs } from 'components/SteelUI/atoms';
import SearchByShipment from 'features/cse/search/SearchByShipment';
import SearchByCarrier from 'features/cse/search/SearchByCarrier';
import theme from 'theme';
import { Helmet } from 'react-helmet-async';
import { usePageView } from 'utils/mixpanel';

type SearchCategory = 'shipment' | 'carrier';

const CSESearchPage = () => {
  const [selectedTab, setSelectedTab] = useState<SearchCategory>('shipment');

  usePageView('Page View: CSE Search');

  const handleChangeTab = (
    _event: React.SyntheticEvent,
    newTab: SearchCategory
  ) => {
    setSelectedTab(newTab);
  };

  return (
    <PageWrapper showShortFooter isNewTheme title="CSE Search">
      <Helmet>
        <link
          href={`https://${process.env.REACT_APP_ALGOLIA_APP_ID}-dsn.algolia.net`}
          rel="preconnect"
        />
        <link
          href={`https://${process.env.REACT_APP_ALGOLIA_APP_ID}-1.algolianet.com`}
          rel="preconnect"
        />
        <link
          href={`https://${process.env.REACT_APP_ALGOLIA_APP_ID}-2.algolianet.com`}
          rel="preconnect"
        />
        <link
          href={`https://${process.env.REACT_APP_ALGOLIA_APP_ID}-3.algolianet.com`}
          rel="preconnect"
        />
      </Helmet>
      <Box
        sx={{
          padding: '32px',
          borderBottom: '1px solid #e0e0e0',
        }}
      >
        <Box
          sx={{
            fontSize: '36px',
            fontWeight: 700,
            lineHeight: '45px',
          }}
        >
          CAPACITY SOURCING ENGINE
        </Box>
        <Box
          sx={{
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: '22px',
          }}
        >
          You can start using the CSE by searching for shipments or carriers.
        </Box>
      </Box>
      <Tabs value={selectedTab} onChange={handleChangeTab} sx={{ pl: '16px' }}>
        <Tab label="By Shipment" value="shipment" sx={{ fontSize: '18px' }} />
        <Tab label="By Carriers" value="carrier" sx={{ fontSize: '18px' }} />
      </Tabs>
      <Box
        sx={{
          backgroundColor: theme.palette.grey['50'],
          paddingTop: '24px',
        }}
      >
        <Box sx={{ display: selectedTab === 'shipment' ? 'inline' : 'none' }}>
          <SearchByShipment />
        </Box>
        <Box sx={{ display: selectedTab === 'carrier' ? 'inline' : 'none' }}>
          <SearchByCarrier />
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default CSESearchPage;
