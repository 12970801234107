import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { AddressFieldGroup } from 'components/library/form/AddressField';
import { GoogleAutoCompleteField } from './GoogleAutocompleteWithPrefill';
import type { PlacePredictionFn } from 'request-for-quote/v2/RequestForQuoteV2';

const useStyles = makeStyles({
  link: {
    transform: 'translate(10px, -25px) scale(0.75)',
    float: 'right',
    height: 0,
    margin: 0,
  },
  selectedLink: {
    float: 'right',
    height: 0,
    margin: 0,
    transform: 'translate(10px, -25px) scale(0.75)',
    color: '#5d0549D5',
  },
});

type GooglePlaceSearchType = '(cities)' | 'address' | '(regions)';
type AddressParts = {
  long_name: string;
  short_name: string;
  types: string[];
};
export type AddressBreakdown = {
  addressParts: AddressParts[];
  geometry?: google.maps.places.PlaceGeometry;
};

export type GoogleAutoCompleteProps = {
  name: string;
  label: string;
  required: boolean;
  searchTypes?: GooglePlaceSearchType[];
  maxResults?: number;
  validator?: (value: string) => string | void;
  initialDescription?: string;
  onClear?: () => void;
  placeFetchers: PlacePredictionFn[];
  places: google.maps.places.AutocompletePrediction[];
  placesServiceInstance: google.maps.places.PlacesService | null;
  setAddressBreakdown?: (addressBreakdown: AddressBreakdown) => void;
};

type AddressFieldProps = {
  placeholder?: string;
  maxLength?: number;
  value?: string;
  disabled?: boolean;
  showManualLabel?: boolean;
} & GoogleAutoCompleteProps;

export const AddressField = ({
  name,
  required = false,
  showManualLabel = true,
  label,
  searchTypes,
  maxResults,
  initialDescription,
  onClear,
  placeFetchers,
  places,
  placesServiceInstance,
  setAddressBreakdown,
}: AddressFieldProps) => {
  const classes = useStyles();

  const [isHidden, setIsHidden] = useState(true);

  const toggleHidden = () => {
    setIsHidden(!isHidden);
  };

  return (
    <div>
      {showManualLabel ? (
        <label
          className={isHidden ? classes.link : classes.selectedLink}
          onClick={() => toggleHidden()}
        >
          Enter Manually
        </label>
      ) : null}
      {isHidden ? (
        <div>
          <GoogleAutoCompleteField
            name={`${name}PlaceId`}
            label={label ?? 'Address'}
            required={required}
            searchTypes={searchTypes}
            maxResults={maxResults}
            initialDescription={initialDescription}
            onClear={onClear}
            placeFetchers={placeFetchers}
            places={places}
            placesServiceInstance={placesServiceInstance}
            setAddressBreakdown={setAddressBreakdown}
          />
        </div>
      ) : (
        <AddressFieldGroup name={name} />
      )}
    </div>
  );
};
