import { makeStyles } from '@mui/styles';
import { Timeline } from '@mui/lab';
import { Order } from 'components/icons/Order';
import { Shape } from 'components/icons/Shape';
import { DeliveryVan } from 'components/icons/Deliveryvan';
import { Home } from 'components/icons/Home';
import ProgressItem from 'components/library/ProgressItem';
import { Status } from 'api/types';

const useStyles = makeStyles(() => ({
  progressBar: {
    padding: 0,
    alignItems: 'center',
  },
  timeline: {
    margin: 0,
    padding: 0,
    alignContent: 'center',
  },
}));

const indexIcons = [
  {
    status: 'started',
    icon: <Order htmlColor="#707070" viewBox="0 0 20 21" />,
    iconActive: <Order htmlColor="#FFFFFF" viewBox="0 0 20 21" />,
  },
  {
    status: ['pickup', 'enRoute'],
    icon: <Shape htmlColor="#707070" viewBox="0 0 20 22" />,
    iconActive: <Shape htmlColor="#FFFFFF" viewBox="0 0 20 21" />,
  },
  {
    status: 'delivery',
    icon: <DeliveryVan htmlColor="#707070" viewBox="0 0 20 22" />,
    iconActive: <DeliveryVan htmlColor="#FFFFFF" viewBox="0 0 20 21" />,
  },
  {
    status: 'delivered',
    icon: <Home htmlColor="#707070" viewBox="0 0 20 22" />,
    iconActive: <Home htmlColor="#FFFFFF" viewBox="0 0 20 21" />,
  },
];

type ArrivalProps = {
  date: string;
  timezone: string;
};

type ShipmentProgressProps = {
  progressStatus: Status;
  progressStatusName: string;
  children: JSX.Element;
  arrival?: ArrivalProps;
  hasDeliveryNext?: boolean;
  lastDelivery?: boolean;
};

const ShipmentProgress = ({
  progressStatus,
  progressStatusName,
  children,
  arrival,
  hasDeliveryNext = false,
  lastDelivery,
}: ShipmentProgressProps) => {
  const classes = useStyles();

  const getShipmentStatus = () => {
    const actualStatusIndex = indexIcons.findIndex((ele) =>
      ele.status.includes(progressStatus?.status)
    );
    const showStatusIndex = indexIcons.findIndex((ele) =>
      ele.status.includes(progressStatusName.toLowerCase())
    );

    let isActive = false;
    if (
      progressStatusName === 'pickup' &&
      progressStatus?.status === 'pickup'
    ) {
      isActive = Boolean(arrival);
    } else if (
      progressStatusName === 'delivery' &&
      progressStatus?.status === 'delivery'
    ) {
      isActive = Boolean(arrival);
    } else if (actualStatusIndex >= showStatusIndex) {
      isActive = true;
    }

    let value;
    if (actualStatusIndex > showStatusIndex) {
      value = 100;
    } else if (actualStatusIndex === showStatusIndex) {
      if (
        (progressStatusName === 'pickup' && !hasDeliveryNext) ||
        (progressStatusName === 'delivery' && !lastDelivery)
      ) {
        value = arrival ? 100 : 0;
      } else {
        value = progressStatus.value;
      }
    } else {
      value = 0;
    }

    return (
      <ProgressItem
        data-testid="progressItem-test"
        isActive={isActive}
        info={children}
        progressValue={value}
        isFirstValue={progressStatusName === 'delivered'}
      >
        {isActive
          ? indexIcons[showStatusIndex].iconActive
          : indexIcons[showStatusIndex].icon}
      </ProgressItem>
    );
  };

  const progressDisplay = getShipmentStatus();
  return (
    <Timeline
      classes={{
        root: classes.timeline,
      }}
    >
      {progressDisplay}
    </Timeline>
  );
};

export default ShipmentProgress;
