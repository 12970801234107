import { Divider, Stack, Theme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { makeStyles } from '@mui/styles';

import { H1, H2 } from 'components/SteelUI/atoms/typography';
import Field from 'features/cse/AutoAward/components/AutoAwardModal/Field';
import {
  formatAutoAwardCarrierOfferContactsEmails,
  formatAutoAwardDate,
  formatAutoAwardMarginPercentage,
  formatAutoAwardRate,
  formatAutoAwardStatus,
  formatAutoAwardTime,
} from 'features/cse/AutoAward/components/AutoAwardModal/utils';

import type { DigitalFreightAward } from 'features/cse/AutoAward/types';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  icon: {
    marginRight: spacing(2),
  },
}));

type Props = {
  autoAward: DigitalFreightAward;
};

const OfferSent = ({ autoAward }: Props) => {
  const { rm15188SelectDigitalOfferEmails } = useFlags();
  const classes = useStyles();

  const {
    status,
    notifiedAt,
    offerAmount,
    customerPay,
    shipment: { shipmentID },
    carrier: { carrierName, offerContacts },
    sentTo,
  } = autoAward;
  const formattedStatus = formatAutoAwardStatus(status);
  const formattedEmails =
    sentTo?.length > 0 && rm15188SelectDigitalOfferEmails
      ? sentTo.join(', ')
      : formatAutoAwardCarrierOfferContactsEmails(offerContacts);
  const formattedDate = formatAutoAwardDate(notifiedAt);
  const formattedTime = formatAutoAwardTime(notifiedAt);
  const formattedOfferRate = formatAutoAwardRate(offerAmount);
  const formattedMarginPercentage = formatAutoAwardMarginPercentage({
    customerPay,
    offerAmount,
  });

  return (
    <Stack direction="column" spacing={3}>
      <Stack direction="row" alignItems="center">
        <FontAwesomeIcon
          icon={faCircleCheck}
          size="2x"
          color="#237960"
          className={classes.icon}
        />
        <H1 weight="semibold">Offer Sent</H1>
      </Stack>
      <Stack
        direction="column"
        spacing={3}
        divider={<Divider orientation="horizontal" />}
      >
        <Stack direction="column" spacing={3}>
          <Stack direction="row" spacing={1}>
            <Field label="Carrier" value={carrierName} />
            <Field label="Shipment ID" value={shipmentID} />
            <Field label="Status" value={formattedStatus} />
          </Stack>
          <Field label="Offer Sent To Email" value={formattedEmails} />
          <Stack direction="row" spacing={2}>
            <Field label="Date Sent" value={formattedDate} />
            <Field label="Time sent" value={formattedTime} />
          </Stack>
        </Stack>
        <Stack direction="column" spacing={2}>
          <H2 weight="semibold">Pay Information</H2>
          <Stack direction="row" spacing={12}>
            <Field
              label="Offer Rate"
              value={formattedOfferRate}
              orientation="vertical"
            />
            <Field
              label="Margin"
              value={formattedMarginPercentage}
              orientation="vertical"
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default OfferSent;
