import { useAuthorizationToken } from '@reibus/frontend-utility';
import { useQuery } from 'react-query';

import { getActivityLogByShipmentId } from 'features/cse/ActivityLog/api';

const useActivityLog = (shipmentId: string) => {
  const authToken = useAuthorizationToken();

  return useQuery(
    'activityLog',
    async () => {
      return await getActivityLogByShipmentId(authToken, shipmentId);
    },
    {
      enabled: !!authToken,
    }
  );
};

export default useActivityLog;
