import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontWeight: 560,
    fontSize: 24,
    lineHeight: '40px',
    padding: theme.spacing(2),
  },
}));

const NoData = () => {
  const classes = useStyles();
  return <div className={classes.root}>No Locations Data</div>;
};

export default NoData;
