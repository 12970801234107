const EARTH_RADIUS_MILES = 3969.0;
const DEGREES_OF_STRAIGHT_LINE = 180;

type Point = {
  latitude: number;
  longitude: number;
};

const getDistanceInMiles = (PointA: Point, PointB: Point): number => {
  const { latitude: lat1, longitude: lon1 } = PointA;
  const { latitude: lat2, longitude: lon2 } = PointB;
  const lon1Radians = (lon1 * Math.PI) / DEGREES_OF_STRAIGHT_LINE;
  const lon2Radians = (lon2 * Math.PI) / DEGREES_OF_STRAIGHT_LINE;
  const lat1Radians = (lat1 * Math.PI) / DEGREES_OF_STRAIGHT_LINE;
  const lat2Radians = (lat2 * Math.PI) / DEGREES_OF_STRAIGHT_LINE;
  const dLon = lon2Radians - lon1Radians;
  const dLat = lat2Radians - lat1Radians;
  const a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.cos(lat1Radians) *
      Math.cos(lat2Radians) *
      Math.pow(Math.sin(dLon / 2), 2);
  const c = 2 * Math.asin(Math.sqrt(a));

  return c * EARTH_RADIUS_MILES;
};

export { getDistanceInMiles };
