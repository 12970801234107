import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Stack } from '@mui/material';
import type { MultiStop } from 'shipment/api';

type Props = {
  route: MultiStop[];
};

const getStopLabel = ({
  stopNumber,
  city,
  region,
  zip,
}: {
  stopNumber: number;
  city: string;
  region: string;
  zip?: string;
}) => {
  const cityRegionLabel = [city, region].filter(Boolean).join(', ');
  const locationLabel = [cityRegionLabel, zip].filter(Boolean).join(' ');
  return `Stop ${stopNumber}: ${locationLabel}`;
};

const MultiStopList = ({ route }: Props) => {
  const CircleIcon = icon({ name: 'circle', style: 'light' });
  return (
    <Stack direction="column" spacing={1}>
      {route.map((stop, index) => {
        const label = getStopLabel({
          stopNumber: index + 1,
          city: stop.address.city,
          region: stop.address.region,
          zip: stop.address.postalCode,
        });
        return (
          <Stack
            key={`multistop-${label}`}
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <FontAwesomeIcon icon={CircleIcon} size="2xs" color="#2D6CCB" />
            <div>{label}</div>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default MultiStopList;
