import { Stack } from '@mui/material';
import CarrierPageCard from '../CarrierPageCard';
import TopShipments from './components/TopShipments';

import CarrierDetails from './components/CarrierDetails';
import { useCarrier } from 'features/cse/Carrier/hooks/useCarrier';

type Props = {
  cseCarrierID: string;
};

const CarrierOverview = ({ cseCarrierID }: Props) => {
  const { data } = useCarrier(cseCarrierID);

  return !data ? null : (
    <Stack spacing={2} width="100%">
      <CarrierPageCard>
        <CarrierDetails details={data} />
      </CarrierPageCard>
      <CarrierPageCard>
        <TopShipments carrier={data} />
      </CarrierPageCard>
    </Stack>
  );
};

export default CarrierOverview;
