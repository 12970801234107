import { useState } from 'react';
import { useShipmentContext } from 'shipment/ShipmentContext';
import {
  postDeclineShipmentDisposition,
  ShipmentDispositionData,
} from 'features/cse/disposition/api';
import Error from 'features/cse/disposition/disposition-modal/Error';
import Submit from 'features/cse/disposition/disposition-modal/flows/decline-shipment/screens/Submit';
import Success from 'features/cse/disposition/disposition-modal/flows/decline-shipment/screens/Success';
import { useDispositionContext } from 'features/cse/disposition/contexts/DispositionContext';
import { trackEvent } from 'utils/mixpanel';
import { useAuthorizationToken } from '@reibus/frontend-utility';

type Screen = 'submit' | 'success' | 'error';

const DeclineShipment = () => {
  const { cseCarrierID, shipmentId, compositeScore, carrierRankId, source } =
    useDispositionContext();
  const { fetchTopCarriers } = useShipmentContext();
  const [screen, setScreen] = useState<Screen>('submit');
  const [declineReason, setDeclineReason] =
    useState<ShipmentDispositionData['reason']>();
  const authToken = useAuthorizationToken();

  const handleDeclineShipment = async ({
    reason,
    subreason,
    subreasonExplanation,
    comments,
  }: Pick<
    ShipmentDispositionData,
    'reason' | 'subreason' | 'subreasonExplanation' | 'comments'
  >) => {
    const shipmentDispositionData: ShipmentDispositionData = {
      disposition: 'DECLINE',
      cseCarrierID,
      shipmentId,
      compositeScore,
      reason,
      subreason,
      subreasonExplanation,
      comments,
      carrierRankId,
      source,
    };

    try {
      await postDeclineShipmentDisposition(authToken, shipmentDispositionData);
      setDeclineReason(reason);
      if (source === 'SHIPMENT') fetchTopCarriers();
      setScreen('success');
      trackEvent('Shipment Disposition - Decline Shipment', {
        shipmentDispositionData,
      });
    } catch (e) {
      setScreen('error');
    }
  };

  switch (screen) {
    case 'submit':
      return <Submit declineShipment={handleDeclineShipment} />;
    case 'success':
      return <Success reason={declineReason} />;
    case 'error':
    default:
      return <Error tryAgain={() => setScreen('submit')} />;
  }
};

export default DeclineShipment;
