import { useQuery } from 'react-query';
import { getEquipmentContactInfo } from '../shipment/api';
import { useAuthorizationToken } from '@reibus/frontend-utility';

export default function useEquipmentContactInfo(
  cseCarrierId: string,
  equipmentIDs: string[],
  isOpen: boolean
) {
  const authorization = useAuthorizationToken();
  return useQuery(
    ['equipmentContactInfo', cseCarrierId],
    async () => await getEquipmentContactInfo(authorization, equipmentIDs),
    { enabled: isOpen && !!authorization }
  );
}
