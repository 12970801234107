import { useUserContext } from 'app/UserContext';

import { LocationDetails, ShipmentDetailsResponse } from 'shipment/api';
import Email from 'shipment/carrier-contact-info-modal/Email';
import { useShipmentContext } from 'shipment/ShipmentContext';
import { MixpanelTag } from 'utils/constants';
import { trackEvent } from 'utils/mixpanel';
import { createEmailContent } from './functions/EmailContentGenerator';
import {
  postOtherShipmentDisposition,
  ShipmentDispositionData,
} from 'features/cse/disposition/api';
import { useDispositionContext } from 'features/cse/disposition/contexts/DispositionContext';
import { useAuthorizationToken } from '@reibus/frontend-utility';
import { useQueryClient } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { formatDateTime, dateTimeFormatList } from '@reibus/reibus-core-utils';

const { dateTime } = dateTimeFormatList;

const formatDate = (date: string) => {
  const dateText = formatDateTime(new Date(date), 'en-US', dateTime);
  return dateText.replace(',', ' ');
};

type Props = {
  carrierName: string;
  email: string;
  carrierID?: string;
};

const DispositionEmail = ({ carrierID, carrierName, email }: Props) => {
  const queryClient = useQueryClient();

  const { rl2549SendOtherDispositionOnEmailClick = false } = useFlags();

  const { user } = useUserContext();
  const { shipmentDetails, locations } = useShipmentContext();
  const authToken = useAuthorizationToken();

  const { subject, body } = createEmailContent(
    user,
    shipmentDetails as ShipmentDetailsResponse,
    locations as LocationDetails,
    carrierName
  );

  const { cseCarrierID, shipmentId, compositeScore, source } =
    useDispositionContext();

  const { firstName, lastName } = user;
  const comments = `${firstName} ${lastName} sent email at ${formatDate(
    new Date().toISOString()
  )}`;

  const shipmentDispositionData: ShipmentDispositionData = {
    disposition: 'OTHER',
    cseCarrierID,
    shipmentId,
    compositeScore,
    reason: 'CONTACTED',
    subreason: 'EMAIL',
    source,
    comments,
  };

  const handleEmailClick = async () => {
    if (rl2549SendOtherDispositionOnEmailClick) {
      await postOtherShipmentDisposition(authToken, shipmentDispositionData);

      queryClient.invalidateQueries(['activityLog']);
    }
    trackEvent(MixpanelTag.EmailCarrier, {
      carrierID,
      carrierName,
      email,
    });
  };

  return (
    <Email
      email={email}
      subject={subject}
      body={body}
      handleClick={handleEmailClick}
    />
  );
};

export default DispositionEmail;
