import { Theme, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { formatCarrierStatus, getHQ } from 'features/cse/Carrier/utils/data';
import type { CarrierDetailResponse } from 'shipment/api';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: 8,
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 700,
    fontSize: 16,
  },
  text: {
    fontWeight: 700,
    fontSize: 16,
  },
}));

type Props = {
  carrierDetails: CarrierDetailResponse | Record<string, never>;
};

const CarrierDetail = ({ carrierDetails }: Props) => {
  const {
    status,
    carrierMCNumber,
    carrierDOTNumber,
    headquarter,
    accountOwnerName,
    source,
  } = carrierDetails;
  const classes = useStyles();

  return (
    <Grid container alignItems="center" className={classes.container}>
      <Grid xs item>
        <Typography className={classes.title}>MC #</Typography>
        <span>{carrierMCNumber || 'N/A'}</span>
      </Grid>
      <Grid xs item>
        <Typography className={classes.title}>US DOT #</Typography>
        <span>{carrierDOTNumber || 'N/A'}</span>
      </Grid>
      <Grid xs item>
        <Typography className={classes.title}>Status</Typography>
        <span>{formatCarrierStatus(source, status)}</span>
      </Grid>
      <Grid xs item>
        <Typography className={classes.title}>HQ</Typography>
        <span>{getHQ(headquarter)}</span>
      </Grid>
      <Grid xs item>
        <Typography className={classes.title}>Last Shipment</Typography>
        <span>N/A</span>
      </Grid>
      <Grid xs item>
        <Typography className={classes.title}>Account Owner</Typography>
        <span>{accountOwnerName || 'N/A'}</span>
      </Grid>
    </Grid>
  );
};

export default CarrierDetail;
