export const RouteArrow = () => (
  <svg
    width="16"
    height="49"
    viewBox="-1 -1 18 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.64645 48.3536C7.84171 48.5488 8.15829 48.5488 8.35355 48.3536L11.5355 45.1716C11.7308 44.9763 11.7308 44.6597 11.5355 44.4645C11.3403 44.2692 11.0237 44.2692 10.8284 44.4645L8 47.2929L5.17157 44.4645C4.97631 44.2692 4.65973 44.2692 4.46447 44.4645C4.2692 44.6597 4.2692 44.9763 4.46447 45.1716L7.64645 48.3536ZM7.5 0V1H8.5V0H7.5ZM7.5 3V5H8.5V3H7.5ZM7.5 7V9H8.5V7H7.5ZM7.5 11V13H8.5V11H7.5ZM7.5 15V17H8.5V15H7.5ZM7.5 19V21H8.5V19H7.5ZM7.5 23V25H8.5V23H7.5ZM7.5 27V29H8.5V27H7.5ZM7.5 31V33H8.5V31H7.5ZM7.5 35V37H8.5V35H7.5ZM7.5 39V41H8.5V39H7.5ZM7.5 43V45H8.5V43H7.5ZM7.5 47V48H8.5V47H7.5Z"
      fill="#A7ACB2"
    />
  </svg>
);
