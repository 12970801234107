import {
  displayFormattingValue,
  valuesFormatList,
} from '@reibus/reibus-core-utils';
import { Commodity, Service } from 'features/cse/AutoAward/Confirmation/api';

const {
  listings: { weight: commaSeparated },
} = valuesFormatList;

const formatWeight = (weight: number) => {
  return !weight
    ? 'N/A'
    : `${displayFormattingValue(Number(weight), commaSeparated)} lbs`;
};

const formatEquipmentNeededDesc = (equipmentNeededDesc: string) => {
  return !equipmentNeededDesc ? 'N/A' : equipmentNeededDesc;
};

const formatServices = (services: Service[]) => {
  return !services.length
    ? 'N/A'
    : services.map((service) => service.description).join(', ');
};

const formatCommodities = (commodities: Commodity[]) => {
  return !commodities.length
    ? 'N/A'
    : commodities
        .map(({ quantity, name }) => {
          return !quantity ? name : [quantity, name].join(' ');
        })
        .join(', ');
};

export {
  formatWeight,
  formatCommodities,
  formatEquipmentNeededDesc,
  formatServices,
};
