export const Info = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.57143 0C2.05 0 0 2.05 0 4.57143V27.4286C0 29.95 2.05 32 4.57143 32H27.4286C29.95 32 32 29.95 32 27.4286V4.57143C32 2.05 29.95 0 27.4286 0H4.57143ZM13.1429 21.7143H14.8571V17.1429H13.1429C12.1929 17.1429 11.4286 16.3786 11.4286 15.4286C11.4286 14.4786 12.1929 13.7143 13.1429 13.7143H16.5714C17.5214 13.7143 18.2857 14.4786 18.2857 15.4286V21.7143H18.8571C19.8071 21.7143 20.5714 22.4786 20.5714 23.4286C20.5714 24.3786 19.8071 25.1429 18.8571 25.1429H13.1429C12.1929 25.1429 11.4286 24.3786 11.4286 23.4286C11.4286 22.4786 12.1929 21.7143 13.1429 21.7143ZM16 11.4286C14.7357 11.4286 13.7143 10.4071 13.7143 9.14286C13.7143 7.87857 14.7357 6.85714 16 6.85714C17.2643 6.85714 18.2857 7.87857 18.2857 9.14286C18.2857 10.4071 17.2643 11.4286 16 11.4286Z"
      fill="#5DD2FC"
    />
  </svg>
);
