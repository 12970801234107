import { Grid } from '@mui/material';

import {
  ApiErrorFailureReasons,
  convertAxiosErrorToReibusApiError,
} from 'api/axiosErrorHandler';
import { FormSubmitMessage } from 'components/library/form/FormSubmitMessage';

import type { AxiosError } from 'axios';

type Props = {
  open: boolean;
  error?: AxiosError;
  onClose: () => void;
};

const ErrorDialog = ({ open, error, onClose }: Props) => {
  let displayMessage =
    'An error was encountered and we were unable to process your request. ' +
    'If the issue persists please contact us at (888) 558-0648.';

  const asReibusApiError = error
    ? convertAxiosErrorToReibusApiError(error)
    : null;

  if (asReibusApiError?.reason && asReibusApiError?.displayMessage) {
    switch (asReibusApiError.reason) {
      case ApiErrorFailureReasons.ORIGIN_DEST_ADDRESS_SAME: {
        displayMessage = asReibusApiError.displayMessage;
        break;
      }
      default:
        break;
    }
  }

  return (
    <FormSubmitMessage
      open={open}
      message={{
        title: (
          <Grid container justifyContent="center">
            Error
          </Grid>
        ),
        message: <p>{displayMessage}</p>,
      }}
      onClose={onClose}
    />
  );
};

export default ErrorDialog;
