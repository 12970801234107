import React, { useState, useEffect } from 'react';
import PageWrapper from 'components/PageWrapper';
import { makeStyles } from '@mui/styles';
import {
  Theme,
  Typography,
  Grid,
  useMediaQuery,
  Dialog,
  Stack,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Loader from 'components/Loader';
import {
  getPublicShipmentDetails,
  PublicShipmentRespProps,
} from 'api/shared-shipment';
import ShipmentStatus from 'shipment-visibility-dashboard/Shipment/ShipmentStatus/ShipmentStatus';
import ShipmentMap from 'shipment-visibility-dashboard/Shipment/ShipmentMap';
import ShipmentDetails from 'shipment-visibility-dashboard/Shipment/ShipmentDetails';
import { StopInfo } from 'api/types';
import {
  getShipmentDetails,
  getShipmentShareLink,
} from 'api/shipment-visibility-dashboard';
import { useUserSession } from '@reibus/frontend-utility';
import { SharedShipmentPageProps } from 'pages/SharedShipmentPage';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme: Theme) => ({
  pageWrap: {
    position: 'relative',
    backgroundColor: '#f5f5f5',
    paddingBottom: theme.spacing(4),
  },
  gridContainer: {
    maxWidth: 900,
  },
  link: {
    color: '#2D6CCB',
    textDecoration: 'none',
  },
  header: {
    zIndex: 1,
    padding: `${theme.spacing(6)} 0px`,
  },
  shipmentTitle: {
    marginBottom: 0,
  },
  shipmentIdTitle: {
    color: '#090A0D',
  },
  shipmentDetailsContainer: {
    padding: `15px 26px`,
    backgroundColor: '#fff',
    borderRadius: 4,
    boxShadow: '0px 0px 8px rgb(0 0 0 / 8%)',
  },
  leftColumn: {
    paddingRight: theme.spacing(6),
    marginBottom: theme.spacing(3),
  },
  rightColumn: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  mapContainer: {
    paddingBottom: theme.spacing(3),
    flexBasis: 'fit-content',
  },
  [theme.breakpoints.down(950)]: {
    gridContainer: {
      maxWidth: '90vw',
    },
    shipmentIdTitle: {
      fontSize: 18,
    },
    leftColumn: {
      width: '100%',
    },
    mapContainer: {
      width: '100%',
    },
  },
  dialogPaper: {
    borderRadius: 8,
    boxShadow: '0px 0px 4px 0px rgba(33, 34, 36, 0.10)',
    padding: theme.spacing(3),
    backgroundColor: '#fff',
  },
  dialogTitle: {
    fontSize: 24,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    color: '#212224',
  },
  dialogContent: {
    color: '#212224',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginTop: theme.spacing(2),
  },
}));

const SharedShipmentDisplay = ({
  shipment,
}: {
  shipment: PublicShipmentRespProps;
}) => {
  const classes = useStyles();
  const mobile = useMediaQuery('(max-width:900px)');

  const {
    customId: shipmentTrackingID,
    globalRoute = [],
    endDate,
    startDate,
    tenderedAt,
    status,
    shipmentStatus,
    id,
  } = shipment;

  const globalRoutesAsCoordArray = globalRoute.map((globalRouteItem) => {
    const { address, stopType } = globalRouteItem;

    return {
      coordinate: {
        lat: address?.lat,
        lon: address?.lon,
      },
      stopType: stopType.value,
    } as StopInfo;
  });

  return (
    <Grid className={classes.gridContainer} container item>
      <Grid sm={12} item>
        <header className={classes.header}>
          <div>
            <Typography className={classes.shipmentTitle} variant="h2">
              Reibus Logistics
            </Typography>
          </div>
          <p>
            Email us at{' '}
            <a className={classes.link} href="mailto:shippersupport@reibus.com">
              shippersupport@reibus.com
            </a>{' '}
            or give us a call at{' '}
            <a className={classes.link} href="tel:+18888580648">
              (888) 858-0648
            </a>{' '}
            if you need assistance or have any questions.
          </p>
        </header>
      </Grid>
      <Grid sm={12} item>
        <Typography
          className={classes.shipmentIdTitle}
          variant="h4"
        >{`Shipment Tracking ID: ${shipmentTrackingID}`}</Typography>
      </Grid>
      <Grid className={classes.shipmentDetailsContainer} sm={12} item>
        <Grid container>
          <Grid className={classes.leftColumn} sm={mobile ? 12 : 7} item>
            <ShipmentStatus
              endDate={endDate.date}
              endDateFull={endDate}
              startDateFull={startDate}
              tenderedAt={tenderedAt}
              statusLabel={status}
              statusCode={shipmentStatus.key}
              globalRoute={globalRoute}
              shipmentID={id}
            />
          </Grid>
          <Grid
            className={classes.rightColumn}
            sm={mobile ? 12 : 5}
            spacing={2}
            item
            container
          >
            <Grid
              sm={12}
              className={classes.mapContainer}
              item
              data-testid="shipmentMap"
            >
              <ShipmentMap routes={globalRoutesAsCoordArray} />
            </Grid>
            <Grid sm={12} item>
              <ShipmentDetails shipmentDetails={shipment} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const SharedShipment: React.FC<SharedShipmentPageProps> = ({
  isDecoded = false,
}) => {
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [shipment, setShipment] = useState<PublicShipmentRespProps | void>(
    undefined
  );
  const [shipmentLoaded, setShipmentLoaded] = useState(false);

  const classes = useStyles();
  const { shipmentIdToken } = useParams<{ shipmentIdToken: string }>();
  const { data: userSession = {} } = useUserSession();
  const { identityToken } = userSession;

  const fetchShipmentList = async (isDecoded = false) => {
    try {
      const partialShipment = isDecoded
        ? await getShipmentDetails(identityToken, shipmentIdToken)
        : null;
      const shipmentShareLink = partialShipment
        ? await getShipmentShareLink(identityToken, partialShipment.id)
        : null;
      const encodedShipmentId = shipmentShareLink?.route
        ? shipmentShareLink.route.split('/logistics/shipments/')[1]
        : shipmentIdToken;

      const shipment = await getPublicShipmentDetails(encodedShipmentId);

      setShipment(shipment);
      setShipmentLoaded(true);
    } catch (e) {
      setMessageDialogOpen(true);
    }
  };

  useEffect(() => {
    fetchShipmentList(isDecoded);
  }, []);

  return (
    <PageWrapper showShortFooter>
      <div className={classes.pageWrap}>
        <Loader hasLoaded={shipmentLoaded}>
          <Grid justifyContent="center" container>
            {shipment ? <SharedShipmentDisplay shipment={shipment} /> : null}
          </Grid>
        </Loader>
      </div>
      <Dialog
        open={messageDialogOpen}
        maxWidth="sm"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
      >
        <Stack direction="column" alignItems="center" justifyContent="center">
          <FontAwesomeIcon
            icon={faCircleExclamation}
            color="#BB121A"
            size="2x"
            className={classes.icon}
          />
          <Typography className={classes.dialogTitle}>
            Oops Looks Like Something Went Wrong
          </Typography>
          <Typography className={classes.dialogContent}>
            Unable to retrieve shipment at this time. This may mean the shipment
            isn’t tendered yet. For additional information, please email us at{' '}
            <a className={classes.link} href="mailto:shippersupport@reibus.com">
              shippersupport@reibus.com
            </a>{' '}
            or give us a call at{' '}
            <a className={classes.link} href="tel:+18888580648">
              (888) 858-0648
            </a>
          </Typography>
        </Stack>
      </Dialog>
    </PageWrapper>
  );
};

export default SharedShipment;
