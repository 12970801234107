import { Box, CircularProgress, Dialog, DialogContent } from '@mui/material';

type Props = {
  isOpen: boolean;
  message?: string[];
};

const LoadingModal = ({ isOpen, message = ['Loading...'] }: Props) => {
  return (
    <Dialog open={isOpen} data-testid="loading-modal">
      <DialogContent>
        <Box sx={{ display: 'flex', placeItems: 'center', flexFlow: 'column' }}>
          <CircularProgress />
          {message.map((msg) => (
            <p key={msg} style={{ marginBottom: 8 }}>
              {msg}
            </p>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingModal;
