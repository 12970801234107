import { useFlags } from 'launchdarkly-react-client-sdk';
import TopShipmentsV1 from './TopShipmentsV1';
import TopShipmentsV2 from './TopShipmentsV2';
import { CarrierDetailResponse } from 'shipment/api';

type Props = {
  carrier: CarrierDetailResponse;
};

const TopShipments = ({ carrier }: Props) => {
  const {
    rl2951OptimizeCarrierPagePaginateCseTopShipments:
      backendPaginationFlag = false,
  } = useFlags();

  return backendPaginationFlag ? (
    <TopShipmentsV2 carrier={carrier} />
  ) : (
    <TopShipmentsV1 carrier={carrier} />
  );
};

export default TopShipments;
