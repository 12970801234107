import { Box } from '@mui/material';
import { getStopDate } from 'features/cse/Carrier/utils/data';

import type { ShipmentWithScores } from 'features/cse/Carrier/types';
import { GlobalRouteStop } from 'search-shipments/search-shipments-form/utils';
import { Stack } from '@mui/material';
import Tooltip from 'components/SteelUI/atoms/Tooltip';
import { MultiStop } from 'shipment/api';

type StopType = 'Pickup Date' | 'Delivery Date';

type Props = {
  stopType: StopType;
  address: ShipmentWithScores['originAddress' | 'destinationAddress'];
  date: ShipmentWithScores['startDateDetailed' | 'endDateDetailed'];
  route?: MultiStop[];
};

const Stop = ({ stopType, address, date, route }: Props) => {
  const { city = null, region = null } = address || {};
  const isMultiStop = route && route?.length > 2;
  const multiStopLabel = isMultiStop ? `(${route?.length})` : '';

  const label = `${city}, ${region} ${multiStopLabel}`;
  const title = !isMultiStop ? null : (
    <Stack direction="column" alignItems="center">
      <div>{`This shipment has ${route?.length} stops:`}</div>
      {route?.map((stop) => {
        const cityRegionLabel = [stop.address.city, stop.address.region]
          .filter(Boolean)
          .join(', ');
        const locationLabel = [cityRegionLabel, stop.address.postalCode]
          .filter(Boolean)
          .join(' ');
        return (
          <div key={`multistoptooltip-${locationLabel}`}>{locationLabel}</div>
        );
      })}
    </Stack>
  );

  return (
    <Box>
      {!city || !region ? (
        'N/A'
      ) : (
        <>
          <Box
            sx={{
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '125%',
              mb: 1,
            }}
          >
            <Tooltip title={title} placement="top" arrow>
              <>{label}</>
            </Tooltip>
          </Box>
          <Box sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '125%' }}>
            {stopType}: {getStopDate(date)}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Stop;
