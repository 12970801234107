import { Box, Stack } from '@mui/material';
import { displayFormattingValue } from '@reibus/reibus-core-utils';
import { Accordion } from 'components/SteelUI/molecules';
import { format } from 'date-fns';
import { AutoAwardLog } from 'features/cse/ActivityLog/api';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { makeStyles } from 'tss-react/mui';

type Props = {
  details: AutoAwardLog;
};

const useStyles = makeStyles()({
  container: {
    fontSize: '14px',
    lineHeight: '125%',
  },
  label: {
    fontWeight: 400,
  },
  bold: {
    fontWeight: 600,
  },
});

const ActivityLogEntryAutoAwardOffer = ({ details }: Props) => {
  const { rm15188SelectDigitalOfferEmails } = useFlags();

  const { classes } = useStyles();
  const {
    notifiedAt,
    offerAmount,
    offerSource,
    carrier: { carrierName, offerContacts },
    sentTo,
  } = details;

  const Label = ({
    carrierName = 'Company Name N/A',
  }: {
    carrierName?: string;
  }) => {
    const { classes } = useStyles();
    return (
      <Stack direction="row" justifyContent="space-between">
        <Box>{carrierName}</Box>
        <Box className={classes.bold}>
          {offerSource === 'MANUAL_TRIGGER' ? 'Manual Award' : 'Auto Award'}
        </Box>
      </Stack>
    );
  };

  const label = (
    <Box className={classes.label}>
      <Label carrierName={carrierName} />
    </Box>
  );
  const posted = !notifiedAt ? 'N/A' : format(new Date(notifiedAt), 'h:mm a');

  const options = {
    trailingZeros: true,
    decimalPlaces: 2,
  };

  const formattedSentTo =
    sentTo.length > 0 && rm15188SelectDigitalOfferEmails
      ? sentTo.join(', ')
      : offerContacts.map((contact) => contact.email).join(', ');

  return (
    <Accordion label={label}>
      <Stack spacing={1} className={classes.container}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box className={classes.bold}>Offer Sent</Box>
          <Box>
            <span className={classes.bold}>Posted: </span>
            {posted}
          </Box>
        </Stack>
        <Stack>
          <Box className={classes.bold}>Note:</Box>
          <Box>
            Offer Amount: {displayFormattingValue(offerAmount, options, 'USD')}
          </Box>
          <Box>Sent to: {formattedSentTo}</Box>
        </Stack>
      </Stack>
    </Accordion>
  );
};

export default ActivityLogEntryAutoAwardOffer;
