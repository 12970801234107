import { PropsWithChildren } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ClickAwayListener, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    flexWrap: 'nowrap',
    backgroundColor: theme.palette.primary.main,
  },
  wrapperV2: {
    flexWrap: 'nowrap',
    backgroundColor: theme.palette.primary.main,
    padding: '0 1.5rem',
    alignItems: 'center',
  },
  dashboardHeader: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    borderTop: 0,
    width: '100%',
  },
  dashboardHeaderV2: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    borderTop: 0,
    width: '100%',
  },
}));

type Props = {
  setAnchorClose?: () => void;
} & PropsWithChildren;

// eslint-disable-next-line @typescript-eslint/no-empty-function
const Wrapper = ({ children, setAnchorClose = () => {} }: Props) => {
  const classes = useStyles();
  const { rm11182ShowRedMetalsInNavBar } = useFlags();
  return rm11182ShowRedMetalsInNavBar ? (
    <div className={classes.dashboardHeader}>
      <ClickAwayListener onClickAway={setAnchorClose}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className={classes.wrapperV2}
        >
          {children}
        </Grid>
      </ClickAwayListener>
    </div>
  ) : (
    <ClickAwayListener onClickAway={setAnchorClose}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className={classes.wrapper}
      >
        {children}
      </Grid>
    </ClickAwayListener>
  );
};
export default Wrapper;
