import ActivityLogEntryDisposition from 'features/cse/ActivityLog/ActivityLogEntry/ActivityLogEntryDisposition';
import ActivityLogEntryStatusChange from 'features/cse/ActivityLog/ActivityLogEntry/ActivityLogEntryStatusChange';
import ActivityLogEntryAutoAwardOffer from 'features/cse/ActivityLog/ActivityLogEntry/ActivityLogEntryAutoAwardOffer';

import type {
  AutoAwardLog,
  ActivityLog,
  ShipmentStatusHistory,
} from 'features/cse/ActivityLog/api';
import { ShipmentDisposition } from 'features/cse/disposition/api';

type Props = {
  details: ActivityLog;
};

const ActivityLogEntry = ({ details }: Props) => {
  if ('notifiedAt' in details) {
    return <ActivityLogEntryAutoAwardOffer details={details as AutoAwardLog} />;
  }

  if ('disposition' in details) {
    return (
      <ActivityLogEntryDisposition
        disposition={details as ShipmentDisposition}
      />
    );
  }

  if ('status' in details) {
    return (
      <ActivityLogEntryStatusChange
        activity={details as ShipmentStatusHistory}
      />
    );
  }

  return null;
};

export default ActivityLogEntry;
