import { Box } from '@mui/material';
import { StopInfo, Coordinate } from 'api/types';
import RouteMap from 'components/SteelUI/organisms/RouteMap';
import { getDistanceInMiles } from 'utils/geography';

type ShipmentMapProps = {
  routes: StopInfo[];
  truckLocation?: Coordinate;
  isMarketplaceShipment?: boolean;
  globalRoute?: StopInfo[];
  height?: string;
};

const ShipmentMap = ({
  routes,
  truckLocation,
  isMarketplaceShipment,
  globalRoute,
  height = '250px',
}: ShipmentMapProps) => {
  // Truck location is only visible if:
  //  - it is not a marketplace shipment
  //  - it is a marketplace shipment and the truck is at least 150 miles from every pickup location
  const isTruckLocationVisible = ({
    truckLocation,
    isMarketplaceShipment,
    globalRoute,
  }: {
    truckLocation?: Coordinate;
    isMarketplaceShipment?: boolean;
    globalRoute?: StopInfo[];
  }): boolean => {
    if (!truckLocation || !globalRoute || globalRoute.length === 0)
      return false;
    if (!isMarketplaceShipment) return true;

    const truckLocationPoint = {
      latitude: truckLocation.lat,
      longitude: truckLocation.lon,
    };

    return globalRoute.every((route) => {
      const {
        stopType,
        coordinate: { lat: latitude, lon: longitude },
      } = route;
      if (stopType !== 'Pickup') return true;

      const pickupLocationPoint = {
        latitude,
        longitude,
      };

      return getDistanceInMiles(truckLocationPoint, pickupLocationPoint) >= 150;
    });
  };

  return (
    <Box data-testid="shipmentMap">
      <RouteMap
        stops={routes}
        height={height}
        truckLocation={
          isTruckLocationVisible({
            truckLocation,
            isMarketplaceShipment,
            globalRoute: globalRoute,
          })
            ? truckLocation
            : undefined
        }
      />
    </Box>
  );
};

export default ShipmentMap;
