import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  ALGOLIA_INDEX_CARRIERS,
  getCarriersByRadius,
  SearchByCarrierHit,
  searchCarriers,
} from './api';
import type { SearchByCarrierResponse } from './api';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { trackEvent } from 'utils/mixpanel';
import { MixpanelTag } from 'utils/constants';
import { useAuthorizationToken } from '@reibus/frontend-utility';

type PlaceResult = google.maps.places.PlaceResult;

type Coordinates = { latitude: string; longitude: string };

type SearchByCarrierFormData = {
  carrierMCNumber?: string;
  carrierDOTNumber?: string;
  carrierName?: string;
  headquarterPlaceId?: string;
};

type SortBy =
  | 'carrierName'
  | 'headquarterLabel'
  | 'status'
  | 'accountOwnerName';

type Sort = {
  sortBy: string;
  sortOrder: 'asc' | 'desc';
};

type SearchByCarrierContextType = {
  isLoading: boolean;
  formData: SearchByCarrierFormData;
  setFormData: (formData: SearchByCarrierFormData) => void;
  search: () => void;
  results: undefined | SearchByCarrierResponse;
  setPage: (page: number) => void;
  page: number;
  sort: Sort;
  setSort: (sort: Sort) => void;
  fetchingCarriersWithinRadius: boolean;
};

const SearchByCarrierContext = createContext<
  SearchByCarrierContextType | undefined
>(undefined);

const getCoordinatesGeocode = async (
  placeDetails: PlaceResult | null
): Promise<Coordinates> => {
  const geocodes = await geocodeByAddress(
    placeDetails?.formatted_address as string
  );
  const { lat, lng } = await getLatLng(geocodes[0]);
  return {
    latitude: String(lat),
    longitude: String(lng),
  };
};

const noResults: SearchByCarrierResponse = {
  hits: [] as SearchByCarrierHit[],
  nbHits: 0,
  page: 0,
  nbPages: 0,
  hitsPerPage: 20,
};

const SearchByCarrierContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<SearchByCarrierFormData>({});
  const [results, setResults] = useState<undefined | SearchByCarrierResponse>(
    undefined
  );
  const [page, setPage] = useState(0);
  const [cseCarrierIdsWithinRadius, setCseCarrierIdsWithinRadius] = useState<
    string[]
  >([]);
  const [sort, setSort] = useState<Sort>({
    sortBy: 'carrierName',
    sortOrder: 'asc',
  });
  const [fetchingCarriersWithinRadius, setFetchingCarriersWithinRadius] =
    useState(false);

  const { placesService } = usePlacesAutocompleteService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
  });
  const authToken = useAuthorizationToken();

  const transformAddressToCoordinates = (addressPlaceId: string) => {
    if (!addressPlaceId) setCseCarrierIdsWithinRadius([]);
    placesService?.getDetails(
      { placeId: addressPlaceId },
      async (placeDetails) => {
        setFetchingCarriersWithinRadius(true);
        const coords = await getCoordinatesGeocode(placeDetails);
        const filteredCseCarrierIds = await getCarriersByRadius(
          authToken,
          coords
        );
        setCseCarrierIdsWithinRadius(filteredCseCarrierIds);
        setFetchingCarriersWithinRadius(false);
      }
    );
  };

  const search = async () => {
    setIsLoading(true);

    try {
      if (formData.headquarterPlaceId && !cseCarrierIdsWithinRadius.length) {
        setResults(noResults);
        return;
      }

      const details = { ...formData, cseCarrierIdsWithinRadius };
      const index = `${ALGOLIA_INDEX_CARRIERS}_${sort.sortBy}_${sort.sortOrder}`;
      const resp = await searchCarriers(details, page, index);
      const { carrierDOTNumber, carrierMCNumber } = formData;
      if (resp.nbHits === 1 && (carrierDOTNumber || carrierMCNumber)) {
        const filters = [
          carrierMCNumber && 'carrierMCNumber',
          carrierDOTNumber && 'carrierDOTNumber',
        ].filter((x) => x);
        const [{ cseCarrierID }] = resp.hits;
        trackEvent(`${MixpanelTag.CarrierSearch}: ${filters.join(', ')}`, {
          cseCarrierID,
        });
        window.open(`/logistics/cse/carrier/${cseCarrierID}`, '_blank');
      }
      if (resp.nbHits === 0 && (carrierDOTNumber || carrierMCNumber)) {
        trackEvent(`${MixpanelTag.CarrierSearch}: No Carrier found`, {
          carrierDOTNumber,
          carrierMCNumber,
        });
      }
      setResults(resp);
    } catch (e) {
      setResults(undefined);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    transformAddressToCoordinates(formData.headquarterPlaceId ?? '');
  }, [formData.headquarterPlaceId]);

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    } else {
      search();
    }
  }, [sort]);

  useEffect(() => {
    search();
  }, [page]);

  return (
    <SearchByCarrierContext.Provider
      value={{
        isLoading,
        formData,
        setFormData,
        search,
        results,
        setPage,
        page,
        sort,
        setSort,
        fetchingCarriersWithinRadius,
      }}
    >
      {children}
    </SearchByCarrierContext.Provider>
  );
};

const useSearchByCarrierContext = () => {
  const context = useContext(SearchByCarrierContext);
  if (context === undefined) {
    throw new Error(
      'useSearchByCarrierContext must be used within a SearchByCarrierContextProvider'
    );
  }

  return context;
};

const exportForTesting = { SearchByCarrierContext };

export {
  SearchByCarrierContextProvider,
  useSearchByCarrierContext,
  exportForTesting,
};
export type { SortBy, Sort };
