import { formatAutoAwardCarrierOfferContactsEmails } from 'features/cse/AutoAward/components/AutoAwardModal/utils';

import type { CarrierContactInfo, ShipmentDetailsResponse } from 'shipment/api';

import type { DigitalFreightAward } from 'features/cse/AutoAward/types';

type ManualAward = {
  carrierName: string;
  offerContacts: string;
  offerRate: number;
  customerPay: number;
};

export const useManualAward = (
  carrierContactInfo?: CarrierContactInfo,
  shipmentDetails?: ShipmentDetailsResponse,
  autoAward?: DigitalFreightAward
): ManualAward => {
  if (!autoAward) {
    if (carrierContactInfo && shipmentDetails) {
      const { carrierName, offerContacts } = carrierContactInfo;
      const { targetPay, totalShipperCost: customerPay } = shipmentDetails;

      return {
        carrierName,
        offerContacts: formatAutoAwardCarrierOfferContactsEmails(offerContacts),
        offerRate: targetPay,
        customerPay: customerPay ?? 0,
      };
    }

    return {
      carrierName: '',
      offerContacts: '',
      offerRate: 0,
      customerPay: 0,
    };
  }

  const {
    offerAmount,
    customerPay,
    carrier: { carrierName, offerContacts },
  } = autoAward;

  return {
    carrierName,
    offerContacts: formatAutoAwardCarrierOfferContactsEmails(offerContacts),
    offerRate: offerAmount,
    customerPay,
  };
};
