import { useState, SyntheticEvent } from 'react';
import { makeStyles } from '@mui/styles';
import { FormControl, TextField, Autocomplete } from '@mui/material';
import { Field } from 'react-final-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormFieldError } from './FormFieldError';
import { SelectOption } from './types';
import { autoSelectValidator as defaultValidator } from './fieldValidators';

const useStyles = makeStyles(() => ({
  asterisk: { color: '#C62828', fontSize: '1.2rem' },
  focused: {},
  error: {},
  underline: {
    '&.Mui-error': {
      '&::after': {
        borderBottomColor: '#92140c',
      },
    },
  },
}));

type AutoSelectSingleProps = {
  placeholder?: string;
  name: string;
  label: string;
  required?: boolean;
  options: SelectOption[];
  validator?: (value: string[]) => string | void;
  initialValue?: SelectOption;
};

export const AutoSelectSingle = ({
  options,
  placeholder,
  required = true,
  name,
  validator = defaultValidator,
  initialValue,
}: AutoSelectSingleProps) => {
  const classes = useStyles();
  const validate = required ? validator : undefined;
  const [value, setValue] = useState<SelectOption | null>(initialValue ?? null);

  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        if (!input?.value) {
          setValue(null);
          input.onChange('');
        }

        const handleChange = (
          _: SyntheticEvent<Element, Event>,
          value: SelectOption | null
        ) => {
          if (!value) {
            input.onChange('');
            setValue(null);
            return;
          }

          input.onChange(value.optionName);
          setValue(value);
        };

        return (
          <FormControl fullWidth required={required} error={false}>
            <Autocomplete
              data-testid={`autocomplete-${name}`}
              getOptionLabel={(option) => option.label}
              options={options}
              onChange={handleChange}
              value={value}
              popupIcon={<ExpandMoreIcon color="primary" />}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    InputLabelProps={{
                      classes: { asterisk: classes.asterisk },
                    }}
                    label={placeholder}
                    required={required}
                    name={name}
                  />
                );
              }}
              onBlur={input.onBlur}
              onFocus={input.onFocus}
            />
            <FormFieldError meta={meta} />
          </FormControl>
        );
      }}
    </Field>
  );
};
