import { Stack } from '@mui/material';
import { displayFormattingValue } from '@reibus/reibus-core-utils';
import { Body, H2 } from 'components/SteelUI/atoms/typography';

type Props = {
  rate: number;
};

const PayInformation = ({ rate }: Props) => {
  const options = {
    trailingZeros: true,
    decimalPlaces: 2,
  };

  const rateFormatted = `$${displayFormattingValue(rate, options)}`;

  return (
    <>
      <H2 weight="bold">Pay Information</H2>
      <Stack direction="column" width="100%" spacing={1}>
        <Body size="medium" weight="bold">
          Rate:
        </Body>
        <H2>{rateFormatted}</H2>
      </Stack>
    </>
  );
};

export default PayInformation;
