import type { ShipmentWithScores } from 'features/cse/Carrier/types';
import {
  formatShipmentDate,
  formatAddress,
  formatEquipmentWeight,
  formatEquipmentType,
} from 'features/cse/Carrier/utils/data';

export const createEmailContent = (
  shipments: ShipmentWithScores[],
  carrierName = 'N/A'
) => {
  const subject = `Top Reibus Loads for ${encodeURIComponent(carrierName)}`;

  const body = `Hello,%0D%0A%0D%0A
Below are the Reibus shipments I’m currently looking to cover. Please reach out if you have an interest in covering any.%0D%0A%0D%0A
${shipments
  .map(
    (shipment) =>
      `${formatShipmentDate(shipment.startDateDetailed)} | ${formatAddress(
        shipment.originAddress
      )} to ${formatAddress(shipment.destinationAddress)} ${
        shipment.route?.length > 2
          ? `| Stops: ${shipment.route
              .map((stop) =>
                [stop.address.city, stop.address.region]
                  .filter(Boolean)
                  .join(', ')
              )
              .join(' / ')} |`
          : '|'
      } Equipment Required: ${shipment.equipmentNeeded
        .map(formatEquipmentType)
        .join(', ')} | Weight: ${formatEquipmentWeight(shipment.netWeight)}`
  )
  .join('%0D%0A%0D%0A')}`;

  const encodedBody = body.replace('&', '%26');

  return {
    subject,
    body: encodedBody,
  };
};
