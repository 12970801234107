import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Theme,
  Container,
  TextField,
  InputAdornment,
  Autocomplete,
  IconButton,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: 0,
    marginTop: theme.spacing(2),
    width: 'inherit',
  },
  root: {
    paddingRight: 0,
  },
  hasClearIcon: {
    paddingRight: 0,
  },
  clearButton: {
    color: theme.palette.grey[700],
  },
  cssOutlinedRoot: {
    '& $cssOutlinedOutline': {
      paddingRight: 0,
    },
    '&:hover $cssOutlinedOutline': {
      border: '1px solid #DCDEE0 !important',
    },
    borderRadius: 8,
  },
  cssOutlinedInputFocused: {
    '& $cssOutlinedOutline': {
      border: '1px solid #DCDEE0 !important',
    },
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
  },
  cssOutlinedInput: {
    padding: 10,
    paddingRight: 0,
    borderRadius: 8,
    minWidth: 250,
  },
  searchButton: {
    color: '#2D6CCB',
    marginRight: `-${theme.spacing(1)}`,
  },
}));

type SearchBarProps = {
  id: string;
  label: string;
  options: string[];
  handleChange: (value: string) => void;
};

const SearchBar = (props: SearchBarProps) => {
  const { id, label, handleChange, options = [] } = props;
  const classes = useStyles({});
  const [input, setInput] = useState<string | undefined>('');

  const handleInputChange = (value: string | undefined) => {
    handleChange(value || '');
  };

  const handleClearSearchInput = () => {
    setInput(undefined);
  };

  return (
    <Container className={classes.container}>
      <Autocomplete
        id={id}
        fullWidth
        data-testid={`${id}-${label}`}
        options={options}
        value={input}
        autoSelect
        onInputChange={(_, value) => {
          setInput(value);
          handleInputChange(value);
        }}
        classes={{
          root: classes.cssOutlinedRoot,
        }}
        disableClearable
        forcePopupIcon={false}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            name={id}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {!input ? null : (
                    <>
                      <IconButton
                        id="listingClearIcon"
                        data-testid="clearIcon"
                        onClick={handleClearSearchInput}
                        className={classes.clearButton}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>

                      <div>|</div>
                    </>
                  )}
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </>
              ),
            }}
          />
        )}
      />
    </Container>
  );
};

export default SearchBar;
