import { makeStyles } from '@mui/styles';
import FormSection from './FormSection';
import { SingleSelect } from 'components/library/form/SingleSelect';
import { NumberField } from 'components/library/form/NumberField';
import { TextBoxField } from 'components/library/form/TextBoxField';
import { useFormState } from 'react-final-form';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles({
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    columnGap: '24px',
    rowGap: '40px',
  },
});

const COMMODITY_OPTIONS = [
  {
    id: '',
    label: 'Master Coil - Eye to The Sky',
    optionName: 'MC_EYE_TO_SKY',
  },
  {
    id: '',
    label: 'Master Coil - Eye to The Side',
    optionName: 'MC_EYE_TO_SIDE',
  },
  {
    id: '',
    label: 'Slit Coil - Eye to The Sky',
    optionName: 'SC_EYE_TO_SKY',
  },
  {
    id: 'ckvl4gwm31eio0d83ndecmvcw',
    label: 'Slit Coil - Eye to The Side',
    optionName: 'SC_EYE_TO_SIDE',
  },
  {
    id: '',
    label: 'Sheet',
    optionName: 'SHEET',
  },
  {
    id: '',
    label: 'Plate',
    optionName: 'PLATE',
  },
  {
    id: '',
    label: 'Blanks',
    optionName: 'BLANKS',
  },
  {
    id: '',
    label: 'Long Product',
    optionName: 'LONG_PRODUCT',
  },
  {
    id: '',
    label: 'Other',
    optionName: 'OTHER',
  },
];

const COMMODITY_OPTIONS_V2 = [
  {
    id: '',
    label: 'Coil',
    optionName: 'COIL',
  },
  {
    id: '',
    label: 'Skidded Coils',
    optionName: 'SKIDDED_COILS',
  },
  {
    id: '',
    label: 'Sheet',
    optionName: 'SHEET',
  },
  {
    id: '',
    label: 'Plate',
    optionName: 'PLATE',
  },
  {
    id: '',
    label: 'Pipe',
    optionName: 'PIPE',
  },
  {
    id: '',
    label: 'Long Product',
    optionName: 'LONG_PRODUCT',
  },
];

const PACKAGING_TYPE_OPTIONS = [
  {
    id: '',
    label: 'Bundles',
    optionName: 'BUNDLES',
  },
  {
    id: '',
    label: 'Coils',
    optionName: 'COILS',
  },
  {
    id: '',
    label: 'Pallets',
    optionName: 'PALLETS',
  },
  {
    id: '',
    label: 'Skids',
    optionName: 'SKIDS',
  },
  {
    id: '',
    label: 'Other',
    optionName: 'OTHER',
  },
];

const SHIPMENT_TYPE_OPTIONS = [
  {
    id: '',
    label: 'Full',
    optionName: 'FULL',
  },
  {
    id: '',
    label: 'Partial',
    optionName: 'PARTIAL',
  },
];

const LONG_PRODUCT_TYPE_OPTIONS = [
  {
    id: '',
    label: 'Beams',
    optionName: 'BEAM',
  },
  {
    id: '',
    label: 'Bars',
    optionName: 'BAR',
  },
  {
    id: '',
    label: 'Pipes',
    optionName: 'PIPE',
  },
  {
    id: '',
    label: 'Tubing',
    optionName: 'TUBE',
  },
  {
    id: '',
    label: 'Angle',
    optionName: 'ANGLE',
  },
  {
    id: '',
    label: 'Channel',
    optionName: 'CHANNEL',
  },
  {
    id: '',
    label: 'Tee',
    optionName: 'TEE',
  },
  {
    id: '',
    label: 'Rebar',
    optionName: 'REBAR',
  },
];

const Load = () => {
  const { ct390ModifyLoadSectionInFreightQuoteTool } = useFlags();
  const classes = useStyles();
  const { values: formData } = useFormState();

  return (
    <FormSection title="Load">
      {ct390ModifyLoadSectionInFreightQuoteTool ? (
        <div className={classes.content}>
          <SingleSelect
            label="Commodity Type"
            name="commodityType"
            options={
              ct390ModifyLoadSectionInFreightQuoteTool
                ? COMMODITY_OPTIONS_V2
                : COMMODITY_OPTIONS
            }
            required
          />
          {formData.commodityType === 'COIL' ? (
            <>
              <NumberField
                name="numberOfCoils"
                label="Number of Coils"
                required
              />
              <NumberField
                name="apxWeightPerCoil"
                label="Approx Weight per Coil (Lbs)"
                required
                thousandSeparator
              />
              <NumberField
                name="weight"
                label="Total Quote Weight (Lbs)"
                thousandSeparator
                disabled
                required={false}
                value={
                  formData.numberOfCoils && formData.apxWeightPerCoil
                    ? formData.numberOfCoils * formData.apxWeightPerCoil
                    : undefined
                }
              />
            </>
          ) : null}
          {formData.commodityType === 'SKIDDED_COILS' ? (
            <>
              <NumberField
                name="quantity"
                label="Number of Skids"
                thousandSeparator
              />
              <NumberField
                name="weight"
                label="Weight (Lbs)"
                required
                thousandSeparator
              />
            </>
          ) : null}
          {formData.commodityType === 'PLATE' ||
          formData.commodityType === 'SHEET' ? (
            <>
              <NumberField
                name="length"
                label="Length (in)"
                required
                decimalScale={2}
              />
              <NumberField
                name="width"
                label="Width (in)"
                required
                decimalScale={2}
              />
              <NumberField
                name="weight"
                label="Weight (Lbs)"
                required
                thousandSeparator
              />
              <NumberField
                name="apxSkidCount"
                label="Approx Skid Count"
                thousandSeparator
              />
            </>
          ) : null}
          {formData.commodityType === 'PIPE' ? (
            <>
              <NumberField
                name="outsideDiameter"
                label="Outside Diameter (in)"
                required
                decimalScale={2}
              />
              <NumberField
                name="length"
                label="Length (ft)"
                required
                decimalScale={2}
              />
              <NumberField name="height" label="Height (in)" decimalScale={2} />
              <NumberField
                name="weight"
                label="Weight (Lbs)"
                required
                thousandSeparator
              />
              <NumberField
                name="apxPieces"
                label="Approx Pieces"
                required
                thousandSeparator
              />
            </>
          ) : null}
          {formData.commodityType === 'LONG_PRODUCT' ? (
            <>
              <NumberField
                name="length"
                label="Length (in)"
                required
                decimalScale={2}
              />
              <NumberField name="width" label="Width (in)" decimalScale={2} />
              <NumberField name="height" label="Height (in)" decimalScale={2} />
              <NumberField
                name="weight"
                label="Weight (Lbs)"
                required
                thousandSeparator
              />
              <NumberField
                name="apxPieces"
                label="Approx Pieces"
                thousandSeparator
              />
            </>
          ) : null}
        </div>
      ) : (
        <div className={classes.content}>
          <SingleSelect
            label="Commodity Type"
            name="commodityType"
            options={COMMODITY_OPTIONS}
            required
          />
          <NumberField
            name="weight"
            label="Weight (Lbs)"
            required
            thousandSeparator
          />
          <SingleSelect
            label="Packaging Type"
            name="packagingType"
            options={PACKAGING_TYPE_OPTIONS}
            required
          />
          {formData.commodityType === 'LONG_PRODUCT' ? (
            <SingleSelect
              label="Long Product Type"
              name="longProductType"
              options={LONG_PRODUCT_TYPE_OPTIONS}
              required
            />
          ) : (
            <div />
          )}
          <div style={{ gridRow: '2 / 4' }}>
            <TextBoxField
              placeholder="Please provide a description of the commodity"
              name="commodityDescription"
              label="Commodity description"
              required={formData.commodityType === 'OTHER'}
              maxLength={150}
            />
          </div>
          <NumberField
            name="length"
            label="Length (in)"
            required={formData.commodityType === 'LONG_PRODUCT'}
            decimalScale={2}
          />
          <NumberField
            name="width"
            label="Width (in)"
            required={false}
            thousandSeparator
            decimalScale={2}
          />
          <NumberField
            name="height"
            label="Height (in)"
            required={false}
            thousandSeparator
            decimalScale={2}
          />
          <SingleSelect
            label="Shipment Type"
            name="shipmentType"
            options={SHIPMENT_TYPE_OPTIONS}
            required={false}
          />
          <NumberField
            name="palletNumber"
            label="Quantity"
            required={false}
            thousandSeparator
          />
        </div>
      )}
    </FormSection>
  );
};

export default Load;
