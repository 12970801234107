import { Grid } from '@mui/material';
import { MyLocation, LocationOn } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { EquipmentDetails } from '../api';
import { TooltipEllipsis } from 'components/TooltipEllipsis';

type Props = Pick<EquipmentDetails, 'origin' | 'destination'>;

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#E9F9FF',
    padding: 24,
  },
  icon: {
    paddingRight: 12,
  },
  address: {
    fontWeight: 700,
  },
}));

const EquipmentDetailsRoute = ({ origin, destination }: Props) => {
  const classes = useStyles();
  const originAddress = `${origin.city}, ${origin.region}`;
  const originZone = !origin.zone ? null : `Zone: ${origin.zone}`;
  const desiredDestinations =
    destination?.length > 0
      ? destination
          .map((d) => {
            const zoneFormatted = !d.zone ? '' : `Zone: ${d.zone}`;
            return [d.city, d.region, zoneFormatted]
              .filter((a) => a)
              .join(', ');
          })
          .join('; ')
      : 'Anywhere';

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6}>
        <Grid container>
          <Grid item className={classes.icon}>
            <MyLocation color="primary" fontSize="inherit" />
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <div>Actual Origin</div>
              <span className={classes.address}>
                <div>{originAddress}</div>
                {!originZone ? null : <div>{originZone}</div>}
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          <Grid item className={classes.icon}>
            <LocationOn color="primary" fontSize="inherit" />
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>Desired Destination</Grid>
              <Grid item className={classes.address} width={224}>
                <TooltipEllipsis textValue={desiredDestinations} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EquipmentDetailsRoute;
