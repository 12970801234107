import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useSearchShipmentsContext } from 'search-shipments/SearchShipmentsContext';

type Props = {
  count: number;
  rowsPerPage: number;
};

const Pagination = ({ count, rowsPerPage }: Props) => {
  const { search, sortOrder, sortBy } = useSearchShipmentsContext();
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(Math.min(count, rowsPerPage));
  const lastPage = Math.ceil(count / rowsPerPage);
  const pageCount = lastPage - 1;

  useEffect(() => {
    setPage(0);
    setStart(1);
    setEnd(count >= rowsPerPage ? rowsPerPage : count);
  }, [count]);

  useEffect(() => {
    setPage(0);
    setStart(1);
  }, [sortOrder, sortBy]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    const offset = newPage * rowsPerPage;
    const lastPageRowCount =
      count % rowsPerPage === 0 ? rowsPerPage : count % rowsPerPage;
    const to = newPage !== pageCount ? rowsPerPage : lastPageRowCount;
    setStart(offset + 1);
    setEnd(offset + to);
    search({
      searchPage: newPage,
      searchSortOrder: sortOrder,
      searchSortBy: sortBy,
    });
  };

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    handleChangePage(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    handleChangePage(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    handleChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    handleChangePage(event, pageCount);
  };

  return (
    <Grid container justifyContent="flex-end" alignItems="center">
      <Grid item>
        {start} - {end} of {count}
      </Grid>
      <Grid item>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
      </Grid>
      <Grid item>
        Page {page + 1} of {lastPage}
      </Grid>
      <Grid item>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= pageCount}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= pageCount}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Pagination;
