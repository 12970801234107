const EQUIPMENT_TYPE_OPTIONS = [
  {
    id: '1',
    label: 'Flatbed',
    optionName: '1',
  },
  {
    id: '2',
    label: 'Dry Van',
    optionName: '2',
  },
  {
    id: '3',
    label: 'Reefer',
    optionName: '3',
  },
  {
    id: '4',
    label: 'Container',
    optionName: '4',
  },
  {
    id: '5',
    label: 'Hopper Bottom',
    optionName: '5',
  },
  {
    id: '6',
    label: 'Conestoga',
    optionName: '6',
  },
  {
    id: '7',
    label: 'Tanker, Steel',
    optionName: '7',
  },
  {
    id: '8',
    label: 'Pneumatic',
    optionName: '8',
  },
  {
    id: '9',
    label: 'Step Deck',
    optionName: '9',
  },
  {
    id: '10',
    label: 'Dump Trailer',
    optionName: '10',
  },
  {
    id: '11',
    label: 'Hotshot',
    optionName: '11',
  },
  {
    id: '12',
    label: 'Drop Deck, Landoll',
    optionName: '12',
  },
  {
    id: '13',
    label: 'Removable Gooseneck',
    optionName: '13',
  },
  {
    id: '14',
    label: 'Stretch Trailer',
    optionName: '14',
  },
  {
    id: '15',
    label: 'Double Drop',
    optionName: '15',
  },
  {
    id: '16',
    label: 'Auto Carrier',
    optionName: '16',
  },
  {
    id: '17',
    label: 'Power Only',
    optionName: '17',
  },
  {
    id: '18',
    label: 'Container Trailer',
    optionName: '18',
  },
  {
    id: '19',
    label: 'Van, Hotshot',
    optionName: '19',
  },
  {
    id: '20',
    label: 'Van w/ Curtains',
    optionName: '20',
  },
  {
    id: '21',
    label: 'Van, Vented',
    optionName: '21',
  },
  {
    id: '22',
    label: 'Other',
    optionName: '22',
  },
];

export { EQUIPMENT_TYPE_OPTIONS };
