import { useRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Theme, Divider, useMediaQuery } from '@mui/material';
import Shipment from './Shipment';
import { AlgoliaShipmentProps } from 'api/shipment-visibility-dashboard';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import ShipmentItem from 'shipment-visibility-dashboard/Shipment/ShipmentItem';
import { InfiniteHitsProvided } from 'react-instantsearch-core';
import { HITS_PER_PAGE } from 'shipment-visibility-dashboard/ShipmentVisibilityDashboard';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingBottom: theme.spacing(1),
  },
  container: {
    marginTop: theme.spacing(1),
    padding: 0,
    overflow: 'auto',
    width: '100%',
  },
  button: {
    padding: 0,
    border: 0,
    backgroundColor: 'white',
    width: '100%',
  },
  statusContainer: {
    padding: 0,
  },
  divider: {
    margin: `${theme.spacing(2)} 0 ${theme.spacing(3)} 0`,
  },
  ref: {
    listStyle: 'none',
  },
  [theme.breakpoints.down('xl')]: {
    container: {},
  },
  [theme.breakpoints.down(900)]: {
    container: {
      height: '100%',
      overflow: 'hidden',
    },
  },
}));

type HitsDisplayProps = {
  selectedShipment?: AlgoliaShipmentProps;
  handleShipmentSelect: (shipment: AlgoliaShipmentProps | undefined) => void;
  setFirstShipment: (shipment: AlgoliaShipmentProps) => void;
  firstShipment?: AlgoliaShipmentProps;
};

export const HitsDisplay = ({
  hits,
  refineNext,
  handleShipmentSelect,
  selectedShipment,
  firstShipment,
  setFirstShipment,
}: InfiniteHitsProvided & HitsDisplayProps) => {
  const sentinel = useRef<HTMLLIElement | null>(null);
  const classes = useStyles();
  const mobile = useMediaQuery('(max-width:1175px)');

  const onSentinelIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      const isThereMoreThanOnePageAvailable = hits.length >= HITS_PER_PAGE;
      if (entry.isIntersecting && isThereMoreThanOnePageAvailable) {
        refineNext();
      }
    });
  };

  useEffect(() => {
    const observer = new window.IntersectionObserver(onSentinelIntersection);
    const elementSentinel = sentinel.current as Element;
    observer.observe(elementSentinel);
    return () => observer.disconnect();
  }, [sentinel, refineNext]);

  useEffect((): void => {
    const isFirstListRender = hits.length > 0 && !firstShipment;
    const newListSelected =
      !hits[0] || (firstShipment && firstShipment.id !== hits[0].id);
    if (isFirstListRender || newListSelected) {
      handleShipmentSelect(hits[0]);
      setFirstShipment(hits[0]);
    }
  }, [hits]);

  const hideShipment =
    (isSelected: boolean) => (e: React.MouseEvent<HTMLElement>) => {
      if (isSelected) {
        e.preventDefault();
        e.stopPropagation();
        handleShipmentSelect(undefined);
      }
    };

  return (
    <div className={classes.wrapper}>
      {hits.map((hit: AlgoliaShipmentProps) => {
        const isSelected = selectedShipment?.shipmentID === hit?.shipmentID;
        const props = {
          key: hit.shipmentID,
          data: hit,
          isSelected,
          hideShipment: hideShipment(isSelected),
        };
        return (
          <div id={hit.shipmentID} key={hit.shipmentID}>
            <button
              className={classes.button}
              key={hit.shipmentID}
              onClick={() => handleShipmentSelect(hit)}
              {...(isSelected ? { 'data-testid': 'shipmentActive' } : {})}
            >
              <Shipment {...props} />
            </button>
            {mobile && isSelected ? (
              <Container
                data-testid="test-shipmentStatusContainer"
                className={classes.statusContainer}
              >
                <Divider className={classes.divider} />
                <ShipmentItem selectedShipment={selectedShipment} />
              </Container>
            ) : null}
          </div>
        );
      })}
      <li className={classes.ref} ref={sentinel} data-testid="sentinelTest" />
    </div>
  );
};

export default connectInfiniteHits(HitsDisplay);
