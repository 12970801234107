import { Box } from '@mui/material';
import Button from 'components/SteelUI/atoms/Button';
import { Dropdown } from 'components/SteelUI/molecules';
import { SyntheticEvent, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import DatePicker from 'components/SteelUI/molecules/DatePicker';
import LocationSearch, {
  getAddressFromPlaceId,
} from 'components/SteelUI/molecules/LocationSearch';
import type { RatesRequest } from '../hooks/useGetCurrentRates';

type Location = {
  city: string;
  state: string;
  zipCode: string;
};

const equipmentOptions = [
  {
    label: 'Flatbed',
    value: '1',
  },
  {
    label: 'Van',
    value: '2',
  },
] as const;

type EquipmentCode = (typeof equipmentOptions)[number]['value'];

type FormData = {
  equipmentCode?: EquipmentCode;
  origin?: Location;
  destination?: Location;
  pickupDate: Date | null;
};

type FormErrors = {
  [K in keyof FormData]?: string;
};

const useStyles = makeStyles()(() => ({
  // TODO: When navigating from marketplace, we still have new-rfq-form.css loaded which affects the form width
  form: { width: '100%', maxWidth: '100%' },
}));

type Props = {
  getRate: (formData: RatesRequest) => void;
};

const Form = ({ getRate }: Props) => {
  const { classes } = useStyles();

  const [formData, setFormData] = useState<FormData>({
    equipmentCode: undefined,
    origin: undefined,
    destination: undefined,
    pickupDate: null,
  });

  const [errors, setErrors] = useState<FormErrors>({});

  const clearError = (field: keyof FormErrors) => {
    setErrors((prev) => ({
      ...prev,
      [field]: undefined,
    }));
  };
  const handleLocationChange = async (
    placeId: string | undefined,
    field: keyof Pick<FormData, 'origin' | 'destination'>
  ) => {
    clearError(field);

    if (!placeId) {
      setFormData({
        ...formData,
        [field]: undefined,
      });
      return;
    }

    try {
      const address = await getAddressFromPlaceId(placeId);
      setFormData({
        ...formData,
        [field]: {
          city: address.city,
          state: address.region,
          zipCode: address.postalCode,
        },
      });
    } catch (error) {
      console.error('Error fetching address details:', error);
    }
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const formErrors: FormErrors = {};
    const { equipmentCode, origin, destination } = formData;
    if (!equipmentCode) {
      formErrors.equipmentCode = 'Equipment Type is required';
    }
    if (!origin) {
      formErrors.origin = 'Pick Up Location is required';
    }
    if (!origin?.city && !origin?.zipCode) {
      formErrors.origin = 'Pick Up Location requires a city or zipcode.';
    }
    if (!destination) {
      formErrors.destination = 'Drop Off Location is required';
    }
    if (!destination?.city && !destination?.zipCode) {
      formErrors.destination = 'Drop Off Location requires a city or zipcode.';
    }
    setErrors(formErrors);
    if (Object.values(formErrors).some((error) => error)) {
      return;
    }
    const equipmentType = parseInt(equipmentCode);
    const pickupDate = formData.pickupDate
      ? formData.pickupDate.toISOString()
      : undefined;

    // convert origin and destination from google maps placeId string to city, state, zipcode
    const request: RatesRequest = {
      equipmentType,
      origin,
      destination,
      pickupDate,
    };
    getRate(request);
  };

  return (
    <form onSubmit={handleSubmit} noValidate className={classes.form}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(9, 1fr)',
          gap: '24px',
        }}
      >
        <Box sx={{ gridColumn: '1 / 3' }}>
          <Dropdown
            label="Equipment"
            value={formData.equipmentCode ? formData.equipmentCode : undefined}
            options={equipmentOptions}
            onChange={(e) => {
              clearError('equipmentCode');
              setFormData({
                ...formData,
                equipmentCode: e.target.value as EquipmentCode,
              });
            }}
            required
            error={errors?.equipmentCode}
          />
        </Box>
        <Box sx={{ gridColumn: '3 / 5' }}>
          <LocationSearch
            label="Pick Up Location"
            onChange={(value) => handleLocationChange(value, 'origin')}
            searchTypes={['(regions)', '(cities)']}
            required
            error={errors?.origin}
          />
        </Box>
        <Box sx={{ gridColumn: '5 / 7' }}>
          <LocationSearch
            label="Drop Off Location"
            onChange={(value) => handleLocationChange(value, 'destination')}
            searchTypes={['(regions)', '(cities)']}
            required
            error={errors?.destination}
          />
        </Box>
        <Box sx={{ gridColumn: '7 / 9' }}>
          <DatePicker
            label="Pick Up Date"
            value={formData.pickupDate}
            onChange={(value) => {
              setFormData({
                ...formData,
                pickupDate: value,
              });
            }}
            minDate={new Date()}
          />
        </Box>
        <Box sx={{ gridColumn: '9 / 10' }}>
          <Button type="submit" sx={{ width: '100%' }}>
            Get Rate
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default Form;
