import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-light-svg-icons';
import { Stack } from '@mui/system';
import { useState } from 'react';

type Props = {
  screen: 'success' | 'error';
  onClose: () => void;
};

const StateScreen = ({ screen, onClose }: Props) => {
  const [, setDeclineShipment] = useState(true);
  const statusColor = screen === 'success' ? '#237960' : '#BB121A';
  const statusIcon = screen === 'success' ? faCheckCircle : faExclamationCircle;

  const handleClose = () => {
    setDeclineShipment(false);
    onClose();
  };

  const getMessage = () => {
    if (screen === 'success') {
      return (
        <Typography>
          Shipment Declined. If you have questions please call{' '}
          <strong style={{ fontWeight: 'bold' }}>+1 (888) 663-0785</strong>
        </Typography>
      );
    }
    return 'Error while declining shipment. Please try again.';
  };

  return (
    <Stack direction="row" spacing={3}>
      <Box
        sx={{
          borderColor: 'rgba(0, 0, 0, 0.38)',
          borderStyle: 'solid',
          borderWidth: '1px',
          minHeight: '50px',
          width: '100%',
          display: 'flex',
          borderRadius: '8px',
          borderLeft: `7px solid ${statusColor}`,
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            padding: '16px 16px 16px 0',
            alignItems: 'flex-start',
          }}
        >
          <FontAwesomeIcon
            icon={statusIcon}
            color={statusColor}
            style={{
              padding: '0 16px',
              paddingTop: '3px',
              marginRight: '3px',
            }}
          />
          <Box>{getMessage()}</Box>
        </Box>

        <Box
          sx={{
            minHeight: '50px',
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Stack>
  );
};

export default StateScreen;
