import { Box, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form as FinalForm, FormSpy } from 'react-final-form';

import Button from 'components/SteelUI/atoms/Button';
import { Body } from 'components/SteelUI/atoms/typography';

import ContactInformation from './ContactInformation';
import Location from './Location';
import Equipment from './Equipment';
import Load from './Load';
import Accessorials from './Accessorials';
import Date from './Date';
import AdditionalInformation from './AdditionalInformation';
import { useState } from 'react';
import Loader from 'components/Loader';
import { usePostFreightQuote } from './usePostFreightQuote';
import { FreightQuoteRequest } from 'api/request-for-quote';
import { useHistory } from 'react-router-dom';
import { trackEvent } from 'utils/mixpanel';
import { AxiosError } from 'axios';
import ErrorDialog from './ErrorDialog';
import SuccessDialog from './SuccessDialog';
import AutoBookedShipmentModal from './AutoBookedShipmentModal';

import type { PlacePredictionFn } from 'request-for-quote/v2/RequestForQuoteV2';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    boxShadow: '0px 0px 4px 0px rgba(33, 34, 36, 0.10)',
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  },
  button: {
    padding: '12px 32px',
    lineHeight: '125%',
  },
  link: {
    color: '#2D6CCB',
    textDecoration: 'none',
  },
  quickQuote: {
    width: '70%',
  },
});

type RequestForQuoteFormProps = {
  isLoading: boolean;
  initialFormData: Record<string, any>;
  placeFetchers: PlacePredictionFn[];
  places: google.maps.places.AutocompletePrediction[];
  placesServiceInstance: google.maps.places.PlacesService | null;
};

// after CT-389, we are using two different input types for equipment. an array of values or a single value.
// let's send data the same way for backwards compat.
const transformFQRDataForPost = (values: Record<string, any>) => {
  if (values?.equipment && !Array.isArray(values?.equipment)) {
    values.equipment = [values.equipment];
  }

  return values;
};

const RequestForQuoteForm = ({
  isLoading,
  initialFormData,
  placeFetchers,
  places,
  placesServiceInstance,
}: RequestForQuoteFormProps) => {
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const postFreightQuote = usePostFreightQuote();
  const history = useHistory();
  const [error, setError] = useState<AxiosError>();
  const [quoteId, setQuoteId] = useState<string>();
  const [autoBookedShipment, setAutoBookedShipment] = useState<
    FreightQuoteRequest | undefined
  >(undefined);

  const handleSubmit = async (values: Record<string, any>) => {
    setSubmitting(true);
    try {
      const transformedValues = transformFQRDataForPost(values);
      const response = await postFreightQuote(
        transformedValues as FreightQuoteRequest
      );
      if (response.data.priceEstimate && !values.bookShipment) {
        const { destinationAddress, originAddress, priceEstimate, refID, id } =
          response.data;

        history.push({
          pathname: `/quote-details`,
          state: {
            ...values,
            priceEstimate,
            destinationAddress,
            originAddress,
            refID,
            id,
          },
        });
      } else if (response.data.priceEstimate && values.bookShipment) {
        setAutoBookedShipment(response.data);
      } else {
        setQuoteId(response.data.id);
      }
      trackEvent('Submitted Freight Quote Request', { values });
    } catch (error) {
      setError(error as AxiosError);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Loader hasLoaded={!isLoading}>
      {error ? (
        <ErrorDialog error={error} open onClose={() => setError(undefined)} />
      ) : null}
      {quoteId ? (
        <SuccessDialog
          id={quoteId}
          open
          onClose={() => setQuoteId(undefined)}
        />
      ) : null}
      {autoBookedShipment ? (
        <AutoBookedShipmentModal
          open={autoBookedShipment !== undefined}
          handleClose={() => {
            setAutoBookedShipment(undefined);
            setQuoteId(undefined);
            window.history.replaceState(null, '', window.location.pathname);
            window.location.reload();
          }}
          shipmentDetails={autoBookedShipment}
        />
      ) : null}
      <div className={classes.root}>
        <Stack direction="column" rowGap="24px">
          {initialFormData.bookShipment ? (
            <Body size="large" className={classes.quickQuote}>
              The quote generated on the quick quote form may change when
              additional shipment details are added. If a quote cannot be
              generated, a logistics representative will contact you with
              updated details.
            </Body>
          ) : null}
          <Body size="large">
            Email us at{' '}
            <a className={classes.link} href="mailto:shippersupport@reibus.com">
              shippersupport@reibus.com
            </a>{' '}
            or give us a call at{' '}
            <a className={classes.link} href="tel:+18888580648">
              (888) 858-0648
            </a>
            , if you need assistance or have any questions.
          </Body>
        </Stack>

        <FinalForm initialValues={initialFormData} onSubmit={handleSubmit}>
          {({ handleSubmit }) => (
            <FormSpy
              subscription={{
                values: true,
                hasValidationErrors: true,
                errors: true,
              }}
            >
              {({ hasValidationErrors }) => {
                return (
                  <form onSubmit={handleSubmit} noValidate>
                    <ContactInformation />
                    <Location
                      placeFetchers={placeFetchers}
                      places={places}
                      placesServiceInstance={placesServiceInstance}
                    />
                    <Equipment />
                    <Load />
                    <Accessorials />
                    <Date />
                    <AdditionalInformation />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                      }}
                    >
                      <Button
                        variant="outlined"
                        disabled={submitting}
                        className={classes.button}
                        onClick={() => window.location.assign('/app/dashboard')}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        className={classes.button}
                        disabled={hasValidationErrors || submitting}
                      >
                        {initialFormData.bookShipment ? 'Book it' : 'Submit'}
                      </Button>
                    </Box>
                  </form>
                );
              }}
            </FormSpy>
          )}
        </FinalForm>
      </div>
    </Loader>
  );
};

export default RequestForQuoteForm;
