import { useState, useEffect, Fragment } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { makeStyles } from '@mui/styles';
import {
  Typography,
  Theme,
  Container,
  Divider,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { getShipmentShareLink } from 'api/shipment-visibility-dashboard';
import { trackEvent } from 'utils/mixpanel';
import ShipmentStatusItem from './ShipmentStatusItem';
import { dateDisplay, dateDisplayNoMidnight } from '../ShipmentUtils';
import ShareButton from 'components/library/ShareButton';
import {
  TurvoGlobalRouteAttributesDate,
  TurvoDate,
  Status,
  Routes,
  TurvoGlobalRoute,
  CurrentLocation,
} from 'api/types';
import { useAuthorizationToken } from '@reibus/frontend-utility';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    textAlign: 'left',
    justifyContent: 'center',
    fontSize: '14px !important',
  },
  infoLine: {
    fontSize: '14px',
    color: '#939393',
    marginBottom: `${theme.spacing(2)}`,
  },
  shipmentTitle: {
    fontSize: 20,
    fontWeight: 600,
    color: '#090A0D',
    textAlign: 'start',
  },
  titleWrapper: {
    marginRight: theme.spacing(2),
  },
  titleDivider: {
    width: 'auto',
    color: 'black',
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  list: {
    padding: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
  },
  item: {
    padding: `14px 0px 0px`,
  },
  [theme.breakpoints.down('lg')]: {
    infoLine: {
      textAlign: 'start',
      marginTop: theme.spacing(1),
    },
    item: {
      textAlign: 'left',
    },
  },
  [theme.breakpoints.down('sm')]: {
    infoLine: {
      fontSize: 12,
    },
  },
}));

const getDeliveryDate = (globalRoute: TurvoGlobalRoute[] | Routes[]) => {
  const index = globalRoute?.length - 1;
  if (index > 0) {
    const route = globalRoute[index];
    const deliveryDate = route?.attributes?.departed?.date;
    const timeZone = route?.attributes?.arrival?.timezone;
    return deliveryDate && timeZone
      ? `Delivered: ${dateDisplay(deliveryDate, timeZone)}`
      : undefined;
  }
};

const getDaysUntilCompletion = (endDate: string): number => {
  const finishDate = new Date(endDate);
  const nowDate = new Date();

  finishDate.setUTCHours(0, 0, 0);
  nowDate.setUTCHours(0, 0, 0);

  const differenceInTime = finishDate.getTime() - nowDate.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return Math.ceil(differenceInDays);
};

const calculateDaysUntilCompletion = (
  statusLabel: Status,
  endDate: string
): string => {
  const remDays = getDaysUntilCompletion(endDate);

  //if no statusLabel, display nothing
  if (!statusLabel) return '';

  const { status } = statusLabel;

  if (status === 'delivered') {
    return 'Delivered';
  }

  if (remDays < 0) {
    return 'Delayed';
  }
  switch (remDays) {
    case 0: {
      return 'Delivering Today';
    }
    case 1: {
      return `${remDays} Day Until Completion`;
    }
    default: {
      return `${remDays} Days Until Completion`;
    }
  }
};

type ShipmentStatusProps = {
  endDate: string;
  endDateFull: TurvoDate;
  startDateFull: TurvoDate;
  tenderedAt?: string;
  statusLabel: Status;
  statusCode: string;
  globalRoute: Routes[] | TurvoGlobalRoute[];
  currentLocation?: CurrentLocation;
  shipmentID: number;
};

const ShipmentStatus = ({
  endDate,
  endDateFull,
  startDateFull,
  tenderedAt,
  statusLabel,
  statusCode,
  globalRoute,
  currentLocation,
  shipmentID,
}: ShipmentStatusProps) => {
  const [shipmentShareLink, setShipmentShareLink] = useState('');
  const authToken = useAuthorizationToken();

  const generateShareLink = (link: string) =>
    `${window.location.origin}${link}`;

  const fetchShipmentShareLink = async (
    shipmentID: string,
    identityToken: string
  ) => {
    const resp = await getShipmentShareLink(identityToken, shipmentID);
    return resp.route;
  };

  const initShipmentShareLink = async (id: number) => {
    try {
      if (!id || !authToken) {
        throw new Error('no id or identity token');
      }

      const link = await fetchShipmentShareLink(`${id}`, authToken);
      const shareLink = generateShareLink(link);
      setShipmentShareLink(shareLink);
    } catch (e) {
      console.error('unable to fetch share link', e);
    }
  };

  const classes = useStyles();
  const mobile = useMediaQuery('(max-width: 500px)');

  const { rl2459SvdUseP44CurrentLocation } = useFlags();

  const completionDate =
    rl2459SvdUseP44CurrentLocation && currentLocation?.estimatedArrivalTime
      ? currentLocation?.estimatedArrivalTime
      : endDate;

  const dayUntilCompletionLabel = calculateDaysUntilCompletion(
    statusLabel,
    completionDate
  );

  useEffect(() => {
    initShipmentShareLink(shipmentID);
  }, [shipmentID]);

  return (
    <>
      <Grid container alignItems="center">
        <Grid className={classes.titleWrapper} item>
          <Typography className={classes.shipmentTitle}>
            Shipment Status
          </Typography>
        </Grid>
        {shipmentShareLink ? (
          <Grid>
            <ShareButton
              buttonText="Share Tracking"
              successText="Link copied to clipboard"
              copyText={shipmentShareLink}
              onClick={() =>
                trackEvent(
                  'Clicked Share Tracking on Shipment Visibility Dashboard'
                )
              }
            />
          </Grid>
        ) : null}
      </Grid>
      <Typography className={classes.infoLine}>
        {dayUntilCompletionLabel}
        {dayUntilCompletionLabel !== 'Delivered' &&
        dayUntilCompletionLabel !== 'Delayed'
          ? `  |  ETA:
            ${
              rl2459SvdUseP44CurrentLocation &&
              currentLocation?.estimatedArrivalTime
                ? dateDisplay(currentLocation?.estimatedArrivalTime)
                : dateDisplayNoMidnight(endDateFull.date, endDateFull.timeZone)
            }`
          : ''}
      </Typography>
      <Divider className={classes.titleDivider} />
      <Container className={classes.list}>
        <Container className={classes.item}>
          <ShipmentStatusItem
            {...{
              title: 'STARTED',
              location: '',
              info: tenderedAt
                ? `Tendered: ${dateDisplay(tenderedAt, startDateFull.timeZone)}`
                : '',
              isStarted: true,
              progressStatus: statusLabel,
              shipmentStatusCode: statusCode,
            }}
          />
        </Container>
        {globalRoute?.map(
          (
            state: Routes | TurvoGlobalRoute,
            index: number,
            array: Array<Routes | TurvoGlobalRoute>
          ) => {
            const scheduleDate: TurvoGlobalRouteAttributesDate = {
              date: state.appointment.date,
              timezone: state.appointment.timeZone,
            };
            return (
              <Container key={state.id} className={classes.item}>
                <ShipmentStatusItem
                  {...{
                    title: state.stopType.value,
                    location: state.address?.locationLabel ?? '',
                    schedule: scheduleDate,
                    arrival: state.attributes?.arrival,
                    departure: state.attributes?.departed,
                    progressStatus: statusLabel,
                    hasDeliveryNext:
                      array[index + 1]?.stopType.value.toLowerCase() ===
                      'delivery',
                    lastDelivery: !array[index + 1],
                    schedulingType: state.schedulingType,
                    flex: state.appointment.flex,
                    shipmentStatusCode: statusCode,
                  }}
                />
              </Container>
            );
          }
        )}
        <Container className={classes.item}>
          <ShipmentStatusItem
            {...{
              title: 'DELIVERED',
              location: '',
              isDelivered: true,
              info: getDeliveryDate(globalRoute) as string,
              progressStatus: statusLabel,
              shipmentStatusCode: statusCode,
            }}
          />
          {mobile ? <Divider className={classes.divider} /> : null}
        </Container>
      </Container>
    </>
  );
};

export default ShipmentStatus;
