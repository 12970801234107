import { useQuery } from 'react-query';
import { getShipmentDetails } from 'shipment/api';
import { useAuthorizationToken } from '@reibus/frontend-utility';

export default function useShipmentDetails(shipmentId: string) {
  const authorization = useAuthorizationToken();
  return useQuery(
    ['shipment', shipmentId],
    () => getShipmentDetails(authorization, shipmentId),
    {
      enabled: !!authorization,
    }
  );
}
