import axios from 'axios';
import { DispositionType } from 'features/cse/disposition/api';
import { getApiRoot } from 'utils/apiUtils';
import { DeclineDispositionOptions } from './hooks/useMutateAutoAward';
import { DigitalFreightAward } from 'features/cse/AutoAward/types';

type Address = {
  address1: string;
  city: string;
  region: string;
  postalCode: string;
};

type SchedulingType =
  | 'BY_APPOINTMENT'
  | 'FCFS'
  | 'TURVO_APPOINTMENT_SCHEDULING';

type Appointment = {
  datetime: string;
  timezone: string;
  flex: number;
  schedulingType: SchedulingType;
};

type Coordinates = { lat: number; lon: number };

type Stop = {
  name: string;
  address: Address;
  appointment: Appointment;
  coordinates: Coordinates;
  notes?: string;
};

type Route = {
  pickup: Stop;
  delivery: Stop;
  distance: number;
};

type Equipment = {
  code: number;
  description: string;
  descriptionExpanded: string;
};

type Service = {
  code: string;
  description: string;
};

type Commodity = {
  name: string;
  quantity: number;
};

type Shipment = {
  id: string;
  label: string;
  weight: number;
  rate: number;
  equipment: Equipment[];
  services: Service[];
  commodities: Commodity[];
  route: Route;
  equipmentNeededDesc: string;
};

type AutoAwardShipmentResponse = {
  digitalFreightAward: Pick<DigitalFreightAward, 'status'>;
  shipment: Shipment;
};

const getAutoAwardById = async (
  id: string
): Promise<AutoAwardShipmentResponse> => {
  const url = `${getApiRoot()}/digital-freight/shipment/confirmation/${id}`;
  const { data } = await axios.get(url);

  return data;
};

const respondToAutoAwardById = async ({
  id,
  disposition,
  declineDispositionOptions,
}: {
  id: string;
  disposition: DispositionType;
  declineDispositionOptions?: DeclineDispositionOptions;
}) => {
  const url = `${getApiRoot()}/digital-freight/${id}/dispositions`;
  const body = JSON.stringify({
    disposition,
    ...(declineDispositionOptions ? { ...declineDispositionOptions } : {}),
  });

  return await axios.post(url, body);
};

export { getAutoAwardById, respondToAutoAwardById };
export type { Route, Equipment, Commodity, Service };
