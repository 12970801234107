import { Grid, Typography, Theme, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { ShipmentDispositionData } from 'features/cse/disposition/api';
import Chip from 'components/library/Chip';
import { formatDateTime, dateTimeFormatList } from '@reibus/reibus-core-utils';
import numeral from 'numeral';

const { dateTime } = dateTimeFormatList;

const useStyles = makeStyles((theme: Theme) => ({
  boldtext: {
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  chipText: {
    textTransform: 'capitalize',
  },
}));

const getTextWithoutSpecialCharacter = (text: string) => {
  return (text ?? '').toLocaleLowerCase().replace(/_/g, ' ');
};

const formatDate = (date: string) => {
  const dateText = formatDateTime(new Date(date), 'en-US', dateTime);
  return dateText.replace(',', ' ');
};

const getChipLabel = (reason = '', subreason = '') => {
  if (reason === 'PRICE') {
    return getTextWithoutSpecialCharacter(reason);
  }
  if (reason === 'CONTACTED' && subreason === 'EMAIL') {
    return getTextWithoutSpecialCharacter('Emailed');
  }
  return getTextWithoutSpecialCharacter(subreason);
};

type Props = Pick<
  ShipmentDispositionData,
  | 'comments'
  | 'reason'
  | 'subreason'
  | 'createdAt'
  | 'user'
  | 'disposition'
  | 'subreasonExplanation'
  | 'source'
>;

const DispositionComment = ({
  comments,
  reason,
  subreason,
  createdAt,
  user,
  disposition,
  subreasonExplanation,
  source,
}: Props) => {
  const classes = useStyles();

  const byPrice = reason === 'PRICE';
  const chip = getChipLabel(reason, subreason);
  const title = disposition === 'DECLINE' ? 'Decline' : reason;

  const rateRequested = `Carrier's desired rate is: ${numeral(
    subreasonExplanation
  ).format('$0,0.00')}`;

  const formatUser = () => {
    if (source === 'DIGITAL_FREIGHT_AWARD') {
      return 'Auto-Awards';
    }

    if (!user?.name) {
      return 'N/A';
    }

    return user.name;
  };

  return (
    <Grid container justifyContent="space-between" spacing={2}>
      <Grid item>
        <Typography className={classes.boldtext}>
          {title ? getTextWithoutSpecialCharacter(title) : null}
        </Typography>
      </Grid>
      <Grid item>
        {chip ? (
          <Chip
            className={classes.chipText}
            label={chip}
            variant="outlined"
            color="secondary"
          />
        ) : null}
      </Grid>
      {byPrice ? (
        <Grid item xs={12}>
          <span>{rateRequested}</span>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <span className={classes.boldtext}>Comments:</span> {comments}
      </Grid>
      <Grid item>
        <span className={classes.boldtext}>User: {formatUser()}</span>
      </Grid>
      <Grid item>{formatDate(createdAt || '')}</Grid>
      <Grid item xs={12} className={classes.divider}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default DispositionComment;
export const EXPORT_FOR_TESTING = {
  getTextWithoutSpecialCharacter,
  formatDate,
};
