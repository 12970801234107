import { DialogContent, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ButtonV2 from 'components/library/ButtonV2';
import { useDispositionContext } from 'features/cse/disposition/contexts/DispositionContext';
import theme from 'theme';
import { useQueryClient } from 'react-query';
import {
  DispositionReason,
  DispositionSource,
} from 'features/cse/disposition/api';

const DEFAULT_MESSAGE =
  "This carrier will be removed from shipment's Top Carriers list.";

const SUCCESS_MESSAGES: Record<
  Extract<DispositionReason, 'PRICE'>,
  Record<DispositionSource, string>
> = {
  PRICE: {
    CARRIER:
      'The carrier has declined this shipment but it will continue to show in the Top Shipments list. The carrier can still disposition the shipment as the reason for declining was the rate offered. Notes of the desired rate have been logged.',
    SHIPMENT:
      'The carrier has been marked as declining the shipment but will continue to show in the Top Carriers list. The carrier can still be dispositioned as the reason for declining was the rate offered. Notes of the desired rate have been logged.',
    DIGITAL_FREIGHT_AWARD:
      'The carrier has declined this shipment via Auto-Awards.',
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(3),
  },
  button: {
    padding: theme.spacing(2, 0),
  },
}));

type SuccessProps = {
  reason?: DispositionReason;
};

const Success = ({ reason }: SuccessProps) => {
  const classes = useStyles();
  const { closeDispositionModal, source, cseCarrierID } =
    useDispositionContext();
  const queryClient = useQueryClient();

  const handleClose = () => {
    if (source === 'CARRIER')
      queryClient.invalidateQueries([
        'shipments-scored-carriers',
        cseCarrierID,
      ]);
    closeDispositionModal();
  };

  const getBodySuccess = () =>
    reason === 'PRICE' ? SUCCESS_MESSAGES[reason][source] : DEFAULT_MESSAGE;

  return (
    <DialogContent>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
      >
        <Grid item>
          <CheckCircleIcon
            style={{ color: theme.palette.success.main }}
            fontSize="large"
          />
        </Grid>
        <Grid item>
          <Typography variant="h3">Shipment Declined</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{getBodySuccess()}</Typography>
        </Grid>
        <Grid item className={classes.button}>
          <ButtonV2 onClick={() => handleClose()}>Close</ButtonV2>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default Success;

const exportForTesting = { SUCCESS_MESSAGES, DEFAULT_MESSAGE };
export { exportForTesting };
