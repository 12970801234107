import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import type {
  EquipmentServices,
  Commodities,
  WeightUnit,
  LoadboardPosting,
} from 'shipment/api';
import {
  displayFormattingValue,
  valuesFormatList,
} from '@reibus/reibus-core-utils';
import { PriceInformation } from './PriceInformation';
import { TooltipEllipsis } from 'components/TooltipEllipsis';
import { LoadboardPostings } from './LoadboardPostings';
import { H2, H4 } from 'components/SteelUI/atoms/typography';

const {
  listings: { weight: commaSeparated },
} = valuesFormatList;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  additionalInfoValue: {
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  additionalInfoBlock: {
    maxWidth: '25%',
    paddingRight: theme.spacing(2),
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  rightColumnItem: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
}));

export const AdditionalInformationValue = ({
  textValue,
}: {
  textValue: string;
}) => <TooltipEllipsis textValue={textValue} />;

export type AdditionalInformationProps = {
  weight?: number;
  weightUnit: WeightUnit;
  equipmentNeededDesc: string;
  commodities: Commodities[];
  services: EquipmentServices[];
  minPay: number | null;
  maxPay: number | null;
  targetPay: number | null;
  shipmentId: string;
  postings: LoadboardPosting[];
};

const WEIGHT_UNIT_CONVERSION = {
  ['POUND']: 'lbs',
};

const AdditionalInformation = (props: AdditionalInformationProps) => {
  const {
    weight,
    weightUnit,
    equipmentNeededDesc = 'N/A',
    commodities = [],
    services = [],
    minPay,
    maxPay,
    targetPay,
    shipmentId,
    postings,
  } = props;

  const classes = useStyles();
  const equipmentValue =
    !equipmentNeededDesc || equipmentNeededDesc === ''
      ? 'N/A'
      : equipmentNeededDesc;
  const servicesValue =
    services.length > 0
      ? services.map((service) => service.description).join(', ')
      : 'N/A';
  const weightValue =
    weight && weightUnit
      ? `${displayFormattingValue(Number(weight), commaSeparated)} ${
          WEIGHT_UNIT_CONVERSION[weightUnit] || ''
        }`
      : 'N/A';
  const commoditiesValue =
    commodities.length > 0
      ? commodities
          .map(({ quantity, name }) => {
            if (!quantity || quantity === 'null') return name;
            return [quantity, name].join(' ');
          })
          .join(', ')
      : 'N/A';

  return (
    <Grid
      container
      justifyContent="space-between"
      className={classes.container}
      wrap="nowrap"
    >
      <Grid item xs={12} md={8}>
        <Grid container direction="column">
          <Grid item xs>
            <H2 weight="semibold">Additional Information</H2>
          </Grid>
          <Grid item xs>
            <Grid container>
              <Grid className={classes.additionalInfoBlock} xs item>
                <H4 weight="bold">Equipment Type:</H4>
                <AdditionalInformationValue textValue={equipmentValue} />
              </Grid>
              <Grid className={classes.additionalInfoBlock} xs item>
                <H4 weight="bold">Services:</H4>
                <AdditionalInformationValue textValue={servicesValue} />
              </Grid>
              <Grid className={classes.additionalInfoBlock} xs item>
                <H4 weight="bold">Weight:</H4>
                <AdditionalInformationValue textValue={weightValue} />
              </Grid>
              <Grid className={classes.additionalInfoBlock} xs item>
                <H4 weight="bold">Commodity:</H4>
                <AdditionalInformationValue textValue={commoditiesValue} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={4}>
        <div className={classes.rightColumn}>
          <div className={classes.rightColumnItem}>
            <PriceInformation
              minPay={minPay}
              maxPay={maxPay}
              targetPay={targetPay}
              shipmentId={shipmentId}
            />
          </div>
          <div className={classes.rightColumnItem}>
            <LoadboardPostings postings={postings} />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
export default AdditionalInformation;
