import { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useQuery } from 'react-query';
import { useAuthorizationToken } from '@reibus/frontend-utility';

import SkeletonWrapper from 'components/library/SkeletonWrapper';
import Pagination from 'components/SteelUI/organisms/Pagination';
import { CarrierDetailResponse } from 'shipment/api';
import { ShipmentContextProvider } from 'shipment/ShipmentContext';
import { getTopShipmentsPaginated } from 'features/cse/Carrier/api';
import NoResults from 'features/cse/Carrier/components/NoResults';
import { DispositionContextProvider } from 'features/cse/disposition/contexts/DispositionContext';
import { DispositionsForShipmentContextProvider } from 'features/cse/disposition/contexts/DispositionsForShipmentContext';

import TopShipment from './components/TopShipment';
import TopShipmentsFiltersV2 from './components/TopShipmentsFilters/TopShipmentsFiltersV2';
import SendTopShipments from './components/SendTopShipments';

import type {
  ShipmentWithScores,
  TopShipmentsFilters,
} from 'features/cse/Carrier/types';

type TopShipmentProps = {
  carrier: CarrierDetailResponse;
};

const RowsPerPage = 10;

const TopShipmentsV2 = ({ carrier }: TopShipmentProps) => {
  const authorization = useAuthorizationToken();
  const { cseCarrierID, carrierName } = carrier;
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<TopShipmentsFilters>();
  const { isLoading, data: { results = [], totalCount = 0 } = {} } = useQuery(
    ['shipments-scored-carriers', cseCarrierID, page + 1, filters],
    () =>
      getTopShipmentsPaginated({
        authorization,
        cseCarrierID,
        page: page + 1,
        filters,
        pageSize: RowsPerPage,
      }),
    { enabled: !!authorization }
  );

  useEffect(() => {
    setPage(0);
  }, [filters]);

  return (
    <>
      <Stack spacing={2}>
        <Box sx={{ fontWeight: 700, fontSize: 24, lineHeight: '125%' }}>
          Top Shipments
        </Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <TopShipmentsFiltersV2
            setFilters={setFilters}
            cseCarrierID={cseCarrierID}
          />
          <SendTopShipments
            carrierName={carrierName}
            shipmentsCount={results.length}
            cseCarrierID={cseCarrierID}
            shipments={results}
          />
        </Stack>
        <SkeletonWrapper isLoading={isLoading} height={232}>
          {results.length ? (
            <>
              {results.map((shipment: ShipmentWithScores) => (
                <DispositionContextProvider
                  key={`top-shipment-${shipment.shipmentID}`}
                >
                  <DispositionsForShipmentContextProvider
                    shipmentId={shipment.shipmentID}
                  >
                    <TopShipment shipment={shipment} carrier={carrier} />
                  </DispositionsForShipmentContextProvider>
                </DispositionContextProvider>
              ))}
              <Pagination
                count={totalCount}
                rowsPerPage={RowsPerPage}
                page={page}
                changePage={setPage}
              />
            </>
          ) : (
            <NoResults description="There are no top shipments to display." />
          )}
        </SkeletonWrapper>
      </Stack>
    </>
  );
};

export default TopShipmentsV2;
