import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CapacityIndicatorsTable from './components/CapacityIndicatorsTable';
import CompositeScore from './components/CompositeScore';
import Modal from 'components/library/Modal';
import { formatInTimeZone } from 'date-fns-tz';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  totalScore: number;
  scoringTime: string;
  indicators: {
    key: string;
    name: string;
    weight: number;
    totalScore: number;
  }[];
};

const useStyles = makeStyles(() => ({
  container: {
    padding: 24,
    '& > *:not(:first-child)': {
      marginTop: 24,
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '120%',
  },
}));

const CompositeScoreDetailsModal = ({
  isOpen,
  handleClose,
  totalScore,
  scoringTime,
  indicators,
}: Props) => {
  const classes = useStyles();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const scoringTimeFormatted = !scoringTime
    ? 'N/A'
    : formatInTimeZone(scoringTime, timezone, 'yyyy-MM-dd HH:mm zzz');

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <Grid container direction="column" className={classes.container}>
        <Grid item className={classes.title}>
          Composite Score Details
        </Grid>
        <Grid item>
          <CompositeScore
            compositeScore={totalScore}
            datetime={scoringTimeFormatted}
          />
        </Grid>
        <Grid item>
          <CapacityIndicatorsTable indicators={indicators} />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CompositeScoreDetailsModal;
