import { makeStyles } from '@mui/styles';
import { Theme, Typography, Box } from '@mui/material';
import { FormSpy } from 'react-final-form';
import { TextField } from './TextField';
import { EmailField } from './EmailField';
import { PhoneField } from './PhoneField';
import { SingleSelect } from './SingleSelect';
import { NumberField } from './NumberField';
import { DateField } from './DateField';
import { AutoSelect } from './AutoSelect';
import { Checkbox } from './CheckBox';
import { TextBoxField } from './TextBoxField';
import { FieldProps, InputIfOption } from './types';
import { InputMeta } from './layoutTypes';
import { AddressField } from './AddressField';
import { KeyAndUntypedValue } from 'api/types';
import { AutoSelectSingle } from './AutoSelectSingle';
import { NameField } from './NameField';
import { Radio } from './Radio';

const useStyles = makeStyles((theme: Theme) => ({
  inputDescription: {
    marginBottom: theme.spacing(2),
  },
}));

const Input = ({
  inputData,
  enableRequired,
  showManualLabel,
}: {
  inputData: FieldProps;
  enableRequired?: boolean;
  showManualLabel?: boolean;
}) => {
  const required = enableRequired || inputData.required;
  switch (inputData.inputType) {
    case 'SELECT':
      return (
        <SingleSelect
          placeholder={inputData.placeholder}
          name={inputData.databaseName}
          label={inputData.label}
          required={required}
          options={inputData.selectOptions || []}
          key={inputData.databaseName}
        />
      );
    case 'DATE':
      return (
        <DateField
          placeholder={inputData.placeholder}
          name={inputData.databaseName}
          label={inputData.label}
          required={required}
          key={inputData.databaseName}
          minDateGreaterThanInput={inputData.minDateGreaterThanInput}
          disablePast={inputData.preventPastDates}
        />
      );
    case 'PHONE':
      return (
        <PhoneField
          placeholder={inputData.placeholder}
          name={inputData.databaseName}
          label={inputData.label}
          required={required}
          key={inputData.databaseName}
        />
      );
    case 'CHECK_BOX':
      return (
        <Checkbox
          name={inputData.databaseName}
          label={inputData.label}
          required={required}
          key={inputData.databaseName}
        />
      );
    case 'NUMBER':
      return (
        <NumberField
          placeholder={inputData.placeholder}
          name={inputData.databaseName}
          label={inputData.label}
          required={required}
          maxLength={inputData.maxLength}
          thousandSeparator={inputData.thousandSeparator}
          prefix={inputData.prefix}
          decimalScale={inputData.decimalScale}
          allowNegative={inputData.allowNegative}
        />
      );
    case 'AUTO_SELECT':
      return (
        <AutoSelect
          placeholder={inputData.placeholder}
          name={inputData.databaseName}
          label={inputData.label}
          required={required}
          key={inputData.databaseName}
          options={inputData.selectOptions || []} // fix me
          defaultValue={inputData.defaultValue}
        />
      );
    case 'AUTO_SELECT_SINGLE':
      return (
        <AutoSelectSingle
          placeholder={inputData.placeholder}
          name={inputData.databaseName}
          label={inputData.label}
          required={required}
          key={inputData.databaseName}
          options={inputData.selectOptions || []} // fix me
        />
      );
    case 'TEXT_BOX':
      return (
        <TextBoxField
          placeholder={inputData.placeholder}
          name={inputData.databaseName}
          label={inputData.label}
          required={required}
          key={inputData.databaseName}
          maxLength={inputData.maxLength}
        />
      );
    case 'EMAIL':
      return (
        <EmailField
          placeholder={inputData.placeholder}
          name={inputData.databaseName}
          label={inputData.label}
          required={required}
          key={inputData.databaseName}
          maxLength={inputData.maxLength}
        />
      );
    case 'ADDRESS':
      return (
        <AddressField
          name={inputData.databaseName}
          label={inputData.label}
          required={inputData.required}
          key={inputData.databaseName}
          showManualLabel={showManualLabel}
        />
      );
    case 'NAME':
      return (
        <NameField
          placeholder={inputData.placeholder}
          name={inputData.databaseName}
          label={inputData.label}
          required={required}
          key={inputData.databaseName}
          maxLength={inputData.maxLength}
        />
      );
    case 'RADIO':
      return (
        <Radio
          name={inputData.databaseName}
          label={inputData.label}
          key={inputData.databaseName}
          options={inputData.radioOptions || []}
        />
      );
    case 'TEXT':
    default:
      return (
        <TextField
          placeholder={inputData.placeholder}
          name={inputData.databaseName}
          label={inputData.label}
          required={required}
          key={inputData.databaseName}
          maxLength={inputData.maxLength}
        />
      );
  }
};

const InputWithDescription = ({
  inputDataForField,
  field,
  enableRequired,
}: {
  inputDataForField: FieldProps;
  field: InputMeta;
  enableRequired?: boolean;
}) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        gridRow: `span ${field.rowHeight || 1}`,
        gridColumn: `span ${field.colWidth}`,
      }}
      style={{ height: '100%' }}
    >
      {inputDataForField.description ? (
        <Typography className={classes.inputDescription}>
          {inputDataForField.description}
        </Typography>
      ) : null}
      <Input
        inputData={inputDataForField}
        enableRequired={enableRequired}
        showManualLabel={field.showManualLabel}
      />
    </Box>
  );
};

// FIX ME - make this more understandable
// this function checks and input against the other current values of the form, to determine if the input should be shown or not
// it uses the ShowIF metadata for this input. this data contains an array of form inputs and their possible values that should determine if the input should be shown
const checkIfInputShouldShow = (
  showIfArray: InputIfOption[],
  values: KeyAndUntypedValue
) => {
  const allCheck = showIfArray.map((si) => {
    const valueToCheck = values[si.databaseName];
    return (
      !!valueToCheck &&
      si.value.filter((siValue) => valueToCheck.includes(siValue)).length > 0
    );
  });
  // based on the array of input conditions that need to be met, are all checks successfully passing?
  return !allCheck.includes(false);
};

type InputProps = {
  inputDataForField: FieldProps;
  field: InputMeta;
};

export const FormInput = (props: InputProps): JSX.Element => {
  const { inputDataForField } = props;
  const { showIf = [], requiredIf = [] } = inputDataForField;
  const inputTracksRequired = requiredIf.length > 0;
  const inputShowsConditionally = showIf.length > 0;
  const trackingOtherInputs = inputTracksRequired || inputShowsConditionally;

  if (trackingOtherInputs) {
    return (
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          const showInput = checkIfInputShouldShow(showIf, values);
          const enableRequired = inputTracksRequired
            ? checkIfInputShouldShow(requiredIf, values)
            : false;

          return showInput ? (
            <InputWithDescription {...props} enableRequired={enableRequired} />
          ) : null;
        }}
      </FormSpy>
    );
  } else {
    return <InputWithDescription {...props} />;
  }
};
