import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  Theme,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  DialogContent,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getLaneHistoryShipments } from 'shipment/api';
import type { Shipment } from 'shipment/api';
import LoadingTable from 'features/cse/LoadingTable';
import numeral from 'numeral';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { formatDateTime, dateTimeFormatList } from '@reibus/reibus-core-utils';
const { shortDate } = dateTimeFormatList;
import { useAuthorizationToken } from '@reibus/frontend-utility';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 700,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  table: {
    width: 1000,
    border: `1px solid ${theme.palette.grey[100]}`,
    marginBottom: theme.spacing(3),
  },
  tableHead: {
    background: theme.palette.grey[100],
  },
  columns: {
    fontWeight: 600,
    width: 125,
  },
  subtitle: {
    color: theme.palette.grey[700],
  },
  dialogTitle: {
    marginBottom: theme.spacing(3),
  },
  content: {
    border: `1px solid ${theme.palette.grey[100]}`,
    height: 40,
  },
}));

type Props = {
  isOpen: boolean;
  carrierName?: string;
  carrierID: string;
  handleClose: () => void;
  reibusId: string;
};
const formatDate = (date: string) => {
  const dateText = formatDateTime(new Date(date), 'en-US', shortDate);
  return dateText;
};

const LaneHistoryModal = ({
  isOpen,
  carrierName,
  carrierID,
  handleClose,
  reibusId,
}: Props) => {
  const classes = useStyles();

  const { rl1994NewLaneHistoryShipmentsEndpoint } = useFlags();
  const [shipments, setShipments] = useState<Shipment[] | null>(null);
  const [isLoading, setIsloading] = useState(true);
  const authToken = useAuthorizationToken();

  const laneShipments = useQuery(
    ['laneHistoryShipments', carrierID],
    () =>
      getLaneHistoryShipments(
        authToken,
        carrierID,
        rl1994NewLaneHistoryShipmentsEndpoint,
        reibusId
      ),
    { enabled: isOpen && !!authToken }
  );

  useEffect(() => {
    if (!laneShipments.isLoading) {
      setIsloading(false);
    } else {
      setIsloading(true);
    }
  }, [laneShipments.isLoading]);

  useEffect(() => {
    if (!laneShipments.data) return;
    setShipments(laneShipments.data);
  }, [laneShipments.data]);

  return (
    <Dialog open={isOpen} maxWidth={'xl'}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.title}>
          {carrierName} - <span className={classes.subtitle}>Lane History</span>
        </Typography>
        <IconButton
          aria-label="close"
          data-testid="icon-closeShipmentByCarrier"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box sx={{ width: 1000 }}>
            <LoadingTable />
          </Box>
        ) : (
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.columns} align="right">
                  Shipment ID
                </TableCell>
                <TableCell className={classes.columns}>Origin</TableCell>
                <TableCell className={classes.columns}>Destination</TableCell>
                <TableCell className={classes.columns}>Customer</TableCell>
                <TableCell className={classes.columns} align="right">
                  Carrier Rate
                </TableCell>
                <TableCell className={classes.columns} align="right">
                  Pickup Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shipments === null
                ? null
                : shipments.map(
                    ({
                      shipmentID,
                      originAddress,
                      destinationAddress,
                      shipmentTMSCompanies,
                      totalCarrierCost,
                      startDate,
                    }: Shipment) => (
                      <TableRow key={`Lane-history-${shipmentID}`}>
                        <TableCell className={classes.content} align="right">
                          {shipmentID}
                        </TableCell>
                        <TableCell className={classes.content}>
                          {originAddress
                            ? `${originAddress.city}, ${originAddress.region}`
                            : 'N/A'}
                        </TableCell>
                        <TableCell className={classes.content}>
                          {destinationAddress
                            ? `${destinationAddress.city}, ${destinationAddress.region}`
                            : 'N/A'}
                        </TableCell>
                        <TableCell className={classes.content}>
                          {shipmentTMSCompanies
                            .map((tms) => tms.tmsName)
                            .join(', ')}
                        </TableCell>
                        <TableCell className={classes.content} align="right">
                          {numeral(totalCarrierCost).format('$0,0.00')}
                        </TableCell>
                        <TableCell className={classes.content} align="right">
                          {formatDate(startDate)}
                        </TableCell>
                      </TableRow>
                    )
                  )}
            </TableBody>
          </Table>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LaneHistoryModal;
