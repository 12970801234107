import { Box, Card, Theme, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Chip from 'components/library/Chip';
import { EquipmentContactInfo } from 'shipment/api';
import DispositionEmail from './DispositionEmail';
import Phone from 'shipment/carrier-contact-info-modal/Phone';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(2),
  },
  poster: {
    color: theme.palette.grey['500'],
  },
}));

const EquipmentContactInfoRow = ({
  callbackPhone,
  contactEmail,
  carrierName,
  capacityIndicatorType,
  posterName,
}: EquipmentContactInfo) => {
  const classes = useStyles();

  const isDAT = capacityIndicatorType === 'DAT';
  const formatPosterName = (value: string) => {
    if (isDAT) return 'Carrier contact';
    return value ?? 'N/A';
  };

  return (
    <Card variant="outlined" className={classes.card}>
      <Stack spacing={4} direction="row" alignItems="center">
        <Box sx={{ width: '250px' }}>
          <Stack spacing={4} direction="row" alignItems="center">
            <Stack spacing={1} direction="column">
              <Box fontWeight="bold">{formatPosterName(posterName)}</Box>
              {!isDAT ? <Box className={classes.poster}>Poster</Box> : null}
            </Stack>
            <Chip
              label={
                ['REIBUS_MANUAL', 'REIBUS_AUTOMATIC'].includes(
                  capacityIndicatorType
                )
                  ? 'REQ'
                  : capacityIndicatorType
              }
              color="secondary"
            />
          </Stack>
        </Box>
        <Box sx={{ alignContent: 'flex-start' }}>
          <Stack spacing={1} direction="column">
            <Phone phone={callbackPhone} />
            <DispositionEmail
              email={contactEmail}
              carrierName={carrierName ?? 'N/A'}
            />
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
};

export default EquipmentContactInfoRow;
