import { getApiRoot } from 'utils/apiUtils';
import axios from 'axios';

export type FreightQuoteRequest = {
  id?: string;
  refID: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  originSystem: string;
  originAddress: {
    address1: string;
    address2: string;
    city: string;
    region: string;
    postalCode: string;
    country: string;
    location: { lat: number; lon: number };
  };
  destinationAddress: {
    address1: string;
    address2: string;
    city: string;
    region: string;
    postalCode: string;
    country: string;
    location: { lat: number; lon: number };
  };
  equipment: string[];
  commodityType: string;
  commodityDescription?: string;
  weight: number;
  palletNumber?: number;
  shipmentType?: string;
  width?: number;
  lenght?: number;
  height?: number;
  straps?: boolean;
  eTracks?: boolean;
  loadTracks?: boolean;
  loadBars?: boolean;
  excessiveCargoValue?: boolean;
  pickupDate: string;
  additionalInformation?: string;
  priceEstimate: number;
  quickForm?: boolean;
};

export const postFreightQuoteRequestPublic = async (
  data: FreightQuoteRequest
) => {
  const url = `${getApiRoot()}/freight-quotes/request/public`;
  const body = JSON.stringify({
    data,
  });

  return await axios.post(url, body);
};

export const postFreightQuoteRequestPrivate = async (
  authorization: string,
  data: FreightQuoteRequest
) => {
  const url = `${getApiRoot()}/freight-quotes/request/private`;
  const body = JSON.stringify({
    data,
  });
  const config = {
    headers: {
      Authorization: authorization,
    },
  };

  return await axios.post(url, body, config);
};
